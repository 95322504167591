<template>
  <BaseToggle
    v-if="canEnablePreviewMode"
    v-model="previewModeEnabled"
    :label="$t('calendar.preview.toggle_label')"
    ml
    v-test="'preview-mode-toggle'"
  />
</template>

<script setup lang="ts">
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { storeToRefs } from 'pinia';

const { canEnablePreviewMode, previewModeEnabled } = storeToRefs(
  useCalendarPreviewStore()
);
</script>
