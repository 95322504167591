<template>
  <div :class="$style.base">
    <router-view />
    <IplTreatmentModal
      v-if="showTreatmentModal"
      v-model="selectedTreatment"
      @close="showTreatmentModal = false"
      @submitted="onSubmitTreatment"
    />
    <IplReportModal
      v-if="showReportModal"
      :treatmentId="treatmentId"
      :treatmentNumber="selectedReportNumber"
      :report="selectedReport"
      @close="
        () => {
          showReportModal = false;
          selectedReport = null;
          selectedReportNumber = null;
          treatmentId = null;
        }
      "
      @submitted="onSubmitReport"
      @deleted="onDeleteReport"
    />
    <div :class="$style.header">
      <BaseButton
        v-if="!loading"
        :class="$style.button"
        mb
        @click="showTreatmentModal = true"
        v-test="'ipl-treatment-create'"
      >
        {{ $t('customers.new_ipl_treatment') }}
      </BaseButton>
    </div>
    <div v-if="!loading && treatments.length">
      <BaseCard
        v-for="(treatment, index) in treatments"
        :key="index"
        :heading="treatment.description"
        mb
        v-test="'ipl-treatments'"
      >
        <BaseTable
          :headers="[
            $t('global.repitition'),
            $t('global.date'),
            $t('global.amount'),
            $t('customers.power'),
            filters.capitalize($t('global.items.employee', 1)),
            $t('global.items.note', 2)
          ]"
          :rows="
            getReports(treatment).map((report) => ({
              id: report ? report.id : null,
              cells: report
                ? [
                    report.treatment,
                    filters.date(report.date),
                    report.amount,
                    report.power,
                    report.employeeName,
                    report.notes
                  ]
                : [],
              clickable: true
            }))
          "
          @select="onSelectReport($event, treatment)"
          v-test="'ipl-reports'"
        />
        <div :class="$style.buttons">
          <BaseButton
            color="inverted"
            @click="
              () => {
                selectedTreatment = treatment;
                showTreatmentModal = true;
              }
            "
            v-test="'ipl-treatment-edit'"
          >
            {{ $t('global.actions.edit') }}
          </BaseButton>
          <BaseButton
            ml
            color="inverted"
            @click="deleteTreatment(treatment.id)"
            v-test="'ipl-treatment-delete'"
          >
            {{ $t('global.actions.delete') }}
          </BaseButton>
        </div>
      </BaseCard>
    </div>
    <BaseSpinner v-else-if="loading" />
    <EmptyPageContent
      v-else-if="showEmptyPageContent"
      imageName="reports"
      :text="{
        title: $t('customers.empty_state.ipl.title'),
        description: $t('customers.empty_state.ipl.description')
      }"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'IplTreatments'
};
</script>

<script lang="ts" setup>
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import IplTreatmentModal from './IplTreatment.vue';
import IplReportModal from './IplReport.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { ref, inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  GET_IPL_TREATMENTS,
  GET_IPL_REPORTS,
  DELETE_IPL_TREATMENT
} from './graphql';
import type { IplTreatment, IplTreatmentReport } from './types';

const showTreatmentModal = ref(false);
const showReportModal = ref(false);

const selectedTreatment = ref(null);
const treatmentId = ref();
const selectedReport = ref();
const selectedReportNumber = ref();

const customer = inject<any>('customer');

const { loading: loadingTreatments, onResult } = useQuery(GET_IPL_TREATMENTS, {
  customerId: customer.value.id
});

const treatments = ref<IplTreatment[]>([]);
const treatmentReports = ref<IplTreatmentReport[]>([]);
const loadingReports = ref(false);

onResult(
  ({
    data: { iplTreatments }
  }: {
    data: { iplTreatments: IplTreatment[] };
  }) => {
    treatments.value = iplTreatments;
    const ids = iplTreatments.map((t) => t.id);
    loadingReports.value = true;

    const { onResult } = useQuery(GET_IPL_REPORTS, {
      customerId: customer.value.id,
      ids
    });

    onResult(({ data }) => {
      treatmentReports.value = data.iplTreatmentReports || [];
      loadingReports.value = false;
    });
  }
);

const loading = computed(() => loadingTreatments.value || loadingReports.value);
const showEmptyPageContent = computed(
  () => !loading.value && !treatments.value.length
);

const getReports = (treatment: IplTreatment): IplTreatmentReport[] =>
  Array.from(
    { length: treatment.treatments },
    (x, i) =>
      treatmentReports.value.find(
        (report) =>
          report.iplTreatmentId === treatment.id && report.treatment === i + 1
      ) ||
      ({
        id: `empty-${i + 1}`,
        treatment: i + 1
      } as IplTreatmentReport)
  );

const { t } = useI18n();

const deleteTreatment = (id: IplTreatment['id']) => {
  modal('confirmation', {
    message: t('global.confirmation.confirm_delete', {
      item: t('customers.ipl_treatment')
    })
  }).then(() => {
    const { mutate } = useMutation(DELETE_IPL_TREATMENT);

    mutate({
      input: {
        id: parseInt(id)
      }
    }).then(() => {
      treatments.value = treatments.value.filter(
        (treatment) => treatment.id !== id
      );
    });
  });
};

const onSelectReport = (
  reportId: IplTreatmentReport['id'],
  treatment: IplTreatment
) => {
  if (reportId?.includes('empty-')) {
    selectedReportNumber.value = parseInt(reportId.replace('empty-', ''));
  } else if (reportId) {
    selectedReport.value = getReports(treatment).find(
      (report) => report.id === reportId
    );
  }
  treatmentId.value = treatment.id;
  showReportModal.value = true;
};

const onSubmitTreatment = (treatment: IplTreatment) => {
  const existingIndex = treatments.value.findIndex(
    (t) => t.id === treatment.id
  );
  if (existingIndex >= 0) {
    treatments.value[existingIndex] = treatment;
  } else {
    treatments.value.unshift(treatment);
  }
};

const onSubmitReport = (report: IplTreatmentReport) => {
  const existingIndex = treatmentReports.value.findIndex(
    (t) => t.id === report.id
  );
  if (existingIndex >= 0) {
    treatmentReports.value[existingIndex] = report;
  } else {
    treatmentReports.value.unshift(report);
  }
};

const onDeleteReport = (id: IplTreatmentReport['id']) => {
  treatmentReports.value = treatmentReports.value.filter(
    (report) => report.id !== id
  );
};
</script>

<style lang="scss" module>
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing;
}

.header {
  display: flex;
  justify-content: flex-end;
}
</style>
