import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

export type SortOrder = {
  id: string;
  sortOrder: number;
};

const SORT_SERVICE_VARIATION_GROUPS = gql`
  mutation SortServiceVariationGroups(
    $input: SortServiceVariationGroupsInput!
  ) {
    sortServiceVariationGroups(input: $input) {
      serviceVariationGroups {
        id
      }
    }
  }
`;

export const sortServiceVariationGroups = (sortableAttributes: SortOrder[]) => {
  const { mutate } = useMutation(SORT_SERVICE_VARIATION_GROUPS, {
    variables: {
      input: {
        sortableAttributes
      }
    }
  });

  return mutate();
};
