<template>
  <div>
    <router-view />
    <MessageField v-if="hasEnabledServices" v-test="'enabled-services-field'">
      <div :class="$style.messageHeader">
        <BaseText bold>
          {{
            $t(
              'marketing.email.automated_emails.rebook_setup.services_selected'
            )
          }}
        </BaseText>
        <BaseText
          link
          iconAfter="edit"
          :routerLink="{ name: 'rebook-reminder-services' }"
          v-test="'enabled-services-field-edit'"
        >
          {{ $t('global.actions.edit') }}
        </BaseText>
      </div>
      <BaseText :mt="0.5">
        {{
          $t(
            'marketing.email.automated_emails.rebook_setup.services_selected_info'
          )
        }}
      </BaseText>
    </MessageField>
    <BaseButton
      v-else
      color="inverted"
      :routerLink="{ name: 'rebook-reminder-services' }"
      v-test="'open-rebook-setup'"
    >
      {{ $t('marketing.email.automated_emails.rebook_setup.title') }}
    </BaseButton>
  </div>
</template>

<script lang="ts">
import MessageField from './MessageField.vue';
import { useServicesStore } from '@/stores/services';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    MessageField
  },
  computed: {
    hasEnabledServices() {
      const { rebookEnabledServicesCount } = useServicesStore();
      return rebookEnabledServicesCount > 0;
    }
  }
});
</script>

<style lang="scss" module>
.messageHeader {
  display: flex;
  justify-content: space-between;
}
</style>
