<template>
  <svg v-if="isTreatwellUser" width="26" height="26" viewBox="0 0 24 24">
    <g>
      <path
        id="svg_6"
        stroke="null"
        :fill="fill"
        d="m5.05703,14.08833c0,1.48235 0.80391,2.3758 2.13043,2.3758c0.42202,0 0.84414,-0.08121 1.30639,-0.2437l0,0.14212c-0.76376,1.19806 -1.84906,1.86814 -3.21574,1.86814c-1.78877,0 -3.09511,-1.13712 -3.09511,-3.28951l0,-5.68549l-1.72845,0l0,-0.22339l4.36127,-4.00017l0.24121,0l0,9.05621z"
      />
      <path
        id="svg_7"
        stroke="null"
        :fill="fill"
        d="m13.02568,18.16977l1.46714,-3.26921l-3.03484,-7.00539l-5.69753,0l0,1.36044l3.14509,0l3.91916,8.91415l0.20098,0z"
      />
      <path
        id="svg_8"
        stroke="null"
        :fill="fill"
        d="m13.95019,7.89518l3.1353,0l3.03485,7.00538l-1.46721,3.26921l-0.20093,0l-4.50202,-10.27459z"
      />
      <path
        id="svg_9"
        stroke="null"
        :fill="fill"
        d="m21.14532,11.81414c0,-0.97465 -0.4421,-2.92397 -0.9446,-3.91895l3.05493,0l-1.96963,3.91895l-0.1407,0z"
      />
    </g>
  </svg>
  <svg
    v-else
    :class="$style.base"
    width="22"
    height="22"
    viewBox="0 0 118 163"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :fill="fill" fill-rule="evenodd">
      <path
        d="M57.55 117.18A56.37 56.37 0 0115.76 99a60.27 60.27 0 01-15-44.22 57.33 57.33 0 0120.58-40.62A63 63 0 0165.38 1.14 95.82 95.82 0 01112 17.22l1.98 1.32a4.65 4.65 0 011.44 6c-3.54 6.39-6.78 12.51-8.76 16.23a3.75 3.75 0 01-5.31 1.47l-2.31-1.35a111.57 111.57 0 00-24-10.89c-12.39-3.48-27.42-2.22-37.17 7.05a32.55 32.55 0 00-7.8 31.92 27 27 0 009 13.47 35.25 35.25 0 0016.26 6.99c1.65.27 3.33.45 5.01.57a14.25 14.25 0 016.9 1.89 13.59 13.59 0 015.43 6.84A13.29 13.29 0 0167 114.51a15.81 15.81 0 01-9.45 2.67z"
      />
      <path
        d="M61 45.72a56.46 56.46 0 0141.79 18.21 60.39 60.39 0 0115 44.25 57.36 57.36 0 01-20.55 40.62 63.15 63.15 0 01-44.07 13.02 95.73 95.73 0 01-46.71-16.14l-1.95-1.32a4.62 4.62 0 01-1.47-6c3.3-6 6.36-11.67 8.34-15.45a4.62 4.62 0 016.45-1.8l.81.51a112.8 112.8 0 0024.87 11.28c12.39 3.48 27.45 2.22 37.17-7.02a32.55 32.55 0 007.8-31.92 27 27 0 00-8.82-13.47 34.92 34.92 0 00-16.29-6.99 46.86 46.86 0 00-4.98-.57 14.37 14.37 0 01-6.93-1.89 13.74 13.74 0 01-5.43-6.84 13.35 13.35 0 015.67-15.81 15.84 15.84 0 019.3-2.67z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useCompanyStore } from '@/stores/company';

export default defineComponent({
  inheritAttrs: false,
  props: {
    pink: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { isTreatwellUser } = useCompanyStore();
    return {
      isTreatwellUser
    };
  },
  computed: {
    fill() {
      return this.pink ? '#FF6575' : '#FFFFFF';
    }
  }
});
</script>

<style lang="scss" module>
.base {
  max-width: 100%;
  height: auto;
}
</style>
