<template>
  <BaseModalSmall testId="confirmation" @close="onNoClick">
    <BaseText size="l">
      {{ data.message || $t('global.confirmation.confirm') }}
    </BaseText>
    <BaseText v-if="data.subMessage" mt>
      {{ data.subMessage }}
    </BaseText>
    <BaseAlert v-if="data.warning" color="warning" :text="data.warning" mt />
    <template #footer>
      <BaseButton
        color="inverted"
        @click="onNoClick"
        v-test="'_modal-confirmation-no'"
        >{{ $t('global.no') }}</BaseButton
      >
      <BaseButton
        class="ml"
        @click="onYesClick"
        v-test="'_modal-confirmation-yes'"
        >{{ $t('global.yes') }}</BaseButton
      >
    </template>
  </BaseModalSmall>
</template>

<script lang="ts">
import eventBus from '@/event-bus';

import type { PropType } from 'vue';
import { defineComponent } from 'vue';

interface ConfirmationModal {
  message: string;
  subMessage?: string;
  warning?: string;
}

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<ConfirmationModal>,
      required: true
    }
  },
  methods: {
    onNoClick() {
      eventBus.$emit('submit-confirmation-modal', false);
    },
    onYesClick() {
      eventBus.$emit('submit-confirmation-modal', true);
    }
  }
});
</script>
