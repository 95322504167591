<template>
  <div
    :class="[
      $style.base,
      {
        [$style.mediumScreen]: $screen === 's' || $screen === 'm'
      }
    ]"
    v-test="'service-variation-about'"
  >
    <BaseDropdown
      v-model="formData.categoryId"
      :options="
        serviceCategories.map((category) => ({
          value: category.id,
          label: category.name
        }))
      "
      :actions="[
        {
          label: $t('service.new_category'),
          id: 'add-new',
          icon: 'plus',
          color: 'success'
        }
      ]"
      :label="$t('global.items.category', 1)"
      required
      :mb="1.5"
      @action="() => (showCategoryModal = true)"
      v-test="'service-category'"
    />
    <CategoryModal
      v-if="showCategoryModal"
      @create="formData.categoryId = $event"
      @close="showCategoryModal = false"
    />
    <ServiceForm
      v-if="$screen === 's'"
      :hasProcessingTimeOption="!formData.requiresProcessingTime"
      :hasBufferTimeOption="!(formData.buffer || hasBufferTime)"
      @moreOptionsSelect="handleMoreOptionsSelect"
    />
    <BaseCard v-else gray :mb="1.5">
      <ServiceForm
        :hasProcessingTimeOption="!formData.requiresProcessingTime"
        :hasBufferTimeOption="!(formData.buffer || hasBufferTime)"
        @moreOptionsSelect="handleMoreOptionsSelect"
      />
    </BaseCard>
    <BaseCard
      v-if="formData.requiresProcessingTime"
      gray
      :mb="1.5"
      noBorder
      v-test="'service-processing-time-card'"
    >
      <div :class="$style.cardHeader">
        <BaseHeading>{{ $t('service.processing_time.heading') }}</BaseHeading>
        <BaseIcon
          clickable
          name="delete"
          @click="formData.requiresProcessingTime = false"
        />
      </div>
      <BaseText mb>{{ $t('service.processing_time.description') }}</BaseText>
      <div :class="$style.row">
        <BaseInput
          v-model="formData.durationSetup"
          unitLabel="minute"
          type="number"
          :label="$t('service.duration_processing.setup')"
          :required="formData.requiresProcessingTime"
          :minValue="formData.requiresProcessingTime ? 1 : 0"
          :maxValue="maxDurationProcessing"
          :mb="$screen === 's'"
          v-test="'service-duration-setup'"
        />
        <BaseInput
          v-model="formData.durationProcessing"
          :label="$t('service.duration_processing.processing')"
          :minValue="0"
          :maxValue="maxDurationProcessing"
          unitLabel="minute"
          type="number"
          :mb="$screen === 's'"
          :info="$t('service.duration_processing.description')"
          v-test="'service-duration-processing'"
        />
        <BaseInput
          v-model="formData.durationFinish"
          unitLabel="minute"
          type="number"
          :label="$t('service.duration_processing.finish')"
          :minValue="0"
          :maxValue="maxDurationProcessing"
          v-test="'service-duration-finish'"
        />
      </div>
    </BaseCard>
    <BaseCard
      v-if="formData.buffer || hasBufferTime"
      gray
      :mb="1.5"
      v-test="'service-buffer-time-card'"
    >
      <div :class="$style.cardHeader">
        <BaseHeading>{{ $t('service.buffer_time.heading') }}</BaseHeading>
        <BaseIcon clickable name="delete" @click="removeBufferTime" />
      </div>
      <BaseText mb>{{ $t('service.buffer_time.description') }}</BaseText>
      <BaseInput
        v-model="formData.buffer"
        :label="$t('service.buffer_time.heading')"
        :minValue="0"
        :maxValue="maxDuration"
        unitLabel="minute"
        type="number"
        :info="$t('service.buffer_time.info')"
        v-test="'service-buffer'"
      />
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ServiceModalGeneralView'
});
</script>

<script setup lang="ts">
import type { ServiceFormData } from '../types';

import { useServicesStore } from '@/stores/services';
import CategoryModal from '@/modules/admin/services/Category.vue';

import ServiceForm from './ServiceForm.vue';
import { storeToRefs } from 'pinia';

const formData = inject<ServiceFormData>('serviceFormData')!;

const showCategoryModal = ref(false);
const hasBufferTime = ref(false);

const { serviceCategories } = storeToRefs(useServicesStore());

const maxDuration = 1439;
const maxDurationProcessing = Math.floor(maxDuration / 3);

watchEffect(() => {
  if (formData?.requiresProcessingTime) {
    formData.duration =
      formData.durationSetup +
      formData.durationProcessing +
      formData.durationFinish;
  }
});

const removeBufferTime = () => {
  formData.buffer = 0;
  hasBufferTime.value = false;
};

const handleMoreOptionsSelect = (value: 'processingTime' | 'bufferTime') => {
  if (value === 'processingTime') {
    formData.requiresProcessingTime = true;
  }

  if (value === 'bufferTime') {
    hasBufferTime.value = true;
  }
};
</script>

<style module lang="scss">
.row {
  display: flex;
  justify-content: space-between;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: $spacing;
}

.moreOptions {
  position: relative;
}
</style>
