import gql from 'graphql-tag';
import apolloClient from '@/apollo/client';
import { modal, flash } from '@/helpers/ui';
import i18n from '@/i18n';
import type {
  WaitingListEntry,
  CreateWaitingListEntryInput,
  DeleteWaitingListEntryInput,
  SendAvailableTimeslotEmailInput,
  SendAvailableTimeslotEmailAllInput,
  QueryWaitingListEntriesArgs
} from '@/types';
import { entryFragment } from './graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import router from '@/router';
import { formatDate, formatWeekDay } from '@/helpers/dates';
import { capitalize } from '@/helpers/formatting';
import { usePreCreateStore, Action } from '@/stores/calendar-pre-create';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useLocationsStore } from '@/stores/locations';
import calendarInterface from '@/modules/calendar/calendar-interface';

const afterUpdate = () => {
  // When the waiting list has been updated, either by adding or deleting an entry, we need to refetch the waiting list items in the calendar
  if (calendarInterface.api) {
    calendarInterface.api.refetchEvents();
  }
};

export const createEntry = (input: CreateWaitingListEntryInput) =>
  new Promise<void>((resolve) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation createWaitingListEntry(
            $input: CreateWaitingListEntryInput!
          ) {
            createWaitingListEntry(input: $input) {
              waitingListEntry {
                ...waitingListEntry
              }
            }
          }
          ${entryFragment}
        `,
        variables: {
          input
        },
        update(cache) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'waitingListEntries' });
          cache.gc();
        }
      })
      .then(
        ({
          data: {
            createWaitingListEntry: { waitingListEntry }
          }
        }) => {
          if (waitingListEntry) {
            flash(i18n.t('global.flash.waiting_list_entry_created'));
            resolve();
            afterUpdate();
          }
        }
      );
  });

export const deleteEntry = (id: DeleteWaitingListEntryInput['id']) =>
  new Promise<void>((resolve) => {
    modal('confirmation', {
      message: i18n.t('global.confirmation.confirm_delete', {
        item: i18n.t('global.items.waiting_list_registration', 1)
      })
    }).then(() => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation deleteWaitingListEntry(
              $input: DeleteWaitingListEntryInput!
            ) {
              deleteWaitingListEntry(input: $input) {
                waitingListEntry {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id
            }
          },
          update(cache) {
            const cacheId = `WaitingListEntry:${id}`;
            cache.evict({ id: cacheId, broadcast: false });
            cache.gc();
          }
        })
        .then(() => {
          flash(i18n.t('global.flash.waiting_list_entry_deleted'));
          resolve();
          afterUpdate();
        });
    });
  });

export const afterAppointmentCreate = (
  id: DeleteWaitingListEntryInput['id']
) => {
  apolloClient.mutate({
    mutation: gql`
      mutation deleteWaitingListEntry($input: DeleteWaitingListEntryInput!) {
        deleteWaitingListEntry(input: $input) {
          waitingListEntry {
            id
          }
        }
      }
    `,
    variables: {
      input: {
        id
      }
    },
    update(cache) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'waitingListEntries' });
      cache.gc();
    }
  });
};

export const emailCustomer = (
  waitingListEntryId: SendAvailableTimeslotEmailInput['waitingListEntryId'],
  date: SendAvailableTimeslotEmailInput['date']
) => {
  if (date) {
    modal('confirmation', {
      message: i18n.t('global.confirmation.confirm_email')
    }).then(() => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation sendAvailableTimeslotEmail(
              $input: SendAvailableTimeslotEmailInput!
            ) {
              sendAvailableTimeslotEmail(input: $input) {
                status
              }
            }
          `,
          variables: {
            input: {
              waitingListEntryId,
              date
            }
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'waitingListEntries' });
            cache.gc();
          }
        })
        .then(() => {
          flash(i18n.t('global.flash.email_sent'));
        });
    });
  } else {
    modal('warning', {
      message: i18n.t('waiting_list.choose_a_date')
    });
  }
};

export const emailCustomerAll = (
  date: SendAvailableTimeslotEmailAllInput['date'],
  ids: SendAvailableTimeslotEmailAllInput['ids']
) => {
  modal('confirmation', {
    message: i18n.t('global.confirmation.confirm_email_all', {
      count: ids?.length || 0
    })
  }).then(() => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation sendAvailableTimeslotEmailAll(
            $input: SendAvailableTimeslotEmailAllInput!
          ) {
            sendAvailableTimeslotEmailAll(input: $input) {
              status
            }
          }
        `,
        variables: {
          input: {
            date,
            ids
          }
        },
        update(cache) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'waitingListEntries' });
          cache.gc();
        }
      })
      .then(() => {
        flash(i18n.t('global.flash.email_sent'));
      });
  });
};

export const viewCustomer = (customerId: number | string) => {
  router.push({ name: 'customer-overview', params: { customerId } });
};

export const createAppointment = (entry: WaitingListEntry) => {
  const { setState } = usePreCreateStore();

  setState({
    action: Action.Create,
    customer: entry.customer,
    serviceIds: entry.services.map((service) => service.id)
  });

  router.push({ name: 'calendar' });

  if (entry.resource) {
    const store = useCalendarFiltersStore();
    store.resourceId = entry.resource.id;
  }
};

export const useEntriesQuery = (date: QueryWaitingListEntriesArgs) => {
  const { locationId, dataScope } = useLocationsStore();

  const { result, loading } = useQuery(
    gql`
      query getWaitingListEntries(
        $date: ISO8601Date
        $locationId: ID
        $dataScope: DataScope
      ) {
        waitingListEntries(
          date: $date
          locationId: $locationId
          dataScope: $dataScope
        ) {
          ...waitingListEntry
        }
      }
      ${entryFragment}
    `,
    { date, locationId, dataScope },
    { fetchPolicy: 'cache-and-network' }
  );

  return {
    entries: computed(() => result.value?.waitingListEntries || []),
    isLoading: loading
  };
};

export const serviceNames = (entry: WaitingListEntry): string =>
  entry.services.map((service) => service.name).join(', ');

export const servicesDuration = (entry: WaitingListEntry): number =>
  entry.services.reduce(
    (total, service) =>
      total +
      (!service.requiresProcessingTime
        ? service.duration
        : (service.durationFinish || 0) +
          (service.durationProcessing || 0) +
          (service.durationSetup || 0)),
    0
  );

export const entryDate = (entry: WaitingListEntry, long: boolean): string => {
  if (entry.date) {
    return formatDate(entry.date);
  } else if (!entry.daysOfWeek) {
    // TODO: Remove when type is made non-nullable from schema (then this function can be one line)
    return '';
  } else {
    return entry.daysOfWeek
      .map((day) => formatWeekDay(day, long ? 'weekdayLong' : 'weekdayShort'))
      .join(', ');
  }
};

export const entryTimes = (entry: WaitingListEntry): string =>
  entry.partsOfDay?.length
    ? entry.partsOfDay
        .map((part) =>
          capitalize(i18n.t(`global.items.${part.toLowerCase()}`, 2))
        )
        .join(', ')
    : '';
