<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-products')"
    :text="{
      title: $t('upgrade_subscription_page.products.all.title'),
      description: $t('upgrade_subscription_page.products.all.description'),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.products.all.learn_more'),
      url: $t('upgrade_subscription_page.products.all.learn_more_url')
    }"
    imageName="products"
  />
  <ModuleBase v-else noBorder>
    <ImportDetails
      v-if="selectedImport"
      :selectedImport="selectedImport"
      :saving="saving"
      @close="showImportsList"
      @openConfirmationModal="openConfirmationModal"
    />
    <div v-else>
      <div :class="$style.header">
        <BaseDatePicker
          v-model="variables.date"
          mr
          :label="$t('global.actions.filter_item', { item: $t('global.date') })"
          v-test="'imports-date'"
        />
        <BaseButton
          :routerLink="{ name: 'import-products' }"
          @click="
            mixpanel.track('Import - Products', { from: 'product-imports' })
          "
        >
          {{ $t('global.actions.import') }}
        </BaseButton>
      </div>
      <BaseSpinner v-if="loading && !firstFetchFinished" />
      <div v-else>
        <BaseTable
          v-if="filteredImports && filteredImports.length"
          :headers="[
            $t('global.date'),
            $t('global.items.product', 1),
            $t('global.state')
          ]"
          :rows="
            filteredImports.map((item) => ({
              id: item.id,
              cells: [
                formatDateTime(item.createdAt),
                item.productsCount,
                item.state
              ],
              actions: setOptions(item),
              disabled: isDeleted(item),
              clickable: !isDeleted(item)
            }))
          "
          @action="onOptionClick"
          @select="onSelect"
          v-test="'imports-table'"
        />
        <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
      </div>
      <div
        v-if="!loading && allDataFetched && !filteredImports.length"
        :class="$style.noResults"
      >
        {{ $t('global.no_results') }}
      </div>
    </div>
  </ModuleBase>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import gql from 'graphql-tag';
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import { importFragment } from '@/graphql-fragments';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useUserStore } from '@/stores/user';
import ImportDetails from './ImportDetails.vue';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';

export default defineComponent({
  components: {
    ImportDetails,
    ModuleBase,
    EmptySubscriptionPage,
    LoadMore
  },
  inject: ['mixpanel'],
  setup() {
    const {
      imports,
      allDataFetched,
      firstFetchFinished,
      loading,
      variables,
      fetchMore
    } = usePagination({
      fieldName: 'imports',
      query: gql`
        query getImports(
          $type: String!
          $pagination: PaginationAttributes
          $date: ISO8601Date
        ) {
          imports(type: $type, pagination: $pagination, date: $date) {
            ...import
          }
        }
        ${importFragment}
      `,
      variables: {
        type: 'products',
        date: null
      }
    });

    return {
      imports,
      allDataFetched,
      loading,
      variables,
      filters,
      firstFetchFinished,
      fetchMore
    };
  },
  data() {
    return {
      selectedImport: null,
      saving: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    filteredImports() {
      return this.imports?.filter((i) => i.productsCount > 0);
    }
  },
  methods: {
    formatDateTime(date) {
      return this.filters.dateTime(date);
    },
    isDeleted(item) {
      return item.state === 'deleted';
    },
    setOptions(item) {
      const options = [];
      if (this.isDeleted(item)) {
        options.push('restore');
      } else {
        options.push('delete');
      }
      return options;
    },
    onSelect(id) {
      this.selectedImport = this.filteredImports.find((item) => item.id === id);
    },
    onOptionClick({ id, option }) {
      const item = this.filteredImports.find((item) => item.id === id);

      if (option === 'view') {
        this.selectedImport = item;
      }
      if (option === 'delete' || option === 'restore') {
        this.openConfirmationModal(item, this.isDeleted(item));
      }
    },
    openConfirmationModal(item, isDeleted) {
      const message = this.$t(
        `global.confirmation.${isDeleted ? 'confirm_restore' : 'confirm_delete'}`,
        { item: this.$t('global.items.product', 2) }
      );

      modal('confirmation', { message }).then(() => {
        if (isDeleted) {
          this.restoreProducts(item.id);
        } else {
          this.deleteProducts(item.id);
        }
      });
    },
    deleteProducts(importId) {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteImportedProducts(
              $input: DeleteImportedProductsInput!
            ) {
              deleteImportedProducts(input: $input) {
                import {
                  ...import
                }
              }
            }
            ${importFragment}
          `,
          variables: {
            input: {
              importId
            }
          }
        })
        .then((data) => {
          const productsCount =
            data.data.deleteImportedProducts.import.productsCount;
          flash(
            this.$t('global.flash.item_deleted', {
              item: `${productsCount} ${this.$t('global.items.product', 2)}`
            })
          );
          this.showImportsList();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    restoreProducts(importId) {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation restoreImportedProducts(
              $input: RestoreImportedProductsInput!
            ) {
              restoreImportedProducts(input: $input) {
                import {
                  id
                  productsCount
                  createdAt
                  state
                }
              }
            }
          `,
          variables: {
            input: {
              importId
            }
          }
        })
        .then((data) => {
          const productsCount =
            data.data.restoreImportedProducts.import.productsCount;
          flash(
            this.$t('global.flash.item_restored', {
              item: `${productsCount} ${this.$t('global.items.product', 2)}`
            })
          );
          this.showImportsList();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    showImportsList() {
      this.selectedImport = null;
    }
  }
});
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.noResults {
  padding: $spacing;
  text-align: center;
  margin-top: $spacing;
}
</style>
