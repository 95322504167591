import { useMutation } from '@vue/apollo-composable';
import {
  DELETE_SERVICE_VARIATION_GROUP,
  DUPLICATE_SERVICE_VARIATION_GROUP
} from './graphql';

export const deleteServiceVariationGroup = (id: number) => {
  const { mutate } = useMutation(DELETE_SERVICE_VARIATION_GROUP, {
    variables: {
      input: {
        id
      }
    }
  });

  return mutate();
};

export const duplicateServiceVariationGroup = (id: number) => {
  const { mutate } = useMutation(DUPLICATE_SERVICE_VARIATION_GROUP, {
    variables: {
      input: {
        id
      }
    }
  });

  return mutate();
};
