<template>
  <BaseCard mb :loading="loading" v-test="`invoices-card`">
    <div :class="$style.header">
      <div>
        <BaseHeading v-if="title">{{ title }}</BaseHeading>
        <BaseText v-if="description" :mt="0.25">{{ description }}</BaseText>
      </div>
      <BaseIcon v-if="hasFailedInvoice" name="alert" color="warning" size="l" />
    </div>
    <div>
      <div
        v-for="invoice in invoices"
        :key="invoice.id"
        :class="$style.invoice"
      >
        <div>
          <BaseText>{{ filters.date(invoice.sealedAt) }}</BaseText>
          <BaseText v-test="`invoice-${invoice.id}-amount`">{{
            filters.currency(invoice.total || 0)
          }}</BaseText>
        </div>
        <BaseLabel :ml="1.5" :state="invoice.realStatus.toLowerCase()">{{
          invoice.realStatus
        }}</BaseLabel>
        <BaseText :ml="1.5">{{ invoice.invoiceNumber }}</BaseText>
        <div :class="$style.actionsContainer">
          <PayInvoiceButton
            v-if="invoice.realStatus !== InvoiceStatus.Paid"
            :stripeInvoice="invoice"
          />
          <BaseButton
            color="inverted"
            icon="download"
            @click="emit('downloadInvoice', invoice.id)"
            v-test="`invoices-card-invoice-${invoice.id}-download-action`"
          />
        </div>
      </div>
      <BaseText
        v-if="allInvoicesLink"
        mt
        link
        @click="emit('allInvoicesLinkClick')"
        v-test="`invoices-card-all-invoices-link`"
        >{{ $t('admin.cards.invoices.see_all_inovices_link_label') }}</BaseText
      >
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { InvoiceStatus, type StripeInvoice } from '@/types';
import filters from '@/filters';
import { computed } from 'vue';
import PayInvoiceButton from './PayInvoiceButton.vue';

const props = defineProps<{
  invoices: StripeInvoice[];
  loading?: boolean;
  title?: string;
  description?: string;
  allInvoicesLink?: boolean;
}>();

const emit = defineEmits(['allInvoicesLinkClick', 'downloadInvoice']);

const hasFailedInvoice = computed(() =>
  props.invoices.some(
    (invoice: StripeInvoice) => invoice.realStatus === InvoiceStatus.Failed
  )
);
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing;
}

.invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: $spacing;
  margin-top: $spacing;
  border-top: 1px solid $color-border;
}

.actionsContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
</style>
