import type { ServiceCategory, ServiceVariationGroup } from '@/types';
import type { SortOrder } from './sort-service-variation-groups';

import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

import { useQuery } from '@vue/apollo-composable';
import { useServicesStore } from '@/stores/services';

import { flash } from '@/helpers/ui';

import { GET_SERVICE_VARIATION_GROUPS } from './graphql';
import { sortServiceVariationGroups } from './sort-service-variation-groups';

export const useServiceGroups = () => {
  const { t } = useI18n();
  const serviceGroups = ref<ServiceVariationGroup[]>([]);

  const { serviceCategories } = storeToRefs(useServicesStore());

  const {
    onResult,
    loading: getServiceGroupsLoading,
    refetch,
    loading
  } = useQuery(GET_SERVICE_VARIATION_GROUPS);

  onResult(({ data: { serviceVariationGroups } }) => {
    serviceGroups.value = serviceVariationGroups;
  });

  const categoriesWithServices = computed(() =>
    serviceCategories.value.map((category: ServiceCategory) => ({
      ...category,
      services: serviceGroups.value.filter(
        (serviceGroup) => serviceGroup?.category?.id === category.id
      )
    }))
  );

  const sortServiceGroups = (items: SortOrder[]) => {
    serviceGroups.value.forEach((serviceGroup, index) => {
      items.forEach(({ id, sortOrder }) => {
        if (serviceGroup.id === id) {
          serviceGroups.value[index].sortOrder = sortOrder;
        }
      });
    });

    serviceGroups.value = serviceGroups.value.sort(
      (a, b) => a.sortOrder - b.sortOrder
    );

    sortServiceVariationGroups(items).then(() => {
      flash(t('global.flash.services_updated'));
    });
  };

  const appendServiceGroup = (serviceGroup: ServiceVariationGroup) => {
    serviceGroups.value = [...serviceGroups.value, serviceGroup].sort(
      (a, b) => a.sortOrder - b.sortOrder
    );
  };

  return {
    serviceGroups,
    getServiceGroupsLoading,
    categoriesWithServices,
    refetch,
    sortServiceGroups,
    appendServiceGroup,
    loading
  };
};
