<template>
  <div :class="$style.base">
    <Action
      v-for="(action, index) in actions"
      :key="index"
      :action="action"
      :tooltip="
        action.disabled && action.disabledTooltip
          ? {
              text: action.disabledTooltip,
              touch: true
            }
          : null
      "
      @click="$emit('select', action.name)"
    />
  </div>
</template>

<script lang="ts">
import Action from './Action.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalActions',
  components: {
    Action
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => [],
      validator: (value) =>
        !value.length || !value.find((item) => !item.name || !item.label)
    }
  },
  emits: ['select']
});
</script>

<style lang="scss" module>
.base {
  border-bottom: 1px solid $color-border;

  &:not(:first-child) {
    border-top: 1px solid $color-border;
  }

  & > * {
    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
  }
}
</style>
