<template>
  <div :class="$style.base">
    <BaseHeading>
      {{ filters.capitalize($t('global.items.item', 2)) }}
    </BaseHeading>
    <OrderItem v-for="item in order.items" :key="item._uid" :item="item" />
    <div :class="$style.total" v-test="'register-payment-total-price'">
      {{ `${$t('global.total')}: ${filters.currency(totalPrice)}` }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import OrderItem from './OrderItem.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';

const { order, totalPrice } = storeToRefs(useRegisterOrderStore());
</script>

<style lang="scss" module>
.item {
  padding: $spacing;
  display: flex;

  border-bottom: 1px solid $color-border;
}

.number {
  min-width: 35px;
}

.name {
  flex: 1;
}

.total {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  background-color: $grey-light;
  padding: $spacing;

  border-bottom: 1px solid $color-border;
}
</style>
