<template>
  <div :class="[$style.base, { [$style.isSmall]: $screen === 's' }]">
    <div :class="$style.nonPrintable">
      <BaseGrid container>
        <BaseGrid :size="$screen === 's' ? 12 : 9" v-test="'dateSelect'">
          <DateSelect
            :label="$t('global.actions.period')"
            @select="onDateSelect"
          />
        </BaseGrid>
        <BaseGrid v-if="$screen !== 's'" alignCenter alignRight :size="3">
          <BaseButton @click="onPrint" v-test="'printButton'">
            {{ $t('global.print') }}
          </BaseButton>
        </BaseGrid>
      </BaseGrid>
    </div>
    <div :class="[$style.upperWrapper, $style.nonPrintable]">
      <BaseSpinner v-show="$apollo.loading" />
      <div v-if="!$apollo.loading" :class="$style.upper">
        <OverviewCard
          icon-name="credit-card"
          title="invoices"
          :value="overviewOrders"
          v-test="'invoicesCard'"
        />
        <OverviewCard
          icon-name="calendar"
          title="appointments"
          :value="overviewAppointments"
          v-test="'appointmentsCard'"
        />
        <OverviewCard
          icon-name="person"
          title="customers"
          :value="overviewCustomers"
          v-test="'customersCard'"
        />
      </div>
    </div>
    <DateHeader :start="startDate" :end="endDate" />
    <div v-show="!$apollo.loading" :class="$style.double">
      <div :class="$style.card">
        <BaseCard
          :heading="$t('reports.totals.totals')"
          mt
          v-test="'totalsTable'"
        >
          <BaseTable
            :rows="
              totals.map((method: any) => ({
                cells: [
                  $t(`reports.totals.${method.key}`),
                  {
                    value: filters.currency(method.value),
                    alignRight: true
                  }
                ]
              }))
            "
          />
        </BaseCard>
      </div>
      <div :class="$style.card">
        <PaymentMethods v-bind="{ paymentMethods, startDate, endDate }" />
      </div>
    </div>
    <div v-if="!$apollo.loading" :class="$style.card">
      <BaseCard
        :heading="$t('reports.totals.vat_rates')"
        :headerMargin="false"
        mt
        v-test="'vatRatesTable'"
      >
        <BaseTableRow :class="$style.customRow">
          <BaseTableCell>
            {{ $t('reports.totals.vat') }}
          </BaseTableCell>
          <BaseTableCell v-if="totalsReport.totals" currency>
            {{ filters.currency(totalsReport.totals.vat) }}
          </BaseTableCell>
        </BaseTableRow>
        <VatTableRows
          v-for="(rate, index) in totalsReport.vatRates"
          :key="index"
          :vat-rate="rate"
        />
      </BaseCard>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import OverviewCard from './OverviewCard.vue';
import VatTableRows from './VatTableRows.vue';
import DateSelect from '../components/DateSelect.vue';
import DateHeader from '../components/DateHeader.vue';
import { underscore } from '@/helpers/formatting';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';
import unleash from '@/unleash';
import PaymentMethods from './PaymentMethods.vue';
import { useQuery } from '@vue/apollo-composable';

export default defineComponent({
  components: {
    OverviewCard,
    VatTableRows,
    DateSelect,
    DateHeader,
    BaseTableRow,
    BaseTableCell,
    PaymentMethods
  },
  setup() {
    const { result } = useQuery(gql`
      query posTerminals {
        posTerminals {
          id
        }
      }
    `);

    const hasSalonizedPay = computed(
      () =>
        unleash.isEnabled('SalonizedPay') &&
        !!result.value?.posTerminals?.length
    );
    provide('hasSalonizedPay', hasSalonizedPay);

    return {
      hasSalonizedPay,
      filters
    };
  },
  data() {
    return {
      totalsReport: {
        overview: {},
        paymentMethods: {},
        totals: {},
        vatRates: []
      },
      totals: [],
      paymentMethods: [],
      startDate: dayjs().startOf('day').format(),
      endDate: dayjs().endOf('day').format()
    };
  },
  apollo: {
    totalsReport: {
      query: gql`
        query totalsReport(
          $startDate: DateTime
          $endDate: DateTime
          $locationId: ID
          $dataScope: DataScope
        ) {
          totalsReport(
            startDate: $startDate
            endDate: $endDate
            locationId: $locationId
            dataScope: $dataScope
          ) {
            overview {
              orders
              appointments
              customers
            }
            totals {
              revenue
              revenueIncl
              revenueProducts
              revenueServices
              revenueTreatwellServices
              giftcardsSold
              prepaidCardsSold
              discount
              drawerTransactions
              vat
            }
            paymentMethods {
              pin
              cash
              creditcard
              bank
              online
              coupon
              giftcard
              prepaidCard
              outstanding
              treatwellOnlinePayment
              pos
              other
            }
            vatRates {
              percentage
              revenue
              vat
            }
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          locationId: this.locationId,
          dataScope: this.dataScope
        };
      },
      result() {
        this.setTotals();
        setTimeout(() => {
          this.setPaymentMethods();
        }, 0);
      }
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    overviewOrders() {
      return this.totalsReport?.overview?.orders;
    },
    overviewAppointments() {
      return this.totalsReport?.overview?.appointments;
    },
    overviewCustomers() {
      return this.totalsReport?.overview?.customers;
    },
    hasTreatwellVenue() {
      return !!this.company.treatwellVenue;
    },
    hasOtherPaymentMethod() {
      return unleash.isEnabled('WithOtherPaymentMethod');
    }
  },
  methods: {
    setTotals() {
      const keys = [
        'revenue',
        'revenueIncl',
        'revenueProducts',
        'revenueServices',
        ...(this.hasTreatwellVenue ? ['revenueTreatwellServices'] : []),
        'giftcardsSold',
        'prepaidCardsSold',
        'discount',
        'drawerTransactions'
      ];
      return (this.totals = keys.map((key) => ({
        key: underscore(key),
        value: this.totalsReport?.totals?.[key] || 0
      })));
    },
    setPaymentMethods() {
      const keys = [
        'pin',
        'cash',
        'creditcard',
        'bank',
        'online',
        'coupon',
        'giftcard',
        'prepaidCard',
        ...(this.hasTreatwellVenue ? ['treatwellOnlinePayment'] : []),
        ...(this.hasSalonizedPay ? ['pos'] : []),
        ...(this.hasOtherPaymentMethod ? ['other'] : []),
        'outstanding'
      ];
      return (this.paymentMethods = keys.map((key) => ({
        key: underscore(key),
        value: this.totalsReport?.paymentMethods?.[key] || 0
      })));
    },

    onDateSelect(dates) {
      this.startDate = dates.startDate;
      this.endDate = dates.endDate;
    },
    onPrint() {
      window.print();
    }
  }
});
</script>
<style>
@media print {
  .main-wrapper {
    padding-top: 0 !important;
  }
}
</style>
<style lang="scss" module>
@include printed-table;

.base {
  min-height: 100%;
}

.inner {
  width: 100%;
}

.card {
  flex: 1;
  margin: $spacing * 0 $spacing * 0.5;
  padding-bottom: $spacing * 0.5;
}

.double {
  margin: 0 $spacing * -0.5;
  display: flex;
  .isSmall & {
    flex-direction: column;
  }
}

.upperWrapper {
  position: relative;
  margin: $spacing * 0.5 $spacing * -0.5 0;
}

.upper {
  display: flex;
  justify-content: space-between;
  .isSmall & {
    flex-direction: column;
  }
}

.under {
  margin: 0 $spacing * 0.5 0;
  padding-bottom: $spacing * 0.5;
}

.select {
  width: 200px;
  margin-right: $spacing;
}

@media print {
  .nonPrintable {
    display: none;
  }
  .card {
    padding: 0 !important;
  }
}
</style>
