<template>
  <div>
    <BaseInputLabel :text="$t('giftcard_widget.settings.amounts.heading')" />
    <div :class="$style.amounts">
      <div
        v-for="(amount, index) in formData.settings.sales.giftcardValues"
        :key="`amount-${index}`"
        :class="$style.amount"
      >
        <BaseInput
          v-model="formData.settings.sales.giftcardValues[index]"
          type="currency"
          v-test="'giftcard-setting-amount'"
        />
        <BaseIcon
          v-if="formData.settings.sales.giftcardValues.length > 1"
          name="close"
          :ml="0.25"
          clickable
          @click="formData.settings.sales.giftcardValues.splice(index, 1)"
          v-test="'giftcard-setting-amount-remove'"
        />
      </div>
    </div>
    <BaseText
      iconBefore="plus"
      link
      inline
      mt
      @click="formData.settings.sales.giftcardValues.push(0)"
      v-test="'giftcard-setting-amount-add'"
    >
      {{ $t('giftcard_widget.settings.amounts.add') }}
    </BaseText>
  </div>
</template>

<script setup lang="ts">
const formData = inject<any>('giftcardSettingsData');
</script>

<style lang="scss" module>
.amounts {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
}

.amount {
  display: flex;
  align-items: center;
}
</style>
