<template>
  <div :class="$style.base">
    <BaseSearch
      v-model="searchQuery"
      :placeholder="$t('register.search_items')"
      focus
      debounce
      v-test="'register-browser-search'"
    />
    <SearchResults
      v-if="searchQuery"
      :searchQuery="searchQuery"
      :category="selected"
      @close="searchQuery = ''"
    />
    <Barcode v-else-if="selected === 'barcode'" @close="selected = null" />
    <Services v-else-if="selected === 'services'" @close="selected = null" />
    <Products v-else-if="selected === 'products'" @close="selected = null" />
    <Appointments
      v-else-if="selected === 'appointments'"
      @close="selected = null"
    />
    <Default v-else @select="selected = $event as BrowserSections | null" />
    <MobileControls v-if="$screen === 's'" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'RegisterBrowser'
};
</script>

<script setup lang="ts">
import { ref } from 'vue';
import Default from './lists/Default.vue';
import Services from './lists/Services.vue';
import Products from './lists/products/index.vue';
import Appointments from './lists/Appointments.vue';
import Barcode from './lists/Barcode.vue';
import SearchResults from './lists/SearchResults.vue';
import MobileControls from './MobileControls.vue';

type BrowserSections =
  | 'barcode'
  | 'services'
  | 'products'
  | 'giftcard'
  | 'prepaidcard'
  | 'appointments'
  | 'note'
  | 'favorites';

const selected = ref<BrowserSections | null>(null);
const searchQuery = ref('');
</script>

<style lang="scss" module>
.base {
  height: 100%;
  overflow-y: auto;
  padding: $spacing;
}
</style>
