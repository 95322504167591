<template>
  <BaseCard :heading="$t('customers.tags.heading')" label="new">
    <div :class="$style.tags">
      <BaseChip
        v-for="(tag, index) in sortedTags"
        :key="index"
        :text="tag.name"
        clickable
        icon="plus"
        :state="modelValue.includes(tag.id) ? 'selected' : undefined"
        @click="toggleTag(tag)"
        v-test="'tag'"
      />
    </div>
    <div>
      <BaseText inline>{{ $t('customers.tags.manage_tags') }}</BaseText>
      <BaseText
        :ml="0.25"
        inline
        :routerLink="{
          name: 'admin-customers'
        }"
      >
        {{ $t('customers.customer_settings') }}
      </BaseText>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { useQuery, useMutation } from '@vue/apollo-composable';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { ASSIGN_CUSTOMER_TAGS, GET_CUSTOMER_TAGS } from './graphql';

const mixpanel = inject<any>('mixpanel');

type Tag = {
  id: number;
  name: string;
  salonized: boolean;
};

const props = defineProps<{
  modelValue: Tag['id'][];
  saveImmediately?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const customer = inject<any>('customer');
const tags = ref<Tag[]>([]);

const { onResult } = useQuery(GET_CUSTOMER_TAGS, {
  fetchPolicy: 'cache-first'
});

onResult(({ data }) => {
  if (data.customerTags) {
    tags.value = [
      ...data.customerTags.salonizedTags,
      ...data.customerTags.companyTags
    ];
  }
});

const { t } = useI18n();

const sortedTags = computed(() =>
  tags.value.sort((a) => (props.modelValue.includes(a.id) ? 1 : 0))
);

const toggleTag = (tag: Tag) => {
  let newTags: Tag['id'][] = [];

  if (props.modelValue.includes(tag.id)) {
    mixpanel.track('customer_label_removed', { tag });
    newTags = props.modelValue.filter((id) => id !== tag.id);
  } else {
    mixpanel.track('customer_label_added', { tag });
    newTags = [...props.modelValue, tag.id];
  }

  emit('update:modelValue', newTags);

  if (props.saveImmediately) {
    updateTags(newTags);
  }
};

const updateTags = (tagIds: Tag['id'][]) => {
  const { mutate } = useMutation(ASSIGN_CUSTOMER_TAGS);

  mutate({
    input: {
      customerId: customer.value?.id || customer.id,
      tagIds
    }
  }).then((result) => {
    if (result && result.data) {
      const {
        assignCustomerTags: { customerTags }
      } = result.data;
      customer.value.customerTags = customerTags;
      flash(t('global.flash.customer_updated'));
    }
  });
};
</script>

<style lang="scss" module>
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 0.5;
  margin-bottom: $spacing;
}
</style>
