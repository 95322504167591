<template>
  <ModuleBase fullHeight noPadding>
    <EmberWrapper />
  </ModuleBase>
</template>

<script lang="ts">
import EmberWrapper from '@/modules/EmberWrapper.vue';
import ModuleBase from '@/modules/ModuleBase.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    EmberWrapper,
    ModuleBase
  }
});
</script>
