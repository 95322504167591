<template>
  <div :class="$style.base">
    <div
      v-if="!order.items.length && !order.note && !orderAppointments.length"
      :class="$style.noItems"
    >
      <NoItems v-test="'register-order-no-items'" />
    </div>
    <div v-else :class="$style.list">
      <BaseAlert
        v-if="totalTwPayments > 0"
        color="warning"
        :text="
          $t('register.tw_payment_info', {
            amount: filters.currency(totalTwPayments)
          })
        "
        :mb="0.5"
        :mr="0.5"
        v-test="'register-order-tw-payment-alert'"
      />
      <BaseAlert
        v-if="serviceItemMismatch"
        :text="$t('register.service_item_mismatch')"
        :mb="0.5"
        :mr="0.5"
        v-test="'register-order-service-item-mismatch'"
      />
      <BaseAlert
        v-for="(discountCode, index) in discountCodes"
        :key="index"
        :text="
          $t('appointment.discount_code_used', {
            code: discountCode?.code,
            percentage: discountCode?.discountPercentage
          })
        "
        :mb="0.5"
        :mr="0.5"
        v-test="'register-order-discount-alert'"
      />

      <div
        v-for="(item, index) in order.items"
        :key="`${item._uid}-${item.name}`"
        :class="$style.listItem"
      >
        <OrderItem :item="item" :index="index" />
      </div>

      <div v-if="orderAppointments.length">
        <BaseHeading size="s" mt mb>
          {{ $t('register.connected_appointments') }}
        </BaseHeading>
        <div
          v-for="(appointment, index) in orderAppointments"
          :key="index"
          :class="$style.listItem"
        >
          <OrderAppointment mt mb :appointment="appointment" />
        </div>
      </div>

      <OrderNote v-if="order.note" v-test="'register-order-note-item'" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'OrderItems'
};
</script>

<script setup lang="ts">
import OrderItem from './OrderItem.vue';
import OrderAppointment from './OrderAppointment.vue';
import OrderNote from '../OrderNote.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import type { OrderItem as OrderItemType } from '@/modules/register/stores/order';
import NoItems from './NoItems.vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import filters from '@/filters';

const { order } = useRegisterOrderStore();
const { orderAppointments, totalTwPayments } = storeToRefs(
  useRegisterOrderStore()
);

const discountCodes = computed(() =>
  orderAppointments.value
    .map((appointment) => appointment.discountCode)
    .filter((value, index, self) => value && self.indexOf(value) === index)
);

const serviceItemMismatch = computed(() => {
  // for online appointments we create an order with the appointment services and send this to the customer.
  // If the user then changed the appointment's services,
  // we should show a warning that the order's services don't match the original order's services.

  if (order.originalOrderId || !orderAppointments.value.length) {
    // no mismatch if crediting order or if order doesnt have appointments
    return false;
  }

  const orderPaidOnline =
    !!order.transactions.find(
      (transaction) =>
        transaction.type === 'ONLINE' ||
        transaction.type === 'TREATWELL_ONLINE_PAYMENT'
    ) || !!order.items.find((item) => item.transactionCost);

  if (!orderPaidOnline) {
    // no mismatch if order was not paid online
    return false;
  }

  let mismatch = false;
  orderAppointments.value.forEach((appointment) => {
    const appointmentItems = order.items.filter(
      (item: OrderItemType) => item.appointmentId === appointment.id
    );
    if (!appointmentItems.length) {
      // mismatch if the order doesnt show the appointments services at all
      mismatch = true;
      return;
    } else {
      appointment.serviceIds?.forEach((serviceId) => {
        if (
          !appointmentItems
            .map((item) => item.serviceId)
            .flat()
            .includes(serviceId)
        ) {
          // mismatch if the appointment services don't match the order services
          mismatch = true;
          return;
        }
      });
    }
  });

  return mismatch;
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.list {
  height: 100%;
}

.noItems {
  height: 100%;
}

.listItem {
  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}
</style>
