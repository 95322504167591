<template>
  <FormElement v-bind="{ label, info, validation }" @error="hasError = $event">
    <div
      :class="[
        $style.base,
        {
          [$style.expanded]: isPopoverVisible,
          [$style.inline]: inline,
          [$style.alignRight]: alignRight,
          [$style.hasRangeLabel]: range && rangeLabel,
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div
        v-if="!inline"
        ref="value"
        :class="$style.value"
        @click="popoverToggle"
        v-test="'_datepicker-toggle'"
      >
        <span v-if="modelValue" v-test="'_datepicker-label'">
          <template v-if="range && rangeLabel">
            {{ rangeLabel }}
          </template>
          <template v-else-if="hideYear">
            {{ filters.date(modelValue, { format: 'monthDay' }) }}
          </template>
          <template v-else-if="hideCalendar">
            {{
              yearOnly
                ? modelValue.year()
                : filters.date(modelValue, { format: 'monthYear' })
            }}
          </template>
          <template v-else>
            {{ filters.date(modelValue, { format: 'datePicker' }) }}
          </template>
        </span>
        <span v-else>
          {{ $t('global.actions.select_date') }}
        </span>
      </div>
      <div v-if="!inline" :class="$style.arrow">
        <BaseIcon name="arrow-down" />
      </div>
      <component
        :is="inline || hideCalendar ? 'div' : 'BasePopover'"
        v-show="isPopoverVisible || inline"
        :class="{
          [$style.calendar]: !hideCalendar,
          [$style.calendarMenu]: calendarMenu
        }"
        :mt="0.5"
        :keepVisibleOn="$refs.value"
        :scrollContainer="scrollContainer"
        autoPositioning
        @close="isPopoverVisible = false"
        v-test="'_datepicker-popover'"
      >
        <BasePopover
          v-show="isMonthSelectVisible"
          :hasClose="false"
          :class="[$style.list, { [$style.listYearOnly]: yearOnly }]"
          :keepVisibleOn="hideCalendar ? $refs.value : $refs.headerMonth"
          :alignRight="alignRight"
          @close="onPopoverClose"
        >
          <div :class="$style.listInner">
            <div
              v-if="!yearOnly"
              ref="monthList"
              :class="[$style.listSegment, $style.listSegmentMonth]"
            >
              <div
                v-for="(month, index) in months"
                :key="`month-${index}`"
                ref="monthSegments"
                :class="[
                  $style.listItem,
                  { [$style.isActive]: month.number === selectedMonth }
                ]"
                :data-month="month.number"
                @click="selectMonth(month)"
                v-test="'_datepicker-month'"
              >
                <div :class="$style.listItemInner">
                  {{ month.label }}
                </div>
              </div>
            </div>
            <div
              ref="yearList"
              :class="[
                $style.listSegment,
                $style.listSegmentYear,
                { [$style.listSegmentYearOnly]: yearOnly }
              ]"
            >
              <div
                v-for="(year, index) in years"
                :key="`year-${index}`"
                ref="yearSegments"
                :class="[
                  $style.listItem,
                  { [$style.isActive]: year === selectedYear }
                ]"
                :data-year="year"
                @click="selectYear(year)"
                v-test="'_datepicker-year'"
              >
                <div :class="$style.listItemInner">
                  {{ year }}
                </div>
              </div>
            </div>
          </div>
        </BasePopover>
        <div v-if="!hideCalendar" :class="$style.header">
          <div
            :class="[$style.navBtn, { [$style.disabled]: disablePrevButton }]"
            :data-disabled="disablePrevButton"
            @click="date = date.subtract(1, 'month')"
            v-test="'_datepicker-prevmonth'"
          >
            <BaseIcon name="arrow-left" />
          </div>
          <div
            ref="headerMonth"
            :class="$style.headerMonth"
            @click="isMonthSelectVisible = !isMonthSelectVisible"
            v-test="'_datepicker-header'"
          >
            <BaseHeading>
              {{ filters.date(date.format(), { format: 'monthYear' }) }}
            </BaseHeading>
            <BaseIcon name="arrow-down" />
          </div>
          <div
            :class="[$style.navBtn, { [$style.disabled]: disableNextButton }]"
            :data-disabled="disableNextButton"
            @click="date = date.add(1, 'month')"
            v-test="'_datepicker-nextmonth'"
          >
            <BaseIcon name="arrow-right" />
          </div>
        </div>
        <div v-if="!hideCalendar">
          <div :class="$style.heading">
            <div
              v-for="(heading, index) in dayHeadings"
              :key="`dayHeading-${index}`"
              :class="$style.col"
            >
              <BaseHeading size="s" center :mb="0.5">
                {{ heading }}
              </BaseHeading>
            </div>
          </div>
          <div :class="$style.content">
            <div :class="$style.weeks">
              <div
                v-for="(week, index) in weeks"
                :key="`week-${index}`"
                :class="$style.week"
              >
                <div :class="$style.dateContent">
                  {{ week }}
                </div>
              </div>
            </div>
            <div :class="$style.days">
              <div
                v-for="(day, index) in dates"
                :key="`day-${index}`"
                :class="$style.col"
              >
                <div
                  :class="[
                    $style.date,
                    {
                      [$style.currentMonth]: !day.monthDiff,
                      [$style.selected]: day.isSelectedDate,
                      [$style.current]: day.isCurrentDate,
                      [$style.hidden]: day.isHidden,
                      [$style.disabled]:
                        day.isDisabled || disallowedDays.includes(index % 7)
                    }
                  ]"
                  :data-date="!day.monthDiff ? day.date : null"
                  @click="selectDate(day)"
                  v-test="'_datepicker-date'"
                >
                  <div :class="$style.dateInner">
                    {{ day.date }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </component>
    </div>
  </FormElement>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';

const addLeadingZero = (value) => {
  const stringValue = value.toString();
  return stringValue.length === 1 ? `0${stringValue}` : stringValue;
};
import FormElement from '@/components/_shared/form-element/index.vue';

import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
  components: {
    FormElement
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Object]
    },
    label: {},
    info: {},
    validation: {},
    minValue: {
      type: String,
      default: ''
    },
    maxValue: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    },
    onlyPastDates: {
      type: Boolean,
      default: false
    },
    onlyFutureDates: {
      type: Boolean,
      default: false
    },
    disallowedDays: {
      type: Array,
      default: () => []
    },
    disabledDates: {
      type: Array,
      default: () => []
    },
    availableDates: {
      type: Array,
      default: () => []
    },
    hideYear: {
      type: Boolean,
      default: false
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    hideCalendar: {
      type: Boolean,
      default: false
    },
    yearOnly: {
      type: Boolean,
      default: false
    },
    range: {
      type: String,
      default: null,
      validator: (value) =>
        !value || ['day', 'week', 'month'].indexOf(value) !== -1
    },
    calendarMenu: {
      type: Boolean,
      default: false
    },
    scrollContainer: {
      type: HTMLElement as PropType<HTMLElement>
    }
  },
  emits: ['visibleRangeChanged', 'update:modelValue'],
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      date: null,
      isPopoverVisible: false,
      isMonthSelectVisible: false,
      isMonthSelected: false,
      isYearSelected: false
    };
  },
  watch: {
    modelValue: {
      handler(value) {
        if (value) {
          if (typeof value === 'string') {
            this.date = dayjs(value);
          } else if (dayjs.isDayjs(value)) {
            this.date = dayjs(value.format());
          }
        } else {
          this.date = dayjs();
        }
      },
      immediate: true,
      deep: true
    },
    isPopoverVisible() {
      if (this.isPopoverVisible) {
        this.date = this.modelValue ? dayjs(this.modelValue) : dayjs();
      }
    },
    isMonthSelectVisible: {
      handler() {
        if (this.isMonthSelectVisible) {
          this.setScrollPosition(false);
        } else {
          this.isMonthSelected = false;
          this.isYearSelected = false;
        }
      },
      immediate: true
    },
    selectedYear() {
      this.setScrollPosition();
      this.emitVisibleRange();
    },
    selectedMonth() {
      this.setScrollPosition();
      this.emitVisibleRange();
    },
    minValue() {
      if (this.minValue && dayjs(this.minValue).diff(this.date) > 0) {
        this.setDate(this.minValue);
      }
    }
  },
  computed: {
    rangeLabel() {
      let label = '';
      const selectedDate = dayjs(this.modelValue);

      if (this.range === 'week') {
        const firstDate = selectedDate.subtract(
          (selectedDate.weekday() || 7) - 1,
          'day'
        );
        const lastDate = selectedDate.add(7 - selectedDate.weekday(), 'day');

        if (firstDate.isSame(lastDate, 'month')) {
          label = `${firstDate.date()} - ${lastDate.date()} ${this.filters.monthShort(lastDate.format())} ${lastDate.year()}`;
        } else {
          label = `${firstDate.date()} ${this.filters.monthShort(firstDate.format())} - ${lastDate.date()} ${this.filters.monthShort(lastDate.format())} ${lastDate.year()}`;
        }
      }

      return label;
    },
    currentDate() {
      return dayjs().tz();
    },
    selectedMonth() {
      return this.date.month();
    },
    selectedYear() {
      return this.date.year();
    },
    dayHeadings() {
      const headings = [];
      const monday = dayjs('2020-03-02');

      for (let i = 0; i < 7; i++) {
        headings.push(this.$d(monday.add(i, 'day').$d, 'weekdayNarrow'));
      }

      return headings;
    },
    months() {
      const months = [];
      const minMonth = this.minDate ? this.minDate.month() : null;
      const minYear = this.minDate ? this.minDate.year() : null;
      const maxMonth = this.maxDate ? this.maxDate.month() : null;
      const maxYear = this.maxDate ? this.maxDate.year() : null;

      for (let i = 0; i < 12; i++) {
        if (
          (!this.minDate ||
            minYear < this.selectedYear ||
            (minYear === this.selectedYear && minMonth <= i)) &&
          (!this.maxDate ||
            maxYear > this.selectedYear ||
            (maxYear === this.selectedYear && maxMonth >= i))
        ) {
          months.push({
            number: i,
            label: this.$d(
              new Date(`2020-${addLeadingZero(i + 1)}-01`),
              'monthLong'
            )
          });
        }
      }
      return months;
    },
    years() {
      const years = [];
      const minYear = this.minDate ? this.minDate.year() : null;
      const maxYear = this.maxDate ? this.maxDate.year() : null;

      for (let i = 1900; i < new Date().getFullYear() + 11; i++) {
        if ((!minYear || i >= minYear) && (!maxYear || i <= maxYear)) {
          years.push(i);
        }
      }
      return years;
    },
    minDate() {
      let minDate;
      if (this.minValue) {
        minDate = dayjs(this.minValue);
      } else if (this.onlyFutureDates) {
        minDate = dayjs();
      }

      return minDate;
    },
    maxDate() {
      let maxDate;
      if (this.maxValue) {
        maxDate = dayjs(this.maxValue);
      } else if (this.onlyPastDates) {
        maxDate = dayjs();
      }
      return maxDate;
    },
    disableNextButton() {
      return this.maxDate && this.date.isSame(this.maxDate, 'month');
    },
    disablePrevButton() {
      return this.minDate && this.date.isSame(this.minDate, 'month');
    },
    dates() {
      const dayOfMonth = this.date.date();
      const daysInMonth = this.date.daysInMonth();
      const daysInLastMonth = this.date.subtract(1, 'month').daysInMonth();

      const firstDate = this.date.subtract(this.date.date() - 1, 'day');
      const lastDate = this.date.add(daysInMonth - dayOfMonth, 'day');

      const numberOfDaysFromLastMonth =
        firstDate.day() === 0 ? 6 : firstDate.day() - 1;
      const numberOfDaysFromNextMonth =
        lastDate.day() === 0 ? 0 : 7 - lastDate.day();

      const selectedDate = dayjs(this.modelValue);

      const createDate = (date, monthDiff = 0) => {
        let month = this.date.month() + monthDiff;
        if (month > 11) {
          month = 0;
        } else if (month < 0) {
          month = 11;
        }

        let isHidden = false;

        if (this.minDate) {
          if (this.selectedYear < this.minDate.year()) {
            isHidden = true;
          } else if (this.selectedYear === this.minDate.year()) {
            if (this.date.month() + monthDiff < this.minDate.month()) {
              isHidden = true;
            }
            if (
              this.date.month() + monthDiff === this.minDate.month() &&
              date < this.minDate.date()
            ) {
              isHidden = true;
            }
          }
        }

        if (this.maxDate) {
          if (this.selectedYear > this.maxDate.year()) {
            isHidden = true;
          } else if (this.selectedYear === this.maxDate.year()) {
            if (this.date.month() + monthDiff > this.maxDate.month()) {
              isHidden = true;
            }
            if (
              this.date.month() + monthDiff === this.maxDate.month() &&
              date > this.maxDate.date()
            ) {
              isHidden = true;
            }
          }
        }

        let isDisabled = false;
        const notDisabledDate =
          this.disabledDates?.length &&
          !!this.disabledDates.find(
            (d) => month === selectedDate.month() && date === dayjs(d).date()
          );
        const availableDate =
          this.availableDates?.length &&
          !this.availableDates.find(
            (d) => month === dayjs(d).month() && date === dayjs(d).date()
          );
        isDisabled = notDisabledDate || availableDate;

        return {
          date,
          monthDiff,
          isCurrentDate:
            month === this.currentDate.month() &&
            date === this.currentDate.date(),
          isSelectedDate:
            month === selectedDate.month() && date === selectedDate.date(),
          isHidden,
          isDisabled
        };
      };

      const currentMonthDates = [...Array(daysInMonth)].map((day, index) =>
        createDate(index + 1)
      );

      const lastMonthDates = [...Array(numberOfDaysFromLastMonth)].map(
        (day, index) =>
          createDate(
            daysInLastMonth - (numberOfDaysFromLastMonth - index) + 1,
            -1
          )
      );

      const nextMonthDates = [...Array(numberOfDaysFromNextMonth)].map(
        (day, index) => createDate(index + 1, 1)
      );

      return [...lastMonthDates, ...currentMonthDates, ...nextMonthDates];
    },
    weeks() {
      const weeks = [];
      this.dates.forEach((date, index) => {
        if (index % 7 === 0) {
          const dateObj = this.date.add(date.monthDiff, 'month');
          weeks.push(
            dayjs(
              `${dateObj.year()}-${dateObj.month() + 1}-${date.date}`
            ).isoWeek()
          );
        }
      });
      return weeks;
    }
  },
  methods: {
    emitVisibleRange() {
      const first = this.dates[0];
      const last = this.dates[this.dates.length - 1];

      const firstVisibleDate = this.date
        .add(first.monthDiff, 'month')
        .set('date', first.date);
      const lastVisibleDate = this.date
        .add(last.monthDiff, 'month')
        .set('date', last.date);

      this.$emit('visibleRangeChanged', {
        from: firstVisibleDate.format('YYYY-MM-DD'),
        to: lastVisibleDate.format('YYYY-MM-DD')
      });
    },
    onPopoverClose() {
      if (this.hideCalendar) {
        this.isPopoverVisible = false;
      }
      this.isMonthSelectVisible = false;
    },
    popoverToggle() {
      if (this.hideCalendar) {
        this.isMonthSelectVisible = !this.isMonthSelectVisible;
      }
      this.isPopoverVisible = !this.isPopoverVisible;
    },
    selectMonth(month) {
      this.date = this.date.month(month.number);
      this.isMonthSelected = true;

      if (this.isYearSelected) {
        this.isMonthSelectVisible = false;
        if (this.hideCalendar) {
          this.isPopoverVisible = false;
          this.emitValue(this.date);
        }
      }
    },
    selectYear(year) {
      this.date = this.date.year(year);
      this.isYearSelected = true;

      if (this.isMonthSelected || this.yearOnly) {
        this.isMonthSelectVisible = false;
        if (this.hideCalendar) {
          this.isPopoverVisible = false;
          this.emitValue(this.date);
        }
      }
    },
    selectDate(date) {
      const dateObj = this.date.add(date.monthDiff, 'month').date(date.date);

      this.isPopoverVisible = false;
      this.setDate(dateObj.format('YYYY-MM-DD'));
    },
    setDate(dateString) {
      if (!this.modelValue || typeof this.modelValue === 'string') {
        this.emitValue(dateString);
      } else {
        this.emitValue(dayjs(dateString));
      }
    },
    setScrollPosition(animated = true) {
      if (
        (!this.$refs.monthList ||
          !this.$refs.monthSegments ||
          !this.$refs.yearList ||
          !this.$refs.yearSegments) &&
        !this.yearOnly
      ) {
        return;
      }

      const setPosition = (list, segment) => {
        setTimeout(() => {
          if (segment) {
            const scrollTo = {
              top:
                segment.offsetTop -
                list.offsetHeight / 2 +
                segment.offsetHeight / 2
            };

            if (animated) {
              scrollTo.behavior = 'smooth';
            }

            list.scrollTo(scrollTo);
          }
        }, 0);
      };

      if (!this.yearOnly) {
        const monthSegment =
          this.$refs.monthSegments[
            this.months.findIndex(
              (month) => month.number === this.selectedMonth
            )
          ];
        setPosition(this.$refs.monthList, monthSegment);
      }

      const yearSegment =
        this.$refs.yearSegments[this.years.indexOf(this.selectedYear)];
      setPosition(this.$refs.yearList, yearSegment);
    },
    emitValue(value) {
      this.$emit('update:modelValue', value);
    }
  },
  created() {
    this.emitVisibleRange();
  }
});
</script>

<style lang="scss" module>
$itemSize: 32px;

.base {
  position: relative;
  user-select: none;

  &.hasRangeLabel {
    min-width: 176px;
  }
}

.value {
  @include input;
  padding-right: calc(20px + #{$spacing});
  display: flex;
  align-items: center;
  height: 42px;
  white-space: nowrap;
  cursor: pointer;

  .base.expanded & {
    border-color: $color-primary;
  }
}

.arrow {
  position: absolute;
  right: $spacing * 0.5;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 20px;
  pointer-events: none;
}

.calendar {
  top: 100%;
  width: ($itemSize * 8) + ($spacing * 2) !important;
  max-width: none !important;

  .base.inline & {
    padding: $spacing;
  }

  .base:not(.alignRight),
  .base.smallScreen & {
    left: 0;
  }

  .base.alignRight:not(.smallScreen) & {
    right: 0;
    left: auto;
  }
}

.calendarMenu {
  left: -62px !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacing * -1 $spacing * -1 $spacing * 0.5;
  text-transform: capitalize;
}

.headerMonth {
  display: flex;
  align-items: center;
  padding: $spacing * 0.5;
  cursor: pointer;

  * {
    pointer-events: none;
  }
}

.navBtn {
  padding: $spacing;
  cursor: pointer;

  @include hover {
    background-color: $color-highlight;
  }

  &.disabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }
}

.heading {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: $spacing * 0.5;
  width: calc(100% + #{$spacing * 0.25});
}

.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing * -0.25;
  width: calc(100% + #{$spacing * 0.25});
}

.days {
  display: flex;
  flex-wrap: wrap;
  width: 224px;
}

.col {
  width: $itemSize;
  text-align: center;
}

.weeks {
  width: 36px;
  margin-left: $spacing * -0.25;
  padding-right: $spacing * 0.25;
  box-shadow: 1px 0 0 $color-border;
}

.week {
  position: relative;
  width: $itemSize;
  height: $itemSize;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  font-size: 11px;
}

.date {
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: $radius;
  }

  &:not(.selected) {
    @include hover {
      background-color: $color-highlight;
    }
  }

  &.current {
    &:after {
      background-color: $color-highlight;
    }
  }

  &.selected {
    &:after {
      background-color: $color-primary;
    }
  }

  &.hidden {
    cursor: default;
    pointer-events: none;
    opacity: 0;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.3;
  }
}

.dateInner {
  position: relative;
  width: $itemSize;
  height: $itemSize;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .date.selected & {
    color: white;
  }
}

.list {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50px;
  max-width: 200px !important;
  overflow: hidden;
  z-index: 231;
}

.listYearOnly {
  max-width: 100px !important;
}

.listInner {
  display: flex;
  margin: $spacing * -1;
}

.listSegment {
  height: 120px;
  overflow-y: auto;
}

.listSegmentMonth {
  width: 65%;
}

.listSegmentYear {
  width: 35%;
}

.listSegmentYearOnly {
  width: 100%;
}

$duration: 0.07s;
$easing: ease-out;

.listItem {
  position: relative;
  text-align: center;
  padding: $spacing * 0.5;
  cursor: pointer;

  @include hover {
    &:not(.isActive) {
      background-color: $color-highlight;
    }
  }
}

.listItemInner {
  position: relative;
  z-index: 1;
  padding: $spacing * 0.5;

  .listItem.isActive & {
    color: white;
    background-color: $color-primary;
    border-radius: $radius;
  }
}
</style>
