<template>
  <div>
    <BackButton @click="$emit('close')" />
    <EmptyResults v-if="items.length < 1 && !loading" />

    <div>
      <BrowserAppointmentItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :selected="findSelected(item.id)"
        @click="select(item)"
      />
    </div>
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script setup lang="ts">
import EmptyResults from '../EmptyResults.vue';
import BrowserAppointmentItem from '../BrowserAppointmentItem.vue';
import { usePagination } from '@/apollo/pagination';
import { REGISTER_SEARCH } from '../graphql';
import LoadMore from '@/components/LoadMore.vue';
import { computed } from 'vue';
import BackButton from '../BackButton.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import { setRegisterAppointment } from '../../actions/appointment';
import dayjs from '@/dayjs';

const { orderAppointments } = storeToRefs(useRegisterOrderStore());

const selected = computed(() =>
  orderAppointments.value.map((appointment) => appointment.id)
);
const findSelected = (value: any) =>
  !!selected.value.find((item) => item === value);

defineEmits(['close']);

const { registerSearch, allDataFetched, fetchMore, loading } = usePagination({
  fieldName: 'registerSearch',
  query: REGISTER_SEARCH,
  variables: {
    type: 'APPOINTMENTS',
    before: dayjs().add(1, 'day').format('YYYY-MM-DD')
  }
});

const items = computed(() =>
  registerSearch.value.map((item: any) => ({
    label: item.name,
    id: item.id,
    price: item.price,
    customerName: item.customerName,
    date: item.startAt
  }))
);

const select = (item: any) => {
  const appointment = registerSearch.value.find(
    (searchItem: any) => searchItem.id === item.id
  );
  setRegisterAppointment(appointment);
};
</script>
