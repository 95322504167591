<template>
  <div>
    <BaseCard
      :heading="$t('global.history')"
      :headerMargin="false"
      :mr="$screen === 'l' ? 0.5 : null"
    >
      <Timeline
        v-if="history.length"
        canDelete
        :data="history"
        mt
        @delete="onDelete"
      />
      <BaseText v-else-if="!history.length" mt>
        {{ $t('global.no_results') }}
      </BaseText>
    </BaseCard>
  </div>
</template>

<script setup lang="ts">
import Timeline from '@/components/Timeline.vue';
import axios from '@/axios';
import { inject } from 'vue';
import { modal } from '@/helpers/ui';

const emit = defineEmits(['refresh']);
defineProps<{
  history: any[];
}>();

const customer = inject<any>('customer');

const onDelete = (timelineId: number) => {
  modal('confirmation').then(() => {
    axios
      .delete(`timeline_items/${timelineId}?customer_id=${customer.value.id}`)
      .then(() => {
        emit('refresh');
      });
  });
};
</script>
