<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('admin.nav.links.services')">
      <template #header>
        <BaseButton
          :options="[
            {
              label: $t('services.add_category'),
              value: 'add_category'
            },
            {
              label: $t('services.add_service'),
              value: 'add_service'
            }
          ]"
          @select="handleAddNewSelect"
          v-test="'btn-add-new'"
        >
          {{ $t('global.actions.create_new') }}
        </BaseButton>
      </template>
      <PageHeader v-if="!showEmptyPageContent && !sortReorder">
        <template #left>
          <BaseDropdown
            v-model="filterValue"
            :options="[
              {
                value: 'all',
                label: $t('services.filter.all')
              },
              {
                value: 'bookable',
                label: $t('services.filter.bookable_online')
              },
              {
                value: 'not_bookable',
                label: $t('services.filter.not_bookable_online')
              }
            ]"
            v-test="'service-filter'"
          />
          <BaseSearch v-model="searchQuery" v-test="'service-search'" />
        </template>
        <template #right>
          <BaseButton
            icon="swap-vertical"
            color="inverted"
            @click="sortReorder = true"
            v-test="'btn-sort-all'"
          />
        </template>
      </PageHeader>
      <BaseAlert
        v-if="sortReorder"
        icon="flip"
        :text="$t('services.reorder_services')"
        :primaryAction="$t('global.done')"
        mb
        @primaryAction="sortReorder = false"
      />
      <List v-if="dataLoaded && !showEmptyPageContent" :data="listData" />
      <div
        :class="$style.addNewCategory"
        @click="$router.push({ name: 'admin-service-category-new' })"
        v-test="'service-category-create-list-bottom'"
      >
        <BaseIcon name="plus" color="success" :mr="0.5" />
        <BaseText bold>
          {{ $t('global.actions.create_category') }}
        </BaseText>
      </div>
      <EmptyPageContent
        v-if="dataLoaded"
        v-show="listData && !listData.length"
        imageName="services"
        :text="
          showEmptyPageContent
            ? {
                title: $t('services.empty_state.title'),
                description: $t('services.empty_state.description'),
                btnPrimary: $t('services.add_service'),
                btnSecondary: $t('services.add_category')
              }
            : null
        "
        @click="onEmptyPageClick"
        v-test="'service-category-noresults'"
      />
      <BaseSpinner v-if="!dataLoaded" />
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import PageHeader from '@/modules/PageHeader.vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import List from './list/index.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useServicesStore } from '@/stores/services';
import { useSessionStore } from '@/stores/session';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import PageWrap from '../PageWrap.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    PageHeader,
    ModuleBase,
    List,
    EmptyPageContent,
    PageWrap
  },
  setup() {
    const sortReorder = ref(false);
    provide('services-category-sort-order', sortReorder);
    return { sortReorder };
  },
  data() {
    return {
      filterValue: 'all',
      searchQuery: ''
    };
  },
  computed: {
    dataLoaded() {
      const { allDataLoaded } = useSessionStore();
      return allDataLoaded;
    },
    showEmptyPageContent() {
      return (
        this.filterValue === 'all' && !this.searchQuery && !this.listData.length
      );
    },
    listData() {
      const inSearchQuery = (name) =>
        this.searchQuery &&
        name.toLowerCase().includes(this.searchQuery.toLowerCase());

      const { categoriesWithServices } = useServicesStore();

      const categories = categoriesWithServices.map((category) => ({
        ...category,
        services: category.services.filter(
          (service) =>
            (this.filterValue === 'all' ||
              (this.filterValue === 'bookable' && service.bookable) ||
              (this.filterValue === 'not_bookable' && !service.bookable)) &&
            (!this.searchQuery ||
              inSearchQuery(service.name) ||
              inSearchQuery(category.name))
        )
      }));

      const visibleCategories =
        this.filterValue !== 'all' || this.searchQuery
          ? categories.filter(
              (category) =>
                category.services.length || inSearchQuery(category.name)
            )
          : categories;

      return visibleCategories;
    }
  },
  methods: {
    onEmptyPageClick(button) {
      if (button === 'primary') {
        this.$router.push({ name: 'admin-service-new' });
      } else if (button === 'secondary') {
        this.$router.push({ name: 'admin-service-category-new' });
      }
    },
    handleAddNewSelect(value: 'add_category' | 'add_service') {
      switch (value) {
        case 'add_category':
          this.$router.push({ name: 'admin-service-category-new' });
          break;
        case 'add_service':
          this.$router.push({ name: 'admin-service-new' });
          break;
      }
    }
  },
  created() {
    // Clear the requirements that are cached for the create / edit appointment section
    // This will also clear the Apollo cache, and thus makes sure that they will get refetched from the backend again

    const { clearRequirements } = useCreateAppointmentStore();
    clearRequirements();
  }
});
</script>

<style lang="scss" module>
.addNewCategory {
  cursor: pointer;
  border: 1px dashed $color-success;
  display: flex;
  margin-top: $spacing * 0.25;
  padding: $spacing;
  border-radius: $radius;
}
</style>
