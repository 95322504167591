<template>
  <div :class="$style.base">
    <TheToolTip />
    <TheNotification />
    <router-view v-if="dataLoaded" />
  </div>
</template>

<script lang="ts">
import TheToolTip from './TheTooltip.vue';
import TheNotification from './TheNotifications.vue';
import { updateDataLayer } from '@/helpers/gtm';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useSessionStore } from '@/stores/session';
import { device } from '@/user-context';
import { defineComponent } from 'vue';
import { useFavicon } from '@vueuse/core';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'App',
  components: {
    TheToolTip,
    TheNotification
  },
  setup() {
    const { isTreatwellUser, treatwellSignup } = storeToRefs(useCompanyStore());

    watchEffect(() => {
      if (treatwellSignup.value || isTreatwellUser.value) {
        const icon = useFavicon();
        icon.value = '/img/favicon/favicon-tw.ico';
        document.title = document.title.replace('- Salonized', '- Treatwell');
      }
    });
  },
  watch: {
    dataLoaded(value) {
      if (value) {
        const preloaderEl = document.querySelector('.js-preloader');
        if (preloaderEl) {
          preloaderEl.remove();
        }
      }
    }
  },
  computed: {
    dataLoaded() {
      const { requiredDataLoaded } = useSessionStore();
      return requiredDataLoaded;
    }
  },
  methods: {
    setupDom() {
      const { setScreenSize } = usePageLayoutStore();

      window.addEventListener('resize', () => {
        setScreenSize();
      });

      setScreenSize();

      if (device.touch) {
        document.body.classList.add('is-touch');
      }
    }
  },
  created() {
    updateDataLayer();
    this.setupDom();
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.preloader {
  &:global(.v-leave-active) {
    transition: opacity 0.1s;
  }
  &:global(.v-leave-to) {
    opacity: 0;
  }
}
</style>
