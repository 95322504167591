<template>
  <BackButton @click="$emit('close')" />
  <div :class="$style.base" v-test="'barcodeBase'">
    <BaseIcon name="barcode" />
    <BaseText>{{ $t('register.scan_product') }}</BaseText>
  </div>
  <input ref="input" hidden type="text" />
  <BaseSpinner v-show="loading" inline />
</template>

<script lang="ts" setup>
import { useLazyQuery } from '@vue/apollo-composable';
import BackButton from '../BackButton.vue';
import { onMounted, ref, onUnmounted } from 'vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import gql from 'graphql-tag';
defineEmits(['close']);

const barcode = ref('');
const input = ref(null);
const { addItem } = useRegisterOrderStore();

const {
  load: findProduct,
  refetch,
  loading,
  onResult
} = useLazyQuery(gql`
  query getProducts($reference: String) {
    products(reference: $reference) {
      id
      name
      price
    }
  }
`);

const onKeyDown = (e: KeyboardEvent) => {
  input.value.focus();
  const key = e.key;

  if (key === 'Tab' || key === 'Enter') {
    // Tab is handled by preventDefault to prevent it from moving to other inputs
    e.preventDefault();
    // findProduct only runs once and returns true after that, this refetches it
    (findProduct(undefined, {
      reference: barcode.value
    }) as unknown as boolean) || refetch({ reference: barcode.value });
  }

  if (
    key.length === 1 &&
    ((key >= '0' && key <= '9') ||
      (key >= 'a' && key <= 'z') ||
      (key >= 'A' && key <= 'Z'))
  ) {
    barcode.value = barcode.value + key;
  }
};

onResult((result) => {
  input.value.focus();

  if (result.data.products.length >= 1 && barcode.value.length > 0) {
    const item = result.data.products[0];

    addItem({
      name: item.name,
      price: item.price,
      productId: item.id
    });
  }
  barcode.value = '';
});

onMounted(() => {
  window.addEventListener('keydown', onKeyDown);
});

onUnmounted(() => {
  window.removeEventListener('keydown', onKeyDown);
});
</script>
<style lang="scss" module>
.base {
  padding: $spacing;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: $spacing * 2;
}
</style>
