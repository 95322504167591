<template>
  <BaseModal
    :heading="$t('marketing.email.automated_emails.rebook_setup.title')"
    :loading="$apollo.loading"
    small
    :parentRoute="{
      name: parentRouteName
    }"
  >
    <BaseText mb>
      {{ $t('marketing.email.automated_emails.rebook_setup.info') }}
    </BaseText>
    <BaseTableRow head>
      <BaseTableCell>
        {{ $t('global.items.category', 2) }}
      </BaseTableCell>
      <BaseTableCell :width="$screen === 's' ? 45 : 25" right>
        {{ $t('marketing.email.automated_emails.rebook_setup.sent_after') }}
      </BaseTableCell>
    </BaseTableRow>
    <CategoryRow
      v-for="(category, index) in categories"
      :key="index"
      :category="category"
    />
    <template #footer>
      <BaseButton
        color="inverted"
        @click="$router.push({ name: parentRouteName })"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="onSave"
        v-test="'rebook-setup-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import CategoryRow from './CategoryRow.vue';
import { useServicesStore } from '@/stores/services';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SetupServices',
  components: {
    CategoryRow,
    BaseTableRow,
    BaseTableCell
  },
  emits: ['close'],
  setup() {
    const { categoriesWithServices } = useServicesStore();

    const categories = categoriesWithServices
      .map((category) => ({
        id: category.id,
        name: category.name,
        services: category.services.map((service) => ({
          id: service.id,
          name: service.name,
          rebookEnabled: service.rebookEnabled || false,
          rebookPeriod: service.rebookPeriod || 30
        }))
      }))
      .filter((category) => !!category.services.length);

    const parentRouteName = 'rebook-reminder-template';

    return { categories, parentRouteName };
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    onSave() {
      const bulkUpdateServiceAttributes = this.categories
        .map((category) => category.services)
        .reduce((acc, val) => acc.concat(val), []);

      this.isLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateServices($input: UpdateServicesInput!) {
              updateServices(input: $input) {
                services {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              bulkUpdateServiceAttributes
            }
          }
        })
        .then(() => {
          const { getServices } = useServicesStore();
          getServices();

          flash(this.$t('global.flash.services_updated'));
          this.$router.push({ name: this.parentRouteName });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>
