<template>
  <div>
    <div :class="$style.items">
      <Requirement
        v-for="item in visibleRequirements"
        :id="item.id"
        :key="`item-${item.id || item._uid}`"
        :primary="item.primary"
        :resourceIds="item.resourceIds"
        :type="item.type"
        @updateSelectedIds="updateSelectedIds($event, item._uid)"
        @close="deleteRequirement(item._uid)"
        v-test="'service-requirement'"
      />
    </div>
    <div v-if="buttons.length" :class="$style.links">
      <BaseText
        v-for="(item, index) in buttons"
        :key="index"
        link
        :iconBefore="item.icon"
        @click="addItem(item.type.toUpperCase())"
        v-test="`service-add-${item.type}`"
      >
        {{ $t(`service.resources.${item.type}.link_add`) }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import { useResourcesStore } from '@/stores/resources';
import Requirement from './ResourceType.vue';
import { computed, defineComponent } from 'vue';

let uid = 0;

export default defineComponent({
  name: 'ServiceRequirements',
  components: {
    Requirement
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const requirements = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });

    const visibleRequirements = computed(() =>
      requirements.value.filter((r: any) => !r.destroy)
    );

    const { resourcesByType } = useResourcesStore();
    const buttons = computed(() => {
      if (!visibleRequirements.value.length) {
        return [];
      } else {
        return [
          {
            type: 'employee',
            icon: 'person'
          },
          {
            type: 'room',
            icon: 'room'
          },
          {
            type: 'equipment',
            icon: 'monitor'
          }
        ].filter((button) => {
          const resourceAmount = resourcesByType(button.type).length;
          const requirementAmount = visibleRequirements.value.filter(
            (req: any) => req.type === button.type.toUpperCase()
          ).length;
          return requirementAmount === 0 || requirementAmount < resourceAmount;
        });
      }
    });

    return {
      requirements,
      visibleRequirements,
      buttons
    };
  },
  methods: {
    updateSelectedIds(ids, _uid) {
      this.requirements.find((r) => r._uid === _uid).resourceIds = ids;
    },
    deleteRequirement(_uid) {
      this.requirements.find((r) => r._uid === _uid).destroy = true;
    },
    addItem(type) {
      uid++;

      this.requirements = [
        ...this.requirements,
        {
          _uid: uid,
          destroy: false,
          primary: !this.requirements.length, // Only the first requirement should be primary
          resourceIds: [],
          type
        }
      ];
    }
  },
  created() {
    if (!this.requirements.length) {
      // Add a default requirement when creating a new service
      this.addItem('EMPLOYEE');
    } else {
      // When editing a service, add uids to existing requirements
      this.requirements = this.requirements.map((r) => {
        uid++;
        return {
          ...r,
          _uid: uid
        };
      });
    }
  }
});
</script>

<style lang="scss" module>
.items {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.links {
  margin-top: $spacing;
  display: flex;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}
</style>
