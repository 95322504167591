<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's' || previewMode,
        [$style.mediumScreen]: $screen === 'm' && !previewMode,
        [$style.largeScreen]: $screen === 'l' && !previewMode,
        [$style.previewMode]: previewMode,
        [$style.noPadding]: noPadding,
        [$style.white]: white
      }
    ]"
  >
    <div v-if="loading" :class="$style.loadingContent">
      <BaseSpinner />
    </div>

    <div v-else :class="$style.content">
      <div v-if="!loading" :class="$style.side">
        <slot name="aside" />
        <ModalDetails v-if="details && details.length" :details="details" />
        <Actions
          v-if="actions.length"
          :actions="actions"
          @select="$emit('action', $event)"
        />
      </div>
      <div v-if="!loading" :class="$style.nav">
        <slot name="nav" />
      </div>
      <div
        ref="scrollContainer"
        :class="$style.main"
        v-test="'_base-modal-scrollContainer'"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Actions from '@/components/_shared/modal-actions/index.vue';
import ModalDetails from '@/components/_shared/ModalDetails.vue';
import { usePageScroll } from '@/helpers/scroll';

import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    Actions,
    ModalDetails
  },
  props: ['actions', 'details', 'noPadding', 'loading', 'previewMode', 'white'],
  emits: ['action'],
  setup() {
    const scrollContainer = ref();
    usePageScroll(scrollContainer);

    return {
      scrollContainer
    };
  }
});
</script>

<style lang="scss" module>
$loadingHeight: 150px;

.base {
  background-color: $grey-light;

  &.white {
    background-color: $white;
  }
}

.loadingContent {
  position: relative;
  height: 150px;
}

.content {
  .base:not(.smallScreen) & {
    display: flex;
    align-items: stretch;
    flex-direction: row-reverse; // Reversing the order in the DOM so we can target .side:empty ~ .main
    height: 100%;
    overflow-y: auto;
  }

  .base.previewMode & {
    height: 100%;
    overflow-y: auto;
  }
}

.main {
  height: 100%;
  min-height: $loadingHeight;
  position: relative;

  .base:not(.smallScreen) & {
    overflow-y: auto;
  }

  .base.largeScreen:not(.noPadding) & {
    padding: $spacing * 1.5;
  }

  .base.mediumScreen:not(.noPadding) & {
    padding: $spacing;
  }

  .base.smallScreen:not(.noPadding) & {
    padding: $spacing * 0.5;
  }

  .side:not(:empty) ~ & {
    .base.largeScreen & {
      width: 75%;
    }

    .base.mediumScreen & {
      width: 67%;
    }
  }

  .base.smallScreen &,
  .side:empty ~ & {
    width: 100%;
  }
}

.nav {
  &:empty {
    display: none;
  }

  order: 1;

  .base.white & {
    border-right: 1px solid $color-border;
  }

  .base:not(.smallScreen) & {
    overflow-y: auto;
    min-width: 400px;
    flex-basis: 400px;
    background-color: $white;
  }

  .base.mediumScreen & {
    min-width: auto;
  }

  .base.smallScreen & {
    display: flex;
    justify-content: stretch;
    background-color: $grey-light;
  }
}

.side {
  border-left: 1px solid $color-border;

  &:not(:empty) {
    height: 100%;

    .base.largeScreen & {
      width: 25%;
    }

    .base.mediumScreen & {
      width: 33%;
    }
  }

  .base.smallScreen & {
    width: 100%;
  }
}
</style>
