<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <div :class="$style.topRow">
      <BaseButton
        v-if="!order.id || order.draft"
        mr
        color="inverted"
        submitForm
        :loading="savingDraft"
        :disabled="!order.items.length"
        @click="saveDraft"
        v-test="'order-draft'"
      >
        {{ $t('register.save_draft') }}
      </BaseButton>
      <BaseButton color="inverted" @click="reset" v-test="'order-reset'">
        {{ $t('global.actions.reset') }}
      </BaseButton>
    </div>
    <div :class="$style.bottomRow">
      <div :class="$style.amount" v-test="'order-amount'">
        {{ filters.currency(totalPrice) }}
      </div>
      <BaseButton
        size="l"
        submitForm
        :disabled="!order.items.length"
        v-test="'order-checkout'"
      >
        {{ $t('global.actions.checkout') }}
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';

const { order, reset } = useRegisterOrderStore();
const { totalPrice, selectedEmployeeId } = storeToRefs(useRegisterOrderStore());
const { hasSingleEmployee } = storeToRefs(useResourcesStore());

defineProps<{
  savingDraft: boolean;
}>();
const emit = defineEmits(['setSavingDraft']);

const saveDraft = () => {
  if (
    order.items.length &&
    (hasSingleEmployee.value || selectedEmployeeId.value)
  ) {
    emit('setSavingDraft');
  }
};
</script>

<style module lang="scss">
.base {
  border-top: 1px solid $color-border;
  display: flex;
  flex-direction: column;
  padding-top: $spacing;
}

.topRow {
  & > * {
    margin-bottom: $spacing * 0.5;
  }
}

.amount {
  font-size: 32px;
  font-weight: bold;
  color: $color-text;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .base:not(.smallScreen) & {
    margin-top: $spacing * -0.5;
  }

  .smallScreen & {
    margin-bottom: $spacing;
  }

  & > * {
    margin-top: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing;
    }
  }
}
</style>
