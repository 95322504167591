import { defineStore, acceptHMRUpdate } from 'pinia';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { reactive, computed } from 'vue';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from './company';

export const useTreatwellStore = defineStore('treatwell', () => {
  const { hasFeatureFlag } = useUserStore();
  const { isTreatwellUser } = useCompanyStore();

  const treatwellStatus = reactive({
    bankDetailsProvided: false,
    contractSigned: false,
    contractType: null,
    descriptionProvided: false,
    freeCommissionRemainingDays: 0,
    isPastFreeCommissionPeriod: false,
    subscriptionIntention: false,
    hasRequest: false,
    hasVenue: false,
    optOutCampaign: false,
    reviewsPublished: false,
    twHardNo: false,
    kycStatus: {
      onboardingUrl: '',
      kycCompleted: false
    }
  });

  const showTreatwell = computed(
    () => hasFeatureFlag('module-treatwell') && !treatwellStatus.twHardNo
  );

  const hasDashboard = computed(
    () =>
      treatwellStatus.contractSigned ||
      treatwellStatus.optOutCampaign ||
      treatwellStatus.hasRequest
  );

  const completedOnboardingTasks = computed(() => {
    if (isTreatwellUser) {
      return treatwellStatus.kycStatus?.kycCompleted;
    } else {
      // Technically this should also include the bank details and company info
      // But for the A/B test I want to limit the DoF so that it is geared to getting users to sign the contract
      return treatwellStatus.contractSigned;
    }
  });

  const getTreatwellStatus = () =>
    new Promise<void>((resolve) => {
      const { onResult } = useQuery(gql`
        query getTreatwellStatus {
          treatwellStatus {
            bankDetailsProvided
            contractSigned
            contractType
            descriptionProvided
            freeCommissionRemainingDays
            subscriptionIntention
            hasVenue
            hasRequest
            optOutCampaign
            reviewsPublished
            twHardNo
            isPastFreeCommissionPeriod
            kycStatus {
              kycCompleted
              onboardingUrl
            }
          }
        }
      `);

      onResult(({ data: { treatwellStatus: _treatwellStatus } }) => {
        Object.keys(_treatwellStatus).forEach((key) => {
          if (
            treatwellStatus[key as keyof typeof treatwellStatus] !== undefined
          ) {
            treatwellStatus[key] = _treatwellStatus[key];
          }
        });

        resolve();
      });
    });

  return {
    getTreatwellStatus,
    treatwellStatus,
    showTreatwell,
    hasDashboard,
    completedOnboardingTasks
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTreatwellStore, import.meta.hot));
}
