<template>
  <div>
    <BaseDropdown
      v-model="formData.languagePublic"
      :label="$t('giftcard_widget.settings.language')"
      :options="publicLanguages"
      :mb="2"
      v-test="'giftcard-setting-languagePublic'"
    />
    <BaseCard gray mb>
      <BaseCheckbox
        v-model="formData.settings.sales.giftcardExpiration"
        :label="$t('giftcard_widget.settings.expiration_date')"
        v-test="'giftcard-setting-giftcardExpiration'"
      />
      <BaseDropdown
        v-if="formData.settings.sales.giftcardExpiration"
        v-model="formData.settings.sales.giftcardExpirationValue"
        :label="$t('giftcard_widget.settings.expires_in')"
        :options="
          [1, 2, 3, 4, 5].map((value) => ({
            value,
            label: `${value} ${$t('global.items.year', value)}`
          }))
        "
        mt
        v-test="'giftcard-setting-giftcardExpirationValue'"
      />
    </BaseCard>
    <Amounts mb />
    <BaseInput
      v-model="formData.giftcardPersonalMessage"
      type="textarea"
      :label="$t('giftcard_widget.settings.personal_message.heading')"
      :info="$t('giftcard_widget.settings.personal_message.info')"
      :maxLength="140"
      characterCount
      mb
      v-test="'giftcard-setting-giftcardPersonalMessage'"
    />
    <BaseInputLabel
      :text="$t('giftcard_widget.settings.picture.heading')"
      :info="$t('giftcard_widget.settings.picture.info')"
    />
    <BaseUpload
      preset="giftcard-picture"
      withImagePreview
      withDelete
      :image="formData.settings.sales.giftcardPicture"
      @uploadResults="
        formData.settings.sales.giftcardPicture = $event.secure_url
      "
      @delete="formData.settings.sales.giftcardPicture = ''"
    />
    <IdealPayments :mt="1.5" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'MainSettings'
};
</script>

<script setup lang="ts">
import IdealPayments from './IdealPayments.vue';
import Amounts from './Amounts.vue';
import { useBookingSettingsOptions } from '@/modules/admin/online-bookings/input-options';

const formData = inject<any>('giftcardSettingsData');
const { publicLanguages } = useBookingSettingsOptions();
</script>
