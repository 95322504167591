<template>
  <BaseModalLarge
    :heading="serviceId ? $t('service.edit') : $t('service.create')"
    noPadding
    :loading="isLoading"
    parentRoute="admin-services"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <CategoryModal
        v-if="showCategoryModal"
        @create="formData.categoryId = $event"
        @close="showCategoryModal = false"
      />

      <ResourcePrices
        v-if="showResourcePricesModal"
        v-model="formData.resourceAdjustmentsAttributes"
        :servicePrice="formData.price"
        @close="showResourcePricesModal = false"
      />

      <div ref="scrollContainer" :class="$style.main">
        <BaseForm
          :scrollContainer="$screen !== 's' ? scrollContainer : null"
          :disableScroll="showResourcePricesModal"
          @submit="submit"
        >
          <BaseHeading mb>
            {{ $t('service.main_settings') }}
          </BaseHeading>
          <div :class="[$style.formRow, $style.mobileFull]">
            <div>
              <BaseInput
                v-model="formData.name"
                :label="$t('service.name')"
                :maxLength="255"
                required
                v-test="'service-name'"
              />
            </div>
            <div>
              <BaseDropdown
                v-model="formData.categoryId"
                :options="
                  categories.map((category) => ({
                    value: category.id,
                    label: category.name
                  }))
                "
                :label="$t('global.items.category', 1)"
                required
                v-test="'service-category'"
              />
              <div v-if="!routeCategoryId" class="mt-05">
                <BaseText link inline @click="showCategoryModal = true">
                  + {{ $t('service.new_category') }}
                </BaseText>
              </div>
            </div>
          </div>
          <div :class="[$style.formRow, $style.mobileFull]">
            <div>
              <BaseInput
                v-model="formData.price"
                :label="$t('global.price')"
                type="currency"
                v-test="'service-price'"
              />
              <div
                v-if="hasFeatureFlag('employee-price-per-service')"
                class="mt-05"
              >
                <BaseText
                  link
                  inline
                  @click="showResourcePricesModal = true"
                  v-test="'btn-resource-prices'"
                >
                  + {{ $t('service.resource_prices.title') }}
                  <span v-show="formData.resourceAdjustmentsAttributes.length"
                    >({{ formData.resourceAdjustmentsAttributes.length }})</span
                  >
                </BaseText>
              </div>
            </div>
            <div>
              <BaseDropdown
                v-model="formData.vatRateId"
                :options="[
                  {
                    value: null,
                    label: $t('products.default_vat')
                  },
                  ...vatRates.map((rate) => ({
                    value: rate.id,
                    label: rate.label
                  }))
                ]"
                :label="$t('global.items.vat_rate', 1)"
              />
            </div>
          </div>
          <div :class="$style.formRow" class="mb-1">
            <div
              :class="[
                $style.durationWrap,
                {
                  [$style.wide]: formData.requiresProcessingTime
                }
              ]"
            >
              <div
                v-if="formData.requiresProcessingTime"
                :class="$style.processingTimeWrap"
              >
                <div :class="$style.processingTime">
                  <BaseInput
                    v-model="formData.durationSetup"
                    :label="$t('service.duration_processing.setup')"
                    :required="formData.requiresProcessingTime"
                    :minValue="formData.requiresProcessingTime ? 1 : 0"
                    :maxValue="maxDurationProcessing"
                    unitLabel="minute"
                    type="number"
                    v-test="'service-duration-setup'"
                  />
                  <BaseInput
                    v-model="formData.durationProcessing"
                    :label="$t('service.duration_processing.processing')"
                    :minValue="0"
                    :maxValue="maxDurationProcessing"
                    unitLabel="minute"
                    type="number"
                    :info="$t('service.duration_processing.description')"
                    v-test="'service-duration-processing'"
                  />
                  <BaseInput
                    v-model="formData.durationFinish"
                    :label="$t('service.duration_processing.finish')"
                    :minValue="0"
                    :maxValue="maxDurationProcessing"
                    unitLabel="minute"
                    type="number"
                    v-test="'service-duration-finish'"
                  />
                </div>
              </div>
              <div v-else>
                <BaseInput
                  v-model="formData.duration"
                  :label="$t('global.duration')"
                  unitLabel="minute"
                  type="number"
                  :minValue="0"
                  :maxValue="maxDuration"
                  v-test="'service-duration'"
                />
              </div>
              <div :class="$style.processingToggle">
                <BaseCheckbox
                  v-model="formData.requiresProcessingTime"
                  :label="$t('service.requires_processing_time')"
                  v-test="'service-processing-toggle'"
                />
              </div>
            </div>
            <div>
              <BaseInput
                v-model="formData.buffer"
                :label="$t('service.buffer_time.heading')"
                :minValue="0"
                :maxValue="maxDuration"
                unitLabel="minute"
                type="number"
                :info="$t('service.buffer_time.info')"
                v-test="'service-buffer'"
              />
            </div>
          </div>
          <BaseCard
            v-if="multiLocation"
            v-show="formData.bookable"
            gray
            :mb="2"
            v-test="'service-locations'"
          >
            <BaseText bold>
              {{ $t('service.locations.heading') }}
            </BaseText>
            <BaseText size="s" mb>
              {{ $t('service.locations.description') }}
            </BaseText>
            <LocationSelect
              v-model="formData.bookableLocationIds"
              v-test="'service-locations-list'"
            />
          </BaseCard>
          <BaseHeading mb>
            {{ $t('service.resources.heading') }}
          </BaseHeading>
          <Requirements
            v-if="!isLoading"
            v-model="formData.requirementsAttributes"
            :isSubmitted="isSubmitted"
          />
        </BaseForm>
      </div>
      <div :class="$style.side">
        <BaseHeading>
          {{ $t('service.extra_settings') }}
        </BaseHeading>
        <BaseCard>
          <BaseCheckbox
            v-model="formData.bookable"
            :disabled="formData.duration <= 0"
            :label="$t('service.bookable.heading')"
            :description="$t('service.bookable.description')"
            v-test="'service-bookable'"
          />
          <BaseText
            v-if="onlineBookableError"
            color="error"
            size="s"
            :mt="0.5"
            v-test="'service-location-bookable-error'"
          >
            {{ $t('service.error_bookable_location') }}
          </BaseText>
          <BaseAlert
            v-if="formData.duration <= 0"
            color="warning"
            :mt="0.5"
            size="s"
            :text="$t('service.warning_bookable_duration')"
            v-test="'service-duration-unbookable'"
          />
        </BaseCard>
        <Availability
          v-if="unleash.isEnabled('ServiceAvailability')"
          v-model="formData.serviceOfferingEnabled"
        />
        <BaseCard v-if="formData.bookable">
          <BaseCheckbox
            v-model="hasDescription"
            :label="$t('service.description.heading')"
            :description="$t('service.description.description')"
            v-test="'service-description-toggle'"
          />
          <BaseInput
            v-if="hasDescription"
            v-model="formData.description"
            type="textarea"
            mt
            v-test="'service-description'"
          />
        </BaseCard>
        <BaseCard>
          <BaseCheckbox
            v-model="hasExtraInformation"
            :label="$t('service.additional_information.heading')"
            :description="$t('service.additional_information.description')"
            v-test="'service-extra-information-toggle'"
          />
          <BaseEditor
            v-if="hasExtraInformation"
            v-model="formData.extraInformation"
            mt
            v-test="'service-extra-information'"
          />
        </BaseCard>
        <BaseCard v-if="showRebookSetting">
          <BaseCheckbox
            v-model="formData.rebookEnabled"
            :label="$t('service.rebook_reminder.heading')"
            :description="$t('service.rebook_reminder.description')"
            v-test="'service-rebook'"
          />
        </BaseCard>
        <BaseCard v-if="rwgEnabled">
          <BaseInput
            v-model="formData.rwgName"
            :label="$t('service.google_reserve.heading')"
            :info="$t('service.google_reserve.description')"
            v-test="'service-google-reserve'"
          />
        </BaseCard>
        <BaseCard v-if="company.medical">
          <BaseCheckbox
            v-model="formData.medical"
            :label="$t('service.medical')"
            v-test="'service-medical-toggle'"
          />
        </BaseCard>
      </div>
    </div>
    <template v-if="serviceId" #footerSub>
      <BaseText iconBefore="delete" link color="error" @click="onDeleteClick">
        {{ $t('global.actions.delete_service') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton color="inverted" @click="close">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="isSaving"
        @click="submit"
        v-test="'service-btn-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useRoute } from 'vue-router';
import useVuelidate from '@vuelidate/core';

import CategoryModal from '@/modules/admin/services/Category.vue';
import LocationSelect from '@/modules/admin/_shared/LocationSelect.vue';
import { useLocationsStore } from '@/stores/locations';
import { useServicesStore } from '@/stores/services';
import { useVatRatesStore } from '@/stores/vat-rates';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';
import { useUserStore } from '@/stores/user';
import unleash from '@/unleash';

import { GET_SERVICE } from '../graphql';
import ResourcePrices from './ResourcePrices.vue';
import Availability from '../availability/index.vue';
import Requirements from './resources/index.vue';
import { useAvailability } from '../availability';
import { flash } from '@/helpers/ui';

export default defineComponent({
  name: 'CreateService',
  components: {
    Requirements,
    CategoryModal,
    LocationSelect,
    ResourcePrices,
    Availability
  },
  inject: ['mixpanel'],
  setup() {
    const { createService, updateService, deleteService } = useServicesStore();
    const scrollContainer = ref();
    const rwgEnabled = ref(false);

    const { getApp } = useCompanyStore();
    getApp('google_reserve').then((app) => {
      rwgEnabled.value = !!app;
    });

    const showResourcePricesModal = ref(false);

    const route = useRoute();
    const serviceId = ref(parseInt(route.params.serviceId as string));

    const { createOffering, availabilitySet } = useAvailability();
    provide('serviceId', serviceId);

    return {
      createService,
      updateService,
      deleteService,
      v$: useVuelidate(),
      scrollContainer,
      rwgEnabled,
      showResourcePricesModal,
      serviceId,
      createOffering,
      availabilitySet,
      unleash
    };
  },
  data() {
    const { locationIds } = useLocationsStore();
    const maxDuration = 1439;

    return {
      maxDuration,
      maxDurationProcessing: Math.floor(maxDuration / 3),
      showCategoryModal: false,
      formData: {
        bookable: true,
        bookableLocationIds: locationIds,
        buffer: 0,
        categoryId: null,
        description: '',
        duration: 0,
        durationFinish: 0,
        durationProcessing: 0,
        durationSetup: 0,
        extraInformation: '',
        serviceOfferingEnabled: false,
        medical: false,
        name: '',
        price: 0,
        rebookEnabled: false,
        requirementsAttributes: [],
        requiresProcessingTime: false,
        resourceAdjustmentsAttributes: [],
        rwgName: '',
        vatRateId: null
      },
      rebookPeriod: null,
      isSubmitted: false,
      isSaving: false,
      hasDescription: false,
      hasExtraInformation: false,
      serviceDataFetched: false
    };
  },
  validations() {
    return {
      formData: {
        rwgName: {
          nameDifferentToItem(value: string) {
            return !/^\d+(\.|\]|\)|:)/.test(value);
          },
          nameDifferentToDuration(value: string) {
            return !/\d+\s*((m(ins?|inutes?|inuut|inuten|\s|\.|$))|(beurt(en)?)|(séance)|(h(ours?|rs?|\s|\.|$))|(s(ecs?|econds?|\s|\.|$))|(uur|uren))/.test(
              value
            );
          },
          nameDifferentToPrice(value: string) {
            return !/[$€]\s*\d+/.test(value);
          },
          nameDifferentToDescription(value: string) {
            return value?.length && this.formData.description?.length
              ? this.formData.description.trim() !== value.trim()
              : true;
          }
        }
      }
    };
  },
  watch: {
    serviceId: {
      handler(value) {
        if (value) {
          this.getService();
        }
      },
      immediate: true
    },
    'formData.description'(value) {
      if (value) {
        this.hasDescription = true;
      }
    },
    hasDescription(value) {
      if (!value) {
        this.formData.description = '';
      }
    },
    'formData.extraInformation'(value) {
      if (value) {
        this.hasExtraInformation = true;
      }
    },
    hasExtraInformation(value) {
      if (!value) {
        this.formData.extraInformation = '';
      }
    },
    'categories.length': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue && !this.serviceId && this.routeCategoryId) {
          const category = this.categories.find(
            (c) => c.id === this.routeCategoryId
          );
          if (category?.id) {
            this.formData.categoryId = category.id;
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useVatRatesStore, ['vatRates']),
    ...mapState(useServicesStore, {
      categories: 'serviceCategories'
    }),
    ...mapState(useCompanyStore, ['company', 'multiLocation']),
    routeCategoryId() {
      return parseInt(this.$route.params.categoryId as string) || null;
    },
    isLoading() {
      return !!this.serviceId && !this.serviceDataFetched;
    },
    showRebookSetting() {
      return this.hasFeatureFlag('module-marketing') && this.rebookPeriod;
    },
    onlineBookableError() {
      return (
        !this.formData.bookableLocationIds?.length &&
        this.formData.bookable &&
        this.isSubmitted
      );
    }
  },
  methods: {
    close() {
      this.$router.push({
        name: 'admin-services'
      });
    },
    getService() {
      this.serviceDataFetched = false;

      this.$apollo
        .query({
          query: GET_SERVICE,
          variables: {
            id: this.serviceId
          }
        })
        .then(({ data: { service } }) => {
          Object.keys(this.formData).forEach((key) => {
            if (service[key] !== undefined) {
              this.formData[key] = service[key];
            }
          });

          this.formData.requirementsAttributes = service.requirements.map(
            (req) => ({
              id: req.id,
              destroy: false,
              primary: req.primary,
              resourceIds: req.resources
                .filter((r) => r.state === 'active')
                .map((r) => r.id),
              type: req.type
            })
          );

          this.formData.categoryId = service.category.id;
          this.rebookPeriod = service.rebookPeriod;

          this.formData.resourceAdjustmentsAttributes =
            service.resourceAdjustments.map((adjustment: any) => ({
              id: adjustment.id,
              price: adjustment.price,
              resourceId: adjustment.resourceId
            }));

          this.serviceDataFetched = true;

          this.formData.serviceOfferingEnabled = service.offeringEnabled;
        });
    },
    onDeleteClick() {
      if (this.serviceId) {
        this.deleteService(this.serviceId).then(() => this.close());
      }
    },
    submit() {
      this.v$.$touch();
      this.isSubmitted = true;

      if (this.v$.$invalid) {
        return;
      }

      if (this.onlineBookableError) {
        return;
      }

      if (this.formData.serviceOfferingEnabled && !this.availabilitySet) {
        this.formData.serviceOfferingEnabled = false;
      }

      const { resourcesByType } = useResourcesStore();

      // When a requirement has been selected, but the company doesnt have any resources of that type, filter it out
      // Do this only for newly created requirements
      this.formData.requirementsAttributes =
        this.formData.requirementsAttributes.filter((requirement: any) => {
          if (requirement.type === 'EMPLOYEE' || requirement.id) {
            return true;
          }

          const resources = resourcesByType(requirement.type.toLowerCase());
          return !!resources.length;
        });

      this.isSaving = true;

      if (!this.formData.requiresProcessingTime) {
        this.formData.durationFinish = 0;
        this.formData.durationProcessing = 0;
        this.formData.durationSetup = 0;
      }

      if (this.serviceId) {
        this.updateService({
          ...this.formData,
          id: this.serviceId
        }).then(({ serviceId }) => {
          flash(this.$t('global.flash.service_updated'));
          this.afterSave(serviceId);
        });
      } else {
        this.createService(this.formData).then(({ serviceId }) => {
          flash(this.$t('global.flash.label_created'));
          this.mixpanel.track('Service created');
          this.afterSave(serviceId);
        });
      }
    },
    afterSave(serviceId: number) {
      this.createOffering(serviceId).then(() => {
        this.close();
        this.isSaving = false;
      });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;

  &:not(.smallScreen) {
    display: flex;
    align-items: stretch;
  }
}

.main {
  background-color: white;

  .base:not(.smallScreen) & {
    width: 65%;
    padding: $spacing * 1.5;
    overflow-y: auto;
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}

.side {
  .base:not(.smallScreen) & {
    border-left: 1px solid $color-border;
    padding: $spacing * 1.5;
    width: 35%;
    overflow-y: auto;
  }

  .base.smallScreen & {
    padding: $spacing;
    border-top: 1px solid $color-border;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
    margin-bottom: $spacing;
  }

  &.mobileFull {
    .base.smallScreen & {
      & > * {
        width: 100%;
      }
    }
  }
}

.processingTimeWrap {
  width: 100% !important;
}

.processingTime {
  display: flex;
  justify-content: space-between;

  & > * {
    width: calc(33.33% - 10px);
  }
}

.processingToggle {
  margin-top: $spacing;
}

.durationWrap {
  &.wide {
    width: 100%;
  }

  &:not(.wide) {
    .processingToggle {
      width: calc(200% + #{$spacing});
    }
  }
}
</style>
