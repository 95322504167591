import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';

import { useLocationsStore } from '@/stores/locations';
import type { ServiceFormData } from './types';
import { GET_SERVICE } from '../graphql';
import { useLeaveConfirmation } from '@/helpers/routing';
import { useServicesStore } from '@/stores/services';

export const useService = (id?: number) => {
  const route = useRoute();
  const isLoading = ref(false);
  const { locationIds } = useLocationsStore();
  const { createService, updateService, deleteService } = useServicesStore();

  const formData: ServiceFormData = reactive({
    bookable: true,
    bookableLocationIds: locationIds,
    buffer: 0,
    categoryId: route.params.categoryId
      ? parseInt(route.params.categoryId as string)
      : 0,
    description: '',
    duration: 0,
    durationFinish: 0,
    durationProcessing: 0,
    durationSetup: 0,
    extraInformation: '',
    serviceOfferingEnabled: false,
    medical: false,
    name: '',
    price: 0,
    rebookEnabled: false,
    requirementsAttributes: [],
    requiresProcessingTime: false,
    resourceAdjustmentsAttributes: [],
    rwgName: '',
    vatRateId: null
  });

  const { resetConfirmation } = useLeaveConfirmation(formData);

  const onlineBookableError = computed(
    () => !formData.bookableLocationIds?.length && formData.bookable
  );
  const rebookPeriod = ref(null);

  if (id) {
    isLoading.value = true;
    const { onResult } = useQuery(GET_SERVICE, { id });
    onResult(({ data: { service } }) => {
      isLoading.value = false;

      Object.keys(formData).forEach((key) => {
        if (service[key] !== undefined) {
          formData[key] = service[key];
        }
      });

      formData.requirementsAttributes = service.requirements.map((req) => ({
        id: req.id,
        destroy: false,
        primary: req.primary,
        resourceIds: req.resources
          .filter((r) => r.state === 'active')
          .map((r) => r.id),
        type: req.type
      }));

      formData.categoryId = service.category.id;

      rebookPeriod.value = service.rebookPeriod;

      formData.resourceAdjustmentsAttributes = service.resourceAdjustments.map(
        (adjustment: any) => ({
          id: adjustment.id,
          price: adjustment.price,
          resourceId: adjustment.resourceId
        })
      );

      formData.serviceOfferingEnabled = service.offeringEnabled;

      nextTick(() => {
        resetConfirmation();
      });
    });
  }

  return {
    isLoading,
    formData,
    onlineBookableError,
    rebookPeriod,
    resetConfirmation,
    createService,
    updateService,
    deleteService
  };
};
