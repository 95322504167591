<template>
  <div :class="$style.base">
    <CreateNote
      v-if="createNoteModal"
      :currentNote="order.note || undefined"
      @submit="order.note = $event"
      @close="createNoteModal = false"
    />
    <div :class="[$style.note]">
      <BaseIcon name="message-circle" :mr="0.5" />
      <BaseText v-if="order.note" v-test="'register-order-note'">
        {{ order.note }}
      </BaseText>
      <div v-else>
        <BaseText
          link
          @click="createNoteModal = true"
          v-test="'register-add-note'"
        >
          {{ filters.capitalize($t('global.actions.add_note')) }}
        </BaseText>
      </div>
    </div>
    <BaseMore
      v-if="order.note"
      :options="['edit', 'delete']"
      @select="onOptionSelect"
      v-test="'register-order-item-options'"
    />
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import CreateNote from '@/modals/CreateNote.vue';
import { ref } from 'vue';

const { order } = useRegisterOrderStore();
const createNoteModal = ref(false);

const onOptionSelect = (option: string) => {
  switch (option) {
    case 'edit':
      createNoteModal.value = true;
      break;
    case 'delete':
      order.note = null;
      break;
  }
};
</script>
<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.note {
  display: flex;
  align-items: center;
}
</style>
