const Integrations = () => import('@/modules/admin/integrations/index.vue');
const Mollie = () => import('@/modules/admin/integrations/settings/Mollie.vue');
const Franchise = () =>
  import('@/modules/admin/integrations/settings/Franchise.vue');
const GoogleCalendar = () =>
  import('@/modules/admin/integrations/settings/google-calendar/index.vue');
const Mailchimp = () =>
  import('@/modules/admin/integrations/settings/Mailchimp.vue');
const TreatwellMarketplace = () =>
  import(
    '@/modules/admin/integrations/settings/treatwell-marketplace/index.vue'
  );

export default {
  path: 'apps',
  name: 'integrations',
  component: Integrations,
  children: [
    {
      path: 'franchise',
      name: 'integration-franchise',
      component: Franchise,
      props: {
        parentRouteName: 'integrations'
      }
    },
    {
      path: 'mailchimp',
      name: 'integration-mailchimp',
      component: Mailchimp,
      props: {
        parentRouteName: 'integrations'
      }
    },
    {
      path: 'mollie',
      name: 'integration-mollie',
      component: Mollie,
      props: {
        parentRouteName: 'integrations'
      }
    },
    {
      path: 'google-calendar',
      name: 'integration-google-calendar',
      component: GoogleCalendar,
      props: {
        parentRouteName: 'integrations'
      }
    },
    {
      path: 'google-reserve',
      name: 'integration-google-reserve',
      component: {
        template: '<template />'
      },
      meta: {
        emberRoute: 'apps.google-reserve'
      }
    },
    {
      path: 'treatwell-marketplace-admin',
      name: 'integration-treatwell-marketplace',
      component: TreatwellMarketplace,
      props: {
        parentRouteName: 'integrations'
      }
    },
    {
      path: 'treatwell',
      name: 'integration-treatwell',
      component: {
        template: '<template />'
      },
      meta: {
        emberRoute: 'apps.treatwell'
      }
    },
    {
      path: 'fiscalization',
      name: 'integration-fiscalization',
      component: {
        template: '<template />'
      },
      meta: {
        emberRoute: 'apps.fiscalization'
      }
    }
  ]
};
