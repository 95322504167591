<template>
  <div :class="$style.base">
    <BaseHeading :mb="0.25">
      {{ $t('marketing.email.automated_emails.title') }}
    </BaseHeading>
    <BaseText>
      {{ $t('marketing.email.automated_emails.description') }}
    </BaseText>
    <div :class="$style.inner">
      <TemplateCard
        :title="$t('marketing.email.automated_emails.rebook.title')"
        :description="$t('marketing.email.automated_emails.rebook.description')"
        :enabled="rebookReminderEnabled"
        :routerLink="{ name: 'rebook-reminder-template' }"
        mr
        mb
      />
      <TemplateCard
        :title="$t('marketing.email.automated_emails.waiting_list.title')"
        :description="
          $t('marketing.email.automated_emails.waiting_list.description')
        "
        :enabled="waitingListEnabled"
        :routerLink="{ name: 'waiting-list-template' }"
        mr
        mb
      />
      <TemplateCard
        :title="$t('marketing.email.automated_emails.birthday.title')"
        :description="
          $t('marketing.email.automated_emails.birthday.description')
        "
        :enabled="birthdayMessageEnabled"
        :routerLink="{ name: 'birthday-template' }"
        mixpanelEvent="Birthday template - Settings clicked"
        mr
        mb
      />
      <TemplateCard
        :title="$t('marketing.email.automated_emails.online_forms.title')"
        :description="
          $t('marketing.email.automated_emails.online_forms.description')
        "
        enabled="true"
        :routerLink="{ name: 'online-forms' }"
        mixpanelEvent="Online forms - Settings clicked"
        mr
        v-intercom="'marketing-email-forms-card'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TemplateCard from './TemplateCard.vue';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';
import { useServicesStore } from '@/stores/services';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    TemplateCard
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    ...mapState(useUserStore, ['hasFeatureFlag']),
    rebookReminderEnabled() {
      const { rebookEnabledServicesCount } = useServicesStore();
      return (
        this.companySettings.bookings.rebookReminderEnabled &&
        rebookEnabledServicesCount > 0
      );
    },
    waitingListEnabled() {
      return this.companySettings.bookings.waitingListEnabled;
    },
    birthdayMessageEnabled() {
      return this.companySettings.customers.birthdayMessageEnabled;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing 0;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  background: $grey-light;
  width: calc(100% + #{$spacing * 2});
  padding: $spacing $spacing;
  margin-top: $spacing;
  margin-left: -$spacing;
  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;
}
</style>
