<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :text="{
      title: $t('upgrade_subscription_page.register.checkout.title'),
      description: $t(
        'upgrade_subscription_page.register.checkout.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.register.checkout.learn_more'),
      url: $t('upgrade_subscription_page.register.checkout.learn_more_url')
    }"
    imageName="register"
    v-test="'empty-page'"
  />

  <BaseSpinner v-else-if="loadingData" />

  <div
    v-else
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div v-show="showMobileBrowser || $screen !== 's'" :class="$style.browser">
      <Browser
        @hideMobileBrowser="showMobileBrowser = false"
        @createNote="showCreateNoteModal = true"
      />
    </div>
    <div v-show="!showMobileBrowser || $screen !== 's'" :class="$style.order">
      <Order
        @showMobileBrowser="showMobileBrowser = true"
        @editNote="showCreateNoteModal = true"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'RegisterBuilder'
};
</script>

<script setup lang="ts">
import Browser from './browser/index.vue';
import Order from './order/index.vue';

import { ref, computed } from 'vue';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';
import { useRoute, useRouter } from 'vue-router';
import { useRegisterStore } from '../stores/register';
import { storeToRefs } from 'pinia';
import { useRegisterOrderStore } from '../stores/order';
import { setAppointmentData } from './actions/appointment';
import { setOrderData } from './actions/order';
import { setCustomerData } from './actions/customer';
import { TransactionType } from '@/types';

const showCreateNoteModal = ref(false);
const { hasFeatureFlag } = useUserStore();
const { showMobileBrowser } = storeToRefs(useRegisterStore());
const { order, reset } = useRegisterOrderStore();
const route = useRoute();
const router = useRouter();

const { loadData: loadAppointment, loading: loadingAppointment } =
  setAppointmentData();
const { loadData: loadOrder, loading: loadingOrder } = setOrderData();
const { loadData: loadCustomer, loading: loadingCustomer } = setCustomerData();
const loadingData = computed(
  () => loadingAppointment.value || loadingOrder.value || loadingCustomer.value
);

const orderIsNotEditable = computed(() => {
  const hasPosTransaction = !!order.transactions.find(
    (t) => t.type === TransactionType.Pos
  );

  return (
    !order.permissions?.editOrderItems ||
    (hasPosTransaction && route.query.action !== 'modify')
  );
});

if (route.query.direct) {
  reset();
}

const checkRedirect = () => {
  if (route.query.direct) {
    router.replace({
      name: 'register-payment',
      query: {
        direct: true
      }
    });
  }
};

if (route.query.orderId) {
  loadOrder().then(() => {
    checkRedirect();
  });
} else if (route.query.appointmentId) {
  loadAppointment().then(() => {
    checkRedirect();
  });
} else if (route.query.customerId) {
  loadCustomer();
} else if (order.id && orderIsNotEditable.value) {
  reset();
}

if (!route.query.direct && Object.keys(route.query).length) {
  router.replace({ query: {} });
}
</script>

<style lang="scss" module>
.base {
  height: 100%;
  display: flex;

  &.smallScreen {
    flex-direction: column;
  }
}

.browser,
.order {
  height: 100%;
}

.browser {
  .base:not(.smallScreen) & {
    width: 35%;
    min-width: 300px;
    border-right: 1px solid $color-border;
  }
}

.order {
  padding: $spacing;

  .base:not(.smallScreen) & {
    width: 65%;
  }
}
</style>
