<template>
  <BaseCard>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div :class="$style.header">
        <div :class="$style.service">
          <BaseHeading size="s">
            {{ $t('global.name') }}
          </BaseHeading>
        </div>
        <div v-if="!hasSingleResource" :class="$style.resources">
          <BaseHeading size="s">
            {{ filters.capitalize($t('global.items.resource', 2)) }}
          </BaseHeading>
        </div>
        <div :class="$style.duration">
          <BaseHeading size="s">
            {{ $t('global.duration') }}
          </BaseHeading>
        </div>
      </div>
      <Part
        v-for="(part, index) in data"
        :key="`part-${index}`"
        :part="part"
        v-test="'appointment-part'"
      />
    </div>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import Part from './Part.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Part
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    ...mapState(useResourcesStore, ['hasSingleResource'])
  }
});
</script>

<style lang="scss" module>
.base {
  margin-bottom: $spacing * -1;
}

.header {
  display: flex;
  border-bottom: 1px solid $color-border;
  padding-bottom: $spacing;
}

.service {
  flex-grow: 1;

  .base.smallScreen & {
    width: 35%;
  }

  .base:not(.smallScreen) & {
    width: 40%;
  }
}

.resources {
  .base.smallScreen & {
    width: 45%;
  }

  .base:not(.smallScreen) & {
    width: 40%;
  }
}

.duration {
  width: 20%;
}
</style>
