import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useUserStore } from '@/stores/user';

export const useRegisterStore = defineStore('register', () => {
  const { hasFeatureFlag } = useUserStore();
  const cashupEnabled = computed(() => !!hasFeatureFlag('beta-cashup'));
  const showMobileBrowser = ref(false);

  return {
    cashupEnabled,
    showMobileBrowser
  };
});
