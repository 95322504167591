<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('admin.nav.links.locations')">
      <template #header>
        <BaseButton
          :class="$style.button"
          :routerLink="{
            name: 'admin-location-new'
          }"
          v-test="'location-new'"
        >
          {{ $t('locations.create_location') }}
        </BaseButton>
      </template>
      <BaseCard v-if="locations.length">
        <BaseTable
          :headers="[
            $t('locations.fields.internal_name'),
            $t('locations.fields.name'),
            $t('locations.bookable'),
            $t('locations.fields.address'),
            $t('locations.fields.sort_order')
          ]"
          :rows="locationRows"
          @action="onAction"
          v-test="'locations'"
        />
      </BaseCard>
      <BaseVisualBanner
        mt
        color="blue"
        :heading="t('locations.banner.heading')"
        imagePath="/img/admin/locations/salon.svg"
        variant="small"
        :description="t('locations.banner.description')"
        :primaryAction="{
          text: $t('global.learn_more'),
          href: $t('locations.banner.url')
        }"
        v-test="'locations-banner'"
      />
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import ModuleBase from '@/modules/ModuleBase.vue';
import { useMutation } from '@vue/apollo-composable';
import { DELETE_LOCATION } from './graphql';
import { computed } from 'vue';
import type { Location, Resource } from '@/types';
import { useI18n } from 'vue-i18n';
import { useResourcesStore } from '@/stores/resources';
import { modal } from '@/helpers/ui';
import { useLocationsStore } from '@/stores/locations';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import PageWrap from '../PageWrap.vue';

const { t } = useI18n();
const { resources } = storeToRefs(useResourcesStore());
const { locations } = storeToRefs(useLocationsStore());

const { mutate: deleteLocation } = useMutation(DELETE_LOCATION);
const router = useRouter();

const onAction = ({ option, id }: { option: string; id: number }) => {
  if (option === 'edit') {
    router.push({ name: 'admin-location', params: { id } });
  }

  if (option === 'delete') {
    const locationResources = resources.value.filter((resource: Resource) =>
      resource.locationIds.includes(id)
    );
    if (locationResources.length) {
      modal('warning', {
        message: t('admin.location.delete_error')
      });
    } else {
      const location = locations.value.find(
        (location: Location) => location.id === id
      );
      modal('confirmation', {
        message: t('global.confirmation.confirm_delete', {
          item: location?.name
        })
      }).then(() => {
        deleteLocation({ input: { id } }).then(
          ({
            data: {
              deleteLocation: { location }
            }
          }: any) => {
            removeLocation(location.id);
          }
        );
      });
    }
  }
};

const sortedLocations = computed(() =>
  [...locations.value]
    .sort((a: any, b: any) => a.id - b.id)
    .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
);

const locationRows = computed(() =>
  sortedLocations.value?.map((location: Location) =>
    locationAttributes(location)
  )
);

const locationAttributes = (location: Location): any => ({
  id: location.id,
  cells: [
    location.internalName,
    location.name,
    location.bookable,
    location.address,
    String(location.sortOrder)
  ],
  name: location.internalName,
  sortOrder: location.sortOrder,
  actions: ['edit', 'delete']
});

const removeLocation = (id: number) => {
  locations.value = locations.value.filter(
    (location: Location) => location.id !== id
  );
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: space-between;
  padding: $spacing;
}
</style>
