<template>
  <div :class="$style.base">
    <SafeTeleport to="#header-teleport">
      <div :class="$style.header">
        {{ $t('nav.register') }}
        <div
          v-if="
            unleash.isEnabled('RegisterRedesign') &&
            hasFeatureFlag('module-register')
          "
          :class="$style.toggle"
        >
          <BaseToggle
            v-model="newDesignEnabled"
            :label="$t('nav.new_calendar.tooltip')"
          />
        </div>
      </div>
    </SafeTeleport>

    <BuilderNew v-if="newDesignEnabled" />
    <BuilderOld v-else />
  </div>
</template>

<script setup lang="ts">
import BuilderNew from '@/modules/register/builder/index.vue';
import BuilderOld from '@/modules/register/builder-old/index.vue';
import { useStorage } from '@vueuse/core';
import unleash from '@/unleash';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import config from '@/config';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const { hasFeatureFlag } = useUserStore();

const { company } = useCompanyStore();
const newDesignEnabled = ref(company.settings.general.registerNewDesign);

provide('newDesignEnabled', newDesignEnabled);

watch(newDesignEnabled, (newDesignEnabled) => {
  const { mutate } = useMutation(gql`
    mutation toggleRegisterDesign($input: ToggleRegisterDesignInput!) {
      toggleRegisterDesign(input: $input) {
        registerNewDesign
      }
    }
  `);

  mutate({
    input: {
      newDesignEnabled
    }
  }).then(() => {
    company.settings.general.registerNewDesign = newDesignEnabled;
  });
});

const _newDesignEnabled = useStorage('register_redesign_enabled', false);
if (_newDesignEnabled.value) {
  newDesignEnabled.value = true;
  _newDesignEnabled.value = false;
}

const mixpanel = inject<any>('mixpanel');

watch(newDesignEnabled, (value) => {
  mixpanel.track(`Register - New design ${value ? 'enabled' : 'disabled'}`);
});

if (!unleash.isEnabled('RegisterRedesign') && !config.isTest) {
  newDesignEnabled.value = false;
}
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  gap: $spacing;
}

.toggle {
  font-size: 12px;
}
</style>
