<template>
  <div>
    <Modal
      v-if="showModal"
      :options="options"
      @submit="onSubmit"
      @close="onClose"
    />

    <BaseCard gray noBorder mb>
      <BaseCheckbox
        v-model="hasAvailabilities"
        :label="t('service.availability.setting_label')"
        :description="t('service.availability.setting_description')"
        v-test="'service-availability-toggle'"
      />
      <BaseCard v-show="hasAvailabilities" mt>
        <div v-if="availabilitySet" :class="$style.inner">
          <BaseText>
            {{ t('service.availability.selected') }}
          </BaseText>
          <BaseText
            link
            @click="showModal = true"
            v-test="'service-availability-edit'"
          >
            {{ $t('global.actions.edit') }}
          </BaseText>
        </div>
        <BaseButton
          v-else
          color="inverted"
          @click="showModal = true"
          v-test="'service-availability-create'"
        >
          {{ t('service.availability.set_availability') }}
        </BaseButton>
      </BaseCard>
    </BaseCard>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ServiceAvailabilities'
};
</script>

<script lang="ts" setup>
import Modal from './modal/index.vue';
import { useI18n } from 'vue-i18n';
import { useAvailability } from './index';
import { useRoute } from 'vue-router';
import { useCloned } from '@vueuse/core';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const route = useRoute();
const serviceId = ref(parseInt(route.params.serviceId as string));

const { t } = useI18n();
const showModal = ref(false);
const hasAvailabilities = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});
const {
  options: originalOptions,
  availabilitySet,
  reset,
  offeringFetched,
  getOffering,
  availabilityChanged
} = useAvailability();
const { cloned: options } = useCloned(originalOptions);

const onSubmit = () => {
  reset();

  originalOptions.value = options.value;
  availabilityChanged.value = true;
  showModal.value = false;
};

const onClose = () => {
  reset();
  showModal.value = false;
};

watch(
  hasAvailabilities,
  () => {
    if (serviceId.value && hasAvailabilities.value && !offeringFetched.value) {
      getOffering(serviceId.value);
    }
  },
  {
    immediate: true
  }
);

onBeforeMount(() => {
  reset();
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: $spacing * 0.5;
  }
}
</style>
