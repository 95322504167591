<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :text="{
      title: $t('upgrade_subscription_page.register.day_overview.title'),
      description: $t(
        'upgrade_subscription_page.register.day_overview.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    imageName="day-overview"
  />

  <div v-else>
    <router-view />
    <BaseGrid container :mb="0.5">
      <BaseGrid :size="3">
        <BaseDatePicker v-model="date" onlyPastDates />
      </BaseGrid>
      <BaseGrid :size="9" :class="$style.buttons">
        <BaseButton
          :mr="0.5"
          color="inverted"
          icon="arrow-left"
          @click="changeDate(-1)"
        />
        <BaseButton
          color="inverted"
          :disabled="showingToday"
          @click="setCurrentDate"
        >
          {{ $t('global.today') }}
        </BaseButton>
        <BaseButton
          :ml="0.5"
          color="inverted"
          :disabled="showingToday"
          icon="arrow-right"
          @click="changeDate(1)"
        />
      </BaseGrid>
    </BaseGrid>
    <div v-show="orders && orders.length" :class="$style.tableWrap">
      <div :class="$style.table">
        <BaseTableRow head>
          <BaseTableCell :width="10">{{ $t('global.number') }}</BaseTableCell>
          <BaseTableCell :width="10">{{ $t('global.time') }}</BaseTableCell>
          <BaseTableCell :width="10">{{
            $t('global.items.employee', 2)
          }}</BaseTableCell>
          <BaseTableCell :width="10">{{
            $t('global.items.customer', 1)
          }}</BaseTableCell>
          <BaseTableCell :width="45">{{
            $t('global.items.item', 2)
          }}</BaseTableCell>
          <BaseTableCell :width="15">{{ $t('global.status') }}</BaseTableCell>
        </BaseTableRow>
        <BaseTableRow
          v-for="(order, index) in orders"
          :key="`row-${index}`"
          :routerLink="{ name: 'day-overview-item', params: { id: order.id } }"
          v-test="'baseTableRow'"
        >
          <BaseTableCell :width="10">{{ order.number }}</BaseTableCell>
          <BaseTableCell :width="10">{{
            filters.time(order.invoicedAt)
          }}</BaseTableCell>
          <BaseTableCell :width="10">{{
            employeeNames(order.items)
          }}</BaseTableCell>
          <BaseTableCell :width="10">{{
            order.customer ? order.customer.fullName : '-'
          }}</BaseTableCell>
          <BaseTableCell :width="45">
            <BaseTableRow
              v-for="(item, index) in order.items"
              :key="`item-sub-${index}`"
              small
            >
              <BaseTableCell>{{ item.description }}</BaseTableCell>
              <BaseTableCell>{{ item.quantity }}</BaseTableCell>
              <BaseTableCell>{{ filters.currency(item.price) }}</BaseTableCell>
              <BaseTableCell>{{
                filters.currency(item.quantity * item.price)
              }}</BaseTableCell>
            </BaseTableRow>
            <BaseTableRow
              v-for="(transaction, index) in order.transactions"
              :key="`transaction-${index}`"
              small
              footer
            >
              <BaseTableCell />
              <BaseTableCell />
              <BaseTableCell>{{
                $t(`global.payments.${transaction.type.toLowerCase()}`)
              }}</BaseTableCell>
              <BaseTableCell>{{
                filters.currency(transaction.amount)
              }}</BaseTableCell>
            </BaseTableRow>
          </BaseTableCell>
          <BaseTableCell :width="15">
            <BaseLabel :state="order.state.toLowerCase()" />
          </BaseTableCell>
        </BaseTableRow>
        <Pagination
          :items="orders"
          :loading="$apollo.loading"
          fieldName="orders"
          :apolloQuery="$apollo.queries.orders"
          :extraVariables="{
            date: date,
            withoutDrafts: true,
            locationId,
            dataScope
          }"
        />
      </div>
    </div>
    <EmptyPageContent v-show="noResults" imageName="day-overview" />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import Pagination from '@/components/_deprecated/Pagination.vue';
import gql from 'graphql-tag';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';

export default defineComponent({
  components: {
    Pagination,
    EmptyPageContent,
    BaseTableRow,
    BaseTableCell,
    EmptySubscriptionPage
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      date: null
    };
  },
  apollo: {
    orders: {
      query: gql`
        query getOrders(
          $withoutDrafts: Boolean
          $date: ISO8601Date
          $pagination: PaginationAttributes
          $locationId: ID
          $dataScope: DataScope
        ) {
          orders(
            withoutDrafts: $withoutDrafts
            date: $date
            pagination: $pagination
            locationId: $locationId
            dataScope: $dataScope
          ) {
            id
            state
            number
            invoicedAt
            customer {
              fullName
            }
            items {
              price
              quantity
              description
              employee {
                name
              }
            }
            transactions {
              type
              amount
            }
          }
        }
      `,
      variables() {
        return {
          withoutDrafts: true,
          date: this.date,
          pagination: {
            currentPage: 1,
            perPage: 25
          },
          locationId: this.locationId,
          dataScope: this.dataScope
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    showingToday() {
      return dayjs(this.date).isToday();
    },
    noResults() {
      return !this.$apollo.loading && this.orders && !this.orders.length;
    }
  },
  methods: {
    setCurrentDate() {
      this.date = dayjs().tz().format('YYYY-MM-DD');
    },
    changeDate(offset) {
      this.date = dayjs(this.date).add(offset, 'day').format('YYYY-MM-DD');
    },
    employeeNames(items) {
      const names = items
        .filter((item) => item.employee)
        .map((item) => item.employee.name);
      return [...new Set(names)].join(', ');
    }
  },
  created() {
    this.setCurrentDate();
  }
});
</script>

<style lang="scss" module>
.tableWrap {
  overflow-x: auto;
}

.table {
  min-width: 1050px;
}

.buttons {
  display: flex;
}
</style>
