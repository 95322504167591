<template>
  <div
    :class="{ [$style.smallScreen]: $screen === 's' }"
    v-test="'register-order-item'"
  >
    <div v-test="'register-order-item-text'">
      <div :class="$style.top">
        <div
          :class="[$style.headers, { [$style.cursor]: canExpand }]"
          @click="setExpand"
        >
          <div v-if="item.giftcard" :class="$style.card">
            {{ `${$t('global.payments.giftcard')} (${item.giftcard.code})` }}
          </div>
          <div v-else-if="item.prepaidCard" :class="$style.card">
            {{
              `${$t('global.payments.prepaid_card')}: ${item.prepaidCard.description} (${item.prepaidCard.visits}x)`
            }}
          </div>
          <div v-else-if="item.transactionCost" :class="$style.card">
            {{ item.name }}
          </div>

          <div v-else :class="$style.item">
            {{ `${item.quantity}x ${item.name}` }}
          </div>

          <BaseIcon
            v-if="adjustedPrice"
            name="info"
            :tooltip="{
              text: $t('register.price_adjusted'),
              touch: true
            }"
            :mr="0.5"
          />

          <div
            v-if="
              item.originalPrice !== 0 &&
              item.discount !== 0 &&
              item.originalPrice !== item.price
            "
          >
            <BaseText
              lineThrough
              inline
              :mr="0.5"
              v-test="'register-order-item-top-price-original'"
            >
              {{ filters.currency(item.quantity! * item.originalPrice!) }}
            </BaseText>
          </div>
          <div v-test="'register-order-item-top-price'">
            {{ filters.currency(item.price! * item.quantity!) }}
          </div>
        </div>
        <BaseButton
          v-if="options.length === 1 && options[0] === 'delete'"
          :ml="0.5"
          :mr="0.5"
          :mt="0.5"
          :mb="0.5"
          icon="delete"
          color="inverted"
          @click="onSelectAction('delete')"
          v-test="'register-order-item-delete'"
        />
        <BaseMore
          v-else
          :options="options"
          @select="onSelectAction"
          v-test="'register-order-item-options'"
        />
      </div>
    </div>
    <div v-show="expanded" :class="$style.inputContainer">
      <div v-if="itemIsCardType" :class="[$style.input, $style.small]">
        <BaseInput
          v-model="item.quantity"
          :label="$t('global.quantity')"
          type="number"
          controls
          mr
          v-test="'register-order-item-quantity'"
        />
      </div>
      <div v-if="itemIsCardType" :class="$style.input">
        <BaseInput
          v-model="item.price"
          :label="$t('global.price')"
          type="currency"
          mr
          @update:modelValue="onPriceUpdate"
          v-test="'register-order-item-price'"
        />
      </div>
      <div v-if="itemIsCardType" :class="[$style.input, $style.small]">
        <BaseInput
          v-model="item.discount"
          :label="$t('global.discount')"
          type="number"
          unitLabel="percentage"
          :decimals="2"
          :maxValue="100"
          :disabled="disabledDiscount"
          mr
          @update:modelValue="onDiscountUpdate"
          v-test="'register-order-item-discount'"
        />
      </div>
      <div v-if="canSetLoyaltyPoints" :class="$style.input">
        <BaseInput
          v-model="item.loyaltyPointsAmount"
          :label="$t('register.redeem_loyalty_points')"
          type="number"
          mr
          :minValue="0"
          :maxValue="selectedCustomer?.loyaltyPoints || 0"
          v-test="'register-order-item-loyalty-points'"
        />
      </div>
      <div
        v-show="!item.transactionCost && (multipleEmployees ?? itemIsCardType)"
        :class="[$style.input, $style.maxWidth]"
      >
        <BaseDropdown
          v-model="item.employeeId"
          :options="
            employees.map((employee) => ({
              value: employee.id,
              label: employee.name
            }))
          "
          :label="$t('global.items.employee', 1)"
          resources
          mr
          :required="
            !item.transactionCost && (multipleEmployees ?? itemIsCardType)
          "
          v-test="'register-order-item-employee'"
        />
      </div>
      <div v-if="company.medical && itemIsCardType" :class="$style.lastItem">
        <BaseCheckbox
          v-model="item.medical"
          :label="$t('global.medical')"
          v-test="'register-order-item-medical'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';
import type { Appointment } from '@/types';
import type { OrderItem } from '@/modules/register/stores/order';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useServicesStore } from '@/stores/services';

const { t } = useI18n();

const props = defineProps<{
  item: OrderItem;
  index: number;
}>();

const { order, removeItem, duplicateItem, removeOrderAppointment } =
  useRegisterOrderStore();
const { orderAppointments } = storeToRefs(useRegisterOrderStore());
const { selectedEmployeeId, selectedCustomer } = storeToRefs(
  useRegisterOrderStore()
);
const { employees } = storeToRefs(useResourcesStore());
const { company, isLoyaltyPointsEnabled } = useCompanyStore();
const { services } = useServicesStore();

const adjustedPrice = ref(false);

const orderLength = computed(() => order.items.length);

const expanded = ref(props.index === orderLength.value - 1);

const disabledDiscount = computed(() => props.item.originalPrice === 0);

const itemIsCardType = computed(
  () =>
    !props.item.prepaidCard &&
    !props.item.giftcard &&
    !props.item.transactionCost
);
const multipleEmployees = computed(
  () => selectedEmployeeId.value === 'multiple'
);
const canSetLoyaltyPoints = computed(
  () =>
    order.customerId &&
    isLoyaltyPointsEnabled &&
    itemIsCardType.value &&
    (!order.state || !['PAID', 'OUTSTANDING'].includes(order.state))
);

const options = computed(() => {
  if (props.item.giftcard || props.item.transactionCost) {
    return ['delete'];
  }

  if (props.item.prepaidCard) {
    return ['delete', 'duplicate'];
  }

  return ['delete', 'edit', 'duplicate'];
});

const onDiscountUpdate = () => {
  if (props.item.price === 0) {
    return;
  }

  if (props.item.originalPrice === 0) {
    return;
  }

  props.item.price = Math.round(
    props.item.originalPrice! * (1 - props.item.discount / 100)
  );
};

if (props.item.discount !== 0) {
  onDiscountUpdate();
}

const onPriceUpdate = () => {
  if (props.item.price === 0) {
    props.item.discount = 0;
  }

  if (props.item.originalPrice === 0) {
    props.item.discount = 0;
  }

  if (props.item.originalPrice !== 0) {
    props.item.discount =
      100 - (props.item.price! * 100) / props.item.originalPrice;
  }
};

const onSelectAction = (action: string) => {
  switch (action) {
    case 'delete':
      checkConnectedAppointment();
      removeItem(props.item._uid);
      break;
    case 'edit':
      if (props.item.prepaidCard || props.item.giftcard) {
        return;
      }

      expanded.value = !expanded.value;
      break;
    case 'duplicate':
      duplicateItem({ _uid: props.item._uid, price: props.item.price || 0 });
      break;
  }
};

const checkConnectedAppointment = () => {
  let lastAppointmentPart;
  let orderAppointment: Appointment | undefined;
  if (props.item.appointmentId) {
    orderAppointment = orderAppointments.value.find(
      (appointment: Appointment) => appointment.id === props.item.appointmentId
    );
    if (orderAppointment) {
      lastAppointmentPart =
        order.items.filter(
          (item: OrderItem) => item.appointmentId === item.appointmentId
        ).length === 1;
    }
  }

  if (lastAppointmentPart) {
    modal('confirmation', {
      message: t('register.confirm_delete_appointment')
    }).then(() => {
      removeOrderAppointment(orderAppointment?.id as number);
    });
  }
};

const canExpand = computed(() => {
  if (
    (props.item.giftcard ||
      props.item.prepaidCard ||
      props.item.transactionCost) &&
    !multipleEmployees.value
  ) {
    return false;
  }

  if (multipleEmployees.value) {
    return true;
  }

  return true;
});

const setExpand = () => {
  if (canExpand.value) {
    expanded.value = !expanded.value;
  }
};

const service = services.find(
  (service: any) => service.id === props.item.serviceId
);

watch(
  () => props.item.employeeId,
  (employeeId) => {
    const serviceHasAdjustments = !!service?.resourceAdjustments?.length;
    const adjustment = service?.resourceAdjustments?.find(
      (adjustment: any) => adjustment.resourceId === employeeId
    );

    if (adjustment) {
      adjustedPrice.value = true;
      props.item.price = adjustment.price;
    } else if (serviceHasAdjustments) {
      adjustedPrice.value = false;
      props.item.price = props.item.originalPrice;
    }

    onPriceUpdate();
    onDiscountUpdate();
  },
  {
    immediate: true
  }
);

watch(selectedEmployeeId, () => {
  if (selectedEmployeeId.value === 'multiple') {
    expanded.value = true;
  }
});

watch(
  orderLength,
  () => {
    if (props.index < orderLength.value - 1) {
      expanded.value = false;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" module>
.top {
  display: flex;

  .smallScreen & {
    margin-top: $spacing * 0.5;
  }
}

.headers {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &.cursor {
    cursor: pointer;
  }
}

.item {
  width: 100%;
}

.card {
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .smallScreen & {
    & > .input {
      margin-top: $spacing;
    }
  }
}

.input {
  display: flex;
  align-items: flex-end;
  width: 150px;
  max-width: 150px;
  margin-bottom: $spacing;

  &.small {
    width: 100px;
  }

  & > * {
    width: 100%;
  }
}

.lastItem {
  height: 30px;
  align-self: center;
}
</style>
