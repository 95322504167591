<template>
  <div :class="$style.base">
    <div
      :class="$style.back"
      @click="
        $router.push({
          name: 'register-builder'
        })
      "
      v-test="'register-browser-back'"
    >
      <BaseText link iconBefore="arrow-left">
        {{ $t('global.back') }}
      </BaseText>
    </div>
    <div :class="$style.items">
      <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="{
          name: item.routeName
        }"
        :class="$style.item"
        :active-class="$style.active"
        v-test="`register-browser-${item.name}`"
      >
        <BaseIcon :name="item.icon" />
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
const items = ref([
  {
    routeName: 'register-builder-services',
    icon: 'briefcase',
    name: 'services'
  },
  {
    routeName: 'register-builder-products',
    icon: 'pricetag',
    name: 'products'
  },
  {
    routeName: 'register-builder-appointments',
    icon: 'calendar',
    name: 'appointments'
  },
  {
    routeName: 'register-builder-giftcard',
    icon: 'gift',
    name: 'giftcard'
  },
  {
    routeName: 'register-builder-prepaidcard',
    icon: 'credit-card',
    name: 'prepaid-card'
  }
]);
</script>

<style lang="scss" module>
.base {
  display: flex;
  border-bottom: 1px solid $color-border;
  height: 30px;
  flex-shrink: 0;
  padding: 0 $spacing * 0.5;
  gap: $spacing;
  margin-top: $spacing;
}

.back {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $spacing * 0.5;
  cursor: pointer;
}

.items {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.item {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: $color-primary;
  }

  &:not(.active) {
    &:after {
      display: none;
    }
  }
}
</style>
