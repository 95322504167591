<template>
  <div>
    <Modal
      v-if="showModal"
      :options="formData.serviceOfferingAttributes.payload.slots"
      @submit="showModal = false"
      @close="showModal = false"
    />

    <BaseCard gray noBorder mb>
      <BaseCheckbox
        v-model="formData.serviceOfferingEnabled"
        :label="t('service.availability.setting_label')"
        :description="t('service.availability.setting_description')"
        v-test="'service-availability-toggle'"
      />
      <BaseCard v-show="formData.serviceOfferingEnabled" mt>
        <div v-if="availabilitySet" :class="$style.inner">
          <BaseText>
            {{ t('service.availability.selected') }}
          </BaseText>
          <BaseText
            link
            @click="showModal = true"
            v-test="'service-availability-edit'"
          >
            {{ $t('global.actions.edit') }}
          </BaseText>
        </div>
        <BaseButton
          v-else
          color="inverted"
          @click="showModal = true"
          v-test="'service-availability-create'"
        >
          {{ t('service.availability.set_availability') }}
        </BaseButton>
      </BaseCard>
    </BaseCard>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ServiceAvailabilities'
};
</script>

<script lang="ts" setup>
import { DaysOfWeek } from '@/types';
import type {
  DayOption,
  ServiceVariationsFormData
} from '../service-variations/types';
import Modal from './modal/index.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const showModal = ref(false);

const formData = inject<ServiceVariationsFormData>('serviceFormData')!;
const availabilitySet = computed(
  () =>
    formData.serviceOfferingAttributes &&
    !!formData.serviceOfferingAttributes.payload.slots.find(
      (option) =>
        option.max || option.startTime || option.endTime || !option.available
    )
);

const daysOfWeek = [
  DaysOfWeek.Mo,
  DaysOfWeek.Tu,
  DaysOfWeek.We,
  DaysOfWeek.Th,
  DaysOfWeek.Fr,
  DaysOfWeek.Sa,
  DaysOfWeek.Su
];

const getServiceOfferingsDefaults = (): DayOption[] =>
  daysOfWeek.map((dayOfWeek) => ({
    available: true,
    dayOfWeek,
    endTime: '',
    max: 0,
    startTime: ''
  }));

watch(
  () => formData.serviceOfferingAttributes,
  () => {
    if (!formData.serviceOfferingAttributes) {
      formData.serviceOfferingAttributes = {
        offeringType: 'WEEKDAYS',
        payload: {
          slots: getServiceOfferingsDefaults()
        }
      };
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" module>
.inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: $spacing * 0.5;
  }
}
</style>
