<template>
  <ModuleBase noBorder gray :loading="loading">
    <PageWrap :heading="$t('promote.nav.links.feedback_widget')">
      <BaseText :mb="2">
        {{ $t('admin.feedback_widget.description') }}
      </BaseText>
      <BaseCard :heading="$t('global.settings')" :mb="2">
        <BaseSwitch
          v-model="widgetSize"
          :options="[
            {
              label: $t('admin.feedback_widget.size.large'),
              value: 'large'
            },
            {
              label: $t('admin.feedback_widget.size.small'),
              value: 'small'
            }
          ]"
          :label="$t('admin.feedback_widget.size.heading')"
          v-test="'feedback-widget-size'"
        />
        <div v-if="widgetSize === 'small'" class="mt-1">
          <BaseInputLabel :text="$t('admin.feedback_widget.see_all_label')" />
          <BaseInput
            v-model="seeAllUrl"
            type="url"
            v-test="'feedback-widget-mini-link'"
          />
        </div>
      </BaseCard>

      <BaseCard :mb="2" :heading="$t('global.widget_code')">
        <BaseText mb>
          {{ $t('admin.feedback_widget.snippet_heading') }}:
        </BaseText>
        <BaseCodeField
          :code="snippetCode"
          mb
          v-test="'feedback-widget-snippet'"
        />
        <BaseButton
          :href="$t('admin.feedback_widget.learn_more_link')"
          color="inverted"
        >
          {{ $t('global.learn_more') }}
        </BaseButton>
      </BaseCard>

      <BaseCard :heading="$t('global.preview')">
        <iframe
          :class="[
            $style.iframe,
            {
              [$style.small]: widgetSize === 'small'
            }
          ]"
          :src="iframeSrc"
          v-test="'feedback-widget-iframe'"
        />
      </BaseCard>
    </PageWrap>
  </ModuleBase>
</template>

<script setup lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import { computed, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useStorage } from '@vueuse/core';

const widgetSize = ref('large');
const widgetUrl = ref('');
const widgetUrlSmall = ref('');

const seeAllUrl = useStorage('feedback_widget_link', '');

const { onResult, loading } = useQuery(gql`
  query getFeedbackWidget {
    feedbackWidget {
      feedbackUrl
      miniFeedbackUrl
    }
  }
`);

onResult(({ data: { feedbackWidget } }) => {
  widgetUrl.value = feedbackWidget.feedbackUrl;
  widgetUrlSmall.value = feedbackWidget.miniFeedbackUrl;

  if (!seeAllUrl.value) {
    seeAllUrl.value = widgetUrl.value;
  }
});

const { result } = useQuery(
  gql`
    query getMicroSite {
      microSite {
        url
      }
    }
  `,
  null,
  {
    nextFetchPolicy: 'cache-first'
  }
);

const microSiteUrl = computed(() => result.value?.microSite.url || '');

const iframeSrc = computed(() => {
  if (widgetSize.value === 'small') {
    return `${widgetUrlSmall.value}?layout=embed&link=${seeAllUrl.value}`;
  } else {
    return `${widgetUrl.value}?layout=embed`;
  }
});

const snippetCode = computed(() => {
  if (widgetSize.value === 'small') {
    return (
      `<script src="https://cdn.salonized.com/widget.js" data-name="salonized" data-microsite-url="${microSiteUrl.value}"><` +
      `/script>
<div class="salonized-reviews-mini" data-link="${seeAllUrl.value}"></div>`
    );
  } else {
    return (
      `<script src="https://cdn.salonized.com/widget.js" data-name="salonized" data-microsite-url="${microSiteUrl.value}"><` +
      `/script>
<div class="salonized-reviews"></div>`
    );
  }
});
</script>

<style lang="scss" module>
.iframe {
  border: 0;

  &.small {
    width: 400px;
  }

  &:not(.small) {
    width: 100%;
    height: 800px;
  }
}
</style>
