<template>
  <BaseCard :heading="$t('admin.user_accounts.logs_area_title')">
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <div :class="$style.header">
        <BaseSearch
          v-model="variables.search"
          debounce
          v-test="'user-logs-search'"
        />
        <BaseDropdown
          v-model="variables.key"
          :label="$t('global.actions.filter_item', { item: $t('global.key') })"
          :placeholder="filters.capitalize($t('global.key'))"
          :options="[
            {
              value: null,
              label: filters.capitalize($t('global.all'))
            },
            ...keys.map((key) => ({
              value: key,
              label: $t(`logs.${key}`)
            }))
          ]"
          v-test="'user-logs-key'"
        />
        <BaseDropdown
          v-if="users.length"
          v-model="variables.userId"
          :label="
            $t('global.actions.filter_item', {
              item: $t('global.items.user', 1)
            })
          "
          :placeholder="filters.capitalize($t('global.items.user', 1))"
          :options="[
            {
              label: $t('global.all'),
              value: null
            },
            ...users.map((user: any) => ({
              label: user.name,
              value: user.id
            }))
          ]"
          v-test="'user-logs-users'"
        />
      </div>
      <BaseTable
        v-if="logs.length"
        :headers="
          [
            $t('global.date'),
            $t('global.key'),
            $t('global.items.user', 1),
            $t('global.items.customer', 1)
          ].filter((header) => !!header)
        "
        :rows="
          logs.map((log: any) => ({
            cells: [
              {
                value: filters.dateTime(log.createdAt),
                type: 'date'
              },
              $t(`logs.${log.key}`),
              log.userName,
              log.customerName
            ]
          }))
        "
        v-test="'user-logs'"
      />
      <LoadMore
        v-show="!allDataFetched && firstFetchFinished"
        @shown="fetchMore"
      />
      <BaseSpinner v-show="!firstFetchFinished" inline mt />
      <BaseText v-if="allDataFetched && !loading && !logs.length" center mt>
        {{ $t('global.no_results') }}
      </BaseText>
    </div>
  </BaseCard>
</template>

<script lang="ts">
export default {
  name: 'UserLogs'
};
</script>

<script lang="ts" setup>
import { usePagination } from '@/apollo/pagination';
import gql from 'graphql-tag';
import filters from '@/filters';
import { ref } from 'vue';
import LoadMore from '@/components/LoadMore.vue';
import { uniqueArray } from '@/helpers/formatting';

const {
  logs,
  variables,
  allDataFetched,
  loading,
  firstFetchFinished,
  onResult,
  fetchMore
} = usePagination({
  fieldName: 'logs',
  query: gql`
    query getLogs(
      $search: String
      $userId: ID
      $key: String
      $pagination: PaginationAttributes!
    ) {
      logs(
        search: $search
        key: $key
        userId: $userId
        pagination: $pagination
      ) {
        id
        customerName
        createdAt
        key
        userId
        userName
      }
    }
  `,
  variables: {
    key: null,
    search: '',
    userId: null
  }
});

const keys = ref<string[]>([]);
const users = ref<
  {
    id: string;
    name: string;
  }[]
>([]);

onResult(({ data }) => {
  if (data?.logs?.length) {
    keys.value = Array.from(
      new Set([
        ...keys.value,
        ...uniqueArray(data.logs, 'key').map((log) => log.key)
      ])
    ).sort();

    data.logs.forEach((log: any) => {
      if (log.userId && !users.value.find((user) => user.id === log.userId)) {
        users.value.push({
          id: log.userId,
          name: log.userName
        });
      }
    });
  }
});
</script>

<style lang="scss" module>
.header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  & > * {
    margin-right: $spacing;
    margin-bottom: $spacing;
  }

  .base.smallScreen & {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
