<template>
  <BaseAlert
    :text="
      $t(
        'register.loyalty_points',
        {
          customer: selectedCustomer?.fullName,
          amount: loyaltyPointsRemaining
        },
        loyaltyPointsRemaining
      )
    "
    :mb="0.5"
  />
</template>
<script lang="ts" setup>
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const { order } = useRegisterOrderStore();
const { selectedCustomer } = storeToRefs(useRegisterOrderStore());
const loyaltyPointsRemaining = computed(
  () =>
    (selectedCustomer.value?.loyaltyPoints || 0) -
    order.items.reduce(
      (acc, item) =>
        acc + (item.loyaltyPointsAmount ? item.loyaltyPointsAmount : 0),
      0
    )
);
</script>
