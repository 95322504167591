<template>
  <div v-if="!showMobileMenu">
    <div v-if="$screen === 'l'" :class="$style.buttons">
      <div
        :class="$style.button"
        @click="onCustomerAction('edit')"
        v-test="'customerEdit'"
      >
        <BaseIcon
          :tooltip="{
            position: 'bottom',
            text: $t('global.actions.edit_customer')
          }"
          name="edit"
        />
      </div>
      <div
        :class="$style.button"
        @click="onCustomerAction('delete')"
        v-test="'customerDelete'"
      >
        <BaseIcon
          :tooltip="{
            position: 'bottom',
            text: $t('global.actions.delete_customer')
          }"
          name="delete"
        />
      </div>
      <div
        :class="$style.button"
        @click="onCustomerAction('merge')"
        v-test="'customerMerge'"
      >
        <BaseIcon
          :tooltip="{
            position: 'bottom',
            text: $t('global.actions.merge_customer')
          }"
          name="person-add"
        />
      </div>
    </div>

    <BaseMore
      v-else
      :options="['edit', 'delete', 'merge']"
      alignLeft
      @select="onCustomerAction"
      v-test="'customer-actions'"
    />
  </div>
</template>

<script lang="ts" setup>
import { usePageLayoutStore } from '@/stores/page-layout';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { flash, modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const emit = defineEmits(['merge']);
const { showMobileMenu } = usePageLayoutStore();

const router = useRouter();
const customer = inject<any>('customer');

const { mutate: deleteCustomer } = useMutation(gql`
  mutation deleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`);

const { t } = useI18n();

const onCustomerAction = (action: string) => {
  switch (action) {
    case 'edit':
      router.push({
        name: 'customers-edit',
        params: { id: customer.value.id }
      });
      break;
    case 'delete':
      {
        modal('confirmation', {
          message: t('global.confirmation.confirm_delete', {
            item: customer.value.fullName
          })
        }).then(() => {
          deleteCustomer({
            input: {
              id: customer.value.id
            }
          }).then(() => {
            router.push({ name: 'customers' });
            flash(t('global.flash.customer_deleted'));
          });
        });
      }
      break;
    case 'merge':
      emit('merge');
      break;
  }
};
</script>

<style lang="scss" module>
.buttons {
  display: flex;
}

.button {
  @include header-item;

  &:not(:first-child) {
    margin-left: 0;
  }
}
</style>
