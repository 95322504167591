<template>
  <div>
    <router-view @updated="refetch" />
    <div :class="$style.header">
      <BaseButton
        :routerLink="{ name: 'customer-report-create' }"
        v-test="'customerCreateReport'"
      >
        {{ $t('global.actions.create_report') }}
      </BaseButton>
    </div>
    <BaseTable
      v-if="appointmentReports.length"
      :headers="
        [
          $t('global.date'),
          hasSingleEmployee ? '' : $t('global.items.employee', 1),
          $t('global.description'),
          $t('global.attachments')
        ].filter((header) => !!header)
      "
      :rows="
        appointmentReports.map((report) => ({
          id: report.id,
          routerLink: {
            name: 'customer-report',
            params: {
              id: report.id
            }
          },
          cells: [
            {
              value: filters.date(report.createdAt),
              type: 'date'
            },
            getEmployeeName(report),
            report.report,
            !!report.attachments.length
          ].filter((cell) => cell !== undefined),
          actions: ['edit', 'delete']
        }))
      "
      @action="onOptionClick"
      v-test="'customer-reports'"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
    <EmptyPageContent
      v-if="!loading && !appointmentReports.length"
      imageName="reports"
      :text="{
        title: $t('customers.empty_state.reports.title'),
        description: $t('customers.empty_state.reports.description'),
        btnPrimary: $t('global.actions.create_report')
      }"
      :moreLink="{
        text: $t('customers.empty_state.reports.more_text'),
        url: $t('customers.empty_state.reports.more_url')
      }"
      @click="$router.push({ name: 'customer-report-create' })"
    />
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import LoadMore from '@/components/LoadMore.vue';
import { usePagination } from '@/apollo/pagination';
import gql from 'graphql-tag';
import { inject } from 'vue';
import { useResourcesStore } from '@/stores/resources';
import { useRouter } from 'vue-router';
import { flash, modal } from '@/helpers/ui';
import { useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';

const customer = inject<any>('customer');

const { hasSingleEmployee, resourceById } = useResourcesStore();

const { appointmentReports, allDataFetched, loading, fetchMore, refetch } =
  usePagination({
    fieldName: 'appointmentReports',
    query: gql`
      query getAppointmentReports(
        $pagination: PaginationAttributes!
        $customerId: Int!
      ) {
        appointmentReports(pagination: $pagination, customerId: $customerId) {
          appointment {
            employeeNames
          }
          attachments {
            id
          }
          createdAt
          id
          employeeId
          report
        }
      }
    `,
    variables: {
      customerId: customer.value.id
    }
  });

const getEmployeeName = (report: any) => {
  if (hasSingleEmployee) {
    return undefined;
  } else if (report.appointment) {
    return report.appointment.employeeNames;
  } else {
    return resourceById(report.employeeId)?.name || '';
  }
};

const router = useRouter();
const { t } = useI18n();

const onOptionClick = ({ id, option }: { id: number; option: string }) => {
  if (option === 'edit') {
    router.push({ name: 'customer-report-edit', params: { id } });
  } else if (option === 'delete') {
    modal('confirmation').then(() => {
      const { mutate } = useMutation(gql`
        mutation deleteAppointmentReport(
          $input: DeleteAppointmentReportInput!
        ) {
          deleteAppointmentReport(input: $input) {
            appointmentReport {
              id
            }
          }
        }
      `);

      mutate({
        input: {
          id
        }
      }).then(() => {
        flash(t('global.flash.report_deleted'));
        refetch();
      });
    });
  }
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing;
}
</style>
