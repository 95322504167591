<template>
  <div
    :class="[
      $style.base,
      {
        [$style.indented]: indented,
        [$style.expanded]: expanded,
        [$style.borderTop]: borderTop
      }
    ]"
    v-test="'register-browser-item'"
  >
    <div :class="$style.section">
      <div v-if="arrowLeft" :class="$style.arrow">
        <BaseIcon name="chevron-down" />
      </div>
      <BaseIcon v-if="item.icon" :name="item.icon" :mr="0.5" />
      <div
        v-if="showFavorite"
        :class="$style.favorite"
        @click.stop="setFavorite"
      >
        <BaseIcon
          :name="favorite ? 'star-filled' : 'star'"
          :color="favorite ? 'primary' : 'default'"
          v-test="'browserItemFavorite'"
        />
      </div>
      <BaseText bold>
        {{ item.label }}
      </BaseText>
    </div>
    <div :class="$style.section">
      <span v-if="item.price !== undefined" v-test="'browser-item-price'">
        {{ filters.currency(item.price) }}
      </span>
      <div v-if="arrow" :class="$style.arrow">
        <BaseIcon name="chevron-down" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@/components/base-icon/types';
import filters from '@/filters';
import { useRegisterBrowserStore } from './store';

const props = defineProps<{
  item: {
    icon?: IconName;
    id?: number;
    label: string;
    name?: string;
    price?: number;
    type?: string;
    favorite?: boolean;
  };
  showFavorite?: boolean;
  arrow?: boolean;
  indented?: boolean;
  expanded?: boolean;
  borderTop?: boolean;
  arrowLeft?: boolean;
}>();

const favorite = computed(() => {
  const { favorites } = useRegisterBrowserStore();
  return favorites.find(
    (f) => f.type === props.item.type && f.id === props.item.id
  );
});

const { addFavorite, removeFavorite } = useRegisterBrowserStore();

const setFavorite = () => {
  favorite.value ? removeFavorite(favorite.value) : addFavorite(props.item);
};
</script>

<style lang="scss" module>
.base {
  padding: $spacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  background-color: white;
  width: calc(100% + #{$spacing * 2});
  margin-left: $spacing * -1;

  &:not(.expanded) {
    border-bottom: 1px solid $color-border-input;
  }

  &.borderTop {
    border-top: 1px solid $color-border-input;
  }

  &.indented {
    padding-left: $spacing * 1.5;
  }
}

.section {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: $spacing * 0.5;
  }

  &:empty {
    display: none;
  }
}

.favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -4px $spacing * 0.5 -4px -4px;
  padding: 4px;
  @include background-hover;
}

.arrow {
  &:first-child {
    margin-right: $spacing * 0.5;
  }

  .base:not(.expanded) & {
    transform: rotate(-90deg);
  }
}
</style>
