<template>
  <CreateGiftcard v-if="newDesignEnabled" />
  <CreateGiftcardOld v-else />
</template>

<script setup lang="ts">
import CreateGiftcard from '@/modules/register/builder/browser/pages/GiftCard.vue';
import CreateGiftcardOld from '@/modules/register/modals/create-giftcard/index.vue';

const newDesignEnabled = inject<boolean>('newDesignEnabled');
</script>
