import { defineStore, acceptHMRUpdate } from 'pinia';
import dayjs from '@/dayjs';
import { useResourcesStore } from '@/stores/resources';
import { ref, computed, watch } from 'vue';
import type { Resource, CreateAbsenceInput, CreateNoteInput } from '@/types';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { combineDateTime } from '@/helpers/dates';
import { useRoute } from 'vue-router';
import { useCompanyStore } from '@/stores/company';

export const useCreateEventStore = defineStore('calendar/createEvent', () => {
  const getDefaultDateTimeFrom = () => {
    // When a date has not been set, use the date that the calendar is using, and set the time to the next full hour
    const now = dayjs().tz();
    const { date, viewType, isTodayVisible } = useCalendarFiltersStore();

    const startAt = dayjs
      .tz(date)
      .set('minute', 0)
      .set('hour', now.hour() + 1);

    if (viewType === 'WEEK' && !isTodayVisible) {
      // When the calendar is in weekview, and the current day is not visible, the default day should be the first day of the week
      return startAt.subtract(startAt.day() - 1, 'day').format();
    } else {
      return startAt.format();
    }
  };

  const getDefaultDateTimeTo = () => {
    const dateTimeFrom = getDefaultDateTimeFrom();
    return dateTimeFrom ? dayjs(dateTimeFrom).tz().add(1, 'hour').format() : '';
  };

  const getDefaultResourceId = (): Resource['id'] | null => {
    // When a resource has not been set, use the resource selected by the calendar
    // Or when the company only has one resource, use the first employee
    const { resourceId, resourceType } = useCalendarFiltersStore();
    const { hasSingleResource, employees, hasSingleEmployee } =
      useResourcesStore();

    if (resourceId) {
      return resourceId;
    } else if (
      hasSingleResource ||
      (resourceType === 'employee' && hasSingleEmployee)
    ) {
      return employees[0].id;
    } else {
      return null;
    }
  };

  const allDay = ref(false);
  const dateTimeFrom = ref('');
  const dateTimeTo = ref('');
  const resourceId = ref<Resource['id'] | null>(null);

  const getDefaultAbsenceState = () => ({
    allDay: allDay.value,
    chore: false,
    endAt: dateTimeTo.value,
    locationId: null,
    name: '',
    resourceId: resourceId.value,
    rrule: null,
    startAt: dateTimeFrom.value
  });

  const getNoteDate = () =>
    dateTimeFrom.value
      ? dayjs(dateTimeFrom.value).tz().format('YYYY-MM-DD')
      : '';

  const getDefaultNoteState = () => ({
    date: getNoteDate(),
    resourceId: resourceId.value,
    text: ''
  });

  const absenceData = ref<CreateAbsenceInput>(getDefaultAbsenceState());
  const noteData = ref<CreateNoteInput>(getDefaultNoteState());

  // When absence or note data changes, update the date refs
  watch(
    () => absenceData.value.startAt,
    (startAt) => {
      dateTimeFrom.value = startAt;
    }
  );
  watch(
    () => absenceData.value.endAt,
    (endAt) => (dateTimeTo.value = endAt)
  );
  watch(
    () => noteData.value.date,
    (date) => {
      if (date && dateTimeFrom.value) {
        dateTimeFrom.value = combineDateTime(
          date,
          dayjs(dateTimeFrom.value).tz().format('HH:mm')
        );
        dateTimeTo.value = dayjs(dateTimeFrom.value)
          .tz()
          .add(1, 'hour')
          .format();
      }
    }
  );

  const route = useRoute();

  // When a date ref changes, update the absence and note data
  watch(dateTimeFrom, (dateTimeFrom) => {
    if (!route || route.params.id) {
      return;
    }

    absenceData.value.startAt = dateTimeFrom;
    noteData.value.date = getNoteDate();
  });

  watch(dateTimeTo, (dateTimeTo) => {
    if (!route || route.params.id) {
      return;
    }

    absenceData.value.endAt = dateTimeTo;
  });

  const duration = computed({
    get() {
      return dateTimeTo.value && dateTimeFrom.value
        ? dayjs(dateTimeTo.value).diff(dateTimeFrom.value, 'minute')
        : 0;
    },
    set(value) {
      if (dateTimeFrom.value) {
        dateTimeTo.value = dayjs(dateTimeFrom.value)
          .tz()
          .add(value || 0, 'minute')
          .format();
      }
    }
  });

  // When absence or note resource change, update the ref
  watch(
    () => absenceData.value.resourceId,
    (absenceResource) => (resourceId.value = absenceResource || null)
  );
  watch(
    () => noteData.value.resourceId,
    (noteResource) => (resourceId.value = noteResource || null)
  );

  // When resourceId changes, update the absence and note data
  watch(resourceId, (resourceId) => {
    absenceData.value.resourceId = resourceId;
    noteData.value.resourceId = resourceId;
  });

  const setEventData = (
    {
      startDate: _startDate,
      endDate: _endDate,
      allDay: _allDay,
      resourceId: _resourceId
    }: {
      startDate?: string;
      endDate?: string;
      allDay?: boolean;
      resourceId?: Resource['id'] | null;
    },
    {
      action
    }: {
      action?: 'drag' | 'resize';
    } = {}
  ) => {
    // The duration of the current event, before the new start/end times are applied
    const currentDuration = duration.value;

    // Set start and end times. We reformat them here just in case Fullcalendar sends the wrong timezone
    if (_startDate) {
      dateTimeFrom.value = dayjs(_startDate).tz().format();
    }

    if (_endDate) {
      if (action === 'drag' || action === 'resize') {
        // When dragging or resizing an event, the appointmentEndBufferTime is already added to the event duration
        // In preview mode, this duration is added again when the preview is created
        // So we need to subtract that duration here, to keep the total duration the same
        const {
          companySettings: {
            agenda: { appointmentEndBuffer, appointmentEndBufferTime }
          }
        } = useCompanyStore();
        if (appointmentEndBuffer && appointmentEndBufferTime) {
          _endDate = dayjs(_endDate)
            .subtract(appointmentEndBufferTime, 'minute')
            .format();
        }
      }

      dateTimeTo.value = dayjs(_endDate).tz().format();
    } else if (_allDay) {
      dateTimeTo.value = dayjs(_startDate).tz().format();
    } else if (_startDate) {
      dateTimeTo.value = dayjs(_startDate)
        .add(dateTimeTo.value ? currentDuration : 60, 'minute')
        .tz()
        .format();
    }

    if (_resourceId) {
      resourceId.value = _resourceId;
    }

    if (_allDay) {
      allDay.value = true;
    }
  };

  const setDefaultState = () => {
    dateTimeFrom.value = dateTimeFrom.value || getDefaultDateTimeFrom();
    dateTimeTo.value = dateTimeTo.value || getDefaultDateTimeTo();
    resourceId.value = resourceId.value || getDefaultResourceId();
    allDay.value = allDay.value || false;
    absenceData.value = getDefaultAbsenceState();
    noteData.value = getDefaultNoteState();
  };

  const resetState = () => {
    dateTimeFrom.value = '';
    dateTimeTo.value = '';
    resourceId.value = null;
    allDay.value = false;
    absenceData.value = getDefaultAbsenceState();
    noteData.value = getDefaultNoteState();
  };

  return {
    dateTimeFrom,
    dateTimeTo,
    resourceId,
    duration,
    absenceData,
    noteData,
    setEventData,
    setDefaultState,
    resetState
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCreateEventStore, import.meta.hot));
}
