<template>
  <div>
    <BaseHeading mb>{{ $t('service.nav.about.title') }}</BaseHeading>
    <div :class="$style.title">
      <BaseIcon
        name="briefcase"
        size="s"
        :mr="0.25"
        :color="!hasError ? 'default' : 'warning'"
      />
      <BaseText inline :italic="hasError">
        {{ !hasError ? formData.name : $t('service.nav.about.name_required') }}
      </BaseText>
    </div>
    <div :class="$style.info">
      <BaseText iconBefore="credit-card" :mr="2">{{
        formatCurrency(formData.price)
      }}</BaseText>
      <BaseText iconBefore="clock"
        >{{ formData.duration }} {{ $t('global.minute_short') }}</BaseText
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ServiceFormData } from '../types';
import { formatCurrency } from '@/helpers/currency';
const formData = inject<ServiceFormData>('serviceFormData')!;
const isServiceFormSubmitted = inject<Ref<boolean>>('isServiceFormSubmitted')!;

const hasError = computed(
  () => isServiceFormSubmitted.value && !formData.name.length
);
</script>

<style lang="scss" module>
.title {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
}

.info {
  display: flex;
}
</style>
