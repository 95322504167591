<template>
  <div :class="$style.wrap" v-intercom="`nav-main-${name}`">
    <component
      :is="link.routeName ? 'router-link' : 'div'"
      :to="link.routeName ? { name: link.routeName } : null"
      :active-class="$style.isActive"
      :class="[
        $style.base,
        {
          [$style.dark]: dark,
          [$style.isNavActive]: showMobileMenu,
          [$style.isSmall]: $screen === 's'
        }
      ]"
      @click="onClick"
      v-test="`nav-main-${link.routeName || link.name}`"
    >
      <div :class="$style.inner">
        <BaseIcon v-if="link.icon" :name="link.icon" color="inverted" />
        <BaseNotification
          v-if="link.notification && link.notification.show"
          :position="
            link.notification.value
              ? { right: 7, top: 7 }
              : { right: 12, top: 12 }
          "
          v-test="`nav-main-notification-${link.routeName}`"
        >
          {{ link.notification.value }}
        </BaseNotification>
        <slot />
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';
import { mapState } from 'pinia';
import { useVersionMismatchStore } from '@/stores/version-mismatch';
import { hideTooltip } from '@/helpers/ui';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    link: {
      type: Object,
      required: true
    },
    dark: {
      type: Boolean,
      required: false
    },
    premium: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    ...mapState(usePageLayoutStore, ['showMobileMenu']),
    ...mapState(useVersionMismatchStore, ['versionMismatchReload']),
    name() {
      return this.link.routeName || this.link.icon;
    }
  },
  methods: {
    onClick() {
      const pageLayoutStore = usePageLayoutStore();
      pageLayoutStore.showMobileMenu = false;

      hideTooltip();

      if (this.versionMismatchReload && this.link.routeName) {
        const route = this.$router.resolve({
          name: this.link.routeName
        });

        if (route) {
          // This triggers a page reload
          window.location.replace(route.fullPath);
        }
      } else {
        this.$emit('click');
      }

      this.mixpanel.track(`Main nav - ${this.name} clicked`);
    }
  }
});
</script>

<style lang="scss" module>
.wrap {
  width: 100%;
}

.base {
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  display: block;

  &:before {
    content: '';
    position: absolute;
    width: calc(100% + #{$radius * 2});
    height: calc(100% + #{$radius * 2});
    top: $radius * -1;
    left: $radius * -1;
    border-radius: $radius;
    background-color: black;
    opacity: 0.15;

    transition: transform 0.15s $easeOutBack;
  }

  &.isSmall:not(.isNavActive) {
    &:before {
      opacity: 0 !important;
    }
  }

  &.dark {
    &.isActive,
    &:not(.isActive) {
      &:before {
        opacity: 0.45;
      }
    }

    &:not(.isActive) {
      @include hover {
        &:before {
          opacity: 0.6;
        }
      }
    }
  }

  &:not(:hover):not(.isActive) {
    &:before {
      transition:
        transform 0.15s $easeInBack,
        opacity 0.1s;
    }
  }

  &:not(:hover):not(.isActive):not(.dark),
  &.isSmall {
    &:before {
      opacity: 0;
    }
  }

  &.isActive {
    &:before {
      transform: scale(0.57);
      opacity: 0.25;
      transition:
        transform 0.15s $easeOutBack,
        opacity 0.15s;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -6px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid white;

      display: none;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  .base:not(.isSmall) & {
    height: $nav-width;
  }

  .base.isSmall & {
    height: $nav-width - 4px;
  }
}
</style>
