<template>
  <BaseCard v-if="!isTreatwellUser" mb noPadding>
    <div
      :class="[$style.offer, { [`${$style.withImage}`]: !noImage }]"
      v-test="'referral-promo-card'"
    >
      <BaseHeading :size="noImage ? 'm' : 'l'" mb>
        {{ t('admin.dashboard.referrals.title') }}
      </BaseHeading>
      <BaseText
        :routerLink="{ name: 'admin-referrals' }"
        @click="mixpanel.track('Referrals link clicked')"
        v-test="'referrals-link'"
      >
        {{ t('admin.dashboard.referrals.learn_more') }}
      </BaseText>
      <img
        v-if="!noImage"
        src="/img/admin/dashboard/training-employee.svg"
        :class="$style.offerImage"
      />
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCompanyStore } from '@/stores/company';

defineProps<{
  noImage?: Boolean;
}>();

const { t } = useI18n();

const mixpanel = inject<any>('mixpanel');
const { isTreatwellUser } = useCompanyStore();
</script>

<style lang="scss" module>
.offer {
  padding: $spacing;
  border-radius: $radius;
  box-sizing: content-box;
}

.withImage {
  height: 260px;
  background-image: url('/img/admin/dashboard/background.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
}

.offerImage {
  position: absolute;
  padding: 0;
  bottom: 0;
  right: $spacing * 2;
  height: 160px;
}
</style>
