<template>
  <PageSplit>
    <template #left>
      <BaseHeading size="l" mb>
        {{ $t('giftcard_widget.heading') }}
      </BaseHeading>
      <BaseText mb>
        {{ $t('giftcard_widget.description') }}
      </BaseText>
      <BaseSpinner v-if="loading" inline />
      <div v-else-if="!hasPaymentGateway" class="mb-1">
        <BaseAlert
          color="warning"
          :text="$t('giftcard_widget.payment_alert.description')"
          mb
        />
        <BaseButton
          :routerLink="{
            name: 'integrations'
          }"
        >
          {{ $t('giftcard_widget.payment_alert.button_text') }}
        </BaseButton>
      </div>
      <BaseForm @submit="submitForm">
        <BaseAccordion
          v-model="visibleGroups"
          :titles="[
            $t('admin.booking.main_settings'),
            $t('admin.booking.style_and_sharing')
          ]"
          :mt="2"
        >
          <template #1>
            <MainSettings />
          </template>
          <template #2>
            <SharingSettings />
          </template>
        </BaseAccordion>
        <div :class="$style.buttonWrap">
          <BaseButton
            submitForm
            :loading="saving"
            v-test="'giftcard-settings-submit'"
          >
            {{ $t('global.actions.save') }}
          </BaseButton>
        </div>
      </BaseForm>
    </template>
    <template #right>
      <iframe
        ref="widgetPreviewIframe"
        :key="iframeUpdate"
        :src="widgetPreviewUrl"
        :class="$style.iframe"
        v-test="'giftcard-settings-preview-iframe'"
      />
    </template>
  </PageSplit>
</template>

<script lang="ts">
export default {
  name: 'VoucherWidgetSettings'
};
</script>

<script setup lang="ts">
import PageSplit from '@/modules/PageSplit.vue';
import { useWidgetData } from './form-data';
import { useCompanyStore } from '@/stores/company';
import MainSettings from './main-settings/index.vue';
import SharingSettings from './sharing-settings/index.vue';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import config from '@/config';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const visibleGroups = ref<string[]>([]);
const { t } = useI18n();

const { formData } = useWidgetData();

const { company, updateCompany, isTreatwellUser } = useCompanyStore();

const widgetPreviewUrl = computed(() => {
  let baseUrl = `${config.bookingWidgetUrl}/?widget=voucher&company=${company.publicId}`;
  baseUrl += `&language=${formData.languagePublic.toLowerCase()}`;
  baseUrl += `&color=${formData.settings.bookingWidget.customColor}`;
  if (!formData.settings.bookingWidget.autoWidth) {
    baseUrl += `&width=${formData.settings.bookingWidget.width}`;
  }
  baseUrl += `&position=${formData.settings.bookingWidget.position.toLowerCase()}`;
  baseUrl += `&inline=${formData.settings.bookingWidget.inline}`;

  if (isTreatwellUser) {
    baseUrl += '&treatwell=true';
  }

  return encodeURI(baseUrl);
});

const widgetPreviewIframe = ref<HTMLIFrameElement | null>(null);
const iframeUpdate = ref(0);

const refreshIframe = () => {
  if (widgetPreviewIframe.value) {
    iframeUpdate.value += 1;
  }
};

watch(widgetPreviewUrl, () => {
  refreshIframe();
});

const saving = ref(false);

const submitForm = () => {
  saving.value = true;

  updateCompany(formData)
    .then(() => {
      flash(t('global.flash.settings_updated'));
      refreshIframe();
    })
    .finally(() => {
      saving.value = false;
    });
};

const { result: currentUser, loading } = useQuery(
  gql`
    query getCurrentUser {
      currentUser {
        company {
          paymentGateway
        }
      }
    }
  `,
  null,
  {
    fetchPolicy: 'cache-first'
  }
);

const hasPaymentGateway = computed(
  () => !!currentUser.value?.currentUser.company.paymentGateway
);
</script>

<style lang="scss" module>
.iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing * 2;
}
</style>
