<template>
  <div>
    <BaseHeading mb>{{ $t('service.nav.resources.title') }}</BaseHeading>
    <div
      v-for="requirement in visibleRequirements"
      :key="requirement.id"
      :class="$style.item"
    >
      <BaseIcon
        size="s"
        :mr="0.25"
        :name="getIconPerRequirementType(requirement.type)"
        mb
        :color="
          isEdit && !requirement.resourceIds.length ? 'warning' : 'default'
        "
      />
      <BaseText :italic="isEdit && !requirement.resourceIds.length">
        {{ getResourceLabel(requirement) }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useResourcesStore } from '@/stores/resources';
import type { ServiceVariationsFormData } from '../types';

const route = useRoute();
const isEdit = computed(() => !!route.params.serviceId);

const formData = inject<ServiceVariationsFormData>('serviceFormData')!;

const { resourcesByType } = useResourcesStore();

const { t } = useI18n();

const getIconPerRequirementType = (type: string) => {
  switch (type.toLocaleLowerCase()) {
    case 'employee':
      return 'person';
    case 'room':
      return 'room';
    case 'equipment':
      return 'monitor';
    default:
      return 'person';
  }
};

const visibleRequirements = computed(() =>
  formData.requirementsAttributes.filter((r: any) => !r.destroy)
);

const getResourceLabel = (requirement) => {
  const resourcesList = resourcesByType(requirement.type.toLowerCase());

  if (requirement.resourceIds.length === resourcesList.length) {
    return t(`service.resources.${requirement.type.toLowerCase()}.all`);
  }

  if (requirement.resourceIds.length === 0 && requirement.primary) {
    return t('service.resources.no_primary');
  }

  if (requirement.resourceIds.length === 0) {
    return t('service.resources.none');
  }

  if (requirement.resourceIds.length > 3) {
    const names = resourcesList
      .filter((resource) => requirement.resourceIds.includes(resource.id))
      .filter((_i, i: number) => i < 3)
      .map((resource) => resource.name)
      .join(', ');
    return t('service.resources.and_more', {
      selected_resources: names,
      amount: requirement.resourceIds.length - 3
    });
  }

  return resourcesList
    .filter((resource) => requirement.resourceIds.includes(resource.id))
    .map((resource) => resource.name)
    .join(', ');
};
</script>

<style module lang="scss">
.item {
  display: flex;
}
</style>
