import { DaysOfWeek, type WeekdayDetails } from '@/types';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref, computed } from 'vue';

export type DayOptions = {
  available: boolean;
  dayOfWeek: string;
  endTime: string;
  max: number;
  startTime: string;
};

const daysOfWeek = [
  DaysOfWeek.Mo,
  DaysOfWeek.Tu,
  DaysOfWeek.We,
  DaysOfWeek.Th,
  DaysOfWeek.Fr,
  DaysOfWeek.Sa,
  DaysOfWeek.Su
];

const getDefaults = (): DayOptions[] =>
  daysOfWeek.map((dayOfWeek) => ({
    available: true,
    dayOfWeek,
    endTime: '',
    max: 0,
    startTime: ''
  }));

const options = ref(getDefaults());
const availabilityChanged = ref(false);
const offeringFetched = ref(false);

export const useAvailability = () => {
  const availabilitySet = computed(
    () =>
      !!options.value.find(
        (option) =>
          option.max || option.startTime || option.endTime || !option.available
      )
  );

  const reset = () => {
    options.value = getDefaults();
    availabilityChanged.value = false;
    offeringFetched.value = false;
  };

  const getOffering = (serviceId: number) => {
    const { onResult } = useQuery(
      gql`
        query getServiceOfferings($serviceId: Int!) {
          serviceOfferings(serviceId: $serviceId) {
            id
            payload {
              slots {
                dayOfWeek
                endTime
                max
                startTime
              }
            }
          }
        }
      `,
      {
        serviceId
      }
    );

    onResult(({ data: { serviceOfferings } }) => {
      if (serviceOfferings?.payload?.slots) {
        options.value = options.value.map((option) => {
          const slot = serviceOfferings.payload.slots.find(
            (slot: WeekdayDetails) => slot.dayOfWeek === option.dayOfWeek
          );

          return {
            available: !!slot,
            dayOfWeek: slot?.dayOfWeek || option.dayOfWeek,
            endTime: slot?.endTime || option.endTime,
            max: slot?.max || option.max,
            startTime: slot?.startTime || option.startTime
          };
        });
      }

      offeringFetched.value = true;
    });
  };

  const createOffering = (serviceId: number) => {
    if (!availabilityChanged.value) {
      return Promise.resolve();
    }

    const { mutate } = useMutation(gql`
      mutation createServiceOffering($input: CreateServiceOfferingInput!) {
        createServiceOffering(input: $input) {
          errors {
            message
          }
        }
      }
    `);

    return mutate({
      input: {
        offeringType: 'WEEKDAYS',
        payload: {
          slots: options.value
            .filter((option) => option.available)
            .map((option) => {
              const { dayOfWeek, endTime, startTime, max } = option;
              return { dayOfWeek, endTime, startTime, max };
            })
        },
        serviceId
      }
    });
  };

  return {
    availabilityChanged,
    options,
    availabilitySet,
    offeringFetched,
    reset,
    getOffering,
    createOffering
  };
};
