<template>
  <div>
    <EmptySubscriptionPage
      v-if="!hasFeatureFlag('module-products')"
      :text="{
        title: $t('upgrade_subscription_page.products.all.title'),
        description: $t('upgrade_subscription_page.products.all.description'),
        btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
      }"
      :moreLink="{
        text: $t('upgrade_subscription_page.products.all.learn_more'),
        url: $t('upgrade_subscription_page.products.all.learn_more_url')
      }"
      imageName="products"
    />

    <div v-else>
      <router-view @delete="deleteProduct" />
      <Actions
        v-show="!showEmptyPageContent && firstFetchFinished"
        v-model:categoryIds="variables.categoryIds"
        v-model:supplierIds="variables.supplierIds"
        :productCategories="[]"
        :suppliers="[]"
        mb
        @search="variables.search = $event"
        @selectedFilter="variables.filter = $event"
      />
      <InventoryStats
        v-show="variables.filter === 'stock_enabled'"
        :categoryIds="variables.categoryIds"
        :supplierIds="variables.supplierIds"
        v-test="'inventoryStats'"
      />
      <ProductsList
        v-if="products && products.length"
        :products="products"
        :filter="variables.filter"
        @delete="deleteProduct"
      />
      <LoadMore
        v-show="firstFetchFinished && !allDataFetched"
        @shown="fetchMore"
      />
      <EmptyPageContent
        v-show="showEmptyPageContent || noResults"
        imageName="products"
        :text="
          showEmptyPageContent
            ? {
                title: $t('products.empty_state.products.title'),
                description: $t('products.empty_state.products.description'),
                btnPrimary: $t('global.actions.create_product'),
                btnSecondary: $t(
                  'products.empty_state.products.import_product_list'
                )
              }
            : null
        "
        :moreLink="
          showEmptyPageContent
            ? {
                text: $t('products.empty_state.products.more_text'),
                url: $t('products.empty_state.products.more_url')
              }
            : null
        "
        @click="onEmptyPageClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ProductsList from './ProductsList.vue';
import Actions from './Actions.vue';
import InventoryStats from './InventoryStats.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';
import { usePagination } from '@/apollo/pagination';
import { GET_PRODUCTS, DELETE_PRODUCT } from './graphql';
import LoadMore from '@/components/LoadMore.vue';
import { useRouter } from 'vue-router';
import { useMutation } from '@vue/apollo-composable';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const mixpanel = inject<any>('mixpanel');
const { hasFeatureFlag } = useUserStore();

const { t } = useI18n();

const {
  products,
  variables,
  offset,
  loading,
  allDataFetched,
  firstFetchFinished,
  fetchMore
} = usePagination({
  fieldName: 'products',
  query: GET_PRODUCTS,
  variables: {
    search: '',
    categoryIds: [],
    supplierIds: [],
    filter: null
  },
  options: {
    offset: true,
    multiLocation: true
  }
});

const noResults = computed(() => !loading.value && !products.value.length);

const router = useRouter();

const onEmptyPageClick = (button: string) => {
  if (button === 'primary') {
    router.push({ name: 'create-product' });
  } else if (button === 'secondary') {
    mixpanel.track('Import - Products', { from: 'empty' });
    router.push({ name: 'import-products' });
  }
};

const showEmptyPageContent = computed(
  () =>
    noResults.value &&
    firstFetchFinished.value &&
    !variables.search &&
    !variables.filter &&
    !variables.supplierIds.length &&
    !variables.categoryIds.length
);

const deleteProduct = (id: number) => {
  const { mutate, onDone } = useMutation(DELETE_PRODUCT, {
    update(cache) {
      const cacheId = `Product:${id}`;
      cache.evict({ id: cacheId, broadcast: false });
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'productCalculations' });
      cache.gc();
    }
  });

  onDone(() => {
    flash(t('global.flash.product_deleted'));
    offset.value--;
  });

  mutate({
    input: {
      id
    }
  });
};
</script>
