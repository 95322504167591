<template>
  <div :class="$style.base">
    <Category
      :data="data"
      :color="color"
      :index="index"
      :expanded="expanded"
      :listLength="listLength"
      @updateSortOrder="$emit('updateSortOrder', $event)"
      @click="$emit('toggle', $el)"
      v-test="'service-category'"
    />
    <div v-if="expanded" class="mb-1">
      <Draggable
        v-if="listItems.length"
        v-model="listItems"
        :disabled="device.touch"
        item-key="id"
      >
        <template #item="{ element, index }">
          <Group
            :service="element"
            :color="color"
            :index="index"
            :listLength="listItems.length"
            @updateSortOrder="sortByClick($event, element)"
            v-test="'group-category-service'"
          />
        </template>
      </Draggable>
      <div
        v-else
        :class="$style.item"
        :style="
          color
            ? {
                'border-color': color
              }
            : null
        "
        v-test="'group-category-empty'"
      >
        {{ $t('services.category_no_services') }}
      </div>
      <div
        :class="[$style.item]"
        :style="
          color
            ? {
                'border-color': color
              }
            : null
        "
        @click="
          $router.push({
            name: 'admin-service-new',
            params: {
              categoryId: data.id
            }
          })
        "
        v-test="'service-category-add-service'"
      >
        <BaseIcon name="plus" color="success" :mr="0.5" />
        <BaseText bold>
          {{ $t('services.add_service_to_category') }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { lightColor } from '@/helpers/formatting';
import Group from './Group.vue';
import Category from './Category.vue';
import Draggable from 'vuedraggable';
import { sortByClick, sortByDrag } from '@/modules/admin/helpers';
import { device } from '@/user-context';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Group,
    Category,
    Draggable
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    listLength: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['updateSortOrder', 'toggle'],
  setup() {
    const { sortServiceGroups } = inject<any>('serviceGroupActions');
    return {
      sortServiceGroups,
      device
    };
  },
  computed: {
    color() {
      return this.data.color ? lightColor(this.data.color) : null;
    },
    listItems: {
      get() {
        return this.data.services;
      },
      set(newValue) {
        this.sortServiceGroups(sortByDrag(newValue, this.listItems));
      }
    }
  },
  methods: {
    sortByClick(newIndex, service) {
      this.sortServiceGroups(sortByClick(newIndex, service, this.listItems));
    }
  }
});
</script>

<style lang="scss" module>
.base {
  padding-top: 4px;
}

.item {
  border-width: 1px;
  border-style: solid;
  border-top: none;
  padding: $spacing;
  display: flex;
  background-color: white;
}

.item:last-child {
  cursor: pointer;

  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}
</style>
