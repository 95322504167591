<template>
  <div :class="$style.base">
    <BaseHeading size="xs" :mb="0.25">
      {{ $t('global.label') }}
    </BaseHeading>
    <BaseDropdown
      v-model="selectedLabel"
      :options="listItems"
      noOverflow
      @action="onEditLabel"
      v-test="'appointment-label-select'"
    />
    <BaseText
      link
      :mt="0.5"
      @click="showPopover = true"
      v-test="'appointment-label-create'"
    >
      {{ $t('appointment.label_new') }}
    </BaseText>
    <Popover
      v-show="showPopover"
      :selectedLabel="
        calendarLabels
          ? calendarLabels.find((label) => label.id === labelEditingId)
          : null
      "
      @close="onPopoverClose"
      @created="$emit('select', $event)"
    />
  </div>
</template>

<script lang="ts">
import { query } from './graphql';
import Popover from './Popover.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Popover
  },
  props: {
    labelId: {
      type: Number
    },
    appointmentId: {
      type: Number,
      required: true
    }
  },
  emits: ['select'],
  data() {
    return {
      showPopover: false,
      labelEditingId: null,
      calendarLabels: []
    };
  },
  apollo: {
    calendarLabels: {
      query,
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    listItems() {
      return [
        {
          value: null,
          label: this.$t('appointment.label_remove'),
          icon: 'close'
        },
        ...this.calendarLabels.map((label) => ({
          value: label.id,
          label: label.title,
          color: label.color,
          actions: ['edit']
        }))
      ];
    },
    selectedLabel: {
      get() {
        return this.labelId;
      },
      set(id) {
        this.$emit(
          'select',
          id ? this.calendarLabels.find((label) => label.id === id) : null
        );
      }
    }
  },
  methods: {
    onEditLabel({ value }) {
      this.showPopover = true;
      this.labelEditingId = value;
    },
    onPopoverClose() {
      this.showPopover = false;
      this.labelEditingId = null;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  padding: $spacing;
}

.popover {
  right: $spacing * 2;
  top: calc(100% - #{$spacing * 2});
}

.colors {
  display: flex;
  margin-bottom: $spacing;
}
</style>
