<template>
  <BaseModalLarge
    :heading="serviceId ? $t('service.edit') : $t('service.create')"
    :loading="isLoading"
    noPadding
    useForm
    white
    headerRightAlign
    :hasBackButton="$screen === 's' && !!activeView"
    :unclosable="!($screen !== 's' || !activeView)"
    @back="activeView = null"
    @close="handleClose"
    @submit="handleSubmit"
    @validationError="onValidationError"
  >
    <template #nav>
      <ServiceNav
        v-if="activeView"
        :activeView="activeView"
        @nav="(view) => (activeView = view)"
      />
    </template>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseAlert
        v-if="hasErrorResponse"
        color="error"
        :text="$t('global.error_generic')"
        mb
      />

      <ServiceSummary v-if="!activeView" @nav="(view) => (activeView = view)" />
      <GeneralView v-show="activeView === 'about'" />
      <RequirementsView v-show="activeView === 'resources'" />
      <AdvancedView v-show="activeView === 'advanced'" />
    </div>

    <template v-if="serviceId" #footerSub>
      <div
        v-if="$screen !== 's' || !activeView"
        :class="$style.deleteButton"
        @click="onDeleteClick"
      >
        <BaseText iconBefore="delete" link center color="error">
          {{ $t('global.actions.delete_service') }}
        </BaseText>
      </div>
    </template>
    <template #footer>
      <BaseButton v-if="$screen !== 's'" color="inverted" @click="handleClose">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        v-if="$screen !== 's' || !activeView"
        submitForm
        :loading="isSaving"
        v-test="'service-btn-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
      <BaseButton v-else @click="activeView = null" v-test="'service-btn-done'">
        {{ $t('global.done') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ServiceModal'
});
</script>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useResourcesStore } from '@/stores/resources';
import { flash } from '@/helpers/ui';

import ServiceNav from './service-nav/index.vue';
import GeneralView from './general-view/index.vue';
import AdvancedView from './AdvancedView.vue';
import ServiceSummary from './ServiceSummary.vue';
import RequirementsView from './requirements-view/index.vue';

import { useService } from './useService';
import { useAvailability } from '../availability';

const route = useRoute();
const router = useRouter();
const serviceId = ref(parseInt(route.params.serviceId as string));
const { screenSize } = usePageLayoutStore();
const { t } = useI18n();

const isSaving = ref(false);
const isSubmitted = ref(false);
const hasErrorResponse = ref(false);
const activeView = ref<null | 'about' | 'resources' | 'advanced'>(
  serviceId.value && screenSize === 's' ? null : 'about'
);

const { createOffering, availabilitySet } = useAvailability();
const {
  isLoading,
  formData,
  onlineBookableError,
  rebookPeriod,
  resetConfirmation,
  createService,
  updateService,
  deleteService
} = useService(serviceId.value);

provide('serviceFormData', formData);
provide('onlineBookableError', onlineBookableError);
provide('rebookPeriod', rebookPeriod);
provide('isServiceFormSubmitted', isSubmitted);

const handleClose = () => {
  router.push({
    name: 'admin-services'
  });
};

const mixpanel = inject<any>('mixpanel');

const afterSave = (serviceId: number) => {
  createOffering(serviceId).then(() => {
    isSaving.value = false;
    handleClose();
  });
};

const onDeleteClick = () => {
  resetConfirmation();
  deleteService(serviceId.value).then(handleClose);
};

const onErrorResponse = () => {
  hasErrorResponse.value = true;
  isSaving.value = false;
};

const onValidationError = () => {
  isSubmitted.value = true;
  activeView.value = 'about';
};

const handleSubmit = () => {
  if (isSaving.value) {
    return;
  }

  isSubmitted.value = true;

  if (onlineBookableError.value) {
    activeView.value = 'advanced';
    return;
  }

  const { resourcesByType } = useResourcesStore();
  formData.requirementsAttributes = formData.requirementsAttributes
    .filter((requirement: any) => {
      if (requirement.type === 'EMPLOYEE' || requirement.id) {
        return true;
      }

      const resources = resourcesByType(requirement.type.toLowerCase());
      return !!resources.length;
    })
    .map((requirement: any) => {
      if (requirement.primary) {
        return requirement;
      }

      if (requirement.resourceIds.length) {
        return requirement;
      }

      return {
        ...requirement,
        destroy: true
      };
    });

  if (formData.serviceOfferingEnabled && !availabilitySet.value) {
    formData.serviceOfferingEnabled = false;
  }

  isSaving.value = true;
  hasErrorResponse.value = false;

  if (!formData.requiresProcessingTime) {
    formData.durationSetup = 0;
    formData.durationProcessing = 0;
    formData.durationFinish = 0;
  }

  if (serviceId.value) {
    updateService({
      ...formData,
      id: serviceId.value
    })
      .then(({ serviceId, errors }) => {
        if (errors) {
          onErrorResponse();
        } else {
          resetConfirmation();
          flash(t('global.flash.service_updated'));
          afterSave(serviceId);
        }
      })
      .catch(() => {
        onErrorResponse();
      });
  } else {
    createService(formData)
      .then(({ serviceId, errors }) => {
        if (errors) {
          onErrorResponse();
        } else {
          resetConfirmation();
          flash(t('global.flash.service_created'));
          mixpanel.track('Service created');
          afterSave(serviceId);
        }
      })
      .catch(() => {
        onErrorResponse();
      });
  }
};
</script>

<style lang="scss" module>
.base {
  padding: 1.5 * $spacing 2 * $spacing;

  &.smallScreen {
    padding: $spacing;
  }
}
</style>
