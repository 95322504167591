<template>
  <div :class="$style.base">
    <div v-if="orderCount" :class="$style.itemCount">
      {{
        filters.capitalize(
          orderCount +
            ' ' +
            $t('global.items.item', orderCount) +
            ' ' +
            $t('global.added')
        )
      }}
    </div>
    <div :class="$style.buttonContainer">
      <BaseButton
        v-if="$screen === 's'"
        color="inverted"
        fullWidth
        mr
        @click="showMobileBrowser = false"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton v-if="orderCount" @click="showMobileBrowser = false">
        {{ $t('global.actions.proceed') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useRegisterStore } from '@/modules/register/stores/register';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { showMobileBrowser } = storeToRefs(useRegisterStore());
const { order } = useRegisterOrderStore();

const orderCount = computed(() => order.items.length);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: $spacing;
  width: 100%;
  background-color: $white;
  box-shadow: $shadow;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  > a {
    flex: 1;
  }
}

.itemCount {
  text-align: center;
  margin: 0 0 $spacing;

  span {
    font-weight: bold;
    color: $color-primary;
  }
}
</style>
