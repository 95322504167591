<template>
  <div>
    <component
      :is="type === 'EMPLOYEE' ? 'EmployeeModal' : 'ResourceModal'"
      v-if="showCreateModal"
      :type="type.toLowerCase()"
      @created="onResourceCreated"
      @close="showCreateModal = false"
    />

    <BaseCard gray :closable="!primary" @close="$emit('close')">
      <div :class="$style.title">
        <BaseText bold>
          {{ $t(`service.resources.${typeKey}.needed`) }}
        </BaseText>
      </div>
      <BaseGroupedCheckboxList
        v-if="listData.length"
        v-model="listValue"
        :data="listData"
        :label="$t(`service.resources.${typeKey}.all`)"
        v-test="'service-requirement-resources'"
      />
      <BaseAlert
        v-else
        color="warning"
        :text="$t(`service.resources.${typeKey}.warning_empty`)"
        v-test="'service-requirement-notavailable'"
      />
      <div class="mt-05">
        <BaseText
          link
          inline
          @click="showCreateModal = true"
          v-test="'service-requirement-create-resource'"
        >
          + {{ $t(`service.resources.${typeKey}.create`) }}
        </BaseText>
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import EmployeeModal from '@/modules/admin/resources/modals/Employee.vue';
import ResourceModal from '@/modules/admin/resources/modals/Resource.vue';
import { useResourcesStore } from '@/stores/resources';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    EmployeeModal,
    ResourceModal
  },
  props: {
    id: {
      type: Number,
      required: false
    },
    primary: {
      type: Boolean,
      required: true
    },
    resourceIds: {
      type: Array,
      required: true,
      validator: (value) =>
        !value.length || !value.find((id) => typeof id !== 'number')
    },
    type: {
      type: String,
      required: true,
      validator: (value) =>
        value === 'EMPLOYEE' || value === 'ROOM' || value === 'EQUIPMENT'
    }
  },
  emits: ['close', 'updateSelectedIds'],
  data() {
    return {
      showCreateModal: false
    };
  },
  computed: {
    typeKey() {
      return this.type.toLowerCase();
    },
    listValue: {
      get() {
        return this.resourceIds;
      },
      set(value) {
        this.$emit('updateSelectedIds', value);
      }
    },
    listData() {
      const { resourcesByType } = useResourcesStore();
      const data = resourcesByType(this.type.toLowerCase());

      return data.map((resource) => ({
        label: resource.name,
        color: resource.color,
        id: resource.id
      }));
    }
  },
  methods: {
    onResourceCreated(resourceId) {
      this.listValue = [...this.listValue, resourceId];
    }
  },
  created() {
    // Select all resources by default when creating a new requirement
    this.$nextTick(() => {
      if (!this.id && !this.resourceIds.length) {
        this.listValue = this.listData.map((r) => r.id);
      }
    });
  }
});
</script>

<style lang="scss" module>
.top {
  padding-bottom: $spacing;
  margin-bottom: $spacing;
  border-bottom: 1px solid $color-border;
}

.error {
  margin-top: $spacing;
  color: $color-error;
  font-size: 12px;
}

.title {
  margin-bottom: $spacing;
  display: flex;
  justify-content: space-between;
}

.multipleButton {
  display: flex;
  justify-content: flex-end;
}

.multipleInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: $spacing;
}
</style>
