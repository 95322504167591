<template>
  <div>
    <BaseHeading mb>{{ $t('service.nav.about.title') }}</BaseHeading>
    <div :class="$style.title">
      <BaseIcon
        name="briefcase"
        size="s"
        :mr="0.25"
        :color="!hasError ? 'default' : 'warning'"
      />
      <BaseText inline :italic="hasError">
        {{ !hasError ? formData.name : $t('service.nav.about.name_required') }}
      </BaseText>
    </div>
    <div v-if="activeVariations.length === 1" :class="$style.info">
      <BaseText iconBefore="credit-card" :mr="2">{{
        formatCurrency(activeVariations[0].price)
      }}</BaseText>
      <BaseText iconBefore="clock"
        >{{ activeVariations[0].duration }}
        {{ $t('global.minute_short') }}</BaseText
      >
    </div>
    <div v-else>
      <div
        v-for="(variation, index) in activeVariations"
        :key="index"
        :class="$style.infoCard"
      >
        <BaseText :color="variation.name.length ? 'default' : 'warning'" mb>
          {{
            variation.name.length
              ? variation.name
              : t('service.nav.about.variation_name_required')
          }}
        </BaseText>
        <div :class="$style.info">
          <BaseText iconBefore="credit-card" :mr="2">{{
            formatCurrency(variation.price)
          }}</BaseText>
          <BaseText iconBefore="clock"
            >{{ variation.duration }} {{ $t('global.minute_short') }}</BaseText
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ServiceVariationsFormData } from '../types';
import { useI18n } from 'vue-i18n';
import { formatCurrency } from '@/helpers/currency';

const { t } = useI18n();
const formData = inject<ServiceVariationsFormData>('serviceFormData')!;

const activeVariations = computed(() =>
  formData.variations.filter((variation) => !variation.destroy)
);
const isServiceFormSubmitted = inject<Ref<boolean>>('isServiceFormSubmitted')!;
const hasError = computed(
  () => isServiceFormSubmitted.value && !formData.name.length
);
</script>

<style lang="scss" module>
.title {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
}

.info {
  display: flex;
}

.infoCard {
  background-color: $white;
  padding: $spacing;
  border: 1px solid $color-border;
  border-bottom: none;
  border-radius: $radius $radius 0 0;

  &:last-child {
    border-bottom: 1px solid $color-border;
    border-radius: 0 0 $radius $radius;
  }
}
</style>
