<template>
  <BaseModal
    :heading="
      outstandingAmount > 0
        ? t('register.saved_as_outstanding')
        : t('register.saved_as_paid')
    "
    testId="register-overview"
    @close="router.push({ name: 'register' })"
  >
    <div :class="{ [$style.smallScreen]: $screen === 's' }">
      <div :class="$style.colTwo">
        <div
          v-if="customer"
          :class="$style.left"
          v-test="'register-overview-customer'"
        >
          <div :class="$style.customer">
            <BaseAvatar :name="customer.fullName" :mr="0.5" />
            <BaseText
              link
              :routerLink="{
                name: 'customer',
                params: { customerId: customer.id }
              }"
            >
              {{ customer.fullName }}
            </BaseText>
          </div>
          <div
            v-if="customer.email"
            :class="$style.details"
            v-test="'register-overview-email'"
          >
            <BaseText :href="`mailto:${customer.email}`">
              {{ customer.email }}
            </BaseText>
          </div>
          <div
            v-if="customer.address"
            :class="$style.details"
            v-test="'register-overview-address'"
          >
            {{ customer.address }}
          </div>
          <div
            v-if="customer.mobilePhone"
            :class="$style.details"
            v-test="'register-overview-mobile'"
          >
            <BaseText :href="`tel:${customer.mobilePhone}`">
              {{ customer.mobilePhone }}
            </BaseText>
          </div>
          <div
            v-if="customer.phone"
            :class="$style.details"
            v-test="'register-overview-phone'"
          >
            <BaseText :href="`tel:${customer.phone}`">
              {{ customer.phone }}
            </BaseText>
          </div>
          <div
            v-if="customer.totalDue"
            :class="$style.details"
            v-test="'register-overview-due'"
          >
            {{
              $t('register.outstanding_balance', {
                amount: filters.currency(customer.totalDue)
              })
            }}
          </div>
          <div
            v-if="isLoyaltyPointsEnabled && customer?.loyaltyPoints"
            :class="$style.details"
            v-test="'register-overview-loyalty'"
          >
            {{
              $t('register.loyalty_points_earned', {
                amount: customer.loyaltyPoints
              })
            }}
          </div>
        </div>
        <div
          v-else
          :class="[$style.left, $style.customer, $style.noCustomer]"
          v-test="'register-overview-no-customer'"
        >
          {{ $t('register.no_customer') }}
        </div>
        <div>
          <div>
            <BaseInput
              v-model="email"
              :label="$t('global.email')"
              v-test="'register-overview-email'"
            />
            <BaseButton
              :disabled="emailInvalid || emailSent"
              fullWidth
              color="inverted"
              mt
              :loading="sendingEmail"
              @click="sendInvoice"
              v-test="'register-overview-send-email'"
            >
              {{
                emailSent
                  ? $t('register.email_sent')
                  : $t('register.send_digital_invoice')
              }}
            </BaseButton>
          </div>
          <div :class="$style.invoice">
            <BaseButton
              v-if="customer"
              fullWidth
              color="inverted"
              :routerLink="{
                name: 'customer',
                params: { customerId: customer.id }
              }"
              v-test="'register-overview-view-customer'"
            >
              {{
                filters.capitalize(
                  $t('global.actions.view_item', {
                    item: $t('global.items.customer', 1)
                  })
                )
              }}
            </BaseButton>
            <BaseButton
              v-if="showPdf"
              mt
              fullWidth
              color="inverted"
              :href="pdfUrl"
              v-test="'register-overview-pdf'"
            >
              {{ $t('register.open_invoice_pdf') }}
            </BaseButton>
          </div>
        </div>
      </div>
      <BaseAlert
        v-if="delayPrinting"
        :text="$t('register.print_warning')"
        mt
        icon="printer"
        color="warning"
        v-test="'register-overview-delayed-printing'"
      />
    </div>
    <template #footer>
      <BaseButton
        v-if="hasFeatureFlag('module-appointments')"
        @click="bookNextAppointment"
        v-test="'register-overview-next-appointment'"
      >
        {{ $t('register.book_next_appointment') }}
      </BaseButton>
    </template>
    <PrintUrl v-if="promptPrint" :url="printUrl" />
  </BaseModal>
</template>

<script setup lang="ts">
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { ref, computed, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { MessageTypeEnum } from '@/types';
import filters from '@/filters';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import config from '@/config';
import { Action, usePreCreateStore } from '@/stores/calendar-pre-create';
import { useRouter } from 'vue-router';
import { CREATE_MESSAGE } from './graphql';
import { useMutation } from '@vue/apollo-composable';
import PrintUrl from '@/components/PrintUrl.vue';
import { useFiscalizationStore } from '@/modules/register/stores/fiscalization';

const mixpanel = inject<any>('mixpanel');

const { companySettings, isLoyaltyPointsEnabled } = useCompanyStore();
const { hasFeatureFlag } = useUserStore();
const { reset } = useRegisterOrderStore();
const {
  order,
  selectedCustomer: customer,
  outstandingAmount
} = useRegisterOrderStore();
const { t } = useI18n();
const router = useRouter();
const { setState } = usePreCreateStore();
const { addOrderToQueue } = useFiscalizationStore();

const email = ref(customer?.email || '');
const emailInvalid = computed(() => !/.+@.+\..+/.test(email.value));
const emailSent = ref(false);

const promptPrint = ref(false);
const printingEnabled = computed(() => companySettings.sales.thermalSupport);
const fiscalizationEnabled = computed(
  () =>
    !!companySettings.fiscalization.tssId ||
    hasFeatureFlag('fiskaly-v2-enabled')
);
const showPdf = computed(
  () => !fiscalizationEnabled.value || order.fiscalizationFinished
);
const delayPrinting = computed(
  () => printingEnabled.value && fiscalizationEnabled.value
);
const printUrl = computed(() => `${config.backendUrl}/orders/${order.id}`);
const pdfUrl = computed(() => `${printUrl.value}.pdf`);

const { mutate: sendEmail, loading: sendingEmail } =
  useMutation(CREATE_MESSAGE);

const bookNextAppointment = () => {
  if (customer) {
    setState({ action: Action.Create, customer });
  }
  router.push({ name: 'calendar' });
  mixpanel.track('Register - Book next appointment clicked', {
    customer: !!customer
  });
};

const sendInvoice = () => {
  sendEmail({
    input: {
      destination: email.value,
      messageType: MessageTypeEnum.OrderInvoice,
      orderId: order.id
    }
  }).then(({ data: { createMessage } }: any) => {
    if (!createMessage.errors) {
      emailSent.value = true;
    }
  });
};

onUnmounted(() => reset());

const listenToOrderFiscalization = () => {
  if (order.id) {
    addOrderToQueue(order.id);
  }
};

if (delayPrinting.value) {
  listenToOrderFiscalization();
} else if (printingEnabled.value) {
  promptPrint.value = true;
}
</script>

<style lang="scss" module>
.invoice {
  border-top: 1px solid $color-border;
  padding-top: $spacing * 2;
  margin-top: $spacing * 2;
}

.details {
  padding: $spacing 0;
  border-bottom: 1px solid $color-border;

  &:last-of-type {
    border-bottom: 0;
  }
}

.customer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-border;
  padding: $spacing;
  height: $spacing * 6;
  font-size: 18px;

  @include hover {
    background-color: $color-highlight;
  }
}

.noCustomer {
  border-radius: $radius;
  border: 1px solid $color-border;
}

.colTwo {
  display: flex;

  > * {
    width: 50%;

    &:not(:first-child) {
      margin-left: $spacing;
      border-left: 1px solid $color-border;
      padding-left: $spacing;
    }
  }

  .smallScreen & {
    flex-direction: column;

    > * {
      width: 100%;

      &:not(:first-child) {
        margin-top: $spacing * 2;
        margin-left: 0;
        border-top: 1px solid $color-border;
        border-left: none;
        padding-top: $spacing;
        padding-left: 0;
      }
    }
  }
}
</style>
