<template>
  <div
    :class="[
      $style.base,
      {
        [$style.mediumScreen]: $screen === 's' || $screen === 'm'
      }
    ]"
    v-test="'service-variation-about'"
  >
    <BaseDropdown
      v-model="formData.categoryId"
      :options="
        serviceCategories.map((category) => ({
          value: category.id,
          label: category.name
        }))
      "
      :actions="[
        {
          label: $t('service.new_category'),
          id: 'add-new',
          icon: 'plus',
          color: 'success'
        }
      ]"
      :label="$t('global.items.category', 1)"
      required
      :mb="1.5"
      @action="() => (showCategoryModal = true)"
      v-test="'service-category'"
    />
    <CategoryModal
      v-if="showCategoryModal"
      @create="formData.categoryId = $event"
      @close="showCategoryModal = false"
    />
    <SingleServiceForm v-if="hasVariations" />
    <div v-if="!hasVariations">
      <ServiceGroupForm />
      <VariationForm
        v-for="(variation, index) in activeVariations"
        :key="index"
        :variation="variation"
        :visualIndex="index"
      />
    </div>
    <BaseChip
      :text="$t('services.add_variation')"
      icon="plus"
      clickable
      @click="addVariation"
      v-test="'service-add-variation'"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ServiceVariationsGeneralView'
});
</script>

<script setup lang="ts">
import type { ServiceVariationsFormData } from '../types';

import { useServicesStore } from '@/stores/services';
import CategoryModal from '@/modules/admin/services/Category.vue';

import SingleServiceForm from './SingleServiceForm.vue';
import { storeToRefs } from 'pinia';
import ServiceGroupForm from './ServiceGroupForm.vue';
import VariationForm from './VariationForm.vue';

const formData = inject<ServiceVariationsFormData>('serviceFormData')!;

const showCategoryModal = ref(false);

const { serviceCategories } = storeToRefs(useServicesStore());

const activeVariations = computed(() =>
  formData.variations.filter((variation) => !variation.destroy)
);
const hasVariations = computed(() => activeVariations.value.length <= 1);

const addVariation = () => {
  formData.variations.push({
    ...activeVariations.value[activeVariations.value.length - 1],
    id: undefined
  });
};
</script>

<style module lang="scss">
.row {
  display: flex;
  justify-content: space-between;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.moreOptions {
  position: relative;
}
</style>
