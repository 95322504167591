const Reports = () => import('@/modules/reports/index.vue');
const ReportsTotals = () => import('@/modules/reports/totals/index.vue');
const ReportsDaily = () => import('@/modules/reports/daily/index.vue');
const ReportsMonthly = () => import('@/modules/reports/monthly/index.vue');
const ReportsProducts = () => import('@/modules/reports/products/index.vue');
const ReportsServices = () => import('@/modules/reports/services/index.vue');
const ReportsEmployees = () => import('@/modules/reports/employees/index.vue');
const ReportsVatOverview = () =>
  import('@/modules/reports/vat-overview/index.vue');
import { redirectRoute } from '../helpers';
import { useUserStore } from '@/stores/user';

export default {
  path: 'reports',
  name: 'reports',
  component: Reports,
  beforeEnter: (to) => {
    const { hasFeatureFlag } = useUserStore();

    if (hasFeatureFlag('module-reports') && to.name === 'reports') {
      return redirectRoute({ name: 'reports-totals' });
    }
  },
  meta: {
    moduleName: 'reports',
    role: 'reports'
  },
  children: [
    {
      path: 'totals',
      name: 'reports-totals',
      component: ReportsTotals,
      meta: {
        featureFlag: 'module-reports',
        mixpanelName: 'Reports - totals'
      }
    },
    {
      path: 'daily',
      name: 'reports-daily',
      component: ReportsDaily,
      meta: {
        featureFlag: 'module-reports',
        mixpanelName: 'Reports - daily'
      }
    },
    {
      path: 'monthly',
      name: 'reports-monthly',
      component: ReportsMonthly,
      meta: {
        featureFlag: 'module-reports',
        mixpanelName: 'Reports - monthly'
      }
    },
    {
      path: 'products',
      name: 'reports-products',
      component: ReportsProducts,
      meta: {
        featureFlag: 'module-reports',
        mixpanelName: 'Reports - products'
      }
    },
    {
      path: 'services',
      name: 'reports-services',
      component: ReportsServices,
      meta: {
        featureFlag: 'module-reports',
        mixpanelName: 'Reports - services'
      }
    },
    {
      path: 'employees',
      name: 'reports-employees',
      component: ReportsEmployees,
      meta: {
        featureFlag: 'module-reports',
        mixpanelName: 'Reports - employees'
      }
    },
    {
      path: 'vat-overview',
      name: 'reports-vat-overview',
      component: ReportsVatOverview,
      meta: {
        featureFlag: 'module-reports',
        mixpanelName: 'Reports - vat overview'
      }
    }
  ]
};
