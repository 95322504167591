<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div
      v-show="showEmber"
      :class="$style.iframeWrap"
      v-test="'admin-page-ember'"
    >
      <iframe
        v-if="!disableEmber"
        ref="iframe"
        :class="$style.iframe"
        :src="emberUrlFull"
      />
    </div>
    <div
      v-if="!disableVue"
      v-show="!showEmber"
      ref="vueContainer"
      :class="$style.vueWrap"
      v-test="'admin-page-vue'"
    >
      <router-view />
    </div>
    <BaseSpinner v-if="showEmber && !isEmberPageLoaded && !isEmberErrored" />
  </div>
</template>

<script lang="ts">
import { modal } from '@/helpers/ui';
import config from '@/config';
import eventBus from '@/event-bus';
import { useLocationsStore } from '@/stores/locations';
import { storeToRefs } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { scrollPage, usePageScroll } from '@/helpers/scroll';
import { defineComponent, ref } from 'vue';
import unleash from '@/unleash';

export default defineComponent({
  setup() {
    const { companyId } = useCompanyStore();
    const { locationId, dataScope } = storeToRefs(useLocationsStore());
    const emberUrl = config.emberUrl || '';
    const emberUrlFull = `${emberUrl}/c/${companyId}/l/${locationId.value}`;

    const vueContainer = ref();
    usePageScroll(vueContainer, {
      instant: true
    });

    return {
      emberUrl,
      companyId,
      locationId,
      dataScope,
      emberUrlFull,
      vueContainer
    };
  },
  data() {
    return {
      isEmberMounted: false,
      isEmberPageLoaded: false,
      isEmberErrored: false,
      currentEmberRoute: null
    };
  },
  watch: {
    $route: {
      handler() {
        this.$nextTick(() => {
          this.changeEmberRoute();
        });
      },
      deep: true
    },
    '$route.name'(newRoute, oldRoute) {
      if (
        newRoute === 'integrations-overview' &&
        oldRoute === 'integration-treatwell-marketplace-admin'
      ) {
        this.reloadEmber();
      }

      scrollPage({
        position: 'top'
      });
    },
    isEmberMounted(value) {
      if (value) {
        this.changeEmberRoute();
      }
    },
    locationId(value) {
      this.sendEmberEvent('changeLocation', value);
    },
    dataScope: 'updateDataScope',
    isEmberPageLoaded: 'updateDataScope'
  },
  computed: {
    emberRoute() {
      return this.$route.meta?.emberRoute;
    },
    disableEmber() {
      return !this.emberUrl;
    },
    disableVue() {
      // The toggling of the Vue router view and the Ember iframe happens with a v-show, for better performance
      // But when the route has a unleash flag, and it's not enabled, we don't want to render the Vue page at all, so we have to disable it with a v-if
      return !!this.unleashRoute && !this.isUnleashEnabled;
    },
    unleashRoute() {
      // Returns either the current route, or any parent route that has a unleash flag
      return this.$route.matched
        .slice()
        .reverse()
        .find((route) => route.meta?.unleash);
    },
    isUnleashEnabled() {
      return unleash.isEnabled(this.unleashRoute?.meta.unleash);
    },
    showEmber() {
      return !this.isUnleashEnabled && !!this.emberRoute;
    }
  },
  methods: {
    updateDataScope() {
      const emberValue = this.dataScope === 'LOCAL' ? 'location' : 'company';
      this.sendEmberEvent('changeDataScope', emberValue);
    },
    changeEmberRoute() {
      const route = Array.isArray(this.emberRoute)
        ? this.emberRoute[0]
        : this.emberRoute;
      const id = Array.isArray(this.emberRoute)
        ? this.emberRoute[1]
        : this.$route.params.id ||
          this.$route.params.serviceId ||
          this.$route.params.categoryId;

      this.sendEmberEvent('changeRoute', {
        route,
        id,
        query: this.$route.query
      });

      if (this.isEmberErrored) {
        this.reloadEmber();
      }
    },
    reloadEmber() {
      this.sendEmberEvent('refresh');
      this.isEmberMounted = false;
      this.isEmberPageLoaded = false;
      this.isEmberErrored = false;
    },
    sendEmberEvent(action, value) {
      const iframe = this.$refs.iframe;
      if (iframe && this.showEmber) {
        iframe.contentWindow.postMessage(
          {
            action,
            value
          },
          this.emberUrl
        );
      }
    },
    sendGlobalEmberEvent(action) {
      const iframe = this.$refs.iframe;
      if (iframe) {
        iframe.contentWindow.postMessage(
          {
            action
          },
          this.emberUrl
        );
      }
    },
    onEmberEvent(event) {
      if (!event || event.origin !== this.emberUrl) {
        return;
      }

      const { data } = event;

      if (data === 'emberMounted') {
        this.isEmberMounted = true;
      }

      if (data === 'emberPageLoaded') {
        setTimeout(() => {
          this.isEmberPageLoaded = true;
        }, 100);
      }

      if (data === 'refetchCompany') {
        const { getUser } = useUserStore();
        getUser();
      }

      if (data.action === 'emberRouteChange' && data.value) {
        this.onEmberRouteChange(data.value, data.id);
      }

      if (data.action === 'externalUrlChange' && data.value) {
        window.location = data.value;
      }

      if (data.action === 'openNewWindow' && data.value) {
        window.open(data.value);
      }

      if (data.action === 'showModal' && data.value) {
        modal(data.value);
      }
    },
    onEmberRouteChange(emberRoute, id) {
      if (
        !this.showEmber ||
        !emberRoute ||
        !this.isEmberMounted ||
        !this.isEmberPageLoaded ||
        (this.currentEmberRoute === emberRoute && this.emberRoute !== 'apps')
      ) {
        return;
      }

      if (emberRoute === 'admin.referrals') {
        this.$router.push({ name: 'admin-referrals' });
        return;
      }

      if (emberRoute === 'admin.sms-credits.purchase') {
        this.$router.push({ name: 'admin-sms-credits' });
        return;
      }

      if (emberRoute === 'subscription.plan') {
        this.$router.push({ name: 'subscription' });
        return;
      }

      if (emberRoute === 'admin.employee.edit' && id) {
        this.$router.push({ name: 'admin-employee', params: { id } });
        return;
      }

      this.currentEmberRoute = emberRoute;

      if (
        emberRoute === 'app_error' ||
        emberRoute === '404' ||
        emberRoute === '401'
      ) {
        this.isEmberErrored = true;
        return;
      }

      const vueRoute = this.$router.getRoutes().find((route) => {
        const routeMeta = route.meta.emberRoute;

        if (!routeMeta) {
          return route.name === emberRoute;
        } else if (Array.isArray(routeMeta) && id) {
          return routeMeta[0] === emberRoute && routeMeta[1] === id;
        } else if (Array.isArray(routeMeta)) {
          return routeMeta[0] === emberRoute;
        } else {
          return routeMeta && routeMeta === emberRoute;
        }
      });

      const params = id ? { id } : {};

      //TODO: Remove this after releasing the new services page for each user
      if (vueRoute?.name === 'admin-service') {
        params.serviceId = id;
      }
      //end

      if (vueRoute?.name && vueRoute.name !== this.$route.name) {
        this.$router.push({ name: vueRoute.name, params });
      }
    }
  },
  created() {
    window.addEventListener('message', this.onEmberEvent);

    eventBus.$on('ember-event', this.sendGlobalEmberEvent);
    eventBus.$on('reload-ember', this.reloadEmber);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.onEmberEvent);
    eventBus.$off('ember-event');
    eventBus.$off('reload-ember');
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.iframeWrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iframe {
  height: 100%;
  width: 100%;
  border: 0;
  overflow: hidden;

  .base.smallScreen & {
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: left top;
  }
}

.vueWrap {
  height: 100%;
  overflow-y: auto;
}
</style>
