<template>
  <Builder v-if="!loading" v-model="toggleValue" :settings="settings">
    <template #actions>
      <TemplateActions :loading="isLoading" @save="onSave" />
    </template>
  </Builder>
</template>

<script lang="ts" setup>
import { modal, flash } from '@/helpers/ui';
import Builder from '@/modules/marketing/email/builder/index.vue';
import TemplateActions from '../../TemplateActions.vue';
import gql from 'graphql-tag';
import { useCompanyStore } from '@/stores/company';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';
import { emailTemplateFragment } from '@/graphql-fragments';
import { computed, inject, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useOnboardingStore } from '@/stores/onboarding';
import eventBus from '@/event-bus';

const mixpanel = inject<any>('mixpanel');

const { companySettings, updateCompany } = useCompanyStore();
const { currentOnboardingFlow } = toRefs(useOnboardingStore());
const store = useStore();
const { t } = useI18n();
const router = useRouter();

const isInvalid = computed(() => store.getters['marketing/isInvalid']);

const isLoading = ref(false);
const settings = reactive({
  emailType: 'REBOOK_REMINDER',
  title: t('marketing.email.automated_emails.rebook.title'),
  description: t('marketing.email.automated_emails.rebook.intro'),
  sentDescription: t('marketing.email.automated_emails.rebook_setup.when'),
  receiveDescription: t('marketing.email.automated_emails.rebook.receive_info'),
  showToggle: true,
  showRebookSettings: true,
  showReset: true,
  hideButtonOptions: true,
  allowImageUpload: false
});

const { loading, result, onResult } = useQuery(
  gql`
    query getEmailTemplate($emailType: EmailTemplateEnum!) {
      emailTemplate(emailType: $emailType) {
        ...emailTemplate
      }
      emailTemplateDefault: emailTemplate(
        emailType: $emailType
        defaultValues: true
      ) {
        ...emailTemplate
      }
    }
    ${emailTemplateFragment}
  `,
  {
    emailType: 'REBOOK_REMINDER'
  }
);

onResult(() => {
  if (result.value.emailTemplate) {
    result.value.emailTemplate.emailTemplateDefault =
      result.value.emailTemplateDefault;
    store.commit('marketing/SET_ATTRS', result.value.emailTemplate);
  }
});

const toggleValue = computed({
  get() {
    return companySettings.bookings?.rebookReminderEnabled || false;
  },
  set(value) {
    const input = {
      settings: {
        bookings: {
          rebookReminderEnabled: value
        }
      }
    };

    updateCompany(input).then(() => {
      if (value) {
        mixpanel.track('Rebook reminder feature enable click');
      }
      if (currentOnboardingFlow.value === 'marketing') {
        eventBus.$emit('open-task-menu');
        currentOnboardingFlow.value = '';
      }
      flash(t(`global.flash.email_${value ? 'enabled' : 'disabled'}`));
    });
  }
});

const onSave = () => {
  store.commit('marketing/SET_SUBMITTED', true);

  if (isInvalid.value) {
    return;
  }

  modal('confirmation').then(() => {
    isLoading.value = true;
    store
      .dispatch('marketing/updateEmailTemplate', 'REBOOK_REMINDER')
      .finally(() => {
        router.push({ name: 'marketing-email' });

        if (currentOnboardingFlow.value === 'marketing') {
          eventBus.$emit('open-task-menu');
          currentOnboardingFlow.value = '';
        }

        flash(t('global.flash.email_saved'));
        store.commit('marketing/SET_SUBMITTED', false);
        mixpanel.track('Rebook reminder template saved');
        isLoading.value = false;
      });
  });
};
</script>
