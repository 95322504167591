<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isExpanded]: expanded
      }
    ]"
    :style="
      color
        ? {
            'background-color': color,
            'border-left-color': data.color
          }
        : null
    "
    v-test="'service-category-header'"
  >
    <div :class="[$style.section, $style.sectionLeft]">
      <BaseText bold v-test="'service-category-name'">
        {{ data.name }}
      </BaseText>
      <BaseText :ml="0.25" v-test="'service-category-amount'">
        ({{ data.services.length }})
      </BaseText>
    </div>
    <div :class="[$style.section, $style.sectionRight]">
      <div v-if="serviceCategorySortOder" :class="$style.sortOrder">
        <BaseCounter
          v-model="sortOrderValue"
          :minValue="0"
          :maxValue="listLength - 1"
          hideValue
          reverse
          v-test="'category-sort-order'"
        />
      </div>
      <div v-else>
        <div :class="$style.more" @click="onMoreClick">
          <BaseMore
            :options="['edit', 'delete', 'sort']"
            @select="onMoreSelect"
          />
        </div>
      </div>
      <div v-if="listLength > 1 && !serviceCategorySortOder">
        <BaseIcon name="chevron-down" v-test="'category-item-chevron'" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { modal } from '@/helpers/ui';
import { useServicesStore } from '@/stores/services';

import { defineComponent, inject } from 'vue';

type MoreAction = 'edit' | 'delete' | 'sort';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      required: false
    },
    listLength: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['updateSortOrder'],
  setup() {
    const serviceCategorySortOder = inject('services-category-sort-order');
    return { serviceCategorySortOder };
  },
  computed: {
    sortOrderValue: {
      get() {
        return this.index;
      },
      set(value) {
        this.$emit('updateSortOrder', value);
      }
    }
  },
  methods: {
    onMoreClick(e) {
      e.stopPropagation();
    },
    editCategory() {
      this.$router.push({
        name: 'admin-service-category',
        params: {
          categoryId: this.data.id
        }
      });
    },
    deleteCategory() {
      if (this.data.services.length > 0) {
        modal('warning', {
          message: this.$t('global.warning.delete', { item: this.data.name }),
          subMessage: this.$t('category.delete_warning')
        });
      } else {
        modal('confirmation', {
          message: this.$t('global.confirmation.confirm_delete', {
            item: this.data.name
          })
        }).then(() => {
          const { deleteCategory } = useServicesStore();
          deleteCategory(this.data.id);
        });
      }
    },
    onMoreSelect(action: MoreAction) {
      switch (action) {
        case 'edit':
          this.editCategory();
          break;
        case 'delete':
          this.deleteCategory();
          break;
        case 'sort':
          this.serviceCategorySortOder = true;
          break;
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing;
  border-left-width: 3px;
  border-left-style: solid;
  border-radius: $radius;
  cursor: pointer;
  min-height: 60px;

  &.isExpanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.section {
  display: flex;
  align-items: center;
}

.sortOrder {
  margin-top: $spacing * -0.5;
  margin-bottom: $spacing * -0.5;
}

.sectionLeft {
  margin-right: $spacing;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionRight {
  flex-shrink: 0;

  & > *:not(:first-child) {
    margin-left: $spacing;
  }
}

.more {
  margin: $spacing * -1;
}
</style>
