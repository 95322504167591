<template>
  <BaseForm fullHeight @submit="submit">
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <OrderActions />
      <OrderLoyaltyPoints
        v-if="isLoyaltyPointsEnabled && order.customerId"
        :mt="$screen === 's'"
        v-test="'register-order-loyalty-points'"
      />
      <div :class="$style.details">
        <OrderItems />
      </div>
      <Footer :savingDraft="savingDraft" @setSavingDraft="savingDraft = true" />
      <OpenDrawerModal
        v-if="showOpenDrawerModal"
        @close="showOpenDrawerModal = false"
        @success="goToPayment"
      />
    </div>
  </BaseForm>
</template>

<script lang="ts">
export default {
  name: 'RegisterBrowser',
  components: { OrderActions }
};
</script>

<script setup lang="ts">
import OrderActions from './OrderActions.vue';
import OrderItems from './items/index.vue';
import Footer from './Footer.vue';
import OpenDrawerModal from '@/modules/register/modals/open-drawer/index.vue';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useRegisterStore } from '@/modules/register/stores/register';
import {
  useRegisterOrderStore,
  type OrderItem
} from '@/modules/register/stores/order';
import { useCompanyStore } from '@/stores/company';
import { useRouter } from 'vue-router';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import OrderLoyaltyPoints from './OrderLoyaltyPoints.vue';
import { flash, modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { mutateOrder } from '../actions/order';

const { t } = useI18n();

const { order, reset } = useRegisterOrderStore();
const { isLoyaltyPointsEnabled } = useCompanyStore();
const { isOpen: isDrawerOpen } = useRegisterDrawerStore();
const { cashupEnabled } = storeToRefs(useRegisterStore());
const router = useRouter();
const showOpenDrawerModal = ref(false);
const savingDraft = ref(false);

const mixpanel = inject<any>('mixpanel');

const submit = () => {
  if (!order.items.length) {
    return;
  }

  if (savingDraft.value) {
    saveDraft();
  } else {
    checkoutOrder();
  }
};

const checkoutOrder = () => {
  mixpanel.track('Register - Checkout order clicked');

  if (cashupEnabled.value && !isDrawerOpen) {
    showOpenDrawerModal.value = true;
  } else {
    goToPayment();
  }
};

const goToPayment = () => {
  router.push({ name: 'register-payment' });
};

const saveDraft = () => {
  mixpanel.track('Register - Save draft clicked');
  const noCards = !order.items.find(
    (item: OrderItem) => item.giftcard || item.prepaidCard
  );

  if (noCards) {
    if (order.id) {
      callOrderMutation('update');
    } else {
      order.draft = true;
      callOrderMutation('create');
    }
  } else {
    modal('warning', {
      message: t('register.draft_notice')
    });
    savingDraft.value = false;
  }
};

const { call: callOrderMutation, response: mutationResponse } = mutateOrder();

watch(mutationResponse, (value) => {
  if (value) {
    handleDraftResponse(value.createOrder || value.updateOrder);
  }
});

const handleDraftResponse = ({ errors }: any) => {
  if (errors) {
    modal('warning', {
      message: t('register.draft_error')
    });
  } else {
    flash(t('register.saved_as_draft'));
  }
  savingDraft.value = false;
  reset();
};
</script>

<style lang="scss" module>
.base {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &.smallScreen {
    .details {
      overflow-y: initial;
    }
  }
}

.details {
  overflow-y: auto;
  flex-grow: 1;
}
</style>
