<template>
  <BaseCard gray :mb="1.5">
    <div
      :class="[
        $style.base,
        {
          [$style.mediumScreen]: $screen === 's' || $screen === 'm'
        }
      ]"
      v-test="'service-variation-about'"
    >
      <div :class="$style.heading">
        <BaseHeading
          >{{ $t('service.variation') }} #{{ visualIndex + 1 }}</BaseHeading
        >
        <BaseIcon
          clickable
          name="delete"
          @click="removeVariation"
          v-test="'service-variation-delete'"
        />
      </div>
      <BaseInput
        v-model="variation.name"
        :label="$t('service.variation_name')"
        :maxLength="255"
        required
        mb
        v-test="'service-variation-name'"
      />
      <div :class="$style.row">
        <div :class="$style.rowGroup">
          <BaseInput
            v-model="variation.price"
            :label="$t('global.price')"
            type="currency"
            :mr="0.5"
            v-test="'service-variation-price'"
          />
        </div>
        <div :class="$style.rowGroup">
          <BaseInput
            v-model="variation.duration"
            :label="$t('global.duration')"
            unitLabel="minute"
            type="number"
            :disabled="variation.requiresProcessingTime"
            :minValue="0"
            :maxValue="maxDuration"
            :mr="0.5"
            :mb="$screen !== 'l'"
            v-test="'service-variation-duration'"
          />
          <div :class="$style.moreOptions">
            <BaseButton
              color="inverted"
              :options="moreOptions"
              :disabled="moreOptions.length <= 0"
              @select="handleMoreOptionsSelect"
              v-test="'service-variation-duration-more-options'"
            >
              {{ $t('global.more_options') }}
            </BaseButton>
          </div>
        </div>
      </div>
      <div
        v-if="variation.requiresProcessingTime"
        :class="$style.section"
        v-test="'service-variation-processing-time-card'"
      >
        <div :class="$style.heading">
          <BaseHeading mb>{{
            $t('service.processing_time.heading')
          }}</BaseHeading>
          <BaseIcon
            clickable
            name="delete"
            @click="variation.requiresProcessingTime = false"
            v-test="'variation-processing-time-remove-btn'"
          />
        </div>
        <BaseText mb>{{ $t('service.processing_time.description') }}</BaseText>
        <div :class="$style.row">
          <BaseInput
            v-model="variation.durationSetup"
            unitLabel="minute"
            type="number"
            :label="$t('service.duration_processing.setup')"
            :required="variation.requiresProcessingTime"
            :minValue="variation.requiresProcessingTime ? 1 : 0"
            :maxValue="maxDurationProcessing"
            :mb="$screen === 's'"
            v-test="'service-variation-duration-setup'"
          />
          <BaseInput
            v-model="variation.durationProcessing"
            :label="$t('service.duration_processing.processing')"
            :minValue="0"
            :maxValue="maxDurationProcessing"
            unitLabel="minute"
            type="number"
            :mb="$screen === 's'"
            :info="$t('service.duration_processing.description')"
            v-test="'service-variation-duration-processing'"
          />
          <BaseInput
            v-model="variation.durationFinish"
            unitLabel="minute"
            type="number"
            :label="$t('service.duration_processing.finish')"
            :minValue="0"
            :maxValue="maxDurationProcessing"
            v-test="'service-variation-duration-finish'"
          />
        </div>
      </div>
      <div
        v-if="variation.buffer || hasBufferTime"
        :class="$style.section"
        v-test="'service-buffer-time-card'"
      >
        <div :class="$style.heading">
          <BaseHeading mb>{{ $t('service.buffer_time.heading') }}</BaseHeading>
          <BaseIcon
            clickable
            name="delete"
            @click="removeBufferTime"
            v-test="'variation-buffer-time-remove-btn'"
          />
        </div>
        <BaseText mb>{{ $t('service.buffer_time.description') }}</BaseText>
        <BaseInput
          v-model="variation.buffer"
          :label="$t('service.buffer_time.heading')"
          :minValue="0"
          :maxValue="maxDuration"
          unitLabel="minute"
          type="number"
          :info="$t('service.buffer_time.info')"
          v-test="'service-variation-buffer'"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import type { ServiceVariation, ServiceVariationsFormData } from '../types';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const maxDuration = 1439;
const moreOptionsPopoverShow = ref(false);
const hasBufferTime = ref(false);

const maxDurationProcessing = Math.floor(maxDuration / 3);

const props = defineProps<{
  variation: ServiceVariation;
  visualIndex: number;
}>();

defineEmits(['removeVariation']);

const formData = inject<ServiceVariationsFormData>('serviceFormData')!;

watchEffect(() => {
  if (props.variation.requiresProcessingTime) {
    props.variation.duration =
      props.variation.durationSetup +
      props.variation.durationProcessing +
      props.variation.durationFinish;
  }
});

const moreOptions = computed(() => {
  const options = [];

  if (!props.variation.requiresProcessingTime) {
    options.push({
      label: t('service.processing_time.heading'),
      value: 'processingTime'
    });
  }

  if (!(props.variation.buffer || hasBufferTime.value)) {
    options.push({
      label: t('service.buffer_time.heading'),
      value: 'bufferTime'
    });
  }

  return options;
});

const removeBufferTime = () => {
  props.variation.buffer = 0;
  hasBufferTime.value = false;
};

const handleMoreOptionsSelect = (value: 'processingTime' | 'bufferTime') => {
  moreOptionsPopoverShow.value = false;

  if (value === 'processingTime') {
    props.variation.requiresProcessingTime = true;
  }

  if (value === 'bufferTime') {
    hasBufferTime.value = true;
  }
};

const removeVariation = () => {
  if (props.variation.id) {
    props.variation.destroy = true;
  } else {
    formData.variations = formData.variations.filter(
      (_, i) => i !== formData.variations.indexOf(props.variation)
    );
  }
};
</script>

<style module lang="scss">
.row {
  display: flex;
  justify-content: space-between;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;
}

.rowGroup {
  display: flex;
  align-items: flex-end;
  margin-right: $spacing;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
    margin-right: 0;
    margin-bottom: $spacing;
  }

  &:first-child {
    flex-grow: 1;
  }
}

.section {
  margin-top: $spacing * 2;
}

.moreOptions {
  position: relative;
}
</style>
