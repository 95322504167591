<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <BaseHeading :size="$screen === 's' ? 'l' : 'xl'" :mb="2" center>
      {{ $t('login.heading') }}
    </BaseHeading>
    <BaseForm @submit="onSubmit">
      <BaseInput
        v-model="email"
        :label="$t('global.email')"
        type="email"
        name="email"
        required
        mb
        v-test="'login-email'"
      />
      <div :class="$style.passwordContainer">
        <BaseInput
          v-model="password"
          :label="$t('global.password')"
          type="password"
          name="password"
          required
          mb
          v-test="'login-password'"
        />
        <div :class="$style.forgotPassword">
          <BaseText
            link
            :routerLink="{ name: 'forgot-password' }"
            v-test="'btn-forgot-password'"
          >
            {{ $t('login.forgot_password') }}
          </BaseText>
        </div>
      </div>
      <BaseButton
        :loading="isLoading"
        size="l"
        submitForm
        fullWidth
        v-test="'login-submit'"
      >
        {{ $t('login.button') }}
      </BaseButton>
      <BaseText
        v-if="loginFailed"
        color="error"
        mt
        v-test="'error-unauthorized'"
      >
        {{ $t('login.unknown_user_wrong_password') }}
      </BaseText>
      <BaseText v-if="loginBlocked" color="error" mt v-test="'error-blocked'">
        {{ $t('login.too_many_attempts') }}
      </BaseText>
    </BaseForm>
    <div :class="$style.nav">
      <BaseText inline> {{ $t('login.signup_1') }}&nbsp; </BaseText>
      <BaseText :routerLink="{ name: 'signup' }" bold v-test="'btn-signup'">
        {{ $t('login.signup_2') }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import axios from '@/axios';
import { redirectRoute } from '@/router/helpers';
import { useUserStore } from '@/stores/user';
import { useSessionStore } from '@/stores/session';

import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      loginFailed: false,
      loginBlocked: false
    };
  },
  methods: {
    onSubmit() {
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.authenticate();
      }
    },
    authenticate() {
      this.isLoading = true;
      this.loginFailed = false;
      this.loginBlocked = false;

      axios
        .post('/sessions', {
          user: {
            email: this.email,
            password: this.password
          }
        })
        .then(({ data }) => {
          if (data.error) {
            this.loginFailed = true;
            this.isLoading = false;
          } else {
            if (data.two_factor_required) {
              this.$router.push({
                name: 'two-factor-auth',
                params: {
                  email: this.email,
                  password: this.password,
                  authenticator_mode: data.authenticator_mode,
                  locale: data.locale
                }
              });
            } else {
              const { fetchAllData } = useSessionStore();
              fetchAllData().then(() => {
                const { user } = useUserStore();
                if (user) {
                  this.$router.push(
                    redirectRoute({ query: this.$route.query })
                  );
                } else {
                  this.loginFailed = true;
                  this.isLoading = false;
                }
              });
            }
          }
        })
        .catch((response) => {
          this.isLoading = false;
          if (response.status === 429) {
            this.loginBlocked = true;
          } else {
            this.loginFailed = true;
          }
        });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.bottom {
  .base:not(.smallScreen) & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .base.smallScreen & {
    & > * {
      display: block;

      &:not(:last-child) {
        margin-bottom: $spacing;
      }
    }
  }
}

.passwordContainer {
  position: relative;
}

.forgotPassword {
  position: absolute;
  top: 0;
  right: 0;
}
.nav {
  display: flex;
  justify-content: center;
  padding-top: $spacing * 2;
}
</style>
