<template>
  <div v-test="'register-browser-category'">
    <EmptyResults v-if="items.length < 1 && !emptyProducts && !loading" />

    <BrowserItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      @click="$emit('select', item.id)"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script setup lang="ts">
import EmptyResults from '../../EmptyResults.vue';
import BrowserItem from '../../BrowserItem.vue';
import { usePagination } from '@/apollo/pagination';
import { GET_PRODUCT_CATEGORIES } from '../../graphql';
import LoadMore from '@/components/LoadMore.vue';
import { computed } from 'vue';

defineProps<{ emptyProducts: boolean }>();

defineEmits<{
  (e: 'select', value: number): void;
}>();

const { productCategories, allDataFetched, fetchMore, loading } = usePagination(
  {
    fieldName: 'productCategories',
    query: GET_PRODUCT_CATEGORIES
  }
);

const items = computed(() =>
  productCategories.value.map((category) => ({
    label: category.name,
    id: category.id
  }))
);
</script>
