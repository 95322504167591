<template>
  <div
    ref="el"
    :class="[
      $style.base,
      $style[`variant-${variant}`],
      $style[`color-${color}`],
      {
        [$style.xSmallSize]: width < 300,
        [$style.smallSize]: width < 460,
        [$style.hasImage]: !!imagePath
      }
    ]"
  >
    <div :class="$style.inner" v-test="'_base-visual-banner'">
      <div :class="$style.content">
        <div :class="$style.text">
          <BaseHeading v-if="heading" :size="variant === 'small' ? 'm' : 'l'">
            {{ heading }}
          </BaseHeading>
          <BaseText v-if="description">
            {{ description }}
          </BaseText>
        </div>
        <div v-if="primaryAction || secondaryAction" :class="$style.buttons">
          <component
            :is="variant === 'small' ? 'BaseText' : 'BaseButton'"
            v-if="secondaryAction"
            :routerLink="secondaryAction.routerLink"
            :href="secondaryAction.href"
            :color="variant === 'small' ? 'default' : 'inverted'"
            :link="variant === 'small'"
            @click="$emit('secondaryAction')"
            v-test="'_base-visual-banner-btn-secondary'"
          >
            {{
              typeof secondaryAction === 'string'
                ? secondaryAction
                : secondaryAction.text
            }}
          </component>
          <component
            :is="variant === 'small' ? 'BaseText' : 'BaseButton'"
            v-if="primaryAction"
            :iconBefore="variant === 'small' ? icon : undefined"
            :icon="variant === 'small' ? undefined : icon"
            :routerLink="primaryAction.routerLink"
            :href="primaryAction.href"
            :link="variant === 'small'"
            @click="$emit('primaryAction')"
            v-test="'_base-visual-banner-btn-primary'"
          >
            {{
              typeof primaryAction === 'string'
                ? primaryAction
                : primaryAction.text
            }}
          </component>
        </div>
      </div>
      <img v-if="imagePath" :class="$style.visual" :src="imagePath" />
    </div>
    <svg
      :class="$style.blob"
      width="317"
      height="266"
      fill="none"
      viewBox="0 0 317 266"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#9895FF"
        fill-opacity=".6"
        d="M4.123 206.246c-6.549-21.2-1.69-40.17.212-47.596C24.224 80.997 184.59-41.736 268.407 15.282c37.901 25.783 46.159 78.804 46.894 83.93.631 4.434 8.177 63.432-28.734 103.654-10.718 11.677-20.729 16.92-38.673 26.313-11.278 5.902-22.876 10.818-99.02 27.95-34.345 7.73-59.375 12.317-86.542 4.283-4.216-1.248-8.11-2.863-12.043-4.823-6.656-3.307-36.17-17.98-46.166-50.343Z"
      />
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
import type { IconName } from '@/components/base-icon/types';
import type { LocationQueryRaw } from 'vue-router';
import { useElementSize } from '@vueuse/core';

type Link = {
  href?: string;
  routerLink?: LocationQueryRaw;
  text: string;
};

withDefaults(
  defineProps<{
    color?: 'purple' | 'green' | 'blue';
    icon?: IconName;
    description?: string;
    heading?: string;
    imagePath?: string;
    primaryAction?: string | Link;
    secondaryAction?: string | Link;
    variant?: 'light' | 'dark' | 'small';
  }>(),
  {
    color: 'purple',
    variant: 'light'
  }
);

defineEmits(['primaryAction', 'secondaryAction']);

const el = ref(null);
const { width } = useElementSize(el);
</script>

<style lang="scss" module>
.base {
  overflow: hidden;
  position: relative;
  border-radius: $radius;
  z-index: 0; // do not remove

  &.variant-light {
    background-color: #efefff;
  }

  &.variant-dark {
    background-color: #231f53;
    color: white;
  }

  &.variant-small {
    &.color-purple {
      background: linear-gradient(87deg, #fff -20.3%, #7c79ff 230.69%);
    }

    &.color-green {
      background: linear-gradient(272deg, #61bda8 -12.81%, #f0fffc 103.01%);
    }

    &.color-blue {
      background: linear-gradient(271deg, #9bc9ff 3.2%, #e6f1ff 101.87%);
    }
  }
}

.inner {
  position: relative;
  z-index: 1;

  .base:not(.smallSize).hasImage & {
    padding-right: 30%;
  }

  .base.smallSize.hasImage & {
    padding-right: 40%;
  }

  .base.xSmallSize.hasImage & {
    padding-right: 0;
  }
}

.content {
  position: relative;
  z-index: 0;

  .base:not(.variant-small) & {
    padding: $spacing * 1.5;
  }

  .base.variant-small & {
    padding: $spacing;
  }
}

.text {
  & > * {
    &:not(:last-child) {
      margin-bottom: $spacing * 0.5;
    }
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;
  margin-top: $spacing;
}

.visual {
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 96%;
  min-height: 66%;

  .base:not(.smallSize) & {
    max-width: 30%;
  }

  .base.smallSize & {
    max-width: 40%;
  }

  .base.xSmallSize & {
    display: none;
  }
}

.blob {
  position: absolute;
  z-index: 0;
  height: 150%;
  bottom: -20%;
  width: 450px;
  max-width: 70%;
  right: -130px;
  filter: blur(40px);

  path {
    .base.color-purple & {
      fill: #9895ff;
    }

    .base.color-green & {
      fill: #03cfa5;
    }

    .base.color-blue & {
      fill: #2d9cdb;
    }
  }

  .base.variant-small & {
    display: none;
  }
}
</style>
