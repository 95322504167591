<template>
  <div :class="$style.base">
    <BaseAccordion
      ref="baseAccordion"
      v-model="activeKeys"
      :stayOpen="$screen === 's'"
      :titles="[
        $t('marketing.email.sections.set_up_the_content'),
        $t('marketing.email.sections.when_it_should_be_sent'),
        $t('marketing.email.sections.who_is_going_to_receive_it')
      ]"
      :labels="labels"
    >
      <template #1>
        <UploadImages />
        <SelectColor />
        <Message />
        <Discount v-if="showDiscount" v-test="'discountSection'" />
        <MainButton />
        <Footer />
      </template>
      <template #2>
        <WhenToSendEmail
          v-if="
            $route.name === 'waiting-list-template' &&
            unleash.isEnabled('EnableAutomatedWaitingList')
          "
        />
        <ScheduleDelivery
          v-if="showScheduleDelivery"
          v-test="'scheduleDelivery'"
        />
        <SelectRebook v-if="showRebookSettings" mt />
      </template>
      <template #3>
        <BaseText mb>
          {{ receiveDescription }}
        </BaseText>
        <div v-if="showFilters">
          <BaseRadio
            v-model="selected"
            inline
            :label="$t('marketing.email.sections.who_to_send')"
            :options="sendOptions"
            mb
            v-test="'whoToSendSelect'"
          />
          <FilterActions v-show="selected === 'filtered'" />
          <BaseSpinner v-show="loadingCustomersCount" inline />
          <BaseAlert
            v-show="!loadingCustomersCount"
            mt
            :text="amountText"
            :color="!filteredCustomersCount ? 'error' : 'primary'"
            v-test="'filteredCustomersCount'"
          />
        </div>
      </template>
    </BaseAccordion>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { mapState } from 'pinia';

import filters from '@/filters';
import unleash from '@/unleash';

import MainButton from './MainButton.vue';
import Footer from './Footer.vue';
import UploadImages from './UploadImages.vue';
import Message from './Message.vue';
import WhenToSendEmail from './WhenToSendEmail.vue';
import SelectColor from './SelectColor.vue';
import Discount from './Discount.vue';
import FilterActions from './FilterActions.vue';
import ScheduleDelivery from './ScheduleDelivery.vue';
import SelectRebook from './SelectRebook.vue';

import { defineComponent } from 'vue';
import { useUserStore } from '@/stores/user';

export default defineComponent({
  components: {
    MainButton,
    Footer,
    UploadImages,
    Message,
    WhenToSendEmail,
    SelectColor,
    Discount,
    FilterActions,
    ScheduleDelivery,
    SelectRebook
  },
  props: {
    loadingFilterCount: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      filters,
      unleash
    };
  },
  data() {
    return {
      selected: 'all',
      activeKeys: []
    };
  },
  watch: {
    selected(selected) {
      if (selected === 'all') {
        this.$store.commit('marketing/CLEAR_FILTERS');
      }
    },
    activeKeys() {
      if (!this.activeKeys.find((key) => key === '1')) {
        this.$store.commit('marketing/SET_SUBMITTED', false);
      }
    },
    isSubmitted() {
      if (this.isSubmitted && this.isInvalid) {
        this.$store.commit('marketing/SET_SHOW_FILTERS', false);
        this.activeKeys = [...this.activeKeys, '1'];
      }
    },
    allFilters: {
      handler() {
        if (this.allFilters.length) {
          this.selected = 'filtered';
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapGetters('marketing', ['allFilters', 'isSubmitted', 'isInvalid']),
    ...mapGetters('marketing/builder', [
      'sentDescription',
      'receiveDescription',
      'showFilters',
      'filteredCustomersCount',
      'loadingCustomersCount',
      'showRebookSettings',
      'hideDiscount'
    ]),
    showDiscount() {
      return !this.hideDiscount && this.hasFeatureFlag('module-marketing');
    },
    showScheduleDelivery() {
      return this.$route.name.startsWith('newsletter-template');
    },
    amountText() {
      return this.$t(
        'marketing.email.sections.receive_email',
        { amount: this.filteredCustomersCount },
        this.filteredCustomersCount
      );
    },
    sendOptions() {
      return [
        {
          label: this.$t('marketing.email.sections.all_customers'),
          value: 'all'
        },
        {
          label: this.$t('marketing.email.sections.filtered_customers'),
          value: 'filtered'
        }
      ];
    },
    labels() {
      return this.$route.name === 'waiting-list-template'
        ? [
            null,
            this.unleash.isEnabled('EnableAutomatedWaitingList') ? 'new' : null,
            null
          ]
        : [];
    }
  },
  mounted() {
    if (this.allFilters.length) {
      this.activeKeys = [...this.activeKeys, '3'];
    }
  }
});
</script>

<style lang="scss" module>
.base {
  width: 100%;
  position: relative;
}
</style>
