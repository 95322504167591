<template>
  <div>
    <CheckoutModal v-if="showCheckoutOverviewModal" />

    <SelectPosTerminalModal
      v-if="showSelectTerminalModal"
      :amount="amount"
      :terminals="posTerminals"
      :selectedTerminal="selectedPosTerminal"
      @close="$emit('close')"
      @submit="
        (terminalUid) => {
          selectedPosTerminal = terminalUid;
          showPaymentSteps = true;
          mixpanel.track(
            'Register - Send payment to selected terminal clicked'
          );
        }
      "
    />
    <TransactionFlow
      v-if="showPaymentSteps"
      v-bind="{ status, amount, refusalReason }"
      @retry="retryPayment"
      @close="handleTransactionFlowClose"
    />
  </div>
</template>

<script lang="ts" setup>
import SelectPosTerminalModal from './SelectPosTerminal.vue';
import TransactionFlow from './TransactionFlow.vue';
import CheckoutModal from '@/modules/register/builder/payment/overview/CheckoutModal.vue';
import { computed, ref, watch } from 'vue';
import { useQuery, useMutation, useSubscription } from '@vue/apollo-composable';
import { mutateOrder, setTransactions, setItems } from '../../../actions/order';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import {
  LISTEN_TO_POS_TERMINAL_PAYMENT,
  GENERATE_POS_PAYMENT,
  GET_ORDER
} from '../graphql';
import type { PosTerminal, PosPaymentRefusalReason } from '@/types';
import { useStorage } from '@vueuse/core';

const mixpanel = inject<any>('mixpanel');

const props = defineProps<{
  amount: number;
  posTerminals: PosTerminal[];
}>();

const { order } = useRegisterOrderStore();

const locationTerminals = computed(() =>
  props.posTerminals.filter(
    (terminal) => terminal.location?.id === order.locationId
  )
);

const showSelectTerminalModal = ref(locationTerminals.value.length > 1);
const showPaymentSteps = ref(!showSelectTerminalModal.value);
const showCheckoutOverviewModal = ref(false);

const selectedPosTerminal = useStorage(
  'selected_pos_terminal',
  locationTerminals.value.length ? locationTerminals.value[0].terminalUid : null
);
if (
  !locationTerminals.value.find(
    (terminal) => terminal.terminalUid === selectedPosTerminal.value
  )
) {
  // If the selected terminal which has been stored in localstorage does not exist in the list of terminals, reset it
  // This can happen after switching locations
  // If theres only one location terminal, set it as selected
  if (locationTerminals.value.length === 1) {
    selectedPosTerminal.value = locationTerminals.value[0].terminalUid;
  } else {
    selectedPosTerminal.value = null;
  }
}

const { outstandingAmount, selectedCustomer } = storeToRefs(
  useRegisterOrderStore()
);
const status = ref('default');
const paymentInTerminal = ref(false);
const refusalReason = ref<PosPaymentRefusalReason | null>(null);

const emit = defineEmits(['close']);

const { call: callOrderMutation, response: mutationResponse } = mutateOrder();

const { mutate: generatePosPayment } = useMutation(GENERATE_POS_PAYMENT);

const { onResult: handlePayment } = useSubscription(
  LISTEN_TO_POS_TERMINAL_PAYMENT
);

handlePayment(
  ({
    data: {
      posTerminalPayment: {
        status: broadcastedStatus,
        order: broadcastedOrder,
        refusalReason: broadcastedRefusalReason
      }
    }
  }) => {
    if (String(order.id) === String(broadcastedOrder.id)) {
      paymentInTerminal.value = false;
      setExistingOrderData(broadcastedOrder);

      refusalReason.value = broadcastedRefusalReason;

      switch (broadcastedStatus) {
        case 'paid':
          handlePaidTransaction();
          break;
        case 'failed':
        case 'expired':
        case 'cancelled':
          handleFailedTransaction();
          break;
      }
    }
  }
);

const setExistingOrderData = (broadcastedOrder: any) => {
  order.invoicedAt = broadcastedOrder.invoicedAt;
  order.pending = broadcastedOrder.pending;
  order.fiscalizationFinished = broadcastedOrder.fiscalizationFinished;
  selectedCustomer.value = broadcastedOrder.customer;
  // reset items & transactions to prevent duplicates
  order.items = [];
  order.transactions = [];
  setTransactions(broadcastedOrder.transactions);
  setItems(broadcastedOrder.items, { action: 'update' });
};

const handlePaidTransaction = () => {
  if (outstandingAmount.value > 0) {
    status.value = 'success';
    setTimeout(() => {
      emit('close');
      status.value = 'default';
    }, 4000);
  } else {
    completeOrder();
  }
};

const startTransaction = () => {
  if (order.id) {
    callOrderMutation('update');
  } else {
    order.pending = true;
    callOrderMutation('create');
  }
};

watch(
  showPaymentSteps,
  (value) => {
    if (value) {
      startTransaction();
    }
  },
  {
    immediate: true
  }
);

watch(mutationResponse, (value: any) => {
  const orderResponse = value.createOrder
    ? value.createOrder.order
    : value.updateOrder.order;

  if (orderResponse) {
    order.id = orderResponse.id;
    sendPaymentToTerminal();
  } else {
    handleFailedTransaction();
  }
});

const sendPaymentToTerminal = () => {
  paymentInTerminal.value = true;

  generatePosPayment({
    input: {
      orderId: order.id,
      terminalUid: selectedPosTerminal.value,
      amount: props.amount
    }
  }).then(
    ({
      data: {
        generatePosPayment: { error }
      }
    }) => {
      if (error) {
        handleFailedTransaction();
      } else {
        status.value = 'pending';
      }
    }
  );
};

const completeOrder = () => {
  status.value = 'success';

  setTimeout(() => {
    showPaymentSteps.value = false;
  }, 4000);

  setTimeout(() => {
    showCheckoutOverviewModal.value = true;
  }, 4100);
};

const handleFailedTransaction = () => {
  status.value = 'failed';
};

const retryPayment = () => {
  status.value = 'retry';
  mixpanel.track('Register - Pos payment retry clicked');
  sendPaymentToTerminal();
};

let getOrderTimeout: any = null;

watch(
  () => paymentInTerminal.value,
  (value) => {
    if (value) {
      getOrderTimeout = setTimeout(() => {
        getOrder();
      }, 150000);
    } else if (getOrderTimeout) {
      clearTimeout(getOrderTimeout);
    }
  }
);

const getOrder = () => {
  if (order.id) {
    const { onResult } = useQuery(GET_ORDER, { id: order.id });

    onResult(({ data: { order: orderResponse } }) => {
      if (orderResponse) {
        onGetOrderResult(orderResponse);
      }
    });
  }
};

const onGetOrderResult = (orderResponse: any) => {
  paymentInTerminal.value = false;
  const transactionAdded =
    orderResponse.transactions.length > order.transactions.length;

  setExistingOrderData(orderResponse);

  if (transactionAdded) {
    handlePaidTransaction();
  } else {
    handleFailedTransaction();
  }
};

const handleTransactionFlowClose = () => {
  emit('close');
  if (status.value === 'success') {
    showCheckoutOverviewModal.value = true;
  }
};
</script>
