<template>
  <div :class="$style.base" v-test="'register-browser-item'">
    <div :class="$style.section">
      <BaseIcon v-if="item.icon" :name="item.icon" :mr="0.5" />
      {{ item.label }}
    </div>
    <div :class="$style.section">
      <span v-if="item.price !== undefined" v-test="'browser-item-price'">
        {{ filters.currency(item.price) }}
      </span>
      <div
        v-if="showFavorite"
        :class="$style.favorite"
        @click.stop="setFavorite(item)"
      >
        <BaseIcon
          :name="item.favorite ? 'star-filled' : 'star'"
          :color="item.favorite ? 'primary' : 'default'"
          v-test="'browserItemFavorite'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@/components/base-icon/types';
import filters from '@/filters';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const emit = defineEmits(['refetch']);

defineProps<{
  item: {
    icon?: IconName;
    id?: number;
    label: string;
    name?: string;
    price?: number;
    type?: string;
    favorite?: boolean;
  };
  showFavorite?: boolean;
}>();

const { mutate: updateProduct } = useMutation(gql`
  mutation updateProducts($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        favorite
      }
    }
  }
`);

const { mutate: updateService } = useMutation(gql`
  mutation updateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      service {
        favorite
      }
    }
  }
`);

const setFavorite = ({ id, favorite, type }: any) => {
  if (type === 'service') {
    updateService({
      input: {
        id,
        favorite: favorite ? false : true
      }
    }).then(() => {
      emit('refetch');
    });
  }
  if (type === 'product') {
    updateProduct({
      input: {
        id,
        favorite: favorite ? false : true
      }
    }).then(() => {
      emit('refetch');
    });
  }
};
</script>

<style lang="scss" module>
.base {
  padding: $spacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}

.section {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: $spacing * 0.5;
  }

  &:empty {
    display: none;
  }
}

.favorite {
  margin: -4px -4px -4px $spacing * 0.5;
  padding: 4px;
}
</style>
