<template>
  <transition @beforeEnter="isVisible = true" @afterLeave="isVisible = false">
    <div v-show="showMobileMenu" :class="$style.base">
      <div :class="$style.content">
        <LocationSelect v-if="multiLocation" mb />
        <FranchiseSelect v-if="hasFeatureFlag('franchise-scope')" mb />
        <Search v-if="isVisible" mobile mb @customerSelect="onCustomerSelect" />
        <BaseCard mb>
          <div :class="$style.user">
            <BaseAvatar :name="user.first_name" :mr="0.5" />
            <BaseHeading bold>
              {{ user.fullName }}
            </BaseHeading>
          </div>
          <div>
            <a
              v-for="(item, index) in userItems"
              :key="index"
              :href="item.href || '#'"
              :class="$style.link"
              @click="emitUserItemSelect(item, $event)"
            >
              <BaseIcon :name="item.icon" :mr="0.5" /> {{ item.label }}
            </a>
          </div>
        </BaseCard>
        <BaseButton
          :routerLink="{
            name: 'dashboard'
          }"
          @click="onDashboardClick"
          v-test="'mobile-nav-dashboard'"
        >
          {{ $t('nav.dashboard') }}
        </BaseButton>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import FranchiseSelect from './FranchiseSelect.vue';
import Search from './Search.vue';
import LocationSelect from './LocationSelect.vue';
import { usePageLayoutStore } from '@/stores/page-layout';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FranchiseSelect,
    Search,
    LocationSelect
  },
  props: {
    userItems: {
      type: Array,
      required: true
    }
  },
  emits: ['franchiseSelect', 'locationSelect', 'userItemSelect'],
  setup() {
    const pageLayoutStore = usePageLayoutStore();
    return {
      pageLayoutStore
    };
  },
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    ...mapState(useCompanyStore, ['company', 'multiLocation']),
    ...mapState(usePageLayoutStore, ['showMobileMenu'])
  },
  methods: {
    onDashboardClick() {
      if (this.showMobileMenu) {
        this.pageLayoutStore.showMobileMenu = false;
      }
    },
    emitFranchiseSelect(value) {
      this.$emit('franchiseSelect', value);
    },
    emitLocationSelect(value) {
      this.$emit('locationSelect', value);
    },
    emitUserItemSelect(value) {
      this.$emit('userItemSelect', value);
    },
    onCustomerSelect() {
      this.pageLayoutStore.showMobileMenu = false;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: fixed;
  left: $nav-width;
  width: calc(100% - #{$nav-width});
  height: 100%;
  top: 50px;
  background: $grey-light;
  z-index: 2010;

  &:global(.v-leave-active),
  &:global(.v-enter-active) {
    transition: transform 0.3s $easeOutCirc;
  }

  &:global(.v-enter-from) {
    transform: translateX(-100%);
  }

  &:global(.v-leave-to) {
    transform: translateX(calc(-100% - #{$nav-width}));
  }
}

.content {
  padding: $spacing;
}

.header {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 66px;
  width: calc(100% + #{$nav-width * 2});
  margin-left: -$nav-width;
  border-bottom: 1px solid $color-border;
  background: $grey-light;
}

.user {
  display: flex;
  align-items: center;
  padding-bottom: $spacing;
  margin-bottom: $spacing * 0.5;
  border-bottom: 1px solid $color-border;
}

.link {
  display: flex;
  align-items: center;
  padding: 6px $spacing;
  padding-left: 0;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  &:last-child {
    margin-bottom: -6px;
  }
}

.text {
  color: $color-text-inverted;
}
</style>
