<template>
  <div :class="$style.base">
    <SafeTeleport to="#header-teleport">
      <div :class="$style.header">
        {{ $t('nav.register') }}
        <div
          v-if="unleash.isEnabled('RegisterRedesign')"
          :class="$style.toggle"
        >
          <BaseToggle
            v-model="newDesignEnabled"
            :label="$t('nav.new_calendar.tooltip')"
          />
        </div>
      </div>
    </SafeTeleport>

    <PaymentNew v-if="newDesignEnabled" />
    <PaymentOld v-else />
  </div>
</template>

<script setup lang="ts">
import PaymentNew from '@/modules/register/builder/payment/index.vue';
import PaymentOld from '@/modules/register/builder-old/payment/index.vue';
import { useCompanyStore } from '@/stores/company';
import unleash from '@/unleash';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useStorage } from '@vueuse/core';

const { company } = useCompanyStore();
const newDesignEnabled = ref(company.settings.general.registerNewDesign);

watch(newDesignEnabled, (newDesignEnabled) => {
  const { mutate } = useMutation(gql`
    mutation toggleRegisterDesign($input: ToggleRegisterDesignInput!) {
      toggleRegisterDesign(input: $input) {
        registerNewDesign
      }
    }
  `);

  mutate({
    input: {
      newDesignEnabled
    }
  }).then(() => {
    company.settings.general.registerNewDesign = newDesignEnabled;
  });
});

const _newDesignEnabled = useStorage('register_redesign_enabled', false);
if (_newDesignEnabled.value) {
  newDesignEnabled.value = true;
  _newDesignEnabled.value = false;
}

const mixpanel = inject<any>('mixpanel');

watch(newDesignEnabled, (value) => {
  mixpanel.track(`Register - New design ${value ? 'enabled' : 'disabled'}`);
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  gap: $spacing;
}

.toggle {
  font-size: 12px;
}
</style>
