<template>
  <div
    id="app-scrollcontainer"
    ref="scrollContainer"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'app-scrollcontainer'"
  >
    <div ref="contentWrap" :class="$style.contentWrap">
      <div ref="header" :class="$style.header">
        <slot name="header" />
      </div>
      <div ref="page" :class="$style.page">
        <slot name="page" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageScroll } from '@/helpers/scroll';
import { usePageLayoutStore } from '@/stores/page-layout';
import { device } from '@/user-context';

const scrollContainer = ref();
const scrollDisabled = computed(() => {
  const { isModalOpen } = usePageLayoutStore();
  return isModalOpen;
});

const { screenSize } = usePageLayoutStore();

usePageScroll(screenSize === 's' ? undefined : scrollContainer, {
  disabled: scrollDisabled
});
</script>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  mounted() {
    /*
        This code provides a way of removing the "flickering" or "jumping" of content when content changes.
        This is due to the scrollbar appearing or disappearing based on the length of the content, pushing the content to the left.
        To make this work it needs 3 elements.
        - The "outer" element is the one containing the scrollbar
        - The "middle" element is the content if which the width changes based on the scrollbar. This is the element that is observed by the resizeobserver.
        - The "inner" element is the one that gets a padding based on the width of the scrollbar.
        We can't put the padding on the middle element, because that would trigger the resizeobserver again, resulting in an infinite loop.

        This code doesn't need to run on touch devices, as there is no scrollbar there.

        This does not work on old browsers, but that's fine.
        https://caniuse.com/resizeobserver
      */

    if (window.ResizeObserver && !device.touch) {
      const scrollElement = this.$el;
      const contentWrap = this.$refs.contentWrap;
      const page = this.$refs.page;
      const header = this.$refs.header;

      if (scrollElement && contentWrap && page && header) {
        const checkScrollbar = (width) => {
          const paddingRight = width === scrollElement.offsetWidth ? '7px' : 0;
          page.style.paddingRight = paddingRight;
          header.style.paddingRight = paddingRight;
        };

        this.resizeObserver = new ResizeObserver((entries) => {
          const entry = entries[0];
          if (entry.contentBoxSize) {
            if (entry.contentBoxSize[0]) {
              checkScrollbar(entry.contentBoxSize[0].inlineSize);
            } else {
              checkScrollbar(entry.contentBoxSize.inlineSize);
            }
          } else {
            checkScrollbar(entry.contentRect.inlineSize);
          }
        });

        this.resizeObserver.observe(contentWrap);

        checkScrollbar();
      }
    }
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
  position: relative;

  &:not(.smallScreen) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.contentWrap {
  height: 100%;
}

.page {
  .base:not(.smallScreen) & {
    height: calc(100% - #{$header-height});
  }

  .base.smallScreen & {
    height: 100%;
  }
}
</style>
