<template>
  <ModuleBase noBorder gray>
    <PageWrap :heading="$t('admin.nav.links.export')">
      <div
        :class="[
          $style.base,
          {
            [$style.smallScreen]: $screen === 's'
          }
        ]"
      >
        <div v-if="!$apollo.loading">
          <BaseAlert
            v-if="hasAsyncExports"
            :text="
              $t('customers.async_export_disclaimer', {
                email: company.notificationEmail
              })
            "
            mb
          />
          <div :class="$style.content">
            <Section
              :heading="$t('export_page.heading_monthly')"
              :months="months"
            />
            <Section :heading="$t('export_page.heading_lifetime')" />
          </div>
        </div>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import Section from './Section.vue';
import gql from 'graphql-tag';
import PageWrap from '@/modules/admin/PageWrap.vue';
import unleash from '@/unleash';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Section,
    ModuleBase,
    PageWrap
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    hasAsyncExports() {
      return unleash.isEnabled('AsyncExports');
    }
  },
  apollo: {
    months: {
      query: gql`
        query getExports {
          exports {
            months
          }
        }
      `,
      update(data) {
        return data.exports.months;
      }
    }
  }
});
</script>

<style lang="scss" module>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    .base.smallScreen & {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: $spacing;
      }
    }

    .base:not(.smallScreen) & {
      width: calc(50% - #{$spacing * 0.5});
    }
  }
}
</style>
