import gql from 'graphql-tag';

export const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories($pagination: PaginationAttributes) {
    productCategories(pagination: $pagination) {
      id
      name
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts($pagination: PaginationAttributes, $categoryIds: [Int!]) {
    products(pagination: $pagination, categoryIds: $categoryIds) {
      id
      name
      price
      medical
      favorite
      type
    }
  }
`;

export const GET_EMPTY_PRODUCTS = gql`
  query getProducts(
    $pagination: PaginationAttributes
    $showNullCategory: Boolean
  ) {
    products(pagination: $pagination, showNullCategory: $showNullCategory) {
      id
      name
      price
      medical
      favorite
      type
    }
  }
`;

export const REGISTER_SEARCH = gql`
  query registerSearch(
    $pagination: PaginationAttributes
    $type: RegisterItemTypeEnum
    $categoryId: Int
    $search: String
    $before: ISO8601Date
  ) {
    registerSearch(
      pagination: $pagination
      type: $type
      categoryId: $categoryId
      search: $search
      before: $before
    ) {
      id
      type
      name
      price
      productId
      serviceId
      ... on Product {
        favorite
        medical
      }
      ... on Service {
        favorite
        medical
      }
      ... on Appointment {
        customerId
        customerName
        discountPercentage
        startAt
        serviceNames
        locationId
        treatwell
        priceAfterDiscount
        paidThroughTreatwell
        pricePaidThroughTreatwell
        parts {
          id
          serviceId
          resourceId
        }
        discountCode {
          id
          code
          discountPercentage
          state
        }
      }
    }
  }
`;

export const GET_FAVORITES = gql`
  query getRegisterItems {
    registerItems(favorite: true) {
      id
      type
      name
      price
      productId
      serviceId
      favorite
      medical
    }
  }
`;
