<template>
  <div>
    <BaseHeading mb>
      {{ $t('global.feedback') }}
    </BaseHeading>
    <BaseCard>
      <div :class="$style.feedback" v-test="'feedback'">
        <BaseIcon
          :name="feedback.score >= 3 ? 'smiley_happy' : 'smiley_sad'"
          :mr="0.25"
        />
        {{ feedback.comment }}
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    feedback: {
      type: Object,
      required: true
    }
  }
});
</script>

<style lang="scss" module>
.feedback {
  display: flex;
  align-items: center;
}
</style>
