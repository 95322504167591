<template>
  <ModuleBase gray>
    <IntentReminderModal />
    <TwUltimatumModal />
    <div
      :class="[
        $style.base,
        {
          [$style.singleColumn]: singleColumn,
          [$style.isSmall]: $screen === 's'
        }
      ]"
    >
      <div :class="$style.inner">
        <div :class="$style.content">
          <div :class="$style.main">
            <BaseAlert
              v-if="showSalonizedPayAlert"
              :text="$t('dashboard.salonized_pay_alert')"
              color="warning"
              :primaryAction="{
                text: $t('global.actions.continue'),
                routerLink: {
                  name: 'salonized-pay'
                }
              }"
              @primaryAction="
                () => {
                  mixpanel.track('Dashboard - Terminal follow up clicked');
                }
              "
              v-test="'sp-alert'"
            />
            <QuickStart v-if="singleColumn" />
            <ScheduleActivity />
          </div>
          <div :class="$style.side">
            <BaseSpinner v-if="$apollo.loading" />
            <template v-else-if="dashboard">
              <OnlineBookings v-if="isTrial" />
              <BoostBanner rounded />
              <SalonizedPayBanner
                v-if="
                  !(
                    canGetBoost &&
                    unleash.isEnabled('SubscriptionBoost') &&
                    !companySettings.general.boughtTerminalFromSubscriptionFlow
                  )
                "
              />
              <NewFeatureCard
                v-if="
                  unleash.isEnabled('ServiceAvailability') &&
                  !unleash.isEnabled('SalonizedPay')
                "
                featureName="waiting_list_per_day"
              />
              <QuickStart v-if="!singleColumn" />
              <Notes :data="dashboard.notes" />
              <Occupation :data="dashboard.resourcesToday" />
              <CustomersToday />
              <CalendarUtilisation
                :utilizationToday="dashboard.utilizationToday"
                :utilizationSevenDays="dashboard.utilizationSevenDays"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </ModuleBase>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import ScheduleActivity from './schedule-activity/index.vue';
import QuickStart from './QuickStart.vue';
import CustomersToday from './CustomersToday.vue';
import Occupation from './Occupation.vue';
import Notes from './Notes.vue';
import NewFeatureCard from './NewFeatureCard.vue';
import CalendarUtilisation from './CalendarUtilisation.vue';
import gql from 'graphql-tag';
import ModuleBase from '@/modules/ModuleBase.vue';
import OnlineBookings from './OnlineBookings.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState, storeToRefs } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import apolloClient from '@/apollo/client';
import TwUltimatumModal from '@/modules/treatwell/modals/UltimatumModal.vue';
import IntentReminderModal from '@/modules/treatwell/modals/IntentReminderModal.vue';
import { defineComponent } from 'vue';
import { modal } from '@/helpers/ui';
import unleash from '@/unleash';
import SalonizedPayBanner from './SalonizedPayBanner.vue';
import BoostBanner from '@/modules/_shared/boost-banner/index.vue';
import { useAdyenStore } from '@/stores/adyen';

export default defineComponent({
  components: {
    ScheduleActivity,
    QuickStart,
    CustomersToday,
    Occupation,
    Notes,
    CalendarUtilisation,
    ModuleBase,
    OnlineBookings,
    IntentReminderModal,
    NewFeatureCard,
    TwUltimatumModal,
    SalonizedPayBanner,
    BoostBanner
  },
  setup() {
    const { canGetBoost } = storeToRefs(useCompanyStore());
    const { companySettings } = useCompanyStore();
    const { adyenAppState } = useAdyenStore();

    const showSalonizedPayAlert = computed(
      () =>
        companySettings.general.boughtTerminalFromSubscriptionFlow &&
        adyenAppState.verificationStatus !== 'VALID'
    );

    const mixpanel = inject<any>('mixpanel');

    return {
      canGetBoost,
      apolloClient,
      unleash,
      showSalonizedPayAlert,
      mixpanel,
      companySettings
    };
  },
  data() {
    return {
      singleColumn: false
    };
  },
  apollo: {
    dashboard: {
      query: gql`
        query getDashboard($locationId: ID) {
          dashboard(locationId: $locationId) {
            notes {
              completedAt
              id
              resourceId
              text
            }
            resourcesToday {
              appointments
              available
              occupied
              resourceId
              roster
              sortOrder
              timePresent
            }
            utilizationSevenDays {
              available
              occupied
              timePresent
            }
            utilizationToday {
              available
              occupied
              timePresent
            }
          }
        }
      `,
      pollInterval: 5 * 60 * 1000,
      variables() {
        return {
          locationId: this.locationId
        };
      }
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['company', 'isTrial']),
    ...mapState(useLocationsStore, ['locationId']),
    isBelgianCompany() {
      return this.company?.country?.toLowerCase() === 'be';
    }
  },
  methods: {
    checkWidth() {
      this.singleColumn = document.documentElement.clientWidth <= 768;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to?.name === 'create-appointment') {
      const store = useCalendarFiltersStore();
      store.date = dayjs.tz().format('YYYY-MM-DD');
    }

    next();
  },
  created() {
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);

    if (this.$route.query.payment === 'success') {
      modal('welcome');

      this.$router.replace({
        name: 'dashboard'
      });
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  }
});
</script>

<style lang="scss" module>
.base {
  max-width: 1000px;
  margin: 0 auto;

  .base.isSmall & {
    padding-top: $spacing;
  }
}

.content {
  .base:not(.singleColumn) & {
    display: flex;
    justify-content: space-between;
  }
}

.main {
  .base.singleColumn & {
    margin-bottom: $spacing;
  }

  .base:not(.singleColumn) & {
    width: calc(61% - #{$spacing * 0.5});
  }
}

.side {
  position: relative;

  .base:not(.singleColumn) & {
    width: calc(39% - #{$spacing * 0.5});
  }
}

.main,
.side {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
