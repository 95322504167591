<template>
  <ModuleBase noBorder gray>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseVisualBanner
        :heading="
          $t('referrals.heading', { amount: filters.currency(rewardAmount) })
        "
        imagePath="/img/admin/referral.svg"
        mb
        v-test="'referrals-heading'"
      />
      <BaseCard mb :heading="`1. ${$t('referrals.step_1')}`">
        <BaseCodeField
          :code="referralUrl"
          mb
          @copy="
            () => {
              if ($gtm) {
                $gtm.trackEvent({ event: 'referral-copy-url' });
              }
            }
          "
          v-test="'referral-url'"
        />
        <div :class="$style.buttons">
          <BaseButton
            :href="mailHref"
            color="inverted"
            icon="email"
            @click="
              () => {
                if ($gtm) {
                  $gtm.trackEvent({ event: 'referral-email' });
                }
              }
            "
          >
            {{ $t('referrals.share_email') }}
          </BaseButton>
          <BaseButton
            :href="whatsappUrl"
            color="inverted"
            icon="whatsapp"
            @click="
              () => {
                if ($gtm) {
                  $gtm.trackEvent({ event: 'referral-whatsapp' });
                }
              }
            "
          >
            {{ $t('referrals.share_whatsapp') }}
          </BaseButton>
          <BaseButton
            :href="facebookUrl"
            color="inverted"
            icon="facebook"
            @click="
              () => {
                if ($gtm) {
                  $gtm.trackEvent({ event: 'referral-facebook' });
                }
              }
            "
          >
            {{ $t('referrals.share_facebook') }}
          </BaseButton>
        </div>
      </BaseCard>
      <BaseCard mb>
        <BaseHeading> 2. {{ $t('referrals.step_2') }} </BaseHeading>
      </BaseCard>
      <BaseCard
        :heading="`3. ${$t('referrals.step_3', { amount: filters.currency(rewardAmount) })}`"
      >
        <BaseSpinner v-if="loadingReferrals" />
        <BaseText v-else>
          <span v-if="referrals.length">🎉</span>
          {{
            $t(
              'referrals.signups_heading',
              { amount: referrals.length },
              referrals.length
            )
          }}
        </BaseText>
        <BaseTable
          v-if="referrals.length"
          :headers="[
            $t('referrals.company_name'),
            $t('referrals.signed_up_on'),
            $t('referrals.rewarded')
          ]"
          :rows="
            referrals.map((referral) => ({
              cells: [
                referral.companyName,
                filters.date(referral.signupAt),
                referral.rewarded
              ]
            }))
          "
          mt
          v-test="'referrals-list'"
        />
      </BaseCard>
    </div>
  </ModuleBase>
</template>

<script lang="ts">
export default {
  name: 'Referrals'
};
</script>

<script setup lang="ts">
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useCompanyStore } from '@/stores/company';
import type { Referral } from '@/types';
import { useI18n } from 'vue-i18n';
import filters from '@/filters';

const { t } = useI18n();

const { result: referralsResult, loading: loadingReferrals } = useQuery(gql`
  query getReferrals {
    referrals {
      companyName
      rewarded
      signupAt
    }
  }
`);

const referrals = computed<Referral[]>(
  () => referralsResult.value?.referrals || []
);
const rewardAmount = 5000;

const { company } = useCompanyStore();
const referralUrl = `https://www.salonized.com/en/r/${company.publicId}`;
const mailHref = computed(() => {
  const subject = t('referrals.email_subject');
  const body = t('referrals.email_message', {
    link: referralUrl,
    amount: filters.currency(rewardAmount)
  });

  return `mailto:?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
});

const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${referralUrl}`;
const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURI(`${t('referrals.message_whatsapp')} ${referralUrl}`)}`;
</script>

<style lang="scss" module>
.base {
  max-width: 800px;
  margin: 0 auto;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing * -0.5;

  & > * {
    margin-bottom: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}
</style>
