<template>
  <div :class="$style.base">
    <div :class="$style.inner">
      <svg
        width="302"
        :class="$style.svg"
        :height="$screen === 's' ? 120 : 183"
        viewBox="0 0 302 183"
        :preserveAspectRatio="$screen === 's' ? 'xMidYMid meet' : null"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="150.827"
          cy="93.335"
          rx="143.114"
          ry="72.523"
          transform="rotate(-6.938 150.827 93.335)"
          fill="#F9FAFC"
        />
        <path
          d="M98.901 133.364s-3.246 13.617 6.112 32.557l-15.056 1.762s2.955-32.852 6.164-35.944c3.208-3.092 2.78 1.625 2.78 1.625z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M73.36 90.29c-9.892 1.596-16.069 6.886-17.495 9.647-3.792 6.347 2.706 28.154 2.13 35.457-1.082 13.756 5.839 2.845 4.756 16.602-.073.935-7.414 7.736.238 6.948 2.123-.219 5.575 6.79 12.937 7.387 13.762 1.116 16.486 1.597 15.783-3.93-1.752-13.785 9.473-24.435 7.666-31.558-2.99-11.786-9.397-39.654-22.19-42.084"
          fill="#A4A5DF"
        />
        <path
          d="M61.325 97.65c-5.214-2.15-9.81 9.96-10.477 14.497-2.56 17.433-.412 30.327-.412 47.932 0 26.954 55.689 7.019 73.314 11.052 4.701 1.03 10.577 1.072 10.618.195.088-1.904-5.151-6.816-9.894-7.434-11.024-.212-15.626-.994-26.989-1.362-8.824-.286-29.2-2.741-30.017-3.087-1.76-.744-2.124-2.953-2.245-4.801-.884-13.478 5.848-30.705 6.457-45.063.197-4.629-2.25-9.146-6.58-11.159-1.201-.558-2.485-.886-3.775-.77z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M141.005 101.001c-3.122.805-4.848 3.513-3.857 6.047l16.065 41.067c.991 2.535 3.84 3.123 7.448 3.132l22.571.255c3.121-.805 4.848-3.513 3.857-6.047l-16.065-41.067c-1.046-2.49-3.891-3.146-6.713-3.146-.245 0-.491.005-.735.014l-22.571-.255z"
          fill="#4E4C58"
        />
        <path
          d="M141.005 101.001c-3.122.805-4.848 3.513-3.857 6.047l16.065 41.067c.991 2.535 3.84 3.123 7.448 3.132l22.571.255c3.121-.805 4.848-3.513 3.857-6.047l-16.065-41.067c-1.046-2.49-3.891-3.146-6.713-3.146-.245 0-.491.005-.735.014l-22.571-.255z"
          stroke="red"
        />
        <path
          d="M156.339 144.716l-14.168-36.95a2 2 0 011.902-2.716l22.525.383c.805.014 1.524.51 1.823 1.258l14.596 36.568a2 2 0 01-1.858 2.741h-22.953a2 2 0 01-1.867-1.284z"
          fill="#fff"
        />
        <path
          class="person-left-hair"
          d="M72.812 82.189a20.09 20.09 0 013.915-7.76c1.808-2.216 4.082-4.052 5.843-6.302.173-.22.347-.46.376-.734.045-.43-.269-.81-.567-1.133-2.14-2.322-4.595-4.841-4.587-7.947.005-1.85.984-3.673 2.548-4.745 1.563-1.072 3.672-1.366 5.486-.764 1.382.459 2.54 1.377 3.812 2.068-.926-1.836-.25-4.063.825-5.822 1.075-1.76 2.538-3.31 3.342-5.199.803-1.89.75-4.355-.885-5.653-1.042-.827-2.48-.998-3.83-.993-7.335.026-19.027 2.534-24.633 7.093-9.084 7.386-3.657 23.487 1.807 32.085.609.959 4.322 5.506 5.313 6.098.99.593-.368.884.597.252"
          fill="#585791"
        />
        <path
          class="person-left-hair"
          d="M104.731 87.993c-3.191 2.97-3.241-7.686-2.295-10.712.989-3.163 4.669-4.502 4.366-7.793-.264-2.868-1.02-5.67-1.772-8.456-1.812-6.71-3.667-13.522-7.355-19.474-.803-1.296-1.984-1.609-3.364-2.322-1.22-.632-2.626-1.229-4.012-1.202-6.83.132-13.322 2.839-17.571 7.994-1.102-3.248-13.124 6.5-11.899 3.294.749-1.96-1.238-19.298 25.065-18.525.697.02 2.127 2.405 2.726 2.06.643-.37 1.123-.946 1.627-1.478 2.308-2.434 5.695-4.196 9.076-3.688 1.518.228 2.935.895 4.206 1.727 5.483 3.588 6.716 10.505 9.758 16.18 7.382 13.765 2.213 33.903 1.814 35.546-.397 1.635-2.329-5.35-2.899-3.76-2.503 6.99-7.471 10.61-7.471 10.61z"
          fill="#282A69"
        />
        <path
          class="person-left-head"
          d="M80.255 43.138c-6.025 9.46-7.172 21.023-7.288 32.121-.073 6.962-6.993 18.474 5.998 27.753 4.725 3.375 3.922-13.219 9.8-13.06 1.456.039 9.431 1.561 12.171-2.515 11.168-16.62 4.973-19.807 3.539-36.382-.136-1.58.418-3.981-.296-5.694-1.997-4.794-2.461-4.953-5.394-6.12-4.322-1.718-10.215-1.366-15.21-.504-6.85 1.182-12.694 5.131-8.772 13.462 1.668 3.546-5.85 6.9-3.254 9.516 1.308 1.317 7.172-.941 7.22 1.018.066 2.76-7.412 16.548-6.837 19.253 1.678 7.895 4.586 9.55 12.916 10.26"
          fill="url(#paint2_linear)"
        />
        <path
          class="person-left-head"
          d="M78.016 70.056c-1.157-3.567-2.829-7.152-5.84-9.506-1.054-.823-2.459-1.496-3.728-1.039-.763.275-1.326.918-1.703 1.613-.862 1.591-.893 3.5-.5 5.256.708 3.16 2.77 6.017 5.597 7.757 1.287.793 3.059 1.328 4.27.431.898-.665 1.138-1.877 1.884-2.698"
          fill="#FF6575"
        />
        <path
          class="person-left-head"
          opacity=".8"
          d="M93.46 90.218c-.322.32-2.568 0-4.494 0-3.563-.374-5.354 7.128-5.457 7.061-5.49-3.554-10.991-22.63-10.3-22.794.881-.209 2.677 6.05 12.084 11.904 3.975 2.474 8.166 3.829 8.166 3.829z"
          fill="url(#paint3_linear)"
        />
        <path
          class="person-left-hair"
          d="M64.749 55.684c-1.117 1.21-2.586 4.923-4.089 5.649-.247-2.402 2.169-6.157 3.994-7.809a22.699 22.699 0 015.916-3.863c.894-.402 1.912-.563 2.87-.34.139.033.272.083.384.166.31.228.206.659.28 1.029.237 1.168-.357.667-.61.955 0 0-4.882.022-8.745 4.213z"
          fill="#282A69"
        />
        <path
          class="person-right-finger-shadow"
          d="M181.247 131.608c-5.509 0-13.773-6.632-16.833-9.182l.765-.766 5.356-.765 6.076-.395 4.636 11.108z"
          fill="url(#paint4_linear)"
          fill-opacity=".31"
        />
        <path
          class="person-right-hand-right"
          d="M177.974 113.116c7.862-9.776 26.34 2.832 30.027 5.021 2.639 1.568 2.711-.471 5.834-.386 1.209.033 2.435-.204 3.626-.02 2.869.445 4.742 3.241 5.255 6.024.766 4.154-.869 8.709-4.154 11.575-.85.742-1.85 1.395-2.972 1.53-1.465.176-2.848-.549-4.085-1.293a58.553 58.553 0 01-11.001-8.547c-1.862-1.843-3.646-3.848-6.004-5.05-2.358-1.203-5.012-4.364-7.116-2.679-4.432 3.551-2.348 2.57-7.877 3.135-6.949.709-3.984-5.818-3.374-7.038l1.841-2.272z"
          fill="url(#paint5_linear)"
        />
        <path
          class="person-right-finger-right"
          d="M165.734 120.895c3.06 0 8.417-3.57 10.712-5.356 1.275.51 3.061 2.143 0 4.591-3.061 2.449-7.397 3.571-9.182 3.826-4.285-1.224-2.806-2.55-1.53-3.061z"
          fill="#FF6575"
        />
        <path
          class="person-right-hair-right"
          d="M222.427 45.487c-.163 3.226-.69 6.449-1.772 9.513-2.109 5.98-6.215 11.112-9.794 16.434-3.58 5.322-6.766 11.243-6.656 17.544.115-4.584 2.265-8.926 4.979-12.732 2.713-3.805 6.011-7.21 8.827-10.949a55.464 55.464 0 005.039-8.027c1.693-3.295 3.066-6.952 2.409-10.56-.141-.777-.442-1.62-1.179-1.992-.737-.371-1.91.173-1.729.942"
          fill="#282A69"
        />
        <path
          class="person-right-arm-right"
          d="M250.694 91.024c7.586 15.282 8.665 32.718 9.203 49.65.108 3.372.193 6.799-.699 10.062-.892 3.262-2.908 6.395-6.035 7.914-1.901.923-17.007-9.699-19.12-9.444-2.406.292-4.092-4.075-6.121-5.356-3.087-1.949 8.864 9.27 9.317 5.741 1.067-8.318 2.959-16.514 4.849-24.694l7.603-32.912"
          fill="url(#paint6_linear)"
        />
        <path
          d="M211.596 28.19c3.857 3.458 7.297 5.324 12.559 5.583.477 3.701-5.653 14.123-4.863 17.774.791 3.652 2.519 7.305 5.684 9.453.466.316.987.605 1.557.62.868.02 1.594-.584 2.356-.983 2.731-1.431 6.14-.247 8.769 1.351.879.534 1.752 1.137 2.302 1.986.435.671 2.569.204 2.316.957-2.124 6.322-.165 14.569.961 21.334-.035.672-1.091-2.629-1.448-2.052-.905 1.461-3.093 1.38-4.834 1.098-2.649-.43-8.301.846-10.105-1.07-1.798-1.91-1.25-5.809-1.48-8.378-1.944.716-2.028.596-4.109.624-2.081.027-4.24-.573-5.728-1.975-1.769-1.666-10.313-7.936-9.257-16.587 1.75-14.346-2.924-23.14 2.305-27.855l3.015-1.88z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M218.717 87.049c-.542-.652-1.076-1.468-.842-2.276.158-.546.634-.941 1.104-1.274 6.381-4.511 15.331-3.367 22.583-.365 3.077 1.274 6.084 2.885 8.341 5.292 3.406 3.632 4.746 8.692 5.157 13.6.673 8.018-.854 16.201-4.378 23.471-1.896 3.911-4.35 7.551-6.131 11.512-4.698 10.451-1.612 15.003.389 18.723 1.651 3.069-.736 6.086-3.998 7.313-29.711 11.184-41.755 5.404-51.048 2.797-.641-.18-.932-.914-.436-1.358 4.191-3.748 17.153-3.42 19.301-8.13 7.987-17.515 9.92-31.021 11.587-50.126l-1.629-19.18z"
          fill="url(#paint8_linear)"
        />
        <path
          class="person-right-hair-left"
          d="M217.803 35.047c.905 7.015-.724 14.311-4.545 20.347-5.123 8.095-14.235 14.529-15.125 23.97.881-5.9 5.383-10.665 10.094-14.53 4.711-3.864 9.954-7.35 13.294-12.368 4.234-6.36 4.77-14.851 1.366-21.658-1.449.426-2.567 1.744-2.713 3.196"
          fill="#282A69"
        />
        <path
          d="M221.611 84.5c-1.852-.724-6.578 2.793-7.5 4.5-9.884 18.295-10.858 34.603-15.657 54.704-.449 1.877-.988 3.827-2.332 5.254-3.11 3.303-8.773 2.257-12.871.184-11.223-5.678-16.631-12.011-26.251-20.67-.436-.392-3.298-4.516-4.829-9.107-.622-1.141-3.757-7.511-3.824-2.465a1.05 1.05 0 00.053.333c5.225 15.8-5.929.55-7.706-.163-.765 17.598 8.417 16.833 10.745 19.96 7.257 7.629 12.162 11.928 20.626 18.298 4.494 3.382 12.139 9.499 17.609 11.051 5.47 1.553 11.803 1.188 16.327-2.156 4.705-3.478 6.609-9.352 8.218-14.85a1475.297 1475.297 0 004.92-17.192c3.682-13.171 7.207-26.61 6.738-40.243-.122-3.574.473-6.025-2.266-8.438-2.74-2.412-8.704 2.007-9.5 5.5"
          fill="url(#paint9_linear)"
        />
        <path
          d="M216.111 75c1.581.641 3.441-.847 4.955-1.624 1.515-.776 2.752-1.962 3.964-3.131 1.213-1.17 2.448-2.357 3.959-3.139.145-.075.32-.147.469-.079.216.1.204.395.155.62a21.882 21.882 0 01-2.225 5.923c-.398.721-.867 1.451-1.602 1.858-.485.269-1.047.373-1.597.473-1.331.243-2.679.486-4.031.4-1.351-.087-2.729-.542-3.647-1.502"
          fill="url(#paint10_linear)"
        />
        <path
          d="M217.692 39.28c-1.16 6.596.148 13.57 3.631 19.357 1.543 2.565 3.578 4.962 6.311 6.323 1.369.682 2.874 1.083 4.393 1.329 6.954 1.127 14.407-1.117 19.459-5.857 5.051-4.74 7.575-11.86 6.588-18.587-.496-3.386-1.975-6.827-4.875-8.798 3.627-2.004 5.697-6.118 5.706-10.146.01-4.03-1.836-7.922-4.519-11.012-3.048-3.51-7.607-6.208-12.328-5.708-6.178.654-10.48 6.476-11.989 12.285-2.149-4.75-7.232-7.99-12.576-8.572-5.343-.582-10.811 1.313-14.946 4.626-4.134 3.312-6.997 7.944-8.647 12.875-.983 2.938-1.416 6.533.779 8.78a2.29 2.29 0 01.604-2.123l3.552 6.808 4.517-6.076c4.564 2.661 9.889 5.423 14.966 3.845"
          fill="#282A69"
        />
        <path
          class="plus plus-bottom"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M145.611 52a2.5 2.5 0 00-2.5 2.5V60a1 1 0 01-1 1h-5.5a2.5 2.5 0 100 5h5.5a1 1 0 011 1v5.5a2.5 2.5 0 105 0V67a1 1 0 011-1h5.5a2.5 2.5 0 000-5h-5.5a1 1 0 01-1-1v-5.5a2.5 2.5 0 00-2.5-2.5z"
          fill="#A4A5DF"
        />
        <g opacity=".6">
          <mask
            id="a"
            maskUnits="userSpaceOnUse"
            x="149.641"
            y="20.425"
            width="30"
            height="30"
            fill="#000"
          >
            <path fill="#fff" d="M149.641 20.425h30v30h-30z" />
            <path
              class="plus plus-center"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M164.414 21.425a2.583 2.583 0 00-2.583 2.582v7.608a1 1 0 01-1 1h-7.608a2.582 2.582 0 000 5.165h7.608a1 1 0 011 1v7.608a2.583 2.583 0 005.165 0V38.78a1 1 0 011-1h7.608a2.582 2.582 0 100-5.165h-7.608a1 1 0 01-1-1v-7.608a2.582 2.582 0 00-2.582-2.582z"
            />
          </mask>
          <path
            class="plus plus-center"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M164.414 21.425a2.583 2.583 0 00-2.583 2.582v7.608a1 1 0 01-1 1h-7.608a2.582 2.582 0 000 5.165h7.608a1 1 0 011 1v7.608a2.583 2.583 0 005.165 0V38.78a1 1 0 011-1h7.608a2.582 2.582 0 100-5.165h-7.608a1 1 0 01-1-1v-7.608a2.582 2.582 0 00-2.582-2.582z"
            fill="#FF6575"
            fill-opacity=".49"
          />
          <path
            class="plus plus-center"
            d="M162.831 24.007c0-.874.709-1.582 1.583-1.582v-2a3.583 3.583 0 00-3.583 3.582h2zm0 7.608v-7.608h-2v7.608h2zm-9.608 2h7.608v-2h-7.608v2zm-1.582 1.582c0-.873.708-1.582 1.582-1.582v-2a3.582 3.582 0 00-3.582 3.582h2zm1.582 1.583a1.582 1.582 0 01-1.582-1.583h-2a3.582 3.582 0 003.582 3.583v-2zm7.608 0h-7.608v2h7.608v-2zm2 9.608V38.78h-2v7.608h2zm1.583 1.582a1.583 1.583 0 01-1.583-1.582h-2a3.583 3.583 0 003.583 3.582v-2zm1.582-1.582c0 .874-.708 1.582-1.582 1.582v2a3.582 3.582 0 003.582-3.582h-2zm0-7.608v7.608h2V38.78h-2zm9.608-2h-7.608v2h7.608v-2zm1.583-1.583c0 .874-.709 1.583-1.583 1.583v2a3.583 3.583 0 003.583-3.583h-2zm-1.583-1.582c.874 0 1.583.709 1.583 1.582h2a3.583 3.583 0 00-3.583-3.582v2zm-7.608 0h7.608v-2h-7.608v2zm-2-9.608v7.608h2v-7.608h-2zm-1.582-1.582c.874 0 1.582.708 1.582 1.582h2a3.582 3.582 0 00-3.582-3.582v2zm3.582 9.19h-2a2 2 0 002 2v-2zm0 7.165v-2a2 2 0 00-2 2h2zm-7.165 0h2a2 2 0 00-2-2v2zm0-7.165v2a2 2 0 002-2h-2z"
            fill="#FF6575"
            fill-opacity=".49"
            mask="url(#a)"
          />
        </g>
        <path
          class="plus plus-top"
          opacity=".3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M137.251 0c-.991 0-1.794.803-1.794 1.793v4.978a1 1 0 01-1 1h-4.977a1.794 1.794 0 00-.001 3.587h4.978a1 1 0 011 1v4.978a1.794 1.794 0 003.587 0v-4.978a1 1 0 011-1h4.978a1.793 1.793 0 000-3.587h-4.978a1 1 0 01-1-1V1.793c0-.99-.803-1.793-1.793-1.793z"
          fill="#9B51E0"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="97.08"
            y1="172.161"
            x2="97.08"
            y2="136.964"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D53A79" />
            <stop offset="1" stop-color="#FF6575" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="91.981"
            y1="97.627"
            x2="46.111"
            y2="174"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".252" stop-color="#FF8894" />
            <stop offset=".93" stop-color="#FF6575" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="72.611"
            y1="69.5"
            x2="105.611"
            y2="67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF6575" />
            <stop offset="1" stop-color="#FF828F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="83.305"
            y1="74.48"
            x2="83.305"
            y2="97.279"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B52C64" />
            <stop offset="1" stop-color="#B52C64" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="172.831"
            y1="120.895"
            x2="172.831"
            y2="131.608"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#282A69" />
            <stop offset="1" stop-color="#282A69" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="209.558"
            y1="122.426"
            x2="178.952"
            y2="111.713"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D53A79" />
            <stop offset="1" stop-color="#FF6575" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="248.496"
            y1="91.024"
            x2="248.496"
            y2="160.331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D53A79" />
            <stop offset="1" stop-color="#FF6575" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="206.111"
            y1="21.5"
            x2="224.155"
            y2="85.476"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF6575" />
            <stop offset="1" stop-color="#FF8995" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="229.937"
            y1="80.492"
            x2="229.937"
            y2="178.91"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#494DBE" />
            <stop offset="1" stop-color="#282A69" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="183.284"
            y1="80.565"
            x2="183.284"
            y2="167.261"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".123" stop-color="#FF8794" />
            <stop offset="1" stop-color="#FF6575" />
          </linearGradient>
          <linearGradient
            id="paint10_linear"
            x1="230.111"
            y1="86.5"
            x2="221.111"
            y2="72"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B52C64" />
            <stop offset="1" stop-color="#B52C64" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <div :class="$style.text">
        <BaseText mt>{{ $t('register.no_items') }}</BaseText>
        <BaseText
          v-if="company.language === 'nl' && isTrial"
          center
          iconBefore="play-circle"
          href="https://vimeo.com/756815400"
          target="_blank"
          @click="mixpanel.track('Academy - Register, { step: checkout }')"
        >
          {{ $t('global.watch_video') }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { inject } from 'vue';

const mixpanel = inject<any>('mixpanel');
const { company, isTrial } = useCompanyStore();
</script>

<style lang="scss" module>
.text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing;
}

.inner {
  max-height: 100%;
  text-align: center;
}

.svg {
  max-height: 100%;
  max-width: 100%;
}
</style>

<style lang="scss" scoped>
$hairDuration: 3s;

.person-right-hair-left {
  transform-origin: 210px 40px;
  animation: person-right-hair-left $hairDuration ease-in-out 0s infinite
    alternate both;
}

@keyframes person-right-hair-left {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}

.person-right-hair-right {
  transform-origin: 210px 40px;
  animation: person-right-hair-right $hairDuration ease-in-out 0.5s infinite
    alternate both;
}

@keyframes person-right-hair-right {
  from {
    transform: rotate(-2deg);
  }
  to {
    transform: rotate(2deg);
  }
}

$armDuration: 2s;
$armDelay: -1s;

.person-right-arm-right {
  transform-origin: 310px 40px;
  animation: person-right-arm-right $armDuration ease-in-out $armDelay infinite
    alternate both;
}

@keyframes person-right-arm-right {
  0%,
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(2deg);
  }
}

.person-right-hand-right,
.person-right-finger-right {
  animation: person-right-hand-finger $armDuration ease-in-out $armDelay
    infinite alternate both;
}

@keyframes person-right-hand-finger {
  0%,
  70% {
    transform: translateX(5px);
  }
}

.person-right-finger-shadow {
  animation: person-right-finger-shadow $armDuration ease-in-out $armDelay
    infinite alternate both;
}

@keyframes person-right-finger-shadow {
  0%,
  70% {
    opacity: 0;
    transform: translateY(4px) translateX(4px);
  }
}

$plusDelay: $armDuration + $armDelay + 0.2s;

.plus {
  opacity: 0;
  animation-timing-function: linear;
  animation-duration: $armDuration * 2;
  animation-delay: $plusDelay;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.plus-bottom {
  animation-name: plus-bottom-transform;
  animation-delay: $plusDelay - 0.3s;
}

.plus-center {
  animation-name: plus-center-transform;
  animation-delay: $plusDelay - 0.2s;
}

.plus-top {
  animation-name: plus-top-transform;
  animation-delay: $plusDelay - 0.1s;
}

@keyframes plus-bottom-transform {
  0% {
    transform: translateY(14%);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: translateY(-2%) translateX(5%);
  }
  75%,
  100% {
    opacity: 0;
  }
}

@keyframes plus-center-transform {
  0% {
    transform: translateY(10%);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: translateY(-2%) translateX(2%);
  }
  75%,
  100% {
    opacity: 0;
  }
}

@keyframes plus-top-transform {
  0% {
    transform: translateY(12%);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: translateY(4%) translateX(-3%);
  }
  75%,
  100% {
    opacity: 0;
  }
}

.person-left-head,
.person-left-hair {
  animation: person-left-head $armDuration ease-in-out $armDelay infinite
    alternate both;
  transform-origin: 80px 150px;
}

.person-left-hair {
  animation-delay: $armDelay + 0.08s;
}

@keyframes person-left-head {
  0% {
    transform: rotate(1deg);
  }
  30%,
  100% {
    transform: rotate(0deg);
  }
}
</style>
