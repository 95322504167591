<template>
  <div :class="$style.base">
    <div>
      <BaseInputLabel :text="$t('promote.booking_widget.settings.color')" />
      <BaseColorLabel
        v-model="formData.settings.bookingWidget.customColor"
        colorPicker
        selected
        :mb="1.5"
        v-test="'giftcard-setting-color'"
      />
    </div>
    <BaseRadio
      v-model="formData.settings.bookingWidget.inline"
      :label="$t('giftcard_widget.settings.share')"
      :options="[
        { value: false, label: $t('promote.booking_widget.button_view') },
        { value: true, label: $t('promote.booking_widget.widget_view') }
      ]"
      v-test="'giftcard-setting-shareMethod'"
    />
    <div v-if="formData.settings.bookingWidget.inline">
      <BaseRadio
        v-model="formData.settings.bookingWidget.autoWidth"
        :label="$t('promote.booking_widget.settings.widget.size')"
        mb
        :options="widthSizeOptions"
        v-test="'booking-settings-autoWidth'"
      />
      <BaseGrid container mb>
        <BaseGrid
          :size="formData.settings.bookingWidget.autoWidth ? 12 : 6"
          :sSize="12"
        >
          <BaseInput
            v-model="formData.settings.bookingWidget.height"
            :label="$t('promote.booking_widget.settings.widget.height')"
            type="number"
          />
        </BaseGrid>
        <BaseGrid
          v-if="!formData.settings.bookingWidget.autoWidth"
          :size="6"
          :sSize="12"
        >
          <BaseInput
            v-model="formData.settings.bookingWidget.width"
            :label="$t('promote.booking_widget.settings.widget.width')"
            type="number"
          />
        </BaseGrid>
      </BaseGrid>
    </div>
    <div v-else>
      <BaseRadio
        v-model="formData.settings.bookingWidget.position"
        :label="$t('promote.booking_widget.settings.button.position')"
        mb
        :options="buttonPositionOptions"
        v-test="'giftcard-setting-buttonPosition'"
      />
    </div>
    <Snippet gray />
  </div>
</template>

<script lang="ts">
export default {
  name: 'SharingSettings'
};
</script>

<script setup lang="ts">
import { useBookingSettingsOptions } from '@/modules/admin/online-bookings/input-options';
import Snippet from './Snippet.vue';

const formData = inject<any>('giftcardSettingsData');

const { buttonPositionOptions, widthSizeOptions } = useBookingSettingsOptions();
</script>

<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 1.5;
  }
}
</style>
