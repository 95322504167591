const Register = () => import('@/modules/register/index.vue');
const Builder = () => import('@/modules/register/builder-toggle/index.vue');
const Payment = () => import('@/modules/register/payment-toggle/index.vue');
const Invoices = () => import('@/modules/register/invoices/index.vue');
const DayOverview = () => import('@/modules/register/day-overview/index.vue');
const Drawer = () => import('@/modules/register/drawer/index.vue');
const DrawerClose = () => import('@/modules/register/drawer/CloseDrawer.vue');
const Cashups = () => import('@/modules/register/cashups/index.vue');
const CashupsZoom = () => import('@/modules/register/cashups/cashup/index.vue');
const GiftCards = () => import('@/modules/register/gift-cards/index.vue');
const PrepaidCards = () => import('@/modules/register/prepaid-cards/index.vue');
const SalonizedPay = () => import('@/modules/register/salonized-pay/index.vue');
const Invoice = () => import('@/modals/invoice/index.vue');
const InvoiceEmail = () => import('@/modals/InvoiceEmail.vue');
const DrawerTransaction = () =>
  import('@/modules/register/drawer/NewTransaction.vue');
const DrawerCurrentCash = () =>
  import('@/modules/register/drawer/EditCurrentCash.vue');
const GiftCard = () => import('@/modules/register/gift-cards/GiftCard.vue');
const PrepaidCard = () =>
  import('@/modules/register/prepaid-cards/PrepaidCard.vue');
const CreateGiftcard = () =>
  import('@/modules/register/builder-toggle/CreateGiftcard.vue');
const CreatePrepaidcard = () =>
  import('@/modules/register/builder-toggle/CreatePrepaidcard.vue');
const Services = () => import('@/modules/register/builder-toggle/Services.vue');
const Products = () => import('@/modules/register/builder-toggle/Products.vue');
const Appointments = () =>
  import('@/modules/register/builder-toggle/Appointments.vue');

export default {
  path: 'register',
  name: 'register',
  component: Register,
  redirect: { name: 'register-builder' },
  meta: {
    moduleName: 'register',
    role: 'basic'
  },
  children: [
    {
      path: 'checkout',
      name: 'register-builder',
      component: Builder,
      meta: {
        hideIntercomButton: true,
        mixpanelName: 'Register - checkout'
      },
      children: [
        {
          path: 'services',
          name: 'register-builder-services',
          component: Services
        },
        {
          path: 'products',
          name: 'register-builder-products',
          component: Products
        },
        {
          path: 'appointments',
          name: 'register-builder-appointments',
          component: Appointments
        },
        {
          path: 'create-giftcard',
          name: 'register-builder-giftcard',
          component: CreateGiftcard,
          props: {
            parentRouteName: 'register-builder'
          }
        },
        {
          path: 'create-prepaidcard',
          name: 'register-builder-prepaidcard',
          component: CreatePrepaidcard
        }
      ]
    },
    {
      path: 'payment',
      name: 'register-payment',
      component: Payment,
      meta: {
        hideIntercomButton: true
      }
    },
    {
      path: 'invoices',
      name: 'invoices',
      component: Invoices,
      meta: {
        mixpanelName: 'Register - invoices'
      },
      children: [
        {
          path: ':id',
          name: 'invoice',
          component: Invoice,
          props: {
            parentRouteName: 'invoices'
          }
        },
        {
          path: ':id/email',
          name: 'invoice-email',
          component: InvoiceEmail,
          props: {
            parentRouteName: 'invoices'
          }
        }
      ]
    },
    {
      path: 'day-overview',
      name: 'day-overview',
      component: DayOverview,
      meta: {
        mixpanelName: 'Register - day overview'
      },
      children: [
        {
          path: 'day-overview/:id',
          name: 'day-overview-item',
          component: Invoice,
          props: {
            parentRouteName: 'day-overview'
          }
        }
      ]
    },
    {
      path: 'drawer',
      name: 'drawer',
      component: Drawer,
      meta: {
        hideIntercomButton: true,
        mixpanelName: 'Register - drawer'
      },
      children: [
        {
          path: 'new-transaction',
          name: 'drawer-transaction',
          component: DrawerTransaction
        },
        {
          path: 'change-current-cash',
          name: 'drawer-current-cash',
          component: DrawerCurrentCash
        },
        {
          path: ':id',
          name: 'drawer-item',
          component: Invoice,
          props: {
            parentRouteName: 'drawer'
          }
        }
      ]
    },
    {
      path: 'close-drawer',
      name: 'drawer-close',
      component: DrawerClose,
      meta: {
        hideIntercomButton: {
          mobile: true
        }
      }
    },
    {
      path: 'cashups',
      name: 'cashups',
      component: Cashups,
      meta: {
        mixpanelName: 'Register - cashups'
      }
    },
    {
      path: 'cashups/:id',
      name: 'cashup',
      component: CashupsZoom
    },
    {
      path: 'salonized-pay',
      name: 'salonized-pay',
      component: SalonizedPay,
      meta: {
        unleash: 'SalonizedPay'
      }
    },
    {
      path: 'gift-cards',
      name: 'gift-cards',
      component: GiftCards,
      meta: {
        mixpanelName: 'Register - gift cards'
      },
      children: [
        {
          path: ':giftcardId',
          name: 'gift-card',
          component: GiftCard
        }
      ]
    },
    {
      path: 'prepaid-cards',
      name: 'prepaid-cards',
      meta: {
        mixpanelName: 'Register - prepaid cards'
      },
      component: PrepaidCards,
      children: [
        {
          path: ':id',
          name: 'prepaid-card',
          component: PrepaidCard,
          props: {
            parentRouteName: 'prepaid-cards'
          }
        }
      ]
    }
  ]
};
