<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <router-view />
    <CreateNoteModal
      v-if="showCreateNoteModal"
      :currentNote="order.note"
      @close="showCreateNoteModal = false"
      @submit="order.note = $event"
    />
    <BrowserItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      @click="selectBrowserCategory(item.name)"
      v-test="`register-browser-${item.name}`"
    >
      <BaseIcon :name="item.icon" :mr="0.5" />
      {{ item.label }}
    </BrowserItem>
    <div v-if="favorites.length">
      <BrowserItem
        v-for="(item, index) in favorites"
        :key="index"
        :item="item"
        showFavorite
        @refetch="refetch"
        @click="addBrowserItem(item)"
        v-test="`register-browser-favorite-${index}`"
      />
    </div>
    <div
      v-else
      :class="$style.noFavorites"
      v-test="'register-browser-no-favorites'"
    >
      {{ $t('register.no_favorites') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import BrowserItem from '../BrowserItem.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import filters from '@/filters';
import { useRouter } from 'vue-router';
import CreateNoteModal from '@/modals/CreateNote.vue';
import type { IconName } from '@/components/base-icon/types';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useUserStore } from '@/stores/user';
import { GET_FAVORITES } from '../graphql';
import { usePagination } from '@/apollo/pagination';

const { t } = useI18n();
const showCreateNoteModal = ref(false);
const { order, addItem } = useRegisterOrderStore();
const { hasFeatureFlag } = useUserStore();

type BrowserSections =
  | 'barcode'
  | 'services'
  | 'products'
  | 'giftcard'
  | 'prepaidcard'
  | 'appointments'
  | 'note';

const items = computed<
  {
    name: BrowserSections;
    icon: IconName;
    label: string;
  }[]
>(
  () =>
    [
      {
        name: 'barcode',
        icon: 'barcode',
        label: t('register.scan_barcode')
      },
      {
        name: 'services',
        icon: 'briefcase',
        label: filters.capitalize(t('global.items.service', 2))
      },
      {
        name: 'products',
        icon: 'pricetag',
        label: filters.capitalize(t('global.items.product', 2))
      },
      {
        name: 'giftcard',
        icon: 'gift',
        label: filters.capitalize(t('global.items.giftcard', 1))
      },
      {
        name: 'prepaidcard',
        icon: 'credit-card',
        label: filters.capitalize(t('global.items.prepaid_card', 1))
      },
      {
        name: 'appointments',
        icon: 'calendar',
        label: filters.capitalize(t('global.items.appointment', 2))
      },
      {
        name: 'note',
        icon: 'message-circle',
        label: filters.capitalize(
          t(
            order.note ? 'global.actions.edit_item' : 'global.actions.add_note',
            {
              item: t('global.items.note', 1)
            }
          )
        )
      }
    ].filter(
      (item) =>
        hasFeatureFlag('module-appointments') || item.name !== 'appointments'
    ) as {
      name: BrowserSections;
      icon: IconName;
      label: string;
    }[]
);

const { registerItems, refetch } = usePagination({
  fieldName: 'registerItems',
  query: GET_FAVORITES
});

const favorites = computed(() =>
  registerItems.value.map((item: any) => ({
    label: item.name,
    id: item.productId ? item.productId : item.serviceId,
    type: item.type,
    price: item.price,
    favorite: item.favorite,
    medical: item.medical,
    ...(item.productId ? { productId: item.productId } : null),
    ...(item.serviceId ? { serviceId: item.serviceId } : null)
  }))
);

const emit = defineEmits<{
  (e: 'select', value: string): void;
}>();

const router = useRouter();

const selectBrowserCategory = (itemName: string) => {
  switch (itemName) {
    case 'note':
      showCreateNoteModal.value = true;
      break;
    case 'giftcard':
      router.push({ name: 'register-builder-giftcard' });
      break;
    case 'prepaidcard':
      router.push({ name: 'register-builder-prepaidcard' });
      break;
    default:
      emit('select', itemName);
  }
};

const addBrowserItem = (item: any) => {
  addItem({
    name: item.label || item.name,
    price: item.price,
    serviceId: item.serviceId,
    productId: item.productId,
    medical: item.medical
  });
};
</script>

<style module lang="scss">
.base {
  &.smallScreen {
    padding-bottom: 100px;
  }
}
.noFavorites {
  padding: $spacing;
}
</style>
