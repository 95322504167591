import gql from 'graphql-tag';
import apolloClient from '@/apollo/client';
import { modal, flash } from '@/helpers/ui';
import i18n from '@/i18n';
import dayjs from '@/dayjs';
import { defineStore } from 'pinia';
import type {
  FormTemplate,
  CreateFormTemplateInput,
  UpdateFormTemplateInput
} from '@/types';

const formTemplateFragment = gql`
  fragment formTemplate on FormTemplate {
    archivedAt
    corona
    formType
    id
    name
    submissionsCount
  }
`;

interface State {
  formTemplates: FormTemplate[];
  dataFetched: boolean;
}

export const useFormTemplatesStore = defineStore('formTemplates', {
  state: (): State => ({
    formTemplates: [],
    dataFetched: false
  }),
  getters: {
    all(): FormTemplate[] {
      return [...this.formTemplates].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
    },
    active(): FormTemplate[] {
      return this.all.filter((item) => !item.archivedAt);
    },
    archived(): FormTemplate[] {
      return this.all.filter((item) => item.archivedAt);
    },
    contract(): FormTemplate[] {
      return this.all.filter((item) => item.formType === 'contract');
    },
    consultation(): FormTemplate[] {
      return this.all.filter((item) => item.formType === 'consultation');
    }
  },
  actions: {
    fetchData() {
      if (this.dataFetched) {
        return;
      }

      apolloClient
        .query({
          query: gql`
            query getFormTemplates($withArchived: Boolean) {
              formTemplates(withArchived: $withArchived) {
                ...formTemplate
              }
            }
            ${formTemplateFragment}
          `,
          variables: {
            withArchived: true
          }
        })
        .then(({ data: { formTemplates } }) => {
          this.formTemplates = formTemplates.filter(
            (template: any) => !template.corona
          );
          this.dataFetched = true;
        });
    },

    archive(id: FormTemplate['id']) {
      const item = this.all.find((item) => item.id === id);

      if (!item) {
        return;
      }

      modal('confirmation').then(() => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation updateFormTemplate($input: UpdateFormTemplateInput!) {
                updateFormTemplate(input: $input) {
                  formTemplate {
                    id
                    archivedAt
                  }
                }
              }
            `,
            variables: {
              input: {
                id,
                archivedAt: item.archivedAt ? null : dayjs().format()
              }
            }
          })
          .then(
            ({
              data: {
                updateFormTemplate: { formTemplate }
              }
            }) => {
              item.archivedAt = formTemplate.archivedAt;
              flash(i18n.t('global.flash.form_template_updated'));
            }
          );
      });
    },

    create(input: CreateFormTemplateInput) {
      return apolloClient
        .mutate({
          mutation: gql`
            mutation createFormTemplate($input: CreateFormTemplateInput!) {
              createFormTemplate(input: $input) {
                formTemplate {
                  ...formTemplate
                }
              }
            }
            ${formTemplateFragment}
          `,
          variables: {
            input
          }
        })
        .then(
          ({
            data: {
              createFormTemplate: { formTemplate }
            }
          }) => {
            this.formTemplates.push(formTemplate);
            flash(i18n.t('global.flash.form_template_created'));
          }
        );
    },

    update(input: UpdateFormTemplateInput) {
      const item = this.all.find((item) => item.id === parseInt(input.id));

      if (!item) {
        return;
      }

      return apolloClient
        .mutate({
          mutation: gql`
            mutation updateFormTemplate($input: UpdateFormTemplateInput!) {
              updateFormTemplate(input: $input) {
                formTemplate {
                  ...formTemplate
                }
              }
            }
            ${formTemplateFragment}
          `,
          variables: {
            input
          }
        })
        .then(
          ({
            data: {
              updateFormTemplate: { formTemplate }
            }
          }) => {
            this.formTemplates = this.formTemplates.map((item) =>
              item.id === formTemplate.id ? formTemplate : item
            );
            flash(i18n.t('global.flash.form_template_updated'));
          }
        );
    },

    delete(id: FormTemplate['id']) {
      const item = this.all.find((item) => item.id === id);

      if (!item) {
        return;
      }

      modal('confirmation', {
        message: i18n.t('global.confirmation.confirm_delete', {
          item: item.name
        })
      }).then(() => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation deleteFormTemplate($input: DeleteFormTemplateInput!) {
                deleteFormTemplate(input: $input) {
                  formTemplate {
                    id
                  }
                }
              }
            `,
            variables: {
              input: {
                id
              }
            }
          })
          .then(() => {
            this.formTemplates = this.formTemplates.filter(
              (item) => item.id !== id
            );
            flash(i18n.t('global.flash.form_template_deleted'));
          });
      });
    }
  }
});
