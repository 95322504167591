<template>
  <Link
    :defaultElement="inline ? 'span' : 'div'"
    :routerLink="routerLink"
    :href="href"
    :class="[
      $style.base,
      $style[color],
      $style[size],
      {
        [$style.bold]: bold,
        [$style.center]: center,
        [$style.hasIcon]: !!iconBefore || !!iconAfter || !!href,
        [$style.hasIconBefore]: !!iconBefore || !!href,
        [$style.hasIconAfter]: !!iconAfter,
        [$style.italic]: italic,
        [$style.lineThrough]: lineThrough,
        [$style.underline]: underline,
        [$style.link]: isLink,
        [$style.oneLine]: oneLine,
        [$style.colored]: isColored
      }
    ]"
    :download="download"
    @click="$emit('click')"
  >
    <div v-if="beforeIcon" :class="$style.iconBefore">
      <BaseIcon
        :name="beforeIcon"
        :color="iconColor"
        :size="size === 's' ? 'xs' : 's'"
        :mr="0.25"
      />
    </div>
    <slot />
    <div v-if="iconAfter" :class="$style.iconAfter">
      <BaseIcon
        :name="iconAfter"
        :color="iconColor"
        :size="size === 's' ? 'xs' : 's'"
        :ml="0.25"
      />
    </div>
  </Link>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
import type { LocationQueryRaw } from 'vue-router';
import { computed } from 'vue';
import Link from '@/components/_shared/Link.vue';
import type { IconName } from '@/components/base-icon/types';

const props = withDefaults(
  defineProps<{
    bold?: boolean;
    center?: boolean;
    color?:
      | 'default'
      | 'inverted'
      | 'success'
      | 'warning'
      | 'error'
      | 'secondary';
    download?: boolean;
    href?: string;
    iconAfter?: IconName;
    iconBefore?: IconName;
    inline?: boolean;
    italic?: boolean;
    lineThrough?: boolean;
    underline?: boolean;
    link?: boolean;
    oneLine?: boolean;
    routerLink?: LocationQueryRaw;
    size?: 's' | 'm' | 'l';
  }>(),
  {
    color: 'default',
    size: 'm'
  }
);

defineEmits(['click']);

const beforeIcon = computed(() =>
  props.href ? 'external_link' : props.iconBefore
);
const isLink = computed(() => props.link || !!props.routerLink || !!props.href);
const isColored = computed(
  () =>
    props.color === 'success' ||
    props.color === 'warning' ||
    props.color === 'error' ||
    props.color === 'inverted'
);
const iconColor = computed(() =>
  isColored.value ? props.color : isLink.value ? 'primary' : 'default'
);
</script>

<style lang="scss" module>
.base {
  font-weight: normal;
  line-height: 1.4;

  a {
    color: $color-primary;
  }

  &.s {
    font-size: 12px;
  }

  &.l {
    font-size: 18px;
  }

  &.bold {
    font-weight: bold;
  }

  &.italic {
    font-style: italic;
  }

  &.lineThrough {
    text-decoration: line-through;
  }

  &.underline {
    text-decoration: underline;
  }

  &.center {
    text-align: center;
  }

  &.error {
    color: $color-error;
  }

  &.warning {
    color: $color-warning;
  }

  &.success {
    color: $color-success;
  }

  &.inverted {
    color: $color-text-inverted;
  }

  &.secondary {
    color: $color-text-secondary;
  }

  &.oneLine {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    br {
      content: '';
      &:after {
        content: ' ';
      }
    }
  }

  &.link {
    display: inline-block;
    cursor: pointer;
    transition: color 0.2s ease;

    &:not(.colored) {
      color: $color-primary;

      &:focus {
        color: $color-primary-dark;
      }

      @include hover {
        color: $color-primary-dark;
      }
    }

    &.error {
      color: $color-error-dark;

      @include hover {
        color: $color-error-text;
      }
    }

    &.warning {
      color: $color-warning-dark;

      @include hover {
        color: $color-warning-text;
      }
    }

    &.success {
      color: $color-success-dark;

      @include hover {
        color: $color-success-text;
      }
    }
  }

  &.hasIcon {
    position: relative;
  }

  &.hasIconBefore {
    &.s {
      padding-left: 16px;
    }

    &:not(.s) {
      padding-left: 19px;
    }
  }

  &.hasIconAfter {
    &.s {
      padding-right: 16px;
    }

    &:not(.s) {
      padding-right: 19px;
    }
  }
}

.iconBefore,
.iconAfter {
  position: absolute;

  .base.s & {
    top: 1px;
  }

  .base:not(.s) & {
    top: 2px;
  }
}

.iconBefore {
  left: 0;
}

.iconAfter {
  right: 0;
}
</style>
