import gql from 'graphql-tag';

export const GET_SERVICE_VARIATION_GROUPS = gql`
  query getServiceVariationGroups {
    serviceVariationGroups {
      id
      name
      sortOrder
      medical
      category {
        id
        name
      }
      variations {
        id
        name
        duration
        price
        sortOrder
      }
    }
  }
`;
