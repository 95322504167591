<template>
  <BaseModal
    small
    :heading="$t('service.resource_prices.title')"
    @close="$emit('close')"
  >
    <BaseAlert
      v-if="showWarning"
      color="warning"
      :text="$t('service.resource_prices.warning_resource_selection')"
      mb
    />
    <BaseTable
      v-if="listItems.length"
      :headers="[$t('global.items.employee', 1), $t('global.price')]"
      :rows="
        listItems.map((item: any, index) => ({
          id: item.uid,
          cells: [
            resourceById(item.resourceId)?.name || '',
            filters.currency(item.price)
          ],
          actions: ['edit', 'delete']
        }))
      "
      mb
      @action="onTableAction"
      v-test="'resource-prices-table'"
    />
    <div v-else>
      <BaseText center>
        {{ $t('service.resource_prices.empty_state') }}
      </BaseText>
    </div>
    <div :class="$style.linkWrap">
      <BaseText
        link
        @click="showAddPriceModal = true"
        v-test="'resource-prices-add'"
      >
        + {{ $t('service.resource_prices.add_price') }}
      </BaseText>
    </div>
    <template #footer>
      <BaseButton @click="$emit('close')" v-test="'resource-prices-submit'">
        {{ $t('global.ok') }}
      </BaseButton>
    </template>
    <BaseModalSmall v-if="showAddPriceModal" @close="showAddPriceModal = false">
      <BaseHeading mb>
        {{ $t('service.resource_prices.add_price') }}
      </BaseHeading>
      <BaseForm @submit="submitPrice">
        <div :class="$style.fieldsWrap">
          <div :class="$style.employee">
            <BaseDropdown
              v-model="resourceId"
              :options="
                availableEmployees.map((employee) => ({
                  value: employee.id,
                  label: employee.name
                }))
              "
              :label="$t('global.items.employee', 1)"
              required
              v-test="'resource-price-employee'"
            />
          </div>
          <div :class="$style.price">
            <BaseInput
              v-model="price"
              type="currency"
              :label="$t('global.price')"
              :minValue="0"
              v-test="'resource-price-amount'"
            />
          </div>
        </div>

        <BaseButton submitForm v-test="'resource-price-submit'">
          {{ $t('global.actions.submit') }}
        </BaseButton>
      </BaseForm>
    </BaseModalSmall>
  </BaseModal>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useResourcesStore } from '@/stores/resources';
import { useCompanyStore } from '@/stores/company';
import { ResourceSelection } from '@/types';
import type { ResourceAdjustmentAttributes } from '@/types';
import { ref, computed, watch } from 'vue';

const emit = defineEmits(['close', 'update:modelValue']);
const props = defineProps<{
  modelValue: ResourceAdjustmentAttributes[];
  servicePrice: number;
}>();

const showWarning = computed(() => {
  const { company } = useCompanyStore();
  return (
    (listItems.value.length &&
      company.settings.bookings.resourceSelection ===
        ResourceSelection.Automatic) ||
    company.settings.bookings.resourceSelection === ResourceSelection.Manual
  );
});

const { employees, resourceById } = useResourcesStore();

const availableEmployees = computed(() =>
  // Hide employees from that list that already have a price set, when creating a new price
  employees.filter(
    (employee) =>
      employee.id === resourceId.value ||
      !props.modelValue.find(
        (adjustment) => adjustment.resourceId === employee.id
      )
  )
);

const onTableAction = ({ id, option }: { id: number; option: string }) => {
  // The uid property is used for the table row id
  if (option === 'delete') {
    listItems.value = listItems.value.filter((item) => item.uid !== id);
  } else if (option === 'edit') {
    const existingItem = listItems.value.find((item) => item.uid === id);
    if (existingItem) {
      price.value = existingItem.price;
      resourceId.value = existingItem.resourceId;
      existingId.value = existingItem.uid;

      showAddPriceModal.value = true;
    }
  }
};

let uid = 0;

const listItems = ref(
  props.modelValue.map((item) => {
    uid++;
    return {
      ...item,
      uid
    };
  })
);

watch(
  listItems,
  (newValue) => {
    emit(
      'update:modelValue',
      newValue.map((item) => {
        const newItem: ResourceAdjustmentAttributes = {
          price: item.price,
          resourceId: item.resourceId
        };

        if (item.id) {
          newItem.id = item.id;
        }

        return newItem;
      })
    );
  },
  {
    deep: true
  }
);

const showAddPriceModal = ref(false);
const price = ref(props.servicePrice);
const resourceId = ref<number | null>(null);
const existingId = ref<number | null>(null);

const submitPrice = () => {
  if (!resourceId.value) {
    return;
  }

  if (existingId.value) {
    const existingItem = listItems.value.find(
      (item) => item.uid === existingId.value
    );
    if (existingItem) {
      existingItem.price = price.value;
      existingItem.resourceId = resourceId.value;
    }

    existingId.value = null;
  } else {
    uid++;

    listItems.value.push({
      uid,
      price: price.value,
      resourceId: resourceId.value
    });
  }

  showAddPriceModal.value = false;
  price.value = props.servicePrice;
  resourceId.value = null;
};
</script>

<style lang="scss" module>
.linkWrap {
  display: flex;
  margin-top: $spacing * 0.5;
}

.fieldsWrap {
  display: flex;
  margin-bottom: $spacing;

  & > * {
    &:not(:last-child) {
      margin-right: $spacing;
    }
  }
}

.employee {
  width: 170px;
}

.price {
  width: 100px;
}
</style>
