<template>
  <div
    :class="[
      $style.base,
      {
        [$style.mediumScreen]: $screen === 's' || $screen === 'm'
      }
    ]"
    v-test="'service-variation-about'"
  >
    <BaseInput
      v-model="formData.name"
      :label="$t('service.name')"
      :maxLength="255"
      required
      mb
      v-test="'form-service-name'"
    />
    <div :class="$style.row">
      <div :class="$style.rowGroup">
        <BaseInput
          v-model="formData.price"
          :label="$t('global.price')"
          type="currency"
          :mr="0.5"
          :mb="$screen !== 'l'"
          v-test="'service-price'"
        />
        <BaseDropdown
          v-model="formData.vatRateId"
          :options="[
            {
              value: null,
              label: $t('products.default_vat')
            },
            ...vatRates.map((rate) => ({
              value: rate.id,
              label: rate.label
            }))
          ]"
          :label="$t('global.items.vat_rate', 1)"
        />
      </div>
      <div :class="$style.rowGroup">
        <BaseInput
          v-model="formData.duration"
          :label="$t('global.duration')"
          unitLabel="minute"
          type="number"
          :disabled="formData.requiresProcessingTime"
          :minValue="0"
          :maxValue="maxDuration"
          :mr="0.5"
          :mb="$screen !== 'l'"
          v-test="'service-duration'"
        />
        <div :class="$style.moreOptions">
          <BaseButton
            color="inverted"
            :disabled="moreOptions.length <= 0"
            :options="moreOptions"
            @select="handleMoreOptionsSelect"
            v-test="'service-duration-more-options'"
          >
            {{ $t('global.more_options') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { ServiceFormData } from '../types';
import { storeToRefs } from 'pinia';
import { useVatRatesStore } from '@/stores/vat-rates';

const { t } = useI18n();

const formData = inject<ServiceFormData>('serviceFormData')!;
const maxDuration = 1439;
const { vatRates } = storeToRefs(useVatRatesStore());
const moreOptionsPopoverShow = ref(false);

const props = defineProps<{
  hasProcessingTimeOption: boolean;
  hasBufferTimeOption: boolean;
}>();

const emit = defineEmits(['moreOptionsSelect']);

const moreOptions = computed(() => {
  const options = [];

  if (props.hasProcessingTimeOption) {
    options.push({
      label: t('service.processing_time.heading'),
      value: 'processingTime'
    });
  }

  if (props.hasBufferTimeOption) {
    options.push({
      label: t('service.buffer_time.heading'),
      value: 'bufferTime'
    });
  }

  return options;
});

const handleMoreOptionsSelect = (value: 'processingTime' | 'bufferTime') => {
  moreOptionsPopoverShow.value = false;

  emit('moreOptionsSelect', value);
};
</script>

<style module lang="scss">
.row {
  display: flex;
  justify-content: space-between;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
  }
}

.rowGroup {
  display: flex;
  align-items: flex-end;
  margin-right: $spacing * 1.5;

  .base.mediumScreen & {
    flex-direction: column;
    align-items: stretch;
    margin-right: 0;
    margin-bottom: $spacing;
  }

  & > *:first-child {
    flex-grow: 1;
  }
}

.moreOptions {
  position: relative;
}
</style>
