<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('admin.nav.links.services')">
      <template #header>
        <BaseButton
          :options="[
            {
              label: $t('services.add_category'),
              routerLink: {
                name: 'admin-service-category-new'
              }
            },
            {
              label: $t('services.add_service'),
              routerLink: {
                name: 'admin-service-new'
              }
            }
          ]"
          v-test="'btn-add-new'"
        >
          {{ $t('global.actions.create_new') }}
        </BaseButton>
      </template>
      <PageHeader v-if="!sortReorder">
        <template #left>
          <BaseDropdown
            v-model="filterValue"
            :options="[
              {
                value: 'all',
                label: $t('services.filter.all')
              },
              {
                value: 'bookable',
                label: $t('services.filter.bookable_online')
              },
              {
                value: 'not_bookable',
                label: $t('services.filter.not_bookable_online')
              }
            ]"
            v-test="'service-filter'"
          />
          <BaseSearch v-model="searchQuery" v-test="'service-search'" />
        </template>
        <template #right>
          <BaseButton
            icon="swap-vertical"
            color="inverted"
            @click="sortReorder = true"
            v-test="'btn-sort-all'"
          />
        </template>
      </PageHeader>
      <BaseAlert
        v-if="sortReorder"
        icon="flip"
        :text="$t('services.reorder_services')"
        :primaryAction="$t('global.done')"
        mb
        @primaryAction="sortReorder = false"
      />
      <List
        v-if="allDataLoaded && !getServiceGroupsLoading && !loading"
        :data="filteredCategoriesWithServices"
        :activeId="activeId"
        @toggle="(id) => (activeId = activeId === id ? null : id)"
      />
      <BaseSpinner v-if="loading" />
      <div
        v-if="!loading"
        :class="$style.addNewCategory"
        @click="$router.push({ name: 'admin-service-category-new' })"
        v-test="'service-category-create-list-bottom'"
      >
        <BaseIcon name="plus" color="success" :mr="0.5" />
        <BaseText bold>
          {{ $t('global.actions.create_category') }}
        </BaseText>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import PageHeader from '@/modules/PageHeader.vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useSessionStore } from '@/stores/session';
import { useServiceGroups } from './useServiceGroups';
import PageWrap from '../../PageWrap.vue';

import List from '../list-variation-groups/index.vue';

const { allDataLoaded } = useSessionStore();
const {
  categoriesWithServices,
  getServiceGroupsLoading,
  refetch,
  appendServiceGroup,
  sortServiceGroups,
  loading
} = useServiceGroups();

const filterValue = ref('all');
const searchQuery = ref('');
const activeId = ref(null);
const sortReorder = ref(false);

provide('services-category-sort-order', sortReorder);
provide('serviceGroupActions', {
  refetch,
  appendServiceGroup,
  sortServiceGroups
});

const filterByNameOrDescription = (
  item: { name: string; description?: string | null },
  searchQuery: string
) =>
  !!(
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (item.description &&
      item.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

const filteredCategoriesWithServices = computed(() =>
  categoriesWithServices.value
    .map((category) => ({
      ...category,
      services: category.services.filter(
        (service) =>
          filterByNameOrDescription(service, searchQuery.value) ||
          filterByNameOrDescription(category, searchQuery.value)
      )
    }))
    .filter(
      (category) =>
        category.services.length ||
        filterByNameOrDescription(category, searchQuery.value)
    )
);
</script>

<style lang="scss" module>
.addNewCategory {
  cursor: pointer;
  border: 1px dashed $color-success;
  display: flex;
  margin-top: $spacing * 0.25;
  padding: $spacing;
  border-radius: $radius;
}
</style>
