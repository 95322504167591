<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isLoading]: $apollo.loading
      }
    ]"
  >
    <BaseSpinner v-if="$apollo.loading" />
    <div v-else-if="events && events.length">
      <ScheduleItem
        v-for="(data, index) in events"
        :key="index"
        :data="data"
        v-test="'schedule-item'"
      />
    </div>
    <div v-else v-test="'empty-schedule'">
      <EmptyPageContent
        imageName="free-schedule"
        :text="{
          description: $t('dashboard.schedule.empty'),
          btnSecondary: hasFeatureFlag('module-appointments')
            ? $t('global.actions.create_appointment')
            : null
        }"
        @click="onEmptyScheduleClick"
      />
    </div>
    <BaseVisualBanner
      v-if="
        !$apollo.loading &&
        showTreatwell &&
        !hasDashboard &&
        !isTreatwellUser &&
        shouldShowTWBanner
      "
      :heading="treatwellHeader"
      icon="treatwell"
      :imagePath="treatwellImage"
      mt
      :primaryAction="$t('treatwell.landing.button_start')"
      @primaryAction="openTreatwellModal"
    />
  </div>
</template>

<script lang="ts">
import ScheduleItem from './ScheduleItem.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useTreatwellStore } from '@/stores/treatwell';
import { modal } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ScheduleItem,
    EmptyPageContent
  },
  inject: ['mixpanel'],
  props: {
    selectedResourceId: {
      type: Number
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { showTreatwell, hasDashboard } = useTreatwellStore();
    const { isTreatwellUser } = useCompanyStore();

    return {
      showTreatwell,
      hasDashboard,
      isTreatwellUser
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId']),
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['company']),
    treatwellImage(): string {
      switch (this.company.companyType) {
        case 'BARBERSHOP':
          return '/img/company-types/barbershop.svg';
        case 'NAIL_STUDIO':
        case 'MANI_PEDICURE':
          return '/img/company-types/nail_studio.svg';
        default:
          return '/img/treatwell/monitor.svg';
      }
    },
    treatwellHeader(): string {
      switch (this.company.companyType) {
        case 'BARBERSHOP':
          return this.$t(
            'treatwell.landing.salon_specific.barbershop.heading_visibility'
          );
        case 'NAIL_STUDIO':
        case 'MANI_PEDICURE':
          return this.$t(
            'treatwell.landing.salon_specific.nail_studio.heading_visibility'
          );
        default:
          return this.$t('treatwell.landing.heading_visibility');
      }
    },
    shouldShowTWBanner(): boolean {
      switch (this.company.companyType) {
        case 'BARBERSHOP':
        case 'NAIL_STUDIO':
        case 'MANI_PEDICURE':
          return !this.events || this.events.length < 5;
        default:
          return !this.events || !this.events.length;
      }
    }
  },
  methods: {
    onEmptyScheduleClick() {
      this.$router.push({ name: 'create-appointment' });
    },
    openTreatwellModal() {
      this.mixpanel.track('SalonizedDashboard-TreatwellPromo');
      modal('treatwell-start');
    }
  },
  apollo: {
    events: {
      query: gql`
        query getEvents($resourceId: Int, $locationId: ID) {
          events(resourceId: $resourceId, locationId: $locationId) {
            ... on Appointment {
              id
              createdAt
              startAt
              endAt
              serviceNames
              online
              parts {
                resources {
                  id
                }
              }
              customer {
                fullName
              }
            }
            ... on Absence {
              allDay
              id
              startAt
              endAt
              name
              resourceId
              chore
              calendarAbsence {
                extendedProps {
                  imported
                }
              }
            }
          }
        }
      `,
      pollInterval: 5 * 60 * 1000,
      variables() {
        return {
          resourceId: this.selectedResourceId,
          locationId: this.locationId
        };
      },
      skip() {
        return !this.isVisible;
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  &.isLoading {
    min-height: 64px;
  }
}
</style>
