<template>
  <div v-if="!loading" :class="$style.base">
    <BaseGrid container noPadding>
      <BaseGrid
        :class="$style.index"
        :size="4"
        noPadding
        v-intercom="
          settings.emailType
            ? `marketing-builder-settings-${settings.emailType.toLowerCase()}`
            : null
        "
      >
        <Settings>
          <slot name="actions" />
        </Settings>
      </BaseGrid>
      <BaseGrid :size="8" noPadding>
        <FiltersBar
          v-show="showFilters"
          @setFilter="setFilter"
          @close="closeFilters"
        />
        <Preview />
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import Settings from './Settings.vue';
import Preview from './preview/index.vue';
import FiltersBar from '@/components/customer-filters/FiltersBar.vue';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { emailTemplateFragment } from '@/graphql-fragments';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    Settings,
    Preview,
    FiltersBar
  },
  props: {
    settings: {
      type: Object,
      required: true,
      validator: (value) =>
        !!value.title &&
        !!value.description &&
        !!value.sentDescription &&
        !!value.receiveDescription
    },
    modelValue: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);

    if (route?.params?.id) {
      loading.value = true;

      const { result, onResult } = useQuery(
        gql`
          query getEmailTemplate($id: ID!, $emailType: EmailTemplateEnum!) {
            emailTemplate(id: $id, emailType: $emailType) {
              ...emailTemplate
            }
            emailTemplateDefault: emailTemplate(
              id: $id
              emailType: $emailType
              defaultValues: true
            ) {
              ...emailTemplate
            }
          }
          ${emailTemplateFragment}
        `,
        {
          id: route.params.id,
          emailType: 'MARKETING_CAMPAIGN'
        }
      );

      onResult(() => {
        if (result.value.emailTemplate) {
          result.value.emailTemplate.emailTemplateDefault =
            result.value.emailTemplateDefault;
          store.commit('marketing/SET_ATTRS', result.value.emailTemplate);
        }

        loading.value = false;
      });

      onResult(() => {
        loading.value = false;
      });
    }

    return {
      loading
    };
  },
  watch: {
    enabled: {
      handler(newValue, oldValue) {
        if (oldValue !== null) {
          this.$emit('update:modelValue', newValue);
        }
      }
    }
  },
  computed: {
    showFilters() {
      return this.$store.getters['marketing/showFilters'];
    },
    enabled() {
      return this.$store.getters['marketing/builder/enabled'];
    }
  },
  methods: {
    setFilter(filter) {
      this.$store.commit('marketing/SET_FILTER', filter);
    },
    closeFilters() {
      this.$store.commit('marketing/SET_SHOW_FILTERS', false);
    }
  },
  created() {
    this.$store.commit('marketing/builder/SET_SETTINGS', this.settings);

    if (this.settings.showToggle) {
      this.$store.commit('marketing/builder/SET_ENABLED', this.modelValue);
    }
  },
  beforeUnmount() {
    this.$store.commit('marketing/RESET_TO_EMPTY_STATE');
    this.$store.commit('marketing/builder/RESET_STATE');
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
  margin: -$spacing;
}

.index {
  z-index: 2;
}
</style>
