<template>
  <div>
    <div :class="$style.heading">
      <div
        :class="[$style.name, { [$style.clickable]: isExpandable }]"
        @click="expanded = isExpandable ? !expanded : false"
        v-test="'register-payment-item'"
      >
        <BaseText inline>
          {{ $t(`global.payments.${type.toLowerCase()}`) }}
        </BaseText>
        <BaseText v-if="isGiftcardOrPrepaidCard" inline>
          : {{ transactions[0].name }}
        </BaseText>
      </div>
      <BaseText
        v-if="!expanded"
        :mr="!options.length"
        v-test="'register-payment-item-amount'"
      >
        {{ filters.currency(totalAmount) }}
      </BaseText>
      <BaseMore
        v-if="options.length"
        :options="options"
        @select="headerSelect"
        v-test="'register-payment-item-options'"
      />
    </div>

    <div v-if="expanded && isExpandable" :class="$style.transactions">
      <div
        v-for="transaction in transactions"
        :key="transaction._uid"
        :class="$style.transaction"
        v-test="'register-payment-transaction'"
      >
        <div :class="$style.transactionDate">
          <BaseText ml>
            {{ filters.dateTime(transaction.transactionAt || dayjs()) }}
          </BaseText>
        </div>
        <BaseText>
          {{ filters.currency(transaction.amount) }}
        </BaseText>
        <BaseMore
          v-if="transaction.deletable"
          :options="['delete']"
          @select="transactionOption($event, transaction)"
          v-test="'register-payment-transaction-options'"
        />
        <BaseIcon
          v-else
          name="slash"
          :tooltip="{
            text: $t(
              `register.${cashupEnabled && transaction.type !== 'POS' && transaction.type !== 'ONLINE' ? 'payment_undeletable_tooltip' : 'transaction_undeletable'}`
            ),
            position: 'left',
            width: 100
          }"
          size="s"
          mt
          mb
          ml
          mr
          v-test="'register-payment-item-options-disabled'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import type { OrderTransaction } from '@/modules/register/stores/order';
import { useRegisterStore } from '@/modules/register/stores/register';
import { computed, ref } from 'vue';
import dayjs from '@/dayjs';

const { removeTransaction, removeAllTransactions } = useRegisterOrderStore();
const { cashupEnabled } = useRegisterStore();

const expanded = ref(false);

const props = defineProps<{
  type: string;
  transactions: OrderTransaction[];
}>();

const totalAmount = computed(() =>
  props.transactions.reduce(
    (acc, transaction) => acc + (transaction.amount || 0),
    0
  )
);

const isGiftcardOrPrepaidCard = computed(
  () => props.type === 'GIFTCARD' || props.type === 'PREPAID_CARD'
);

const isExpandable = computed(
  () =>
    (cashupEnabled && !isGiftcardOrPrepaidCard.value) || props.type === 'POS'
);

const isTransactionGroupDeletable = computed(() =>
  props.transactions.every((transaction) => transaction.deletable)
);
const deletable = computed(
  () =>
    props.type !== 'POS' &&
    (!cashupEnabled || isTransactionGroupDeletable.value)
);

const options = computed(() => {
  if (!deletable.value) {
    return isGiftcardOrPrepaidCard.value ? [] : ['view'];
  } else if (isExpandable.value) {
    return ['edit'];
  } else {
    return ['delete'];
  }
});

const headerSelect = (option: string) => {
  switch (option) {
    case 'edit':
    case 'view':
      expanded.value = !expanded.value;
      break;
    case 'delete':
      isGiftcardOrPrepaidCard.value
        ? removeTransaction(props.transactions[0])
        : removeAllTransactions(props.transactions[0]);
      break;
  }
};

const transactionOption = (option: string, transaction: OrderTransaction) => {
  switch (option) {
    case 'delete':
      removeTransaction(transaction);
      break;
  }
};
</script>

<style lang="scss" module>
.heading {
  display: flex;
  align-items: center;
}

.name {
  flex: 1;
  padding: $spacing * 0.5;

  &.clickable {
    cursor: pointer;
  }
}

.transactions {
  display: flex;
  flex-direction: column;
}

.transaction {
  display: flex;
  align-items: center;

  background-color: $grey-light;
  border-bottom: 1px solid $color-border;

  &:last-of-type {
    border-bottom: none;
  }
}

.transactionDate {
  flex: 1;
}
</style>
