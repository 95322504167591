<template>
  <BaseModal
    useForm
    :heading="
      $t('global.actions.new_item', {
        item: $t('global.items.prepaid_card', 1)
      })
    "
    small
    testId="create-prepaidcard"
    parentRoute="register-builder"
    @submit="submit"
  >
    <BaseAlert
      color="warning"
      :text="$t('prepaid_card.delete_warning')"
      mb
      v-test="'prepaid-card-warning'"
    />
    <BaseInput
      v-model="description"
      :label="$t('global.description')"
      required
      mb
      v-test="'create-prepaidcard-description'"
    />
    <BaseInput
      v-model="visits"
      :label="$t('prepaid_card.amount')"
      type="number"
      required
      :minValue="1"
      mb
      v-test="'create-prepaidcard-quantity'"
    />
    <div :class="$style.bottomRow">
      <BaseInput
        v-model="price"
        mr
        :label="$t('global.price')"
        type="currency"
        required
        :minValue="1"
        v-test="'create-prepaidcard-price'"
      />
      <BaseDropdown
        v-if="vatRates.length"
        v-model="vatRateId"
        :options="
          vatRates.map((rate) => ({
            value: rate.id,
            label: rate.name
          }))
        "
        :label="$t('global.items.vat_rate', 1)"
        v-test="'create-prepaidcard-vat'"
      />
    </div>

    <template #footer>
      <BaseButton submitForm v-test="'create-prepaidcard-save'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useVatRatesStore } from '@/stores/vat-rates';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

const router = useRouter();
const { addItem } = useRegisterOrderStore();
const { vatRates } = storeToRefs(useVatRatesStore());

const price = ref(0);
const vatRateId = ref<number>();
const description = ref('');
const visits = ref(1);
const { t } = useI18n();

const submit = () => {
  const prepaidCard = {
    description: description.value,
    price: price.value,
    visits: visits.value,
    vatRateId: vatRateId.value
  };

  addItem({
    prepaidCard,
    price: prepaidCard.price,
    name: `${t('global.payments.prepaid_card')}: ${prepaidCard.description} (${visits.value}x)`,
    quantity: 1,
    vatRateId: prepaidCard.vatRateId
  });

  router.push({ name: 'register-builder' });
};
</script>

<style lang="scss" module>
.bottomRow {
  display: flex;

  & > * {
    width: 50%;
  }
}
</style>
