<template>
  <ModuleBase gray>
    <ChangePassword
      v-if="showPasswordModal"
      @close="showPasswordModal = false"
    />
    <PageWrap :class="$style.base" :heading="$t('account.heading')">
      <BaseText :mb="2">
        {{ $t('account.description') }}
      </BaseText>

      <div>
        <BaseCard :heading="$t('account.login_info')" mb>
          <BaseInput
            v-model="firstName"
            :label="$t('global.first_name')"
            required
            mb
            v-test="'account-first-name'"
          />
          <BaseInput
            v-model="lastName"
            :label="$t('global.last_name')"
            mb
            v-test="'account-last-name'"
          />
          <BaseInput
            v-model="email"
            :label="$t('global.email')"
            required
            :error="
              isEmailUsed
                ? filters.capitalize(
                    $t('global.validations.in_use', {
                      value: $t('global.email')
                    })
                  )
                : ''
            "
            type="email"
            v-test="'account-email'"
          />
          <BaseButton
            color="inverted"
            mt
            @click="showPasswordModal = true"
            v-test="'account-btn-password'"
          >
            {{ $t('account.change_password') }}
          </BaseButton>
        </BaseCard>

        <BaseSpinner v-if="loading" inline />
        <TwoFactorAuthentication
          v-else
          :is2faActive="isTwoFactorEnabled"
          v-test="'two-factor-authentication'"
        />
        <BaseCard
          :heading="filters.capitalize($t('global.items.notification', 2))"
          mb
        >
          <BaseCheckbox
            v-model="updates"
            :label="$t('account.updates')"
            v-test="'account-updates'"
          />
          <BaseCheckbox
            v-if="hasFeatureFlag('module-reports')"
            v-model="dayReports"
            :label="$t('account.day_reports')"
            mt
            v-test="'account-day-reports'"
          />
        </BaseCard>
        <div :class="$style.buttonWrap">
          <BaseButton
            :loading="isLoading"
            @click="submit"
            v-test="'account-submit'"
          >
            {{ $t('global.actions.save') }}
          </BaseButton>
        </div>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import filters from '@/filters';
import { flash } from '@/helpers/ui';
import ModuleBase from '@/modules/ModuleBase.vue';
import useVuelidate from '@vuelidate/core';
import ChangePassword from './ChangePassword.vue';
import TwoFactorAuthentication from './TwoFactorAuthentication.vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { defineComponent, computed } from 'vue';
import { logValidationError } from '@/helpers/datadog';
import PageWrap from '@/modules/admin/PageWrap.vue';

export default defineComponent({
  components: {
    ModuleBase,
    ChangePassword,
    TwoFactorAuthentication,
    PageWrap
  },
  setup() {
    const { result, loading } = useQuery(gql`
      query getCurrentUser {
        currentUser {
          twoFactorSettings {
            status
          }
        }
      }
    `);

    const isTwoFactorEnabled = computed(
      () => result.value?.currentUser.twoFactorSettings?.status === 'ENABLED'
    );

    return {
      v$: useVuelidate(),
      filters,
      loading,
      isTwoFactorEnabled
    };
  },
  data() {
    const { user } = useUserStore();
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      dayReports: user.settings.notifications.dayReports,
      updates: user.settings.notifications.updates,
      admin: user.admin,
      isLoading: false,
      isEmailUsed: false,
      showPasswordModal: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag'])
  },
  methods: {
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      const { updateUser } = useUserStore();

      const input = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        settings: {
          notifications: {
            dayReports: this.dayReports,
            updates: this.updates
          }
        }
      };

      updateUser(input)
        .then(() => {
          flash(this.$t('global.flash.account_updated'));
          this.isEmailUsed = false;
        })
        .catch((errors) => {
          this.isEmailUsed = errors?.includes('email') || false;
          if (!this.isEmailUsed) {
            logValidationError('updateUser', errors, input);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>

<style lang="scss" module>
.buttonWrap {
  display: flex;
  justify-content: flex-end;
}
</style>
