<template>
  <div
    :class="[$style.base, { [$style.isMobile]: mobile }]"
    v-test="'header-customer-search'"
  >
    <transition @after-enter="isAnimated = true">
      <div v-show="isInputActive" :class="$style.inputWrap">
        <CustomerSearch
          v-model="searchQuery"
          :small="!mobile"
          :focus="searchFocus"
          @select="onCustomerSelect"
        />
      </div>
    </transition>

    <transition>
      <div
        v-show="!isInputActive"
        :class="$style.button"
        @click="isInputActive = true"
      >
        <BaseIcon
          name="search"
          :tooltip="{
            position: 'bottom',
            text: $t('nav.search')
          }"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import CustomerSearch from '@/components/CustomerSearch.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CustomerSearch
  },
  inject: ['mixpanel'],
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['customerSelect'],
  data() {
    return {
      searchQuery: '',
      isInputActive: !!this.mobile,
      isAnimated: false,
      searchFocus: false
    };
  },
  watch: {
    isInputActive(value) {
      if (!value) {
        this.searchQuery = '';
        this.searchFocus = false;
      } else {
        this.$nextTick(() => {
          this.searchFocus = true;
        });
      }
    }
  },
  methods: {
    onCustomerSelect(customer) {
      this.$emit('customerSelect');
      this.isInputActive = !!this.mobile;

      this.$router.push({
        name: 'customer-overview',
        params: { customerId: customer.id }
      });

      this.mixpanel.track('customer_search_selected');
    },
    onDocumentClick(e) {
      if (this.isAnimated && !this.$el.contains(e.target)) {
        this.isInputActive = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.onDocumentClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  height: 32px;

  &.isMobile {
    height: initial;
  }
}

.inputWrap {
  position: absolute;
  right: 0;
  top: 1px;
  width: 230px;
  z-index: 1;

  &:global(.v-enter-active) {
    transition: opacity 0.1s linear;
  }

  .isMobile & {
    position: relative;
    width: 100%;
  }
}

.button {
  @include header-item;

  &:global(.v-enter-active) {
    transition:
      transform 0.15s $easeOutBack 0.1s,
      opacity 0.1s linear 0.1s;
  }
  &:global(.v-enter-from) {
    transform: scale(0.5);
    opacity: 0;
  }
}
</style>
