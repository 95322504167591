<template>
  <div>
    <BackButton
      @click="selectedCategory ? (selectedCategory = null) : $emit('close')"
    />
    <EmptyResults v-if="items.length < 1" />

    <BrowserItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :showFavorite="selectedCategory ? true : false"
      @click="onSelect(item.id)"
      @refetch="getServices"
    >
      {{ item.label }}
    </BrowserItem>
  </div>
</template>

<script setup lang="ts">
import EmptyResults from '../EmptyResults.vue';
import { useServicesStore } from '@/stores/services';
import { ref, computed } from 'vue';
import BrowserItem from '../BrowserItem.vue';
import BackButton from '../BackButton.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
defineEmits(['close']);

const { services, serviceCategories } = storeToRefs(useServicesStore());
const { getServices } = useServicesStore();

const selectedCategory = ref<number | null>(null);

const items = computed(() => {
  if (selectedCategory.value) {
    return services.value
      .filter((service) => service.category.id === selectedCategory.value)
      .map((item: any) => ({
        id: item.id,
        label: item.name,
        price: item.price,
        favorite: item.favorite,
        type: 'service',
        medical: item.medical
      }));
  } else {
    return serviceCategories.value.map((category: any) => ({
      id: category.id,
      label: category.name
    }));
  }
});

const onSelect = (id: number) => {
  if (selectedCategory.value) {
    const service = services.value.find((service: any) => service.id === id);
    if (service) {
      const { addItem } = useRegisterOrderStore();

      addItem({
        name: service.name,
        price: service.price,
        serviceId: service.id,
        medical: service.medical!
      });
    }
  } else {
    selectedCategory.value = id;
  }
};
</script>
