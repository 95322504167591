<template>
  <div v-test="'register-browser-empty-category-item'">
    <BrowserItem
      v-if="!showEmptyCategories && emptyProducts"
      :item="{ label: $t('register.no_category') }"
      @click="showEmptyCategories = true"
    />

    <div v-if="showEmptyCategories && emptyProducts">
      <BrowserItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        showFavorite
        @click="onSelect(item.id)"
        @refetch="refetch"
      />
      <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
    </div>
  </div>
</template>

<script setup lang="ts">
import BrowserItem from '../../BrowserItem.vue';
import LoadMore from '@/components/LoadMore.vue';

import { usePagination } from '@/apollo/pagination';
import { GET_EMPTY_PRODUCTS } from '../../graphql';
import { computed, ref } from 'vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';

const emptyProducts = ref(false);

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'productsExist']);

const showEmptyCategories = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const { products, allDataFetched, fetchMore, refetch, onResult } =
  usePagination({
    fieldName: 'products',
    query: GET_EMPTY_PRODUCTS,
    variables: {
      showNullCategory: true
    }
  });

const items = computed(() =>
  products.value.map((product: any) => ({
    label: product.name,
    id: product.id,
    type: product.type,
    favorite: product.favorite,
    price: product.price
  }))
);

onResult((result: any) => {
  if (result.data?.products?.length > 0) {
    emptyProducts.value = true;
    emit('productsExist');
  }
});

const onSelect = (id: number) => {
  const product = products.value.find((product: any) => product.id === id);
  if (product) {
    const { addItem } = useRegisterOrderStore();

    addItem({
      name: product.name,
      price: product.price,
      productId: product.id
    });
  }
};
</script>
