<template>
  <div :class="$style.base">
    <BaseButton
      :mr="0.5"
      icon="arrow-left"
      color="inverted"
      @click="onPrevious"
      v-test="'previousButton'"
    />
    <BaseDatePicker
      v-model="date"
      onlyPastDates
      :minValue="minDate"
      hideCalendar
      :yearOnly="year"
      v-test="'dateSelect'"
    />

    <BaseButton
      :ml="0.5"
      :disabled="isDisabled"
      icon="arrow-right"
      color="inverted"
      @click="onNext"
      v-test="'nextButton'"
    />
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    year: {
      type: Boolean,
      default: false
    },
    month: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],

  data() {
    return {
      date: dayjs()
    };
  },

  watch: {
    dateString() {
      this.change();
    }
  },

  computed: {
    datePart() {
      return this.year ? 'year' : 'month';
    },

    isDisabled() {
      return this.date.format() === dayjs().format();
    },
    minDate() {
      return dayjs('2000-01-01').format();
    },
    dateString() {
      return this.date.format();
    }
  },

  methods: {
    change() {
      this.$emit('change', this.date);
    },

    onNext() {
      this.date = this.date.add(1, this.datePart);
    },

    onPrevious() {
      this.date = this.date.subtract(1, this.datePart);
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: flex-end;
}

.selected {
  min-width: 100px;
}

.month {
  min-width: 170px;
}
</style>
