<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.noPadding]: noPadding
      }
    ]"
  >
    <div :class="[$style.segment, $style.service]">
      <div :class="$style.segmentInner">
        {{ part.name || part.service.name }}
      </div>
    </div>
    <div v-if="!hasSingleResource" :class="[$style.segment, $style.resources]">
      <div :class="$style.segmentInner">
        <div>
          <div
            v-for="(resource, index) in resources"
            :key="`resource-${index}`"
            :class="$style.resource"
            v-test="'appointment-part-resource'"
          >
            <div :class="$style.resourceImage">
              <BaseAvatar
                v-if="resource.type === 'employee'"
                :resource="resource"
              />
              <BaseIcon v-if="resource.type === 'room'" name="pin" />
              <BaseIcon v-if="resource.type === 'equipment'" name="monitor" />
            </div>
            <div>
              {{ resource.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="[$style.segment, $style.duration]">
      <div :class="$style.segmentInner">
        {{ `${part.duration} ${$t('global.minute_short')}` }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    part: {
      type: Object,
      required: true
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useResourcesStore, ['hasSingleResource']),
    resources() {
      if (!this.part.resources?.length) {
        return [];
      }

      const resources = [...this.part.resources];
      return resources.sort((a, b) =>
        a.type === 'employee' && b.type !== 'employee' ? -1 : 1
      );
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;

  &:not(.noPadding) {
    padding: $spacing 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}

.segment {
  display: flex;
  align-items: flex-start;
}

.segmentInner {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.service {
  flex-grow: 1;

  .base.smallScreen & {
    width: 35%;
  }

  .base:not(.smallScreen) & {
    width: 40%;
  }
}

.resources {
  .base.smallScreen & {
    width: 45%;
  }

  .base:not(.smallScreen) & {
    width: 40%;
  }
}

.duration {
  width: 20%;
}

.resource {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.25;
  }
}

.resourceImage {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing * 0.25;
}
</style>
