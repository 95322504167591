<template>
  <BaseModal small @close="emit('close')">
    <div :class="$style.base">
      <div :class="$style.logo">
        <BaseLogo pink />
      </div>
      <BaseHeading size="xl" :mt="1">
        {{
          isTreatwellUser
            ? $t('onboarding.demo_treatwell')
            : $t('onboarding.demo')
        }}
      </BaseHeading>
      <BaseGrid :size="7" center alignCenter :mt="1.5">
        <BaseDropdown
          v-if="!user.treatwellAdmin"
          v-model="state.salonizedEmployeeId"
          :loading="employeesLoading"
          :label="$t('onboarding.demo_by')"
          :options="
            employees.map((e: any) => ({
              value: e.id,
              label: e.name,
              avatar: { picture: e.profileImage }
            }))
          "
          required
          v-test="'demoOnboardingDropdown'"
        />
        <BaseInput
          v-model="state.name"
          :label="$t('onboarding.name')"
          required
          mt
          v-test="'onboardingNameInput'"
        />
        <BaseInput
          v-model="state.email"
          type="email"
          :label="$t('signup.email')"
          mt
          required
          :error="state.emailInUse ? $t('signup.errors.email_in_use') : null"
          v-test="'onboardingEmailInput'"
        />
        <BaseInput
          v-model="state.phone"
          type="tel"
          :minLength="9"
          :maxLength="16"
          :label="$t('signup.phone')"
          mt
          v-test="'onboardingPhoneInput'"
        />
        <BaseButton
          fullWidth
          :mt="2"
          :mb="1"
          size="l"
          :loading="loading"
          @click="handleMutation"
          v-test="'onBoardingCreate'"
        >
          {{ $t('onboarding.start_demo') }}
        </BaseButton>
        <BaseText size="s" center>
          {{ $t('onboarding.demo_terms') }}
        </BaseText>
      </BaseGrid>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useMutation, useQuery } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStorage } from '@vueuse/core';
import { redirectRoute } from '@/router/helpers';
import { useSessionStore } from '@/stores/session';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';

const emit = defineEmits(['close']);

const router = useRouter();
const { user } = useUserStore();
const { isTreatwellUser } = useCompanyStore();

const state = reactive({
  salonizedEmployeeId: '',
  name: '',
  email: '',
  phone: '',
  emailInUse: false
});

const v$ = useVuelidate();

const {
  result,
  loading: employeesLoading,
  onResult
} = useQuery(gql`
  query salonizedEmployees {
    salonizedEmployees {
      name
      id
      profileImage
      isLastDemoGiver
    }
  }
`);

const employees = computed(() => result.value?.salonizedEmployees || []);
onResult(() => {
  state.salonizedEmployeeId = useStorage('last_employee_id', null);
});

const { mutate: createTradeShowAccount, loading } = useMutation(gql`
  mutation createTradeShowTrial($input: CreateTradeShowTrialInput!) {
    createTradeShowTrial(input: $input) {
      company {
        name
        id
        primaryLocationId
      }
      errors {
        attribute
        message
        type
      }
    }
  }
`);

const handleMutation = () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return;
  }

  createTradeShowAccount({
    input: {
      salonizedEmployeeId: state.salonizedEmployeeId,
      name: state.name,
      email: state.email,
      phone: state.phone
    }
  }).then((response: any) => {
    const emailTaken = response.data.createTradeShowTrial.errors?.some(
      (e: any) => e.type === 'taken' && e.attribute === 'email'
    );

    if (emailTaken) {
      state.emailInUse = true;
    } else {
      const { fetchAllData } = useSessionStore();
      fetchAllData().then(() => {
        emit('close');
        router.push(redirectRoute());
      });
    }
  });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: $spacing;
}

.logo {
  height: 32px;
  width: 25px;
}
</style>
