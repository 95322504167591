import { serviceFragment } from '@/graphql-fragments';
import gql from 'graphql-tag';

export const GET_SERVICE = gql`
  query getService($id: Int!) {
    service(id: $id) {
      ...service
    }
  }
  ${serviceFragment}
`;

export const serviceVariationGroupFragment = gql`
  fragment serviceVariationGroupAll on ServiceVariationGroup {
    id
    name
    sortOrder
    favorite
    medical
    bookable
    description
    twTreatments {
      id
      name
      group {
        id
        name
      }
    }
    rebookPeriod
    rebookEnabled
    vatRateId
    rwgName
    extraInformation
    bookableLocationIds
    popular
    offeringEnabled
    serviceOffering {
      id
      offeringType
      payload {
        slots {
          dayOfWeek
          endTime
          max
          startTime
        }
      }
      serviceId
    }
    requirements {
      id
      name
      primary
      required
      resources {
        id
        name
        # serviceIds
        type
      }
      state
      target
      type
      groupId
    }
    category {
      id
      name
    }
    variations {
      id
      name
      duration
      buffer
      requiresProcessingTime
      durationSetup
      durationProcessing
      durationFinish
      price
      sortOrder
    }
  }
`;

export const CREATE_SERVICE_VARIATION_GROUP = gql`
  mutation createServiceVariationGroup(
    $input: CreateServiceVariationGroupInput!
  ) {
    createServiceVariationGroup(input: $input) {
      serviceVariationGroup {
        ...serviceVariationGroupAll
      }
      errors {
        type
      }
    }
  }
  ${serviceVariationGroupFragment}
`;

export const GET_SERVICE_VARIATION_GROUP = gql`
  query getServiceVariationGroup($id: Int!) {
    serviceVariationGroup(id: $id) {
      ...serviceVariationGroupAll
    }
  }
  ${serviceVariationGroupFragment}
`;

export const UPDATE_SERVICE_VARIATION_GROUP = gql`
  mutation updateServiceVariationGroup(
    $input: UpdateServiceVariationGroupInput!
  ) {
    updateServiceVariationGroup(input: $input) {
      serviceVariationGroup {
        ...serviceVariationGroupAll
      }
      errors {
        type
      }
    }
  }
  ${serviceVariationGroupFragment}
`;

export const DELETE_SERVICE_VARIATION_GROUP = gql`
  mutation deleteServiceVariationGroup(
    $input: DeleteServiceVariationGroupInput!
  ) {
    deleteServiceVariationGroup(input: $input) {
      status
    }
  }
`;

export const DUPLICATE_SERVICE_VARIATION_GROUP = gql`
  mutation duplicateServiceVariationGroup(
    $input: DuplicateServiceVariationGroupInput!
  ) {
    duplicateServiceVariationGroup(input: $input) {
      errors {
        type
      }
      serviceVariationGroup {
        ...serviceVariationGroupAll
      }
    }
  }
  ${serviceVariationGroupFragment}
`;
