<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <div v-if="!cashupEnabled" :class="$style.dropdown">
      <BaseDatePicker
        v-model="order.invoicedAt"
        mr
        v-test="'register-order-cashup-date'"
      />
    </div>
    <div v-if="!hasSingleEmployee" :class="$style.dropdown">
      <BaseDropdown
        v-model="selectedEmployeeId"
        :options="employeeList"
        :searchable="employeeList.length > 6"
        :required="!!order.items.length"
        resources
        @update:modelValue="trackEmployee"
        v-test="'register-order-employee-dropdown'"
      />
    </div>
    <div :class="$style.search">
      <RegisterCustomerSearch />
    </div>
    <BaseButton
      v-if="$screen === 's'"
      size="l"
      fullWidth
      mt
      @click="showMobileBrowser = true"
    >
      {{
        filters.capitalize(
          $t('global.actions.add_item', { item: $t('global.items.item', 2) })
        )
      }}
    </BaseButton>
  </div>
</template>
<script lang="ts" setup>
import filters from '@/filters';
import { useResourcesStore } from '@/stores/resources';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useRegisterStore } from '@/modules/register/stores/register';
import RegisterCustomerSearch from './RegisterCustomerSearch.vue';
import { storeToRefs } from 'pinia';

const { employees, hasSingleEmployee } = storeToRefs(useResourcesStore());
const { order } = useRegisterOrderStore();
const { selectedEmployeeId } = storeToRefs(useRegisterOrderStore());
const { cashupEnabled, showMobileBrowser } = storeToRefs(useRegisterStore());
const mixpanel = inject<any>('mixpanel');

const trackEmployee = () => {
  mixpanel.track('Register - Employee changed');
};

const { t } = useI18n();

const employeeList = computed(() => {
  const list = employees.value.map((employee: any) => ({
    value: employee.id,
    label: employee.name
  }));

  list.unshift({ value: 'multiple', label: t('global.multiple') });
  return list;
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &.smallScreen {
    flex-direction: column;
  }
}

.dropdown {
  width: 50%;
  margin-bottom: $spacing;
  flex: 1;
  min-width: 200px;

  .base.smallScreen & {
    width: 100%;
  }

  .base:not(.smallScreen) & {
    margin-right: $spacing;
  }
}

.search {
  flex: 2;

  .base:not(.smallScreen) & {
    margin-bottom: $spacing;
  }
}
</style>
