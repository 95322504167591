<template>
  <div :class="$style.base">
    <BaseHeading mb>
      {{ filters.capitalize($t('global.items.payment', 2)) }}
    </BaseHeading>
    <div v-if="order.transactions.length">
      <Transaction
        v-for="(group, index) in groupedTransactions"
        :key="index"
        :transactions="group.transactions"
        :type="group.type"
      />
    </div>
    <BaseText v-else mb v-test="'register-transactions-empty'">
      {{ $t('register.no_payments') }}
    </BaseText>

    <div :class="[$style.row, $style.table]">
      <BaseText v-test="'register-transactions-total'">
        {{ $t('global.total') }}: {{ filters.currency(totalPaid) }}
      </BaseText>
    </div>
    <div :class="$style.row">
      <div>
        <BaseHeading size="s" :mb="0.5">
          {{ $t('register.amount_due') }}
        </BaseHeading>
        <BaseText size="l" bold v-test="'register-transactions-due'">
          {{ filters.currency(outstandingAmount > 0 ? outstandingAmount : 0) }}
        </BaseText>
      </div>
    </div>
    <div v-if="outstandingAmount < 0" :class="[$style.row, $style.change]">
      <BaseText v-test="'register-transactions-change'">
        {{ $t('register.change_money') }}:
        {{ filters.currency(Math.abs(outstandingAmount)) }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import {
  useRegisterOrderStore,
  type OrderTransaction
} from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import Transaction from './Transaction.vue';
import type { TransactionType } from '@/types';

import { computed } from 'vue';

const { order } = useRegisterOrderStore();
const { totalPaid, outstandingAmount } = storeToRefs(useRegisterOrderStore());

const groupedTransactions = computed(() => {
  const transactions = order.transactions.filter(
    (transaction: OrderTransaction) => !transaction.destroy
  );
  const grouped = transactions.reduce(
    (acc, transaction: OrderTransaction) => {
      const type = transaction.type as TransactionType;
      const group =
        type === 'GIFTCARD' || type === 'PREPAID_CARD'
          ? null
          : acc.find((group: any) => group.type === type);
      if (group) {
        group.transactions.push(transaction);
      } else {
        acc.push({
          type,
          transactions: [transaction]
        });
      }
      return acc;
    },
    [] as { type: TransactionType; transactions: any[] }[]
  );

  return grouped;
});
</script>

<style lang="scss" module>
.base {
  margin-top: $spacing * 2;
}

.row {
  display: flex;
  justify-content: flex-end;
  padding: $spacing;
  text-align: right;
}

.table {
  background-color: $grey-light;
  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;
}

.change {
  border-top: 1px solid $color-border;
}
</style>
