<template>
  <div>
    <BaseModalSmall
      v-if="showSubscriptionConfirmation"
      @close="showSubscriptionConfirmation = false"
    >
      <BaseText size="l">
        {{ $t('treatwell.landing.no_subscription.message') }}
      </BaseText>
      <template #footer>
        <BaseButton
          color="inverted"
          @click="showSubscriptionConfirmation = false"
          v-test="'btn-tw-subscription-cancel'"
        >
          {{ $t('global.actions.cancel') }}
        </BaseButton>
        <BaseButton
          @click="onSubscriptionClick"
          v-test="'btn-tw-subscription-submit'"
        >
          {{ $t('treatwell.landing.no_subscription.button') }}
        </BaseButton>
      </template>
    </BaseModalSmall>

    <BaseButton
      size="l"
      fullWidth
      :loading="loading"
      @click="onStartClick"
      v-test="'btn-treatwell-start'"
    >
      {{ $t('treatwell.landing.button_start') }}
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { useTreatwellStore } from '@/stores/treatwell';
import { useMutation } from '@vue/apollo-composable';
import { inject, ref } from 'vue';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';
import { useStorage } from '@vueuse/core';

const mixpanel = inject<any>('mixpanel');
const router = useRouter();

const showSubscriptionConfirmation = ref(false);

const { isTrial } = useCompanyStore();
const { treatwellStatus } = useTreatwellStore();

const { mutate, loading } = useMutation(
  gql`
    mutation treatwellIntegrationRequestCreate(
      $input: IntegrationRequestCreateInput!
    ) {
      treatwellIntegrationRequestCreate(input: $input) {
        treatwellIntegrationRequest {
          id
        }
      }
    }
  `,
  {
    variables: {
      input: {}
    }
  }
);

const onStartClick = () => {
  mixpanel.track('TWLandingPage-LinkYourAccount');
  if (isTrial) {
    showSubscriptionConfirmation.value = true;
  } else {
    const tw_linked_account_at = useStorage('tw_linked_account_at', 0);
    tw_linked_account_at.value = Date.now();
    if (!treatwellStatus.hasRequest) {
      mutate().then(() => {
        treatwellStatus.hasRequest = true;
        router.push({ name: 'treatwell-admin' });
      });
    } else {
      router.push({ name: 'treatwell-admin' });
    }
  }
};

const onSubscriptionClick = () => {
  showSubscriptionConfirmation.value = false;
  mixpanel.track('TWLandingPage-LinkYourAccount-TrialSub');
  router.push({ name: 'subscription' });
};
</script>
