<template>
  <div
    v-show="!loadingQuery"
    :class="[
      $style.base,
      { [$style.submitted]: submitted, [$style.smallScreen]: $screen === 's' }
    ]"
  >
    <div v-if="!submitted">
      <div :class="$style.header">
        <BaseIcon
          name="close"
          clickable
          @click="remindLater"
          v-test="'nps-close'"
        />
      </div>
      <div>
        <BaseText mb>
          {{ $t('banner.nps.question') }}
        </BaseText>
        <div :class="$style.scoreButtons">
          <div
            v-for="(option, idx) in options"
            :key="option"
            :class="[
              $style.button,
              {
                [$style.belowSelected]: !noScore && idx < npsData.score!,
                [$style.aboveSelected]: !noScore && idx > npsData.score!
              }
            ]"
            :mr="option !== 10 ? 0.5 : null"
            :mb="0.5"
            @click="npsData.score = option"
            v-test="`nps-score-${option}`"
          >
            {{ option }}
          </div>
        </div>
        <div :class="$style.scoreExplanation">
          <BaseText size="s" color="secondary">
            😡 {{ $t('banner.nps.very_bad') }}
          </BaseText>
          <BaseText size="s" color="secondary">
            🥰 {{ $t('banner.nps.very_good') }}
          </BaseText>
        </div>
      </div>
      <div v-if="!noScore">
        <BaseText mb>
          {{ $t('banner.nps.reason') }}
        </BaseText>
        <BaseInput
          v-model="npsData.comment"
          type="textarea"
          :rows="2"
          :mb="0.5"
          v-test="'nps-reason'"
        />
        <div :class="$style.footer">
          <BaseButton
            :disabled="loadingMutation"
            @click="submit"
            v-test="'nps-save'"
          >
            {{ $t('banner.nps.button') }}
          </BaseButton>
        </div>
      </div>
      <BaseText link @click="remindLater">
        {{ $t('banner.nps.ask_later') }}
      </BaseText>
    </div>
    <div v-else v-test="'nps-submitted'">
      <div :class="$style.inner">
        <img :class="$style.image" src="/img/nps/submitted.svg" />
        <div :class="$style.text">
          <BaseText ml>{{ $t('banner.nps.thanks') }}</BaseText>
        </div>
        <BaseIcon name="close" clickable @click="close" v-test="'nps-finish'" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useUserStore } from '@/stores/user';

const emit = defineEmits(['close']);

const npsData: {
  id: number | null;
  score: number | null;
  comment: string | null;
  remindLater: boolean;
} = reactive({
  id: null,
  score: null,
  comment: null,
  remindLater: false
});

const submitted = ref(false);

const options = [...Array(11).keys()];
const noScore = computed(() => npsData.score === null);

const { mutate: upsertNps, loading: loadingMutation } = useMutation(
  gql`
    mutation upsertNpsFeedback($input: UpsertNpsFeedbackInput!) {
      upsertNpsFeedback(input: $input) {
        npsFeedback {
          id
        }
      }
    }
  `,
  {
    variables: {
      input: npsData
    }
  }
);

const saveAndClose = () => {
  upsertNps().then(() => close());
};

const remindLater = () => {
  npsData.remindLater = true;
  saveAndClose();
};

const submit = () => {
  upsertNps().then(() => (submitted.value = true));
  setTimeout(() => {
    close();
  }, 1500);
};

const close = () => {
  const { user } = useUserStore();
  user.npsFeedbackDue = false;
  emit('close');
};

const { onResult, loading: loadingQuery } = useQuery(gql`
  query getNpsFeedback {
    npsFeedback {
      id
    }
  }
`);

onResult(({ data: { npsFeedback } }) => {
  if (npsFeedback) {
    npsData.id = npsFeedback.id;
  }
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing;
  background-color: white;
  border-radius: $radius;
  box-shadow: $shadow;
  position: relative;
  width: 80%;

  &.smallScreen {
    width: 100%;
  }

  &.submitted {
    background-color: #e5f4f2;

    .inner {
      display: flex;

      .image {
        margin-right: $spacing;
      }
      .text {
        align-self: center;
        flex-grow: 1;
      }
    }
  }
}

.header {
  right: 10px;
  top: 10px;
  position: absolute;
}

.scoreButtons {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  justify-content: center;
}

.scoreExplanation {
  display: flex;
  justify-content: space-between;

  margin-bottom: $spacing;
}

.footer {
  text-align: right;

  .smallScreen & {
    text-align: left;
  }
}

.closeIcon {
  text-align: right;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;

  .smallScreen & {
    justify-content: flex-start;
  }

  margin-top: $spacing;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 44px;
  height: 44px;
  font-weight: bold;
  border-radius: $radius;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition:
    background-color 0.1s ease,
    border-color 0.1s ease;
  user-select: none;
  text-decoration: none;
  flex-shrink: 0;
  margin-bottom: $spacing * 0.5;

  color: $color-text-inverted;
  background-color: $color-primary;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: $spacing * 0.5;

  .smallScreen & {
    margin-top: $spacing * 0.5;
  }

  @include hover {
    color: $color-text-inverted;
    background-color: $color-primary-dark;
  }

  &.aboveSelected {
    background-color: $white;
    font-weight: normal;
    border: 1px solid $color-border-input;
    color: $color-text;

    @include hover {
      color: $color-text;
      background-color: $color-highlight;
    }
  }

  &.belowSelected {
    color: $color-text-secondary;
    background-color: $grey-light;
    border: none;
  }
}
</style>
