<template>
  <div v-test="'service-variation-resources'">
    <Requirement
      v-for="item in visibleRequirements"
      :id="item.id"
      :key="`item-${item.id || item._uid}`"
      :primary="item.primary"
      :resourceIds="item.resourceIds"
      :type="item.type"
      mb
      @updateSelectedIds="updateSelectedIds($event, item._uid)"
      @close="deleteRequirement(item._uid)"
      v-test="'service-requirement'"
    />
    <div v-if="buttons.length">
      <BaseChip
        v-for="button in buttonList"
        :key="button.value"
        :text="button.label"
        :icon="button.icon"
        clickable
        mr="0.5"
        mt="0.5"
        @click="handleResourceOptionSelect(button.value)"
        v-test="`add-resource-${button.value}`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ServiceModalRequirementsView'
});
</script>

<script lang="ts" setup>
import type { ServiceFormData } from '../types';
import type { IconName } from '@/components/base-icon/types';

import { useI18n } from 'vue-i18n';
import { useResourcesStore } from '@/stores/resources';

import Requirement from './ResourceType.vue';

type ResourceType = 'employee' | 'room' | 'equipment';
type ButtonItem = {
  type: string;
  icon: string;
};

let uid = 0;

const formData = inject<ServiceFormData>('serviceFormData')!;

const showResourceOptions = ref(false);

const visibleRequirements = computed(() =>
  formData.requirementsAttributes.filter((r: any) => !r.destroy)
);

const { resourcesByType } = useResourcesStore();
const { t } = useI18n();

const buttons: ComputedRef<ButtonItem[]> = computed(() => {
  if (!visibleRequirements.value.length) {
    return [];
  } else {
    return [
      {
        type: 'employee',
        icon: 'person'
      },
      {
        type: 'room',
        icon: 'room'
      },
      {
        type: 'equipment',
        icon: 'monitor'
      }
    ].filter((button) => {
      const resourceAmount = resourcesByType(button.type).length;
      const requirementAmount = visibleRequirements.value.filter(
        (req: any) => req.type === button.type.toUpperCase()
      ).length;
      return requirementAmount === 0 || requirementAmount < resourceAmount;
    });
  }
});

const buttonList = computed(() =>
  buttons.value.map((button: ButtonItem) => ({
    label: t(`service.resources.${button.type}.link_add`),
    value: button.type as ResourceType,
    icon: button.icon as IconName
  }))
);

const handleResourceOptionSelect = (type: ResourceType) => {
  showResourceOptions.value = false;
  addItem(type.toUpperCase());
};

const updateSelectedIds: (ids: number[], uid: number) => void = (ids, uid) => {
  formData.requirementsAttributes.find((r) => r._uid === uid).resourceIds = ids;
};

const deleteRequirement: (uid: number) => void = (uid) => {
  formData.requirementsAttributes.find((r) => r._uid === uid).destroy = true;
};

const addItem: (type: string) => void = (type) => {
  uid++;

  formData.requirementsAttributes = [
    ...formData.requirementsAttributes,
    {
      _uid: uid,
      destroy: false,
      primary: !formData.requirementsAttributes.length, // Only the first requirement should be primary
      resourceIds: [],
      type
    }
  ];
};

if (!formData.requirementsAttributes.length) {
  // Add a default requirement when creating a new service
  addItem('EMPLOYEE');
} else {
  // When editing a service, add uids to existing requirements
  formData.requirementsAttributes = formData.requirementsAttributes.map((r) => {
    uid++;
    return {
      ...r,
      _uid: uid
    };
  });
}
</script>
