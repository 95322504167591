<template>
  <div>
    <div>
      <div :class="$style.flex">
        <BaseText>
          {{ $t('global.logo') }}
        </BaseText>
        <BaseToggle
          :modelValue="hasLogo"
          :disabled="!company.logo"
          @update:modelValue="onToggleLogo"
          v-test="'toggleLogo'"
        />
      </div>
      <BaseAlert
        v-if="!company.logo"
        size="s"
        :text="$t('marketing.email.sections.logo_missing')"
        mb
        v-test="'missingLogoText'"
      />
    </div>
    <div :class="$style.banner">
      <div :class="$style.flex">
        <BaseText>
          {{ $t('global.image') }}
          <BaseIcon
            :tooltip="$t('marketing.email.sections.image_size')"
            inline
            size="s"
            name="info"
          />
        </BaseText>
        <BaseToggle
          :modelValue="hasImage"
          @update:modelValue="onToggleImage"
          v-test="'toggleBanner'"
        />
      </div>
      <div v-show="hasImage">
        <MessageField v-show="hasImageUrl" mb v-test="'bannerMessage'">
          <div :class="$style.message">
            <BaseText>
              {{ imageStatus }}
            </BaseText>
            <BaseIcon
              name="delete"
              clickable
              color="primary"
              @click="onDelete"
            />
          </div>
        </MessageField>
        <MessageField v-show="showError" danger mb v-test="'bannerError'">
          <BaseText>
            {{ $t('global.image_upload_fail') }}
          </BaseText>
          <BaseText size="s">
            {{ $t('global.image_format_not_supported') }}
          </BaseText>
        </MessageField>
        <BaseUpload
          :hasImage="hasImageUrl"
          preset="marketing_newsletter"
          @uploadResults="onUpload"
          @error="onError"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { modal } from '@/helpers/ui';
import { mapGetters } from 'vuex';
import MessageField from './MessageField.vue';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    MessageField
  },
  data() {
    return {
      showError: false
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapGetters('marketing', [
      'hasLogo',
      'hasImage',
      'imageUrl',
      'defaultImageUrl'
    ]),
    imageStatus() {
      return this.$t('global.image_uploaded');
    },
    hasImageUrl() {
      return !!this.imageUrl && this.imageUrl !== this.defaultImageUrl;
    }
  },
  methods: {
    onToggleLogo() {
      this.$store.commit('marketing/TOGGLE_LOGO');
    },
    onToggleImage() {
      this.$store.commit('marketing/TOGGLE_IMAGE');
    },
    onUpload(result) {
      this.showError = false;
      this.$store.commit('marketing/SET_IMAGE_URL', result.url);
    },
    onDelete() {
      modal('confirmation').then(() => {
        this.$store.commit('marketing/SET_IMAGE_URL', this.defaultImageUrl);
      });
    },
    onError() {
      this.showError = true;
    }
  },
  created() {
    if (!this.company.logo) {
      this.$store.commit('marketing/SET_LOGO_STATE', false);
    }
  }
});
</script>

<style lang="scss" module>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing;
}

.banner {
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  padding: $spacing 0;
  margin-bottom: $spacing;
}

.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
