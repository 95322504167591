<template>
  <div>
    <BaseHeading mb>
      {{ $t('appointment.reports') }}
    </BaseHeading>
    <BaseCard>
      <router-link
        v-for="(report, index) in reports"
        :key="`report-${index}`"
        :class="$style.report"
        :to="`${appointmentId}/report/${report.id}`"
        v-test="'appointment-report'"
      >
        <div :class="$style.reportLeft">
          <BaseIcon size="s" name="clipboard" :mr="0.5" />
          <div :class="$style.reportText">
            {{ report.report }}
          </div>
        </div>
        <div :class="$style.reportRight">
          <BaseText size="s">
            {{ filters.dateTime(report.createdAt) }}
          </BaseText>
        </div>
      </router-link>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    appointmentId: {
      type: Number,
      required: true
    },
    reports: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>

<style lang="scss" module>
.report {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.reportLeft {
  display: flex;
  align-items: center;
  width: calc(100% - 100px);
}

.reportRight {
  min-width: 100px;
  text-align: right;
}

.reportText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
