<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div v-if="$screen !== 's'" :class="$style.toggle">
      <BaseSwitch
        v-model="currentContext"
        :options="[
          {
            label: $t('header.location_select.toggle_global'),
            value: 'GLOBAL'
          },
          {
            label: $t('header.location_select.toggle_local'),
            value: 'LOCAL'
          }
        ]"
        small
        :disabled="toggleDisabled"
        :tooltip="
          toggleDisabled
            ? {
                position: 'bottom',
                text: $t('header.location_select.toggle_unavailable'),
                touch: true
              }
            : null
        "
        @update:modelValue="onContextSelect"
        v-test="'location-toggle'"
      />
    </div>
    <div v-if="locationId" :class="$style.select" v-test="'location-select'">
      <BaseDropdown
        v-model="locationId"
        :options="
          locations.map((location) => ({
            value: location.id,
            label: location.internalName
          }))
        "
        :small="$screen !== 's'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

const localRoutes = [
  'admin-absences',
  'admin-employees',
  'admin-rooms',
  'admin-equipment',
  'admin-roster',
  'cashups',
  'cashup',
  'dashboard',
  'drawer',
  'drawer-transaction',
  'drawer-close',
  'products',
  'products-stock-orders',
  'register-builder',
  'register-payment'
];

const toggleRoutes = [
  'admin-export',
  'agenda',
  'calendar',
  'day-overview',
  'day-overview-item',
  'feedback',
  'feedback-item',
  'feedbacks',
  'feedback-publish',
  'invoices',
  'reports'
];

import { defineComponent } from 'vue';
import { useStorage } from '@vueuse/core';

export default defineComponent({
  setup() {
    return {
      chosenContext: useStorage('location_context', '')
    };
  },
  data() {
    const locationsStore = useLocationsStore();
    const { dataScope } = useLocationsStore();

    return {
      currentContext: dataScope,
      defaultContext: dataScope,
      toggleDisabled: false,
      locationsStore
    };
  },
  watch: {
    isGlobalRoute() {
      this.updateContext();
    },
    isLocalRoute() {
      this.updateContext();
    },
    currentContext: {
      handler(value) {
        if (this.$route.params.locationId) {
          // Only update the scope when the route has a location ID.
          // This is mainly to prevent updating the scope when the user logs out and navigates to the "logged out" page, which would trigger watchers in the calendar.
          this.locationsStore.dataScope = value;
        }
      },
      immediate: true
    },
    locationId() {
      if (this.isToggleRoute) {
        this.currentContext = 'LOCAL';
      }
    }
  },
  computed: {
    ...mapState(useLocationsStore, ['locations']),
    routeName() {
      return this.$route.name;
    },
    isLocalRoute() {
      return !!this.$route?.matched.find((route) =>
        localRoutes.includes(route.name)
      );
    },
    isToggleRoute() {
      return !!this.$route?.matched.find((route) =>
        toggleRoutes.includes(route.name)
      );
    },
    isGlobalRoute() {
      return !this.isLocalRoute && !this.isToggleRoute;
    },
    locationId: {
      get() {
        const { locationId } = useLocationsStore();
        return locationId;
      },
      set(value) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            locationId: value
          }
        });
      }
    }
  },
  methods: {
    onContextSelect(value) {
      this.chosenContext = value;
    },
    updateContext() {
      if (this.isGlobalRoute) {
        this.currentContext = 'GLOBAL';
        this.toggleDisabled = true;
      } else if (this.isLocalRoute) {
        this.currentContext = 'LOCAL';
        this.toggleDisabled = true;
      } else {
        this.currentContext = this.chosenContext || this.defaultContext;
        this.toggleDisabled = false;
      }
    }
  },
  created() {
    this.updateContext();
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
}

.select {
  .base:not(.smallScreen) & {
    width: 150px;
    margin-left: $spacing * 0.5;
  }
}
</style>
