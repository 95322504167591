<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <div :class="$style.bottomRow">
      <BaseButton
        v-if="canModify"
        :fullWidth="$screen === 's'"
        size="l"
        :disabled="!order.items.length"
        color="inverted"
        @click="handleModifyClick"
        v-test="'order-modify'"
      >
        {{ $t('global.actions.modify_invoice') }}
      </BaseButton>

      <BaseButton
        v-if="outstandingAmount > 0"
        :fullWidth="$screen === 's'"
        size="l"
        submitForm
        :disabled="!order.items.length"
        :loading="savingOrder"
        color="warning"
        v-test="'order-save-unpaid'"
      >
        {{ t('register.save_as_unpaid') }}
      </BaseButton>

      <BaseButton
        v-else
        :fullWidth="$screen === 's'"
        size="l"
        :disabled="disableCheckout"
        :loading="savingOrder"
        submitForm
        v-test="'order-complete-invoice'"
      >
        {{ $t('global.actions.complete_invoice') }}
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useRegisterStore } from '@/modules/register/stores/register';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { modal } from '@/helpers/ui';
import { computed } from 'vue';

const { order } = useRegisterOrderStore();
const { totalPrice, outstandingAmount } = storeToRefs(useRegisterOrderStore());
const { isOpen: isDrawerOpen } = useRegisterDrawerStore();
const router = useRouter();
const { t } = useI18n();
const { cashupEnabled } = storeToRefs(useRegisterStore());
const mixpanel = inject<any>('mixpanel');

defineProps<{
  savingOrder: boolean;
}>();

const canModify = computed(
  () =>
    !cashupEnabled.value ||
    (cashupEnabled.value && isDrawerOpen && order.permissions?.editOrderItems)
);

const disableCheckout = computed(() => {
  const change = outstandingAmount.value < 0 ? outstandingAmount.value * -1 : 0;

  return totalPrice.value < 0 && change > 0;
});

const handleModifyClick = () => {
  const editingExisting =
    order.state === 'PAID' || order.state === 'OUTSTANDING';
  mixpanel.track('Register - Modify order clicked');

  if (editingExisting) {
    modal('confirmation', {
      message: t('register.edit_warning')
    }).then(() => {
      router.push({ name: 'register', query: { action: 'modify' } });
    });
  } else {
    router.push({ name: 'register', query: { action: 'modify' } });
  }
};
</script>

<style module lang="scss">
.base {
  border-top: 1px solid $color-border;
  display: flex;
  padding-top: $spacing;
  justify-content: flex-end;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;

  .base:not(.smallScreen) & {
    margin-top: $spacing * -0.5;
  }

  .smallScreen & {
    margin-bottom: $spacing;
    flex-direction: column;
  }

  & > * {
    margin-top: $spacing * 0.5;
    margin-left: $spacing;

    .smallScreen & {
      margin-left: 0px;
    }
  }
}
</style>
