<template>
  <div>
    <div :class="$style.row">
      <div :class="$style.cell">
        <BaseCheckbox
          v-model="allEnabled"
          :label="category.name"
          v-test="'category-checkbox'"
        />
        <BaseIcon
          :name="`chevron-${collapsed ? 'up' : 'down'}`"
          :ml="0.25"
          color="primary"
          @click="collapsed = !collapsed"
          v-test="'category-chevron'"
        />
        <BaseText :ml="0.25" size="s" v-test="'category-enabled-amount'">
          {{ `(${enabledAmount}/${services.length})` }}
        </BaseText>
      </div>
      <div v-if="allEnabled && allContainSameValue" :class="$style.cell">
        <div :class="$style.inputWrap">
          <BaseInput
            v-model="collectiveValue"
            type="number"
            :minValue="1"
            :maxValue="400"
            v-test="'category-value'"
          />
        </div>
        <BaseText grey :ml="0.5">
          {{ $t('global.items.day', collectiveValue) }}
        </BaseText>
      </div>
      <div v-else-if="enabledAmount" :class="$style.cell">
        <BaseText size="s">
          {{ $t('global.multiple') }}
        </BaseText>
      </div>
    </div>
    <div v-show="collapsed">
      <div
        v-for="(service, index) in services"
        :key="index"
        :class="[$style.row, $style.rowSub]"
      >
        <div :class="$style.cell">
          <BaseCheckbox
            v-model="service.rebookEnabled"
            :label="service.name"
            v-test="'service-checkbox'"
          />
        </div>
        <div v-if="service.rebookEnabled" :class="$style.cell">
          <div :class="$style.inputWrap">
            <BaseInput
              v-model="service.rebookPeriod"
              :minValue="1"
              :maxValue="400"
              type="number"
              v-test="'service-value'"
            />
          </div>
          <BaseText grey :ml="0.5">
            {{ $t('global.items.day', service.rebookPeriod) }}
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent } from 'vue';

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  emits: ['updateCategory'],
  setup(props) {
    const services = reactive(props.category.services);

    return {
      services
    };
  },
  data() {
    return {
      collapsed: false
    };
  },
  computed: {
    allEnabled: {
      get() {
        return !this.services.find((service) => !service.rebookEnabled);
      },
      set(value) {
        this.services.forEach((service) => {
          service.rebookEnabled = value;
        });
      }
    },
    firstItemValue() {
      return this.services[0]?.rebookPeriod || 0;
    },
    allContainSameValue() {
      return !this.services.find(
        (service) => service.rebookPeriod !== this.firstItemValue
      );
    },
    collectiveValue: {
      get() {
        return this.firstItemValue;
      },
      set(value) {
        this.services.forEach((service) => {
          service.rebookPeriod = value;
        });
      }
    },
    enabledAmount() {
      return this.services.filter((item) => item.rebookEnabled).length;
    }
  }
});
</script>

<style lang="scss" module>
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing;
  border-bottom: 1px solid $color-border;

  &.rowSub {
    padding-left: $spacing * 2;
  }
}

.cell {
  display: flex;
  align-items: center;
  min-height: $input-height;
}

.inputWrap {
  max-width: 50px;
  min-width: 50px;
  margin-left: 5px;
}
</style>
