<template>
  <div>
    <BackButton @click="goBack" />

    <ProductCategories
      v-if="!selectedCategory && !showEmptyProducts"
      :emptyProducts="emptyProductsExist"
      @select="selectedCategory = $event"
    />

    <NoCategoryProducts
      v-if="!selectedCategory"
      v-model="showEmptyProducts"
      @productsExist="emptyProductsExist = true"
    />

    <Products v-else :categoryId="selectedCategory" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'ProductsList'
};
</script>

<script setup lang="ts">
import { ref } from 'vue';
import ProductCategories from './ProductCategories.vue';
import Products from './Products.vue';
import BackButton from '../../BackButton.vue';
import NoCategoryProducts from './NoCategoryProducts.vue';

const emit = defineEmits(['close']);
const selectedCategory = ref<number | null>(null);
const showEmptyProducts = ref(false);
const emptyProductsExist = ref(false);

const goBack = () => {
  if (selectedCategory.value) {
    selectedCategory.value = null;
    return;
  }

  if (!selectedCategory.value && showEmptyProducts.value) {
    showEmptyProducts.value = false;
    return;
  }

  emit('close');
};
</script>
