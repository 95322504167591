<template>
  <div>
    <BaseHeading v-if="data.date" size="s" :mb="0.5">
      {{ filters.date(data.date) }}
    </BaseHeading>
    <div :class="$style.heading">
      <BaseHeading v-if="data.title" size="xl" mr>
        {{ data.title }}
      </BaseHeading>
      <BaseLabel
        v-if="data.category"
        :color="data.category === 'new' ? 'success' : 'default'"
      >
        {{ $t(`change_logs.${data.category}`) }}
      </BaseLabel>
    </div>
    <div :class="$style.description" v-html="data.description" />
    <BaseButton
      v-if="data.helpPageUrl"
      :href="data.helpPageUrl"
      color="inverted"
      mt
    >
      {{ $t('new_features.button') }}
    </BaseButton>
    <BaseImage
      v-if="data.imagePublicId"
      :cloudinaryId="data.imagePublicId"
      mt
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>

<style lang="scss" module>
.description {
  a {
    color: $color-primary;

    @include hover {
      color: $color-primary;
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
}
</style>
