const TreatwellSettings = () => import('@/modules/treatwell/index.vue');
const ContractModal = () =>
  import('@/modules/treatwell/modals/ContractModal.vue');
const IbanModal = () => import('@/modules/treatwell/modals/IbanModal.vue');
const EnableFeedbackModal = () =>
  import('@/modules/treatwell/modals/EnableFeedbackModal.vue');
const PublishBatchModal = () =>
  import('@/modules/feedback/publish-batch/index.vue');
const CreateRosterModal = () =>
  import('@/modules/treatwell/modals/CreateRosterModal.vue');
const TreatwellLanding = () => import('@/modules/treatwell/landing/index.vue');
const SalonDescriptionModal = () =>
  import('@/modules/treatwell/modals/SalonDescriptionModal.vue');
const SalonAddressModal = () =>
  import('@/modules/treatwell/modals/SalonAddressModal.vue');
const LastMinuteDiscountModal = () =>
  import('@/modules/treatwell/modals/LastMinuteDiscountModal.vue');
const DisableLastMinuteDiscountModal = () =>
  import('@/modules/marketing/modals/DisableLastMinuteDiscount.vue');

import { redirectRoute } from '@/router/helpers';
import { useTreatwellStore } from '@/stores/treatwell';

export default [
  {
    path: 'treatwell',
    name: 'treatwell-admin',
    component: TreatwellSettings,
    children: [
      {
        path: 'sign-contract',
        name: 'treatwell-sign-contract',
        component: ContractModal,
        props: {
          parentRouteName: 'treatwell-admin'
        },
        beforeEnter: () => {
          const { treatwellStatus } = useTreatwellStore();

          if (treatwellStatus.contractSigned) {
            return redirectRoute({
              name: 'treatwell-admin'
            });
          }
        }
      },
      {
        path: 'provide-iban',
        name: 'treatwell-provide-iban',
        component: IbanModal
      },
      {
        path: 'salon-description',
        name: 'treatwell-salon-description',
        component: SalonDescriptionModal,
        props: {
          parentRouteName: 'treatwell-admin'
        }
      },
      {
        path: 'salon-last-minute-discount',
        name: 'treatwell-salon-last-minute-discount',
        component: LastMinuteDiscountModal
      },
      {
        path: 'disable-tw-last-minute-discount',
        name: 'treatwell-salon-disable-last-minute-discount',
        component: DisableLastMinuteDiscountModal,
        props: {
          parentRouteName: 'treatwell-admin'
        }
      },
      {
        path: 'salon-info',
        name: 'treatwell-salon-info',
        component: SalonAddressModal
      },
      {
        path: 'set-roster',
        name: 'treatwell-set-roster',
        component: CreateRosterModal
      },
      {
        path: 'enable-feedback',
        name: 'treatwell-enable-feedback',
        component: EnableFeedbackModal
      },
      {
        path: 'publish-batch',
        name: 'treatwell-publish-batch',
        component: PublishBatchModal,
        props: {
          parentRouteName: 'treatwell-admin'
        }
      }
    ],
    meta: {
      moduleName: 'treatwell-integration',
      featureFlag: 'module-treatwell'
    },
    beforeEnter: () => {
      const { hasDashboard } = useTreatwellStore();

      if (!hasDashboard) {
        return redirectRoute({
          name: 'treatwell-landing'
        });
      }
    }
  },
  {
    path: 'get-treatwell',
    name: 'treatwell-landing',
    component: TreatwellLanding,
    meta: {
      moduleName: 'treatwell-landing',
      featureFlag: 'module-treatwell'
    },
    beforeEnter: () => {
      const { hasDashboard } = useTreatwellStore();

      if (hasDashboard) {
        return redirectRoute({
          name: 'treatwell-admin'
        });
      }
    }
  }
];
