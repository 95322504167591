<template>
  <BaseForm fullHeight @submit="submit">
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <div :class="$style.header">
        <div :class="$style.locationSelect">
          <BaseDropdown
            v-if="multiLocation && order.permissions?.editOrderItems"
            v-model="order.locationId"
            :mr="$screen === 's' ? 0 : 1"
            :options="
              locations.map((location) => ({
                value: location.id,
                label: location.internalName
              }))
            "
            v-test="'register-order-location'"
          />
        </div>
        <div v-if="$screen !== 's'" :class="$style.customerSearch">
          <RegisterCustomerSearch />
        </div>
      </div>
      <OrderLoyaltyPoints
        v-if="isLoyaltyPointsEnabled && order.customerId"
        mt
        v-test="'register-order-loyalty-points'"
      />
      <div :class="$style.mainInner">
        <OrderItems />
        <Transactions />
        <OrderNote mb />
      </div>
      <Footer :savingOrder="savingOrder" />
    </div>
    <OpenDrawerModal
      v-if="showOpenDrawerModal"
      @close="showOpenDrawerModal = false"
      @success="sendPayload"
    />
    <CheckoutModal v-if="showCheckoutOverviewModal" />
  </BaseForm>
</template>

<script lang="ts" setup>
import OrderLoyaltyPoints from '../../order/OrderLoyaltyPoints.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useCompanyStore } from '@/stores/company';
import { ref, watch } from 'vue';
import { modal } from '@/helpers/ui';
import RegisterCustomerSearch from '../../order/RegisterCustomerSearch.vue';
import Footer from './Footer.vue';
import OpenDrawerModal from '@/modules/register/modals/open-drawer/index.vue';
import OrderNote from '../../order/OrderNote.vue';
import Transactions from './Transactions.vue';
import OrderItems from './OrderItems.vue';
import { useRegisterStore } from '@/modules/register/stores/register';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { storeToRefs } from 'pinia';
import CheckoutModal from './CheckoutModal.vue';
import { useI18n } from 'vue-i18n';
import { mutateOrder } from '../../actions/order';
import { useLocationsStore } from '@/stores/locations';

const { order } = useRegisterOrderStore();
const { selectedCustomer } = storeToRefs(useRegisterOrderStore());
const { isOpen: isDrawerOpen } = useRegisterDrawerStore();
const { cashupEnabled } = storeToRefs(useRegisterStore());
const { isLoyaltyPointsEnabled, multiLocation } = useCompanyStore();
const { locations } = useLocationsStore();
const showOpenDrawerModal = ref(false);
const { t } = useI18n();
const showCheckoutOverviewModal = ref(false);
const savingOrder = ref(false);

const mixpanel = inject<any>('mixpanel');

const submit = () => {
  if (savingOrder.value) {
    return;
  }

  mixpanel.track('Register - Save order clicked');
  if (cashupEnabled.value && !isDrawerOpen) {
    showOpenDrawerModal.value = true;
  } else {
    sendPayload();
  }
};

const { call: callOrderMutation, response: mutationResponse } = mutateOrder();

const sendPayload = () => {
  savingOrder.value = true;
  if (order.id && !order.originalOrderId) {
    order.draft = false;

    if (order.transactions.length > 0) {
      order.pending = false;
    }

    callOrderMutation('update', { withCustomerResponse: true });
  } else {
    callOrderMutation('create', { withCustomerResponse: true });
  }
};

watch(mutationResponse, (value) => {
  if (value) {
    if (value.updateOrder?.errors?.record_not_destroyed) {
      modal('warning', {
        message: t('register.items_cashup_error')
      });
    } else {
      handleResponse(value.createOrder || value.updateOrder);
    }
  }
});

const handleResponse = ({ order: savedOrder, errors }: any) => {
  if (errors) {
    handleErrors(errors);
    savingOrder.value = false;
  }

  if (savedOrder) {
    order.id = savedOrder.id;
    order.fiscalizationFinished = savedOrder.fiscalizationFinished;
    selectedCustomer.value = savedOrder.customer;
    showCheckoutOverviewModal.value = true;
  }
};

const handleErrors = (errors: any) => {
  if (errors.location_id) {
    modal('warning', {
      message: errors.location_id[0]
    });
    return;
  } else if (errors['items.prepaid_card']) {
    modal('warning', {
      message: t('prepaid_card.no_funds')
    });
    return;
  } else if (errors['items.appointment_part']) {
    modal('warning', {
      message: t('register.appointment_paid')
    });
    return;
  } else if (errors['items.base']) {
    modal('warning', {
      message: t('register.items_cashup_error')
    });
    return;
  } else {
    modal('warning', {
      message: t('register.general_error')
    });
  }
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.locationSelect {
  .base.smallScreen & {
    width: 100%;
  }
}

.customerSearch {
  flex-grow: 1;
}

.mainInner {
  overflow-y: auto;
  margin-top: $spacing;
  flex-grow: 1;
}
</style>
