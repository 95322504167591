<template>
  <div :class="$style.base" v-test="'register-order-appointment'">
    <div>
      <BaseText
        v-if="appointment.customerName || appointment.customer"
        bold
        v-test="'register-order-appointment-customer'"
      >
        {{ appointment.customerName || appointment.customer?.fullName }}
      </BaseText>
      <BaseText v-test="'register-order-appointment-services'">{{
        appointment.serviceNames
      }}</BaseText>
    </div>
    <div :class="$style.actions">
      <BaseText v-test="'register-order-appointment-date'">{{
        filters.dateTime(appointment.startAt)
      }}</BaseText>
      <BaseButton
        ml
        :mr="0.5"
        icon="delete"
        color="inverted"
        @click="deleteOrderAppointment(appointment.id)"
        v-test="'register-order-appointment-delete'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import type { Appointment } from '@/types';
import { useRegisterOrderStore } from '@/modules/register/stores/order';

const { removeOrderAppointment } = useRegisterOrderStore();
const mixpanel = inject<any>('mixpanel');

const deleteOrderAppointment = (id: number) => {
  mixpanel.track('Register - Disconnect associated appointment');
  removeOrderAppointment(id);
};

defineProps<{
  appointment: Appointment;
}>();
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
}

.actions {
  display: flex;
  align-items: center;
}
</style>
