<template>
  <div>
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <BaseSpinner v-if="loading" inline mb />
      <div v-else :class="$style.cardsGrid">
        <UserAccountCard
          v-for="user in userAccounts"
          :id="user.id"
          :key="user.id"
          :canDelete="!currentUser?.owner || user.id !== currentUser.id"
          :name="`${user.firstName} ${user.lastName}`"
          :email="user.email"
          :role="
            $t(
              `admin.user_accounts.modal.permissions.${getUserRoleFromPermissions(user)}.title`
            )
          "
          @edit="handleAccountEdit"
          @delete="handleAccountDelete"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import UserAccountCard from './UserAccountCard.vue';
import { injectUserAccounts, useUserAccounts } from './useUserAccounts';
import router from '@/router';
import { getUserRoleFromPermissions } from './helpers';
import { useI18n } from 'vue-i18n';
import { flash, modal } from '@/helpers/ui';
import { useUserAccount } from './useUserAccount';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();
const { loading, refetch } = useUserAccounts();
const { deleteUser } = useUserAccount();
const { userAccounts } = injectUserAccounts();

const { user: currentUser } = useUserStore();

const handleAccountEdit = (id: string) => {
  router.push({ name: 'admin-account-edit', params: { accountId: id } });
};

const handleAccountDelete = ({ id, name }: { id: string; name: string }) => {
  modal('confirmation', {
    message: t('admin.user_accounts.user_delete_confimation_message', {
      user: name
    })
  }).then(() => {
    deleteUser({
      input: {
        id
      }
    }).then(() => {
      flash(
        t('admin.user_accounts.user_delete_success_message', { user: name })
      );
      refetch();
    });
  });
};
</script>

<style lang="scss" module>
.cardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: $spacing;
}
</style>
