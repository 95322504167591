<template>
  <CreatePrepaidcard v-if="newDesignEnabled" />
  <CreatePrepaidcardOld v-else />
</template>

<script setup lang="ts">
import CreatePrepaidcard from '@/modules/register/builder/browser/pages/PrepaidCard.vue';
import CreatePrepaidcardOld from '@/modules/register/modals/create-prepaid-card/index.vue';

const newDesignEnabled = inject<boolean>('newDesignEnabled');
</script>
